import palette from './palette';

export default {
	h1: {
		color: palette.text.primary,
		fontWeight: 700,
		fontSize: '50px',
		lineHeight: '57px',
		letterSpacing: '-1px',
		font: 'Bold 50px/57px Ubuntu',
		opacity: 1
	},
	h2: {
		color: palette.text.primary,
		fontSize: 32,
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '50px'
	},
	h3: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '24px',
		letterSpacing: '-0.06px',
		lineHeight: '28px',
		fontFamily: 'Bebas Neue'
	},
	h4: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '20px',
		letterSpacing: '-0.06px',
		lineHeight: '24px',
		fontFamily: 'Bebas Neue'
	},
	h5: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '16px',
		letterSpacing: '-0.05px',
		lineHeight: '20px',
		fontFamily: 'Bebas Neue'
	},
	h6: {
		color: palette.text.primary,
		fontWeight: 500,
		fontSize: '14px',
		letterSpacing: '-0.05px',
		lineHeight: '20px',
		fontFamily: 'Bebas Neue'
	},
	subtitle1: {
		color: palette.text.primary,
		fontSize: '16px',
		letterSpacing: '-0.05px',
		lineHeight: '25px',
		fontFamily: 'Bebas Neue'
	},
	subtitle2: {
		color: palette.text.secondary,
		fontWeight: 400,
		fontSize: '14px',
		letterSpacing: '-0.05px',
		lineHeight: '21px',
		fontFamily: 'Bebas Neue'
	},
	body1: {
		color: palette.text.primary,
		fontSize: '14px',
		letterSpacing: '-0.05px',
		lineHeight: '21px',
		fontFamily: 'Ubuntu'
	},
	body2: {
		color: palette.text.secondary,
		fontSize: '12px',
		letterSpacing: '-0.04px',
		lineHeight: '18px',
		fontFamily: 'Ubuntu'
	},
	/* button: {
        color: palette.text.primary,
        fontSize: "14px",
        fontFamily: "Ubuntu",
    }, */
	caption: {
		color: palette.text.secondary,
		fontSize: '11px',
		letterSpacing: '0.33px',
		lineHeight: '13px',
		fontFamily: 'Ubuntu'
	},
	overline: {
		color: palette.text.secondary,
		fontSize: '11px',
		fontWeight: 500,
		letterSpacing: '0.33px',
		lineHeight: '13px',
		textTransform: 'uppercase',
		fontFamily: 'Ubuntu'
	},
	paragraph: {
		color: palette.text.primary,
		fontSize: 14,
		fontFamily: 'Ubuntu',
		fontWeight: '600',
		lineHeight: '24px',
		opacity: 1
	},
	cardBody: {
		color: palette.text.primary,
		fontSize: 13,
		fontFamily: 'Ubuntu',
		lineHeight: '20px',
		letterSpacing: '-0.26px'
	},
	button: {
		color: '#FAFAF5',
		fontFamily: 'Bebas Neue',
		textTransform: 'uppercase',
		fontWeight: 700,
		paddingTop: 5,
		fontSize: '18px',
		// lineHeight: "18px",
		// letterSpacing: "0.18px",
		textAlign: 'left'
		// height: "24px",
	}
};
