import React, { useState, useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Typography
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FileResults } from '../../views/Files/File/components/Results';
import { getFiles } from '../../store/file/actions';
import { Dropzone } from '../../views/Files/File/components/Dropzone';
import { FILE_TYPE } from '../../store/types';

const useStyles = makeStyles(() => ({
	btnContainer:{
		display: 'flex',
		flexDirection: 'row'
	}
}));

function FileSelector(props) {
	const { t, setFileId } = props;
	const [open, setOpen] = useState(false);
	const [file, setFile] = useState(props.file ? props.file : null);
	const files = useSelector((state) => state.file.files);
	const classes = useStyles();

	useEffect(() => {
		fileList();
	}, []);

	const fileList = () => {
		if (files && files.meta) {
		} else {
			getFiles({ limit: 18, page: 1 });
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const selectItem = (file) => {
		setFile(file);
		setOpen(false);
		setFileId(file.id);
	};

	return (
		<>
			<div className={classes.btnContainer}>
			<Button variant='outlined' color='primary' onClick={handleClickOpen}>
				{t('selectFile')}
			</Button>
			<Dropzone type={FILE_TYPE.ATTACHMENT} selected = {(id) => {
				selectItem(id)
			}}/>
			</div>
			{file && (
				<Typography>
					{t('selectedFile')}:{' '}
					{file.url.substring(file.url.lastIndexOf('/') + 1, file.url.length)}{' '}
				</Typography>
			)}
			<Dialog
				fullWidth
				maxWidth='lg'
				open={open}
				onClose={handleClose}
				aria-labelledby='max-width-dialog-title'>
				<DialogTitle id='max-width-dialog-title'>
					{t('dialogtitle')}
				</DialogTitle>
				<DialogContent>
					<FileResults selector selectItem={selectItem} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						{t('close')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default withNamespaces('fileSelector')(FileSelector);
