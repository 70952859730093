import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getNews } from '../../../../../../store/news/element/actions';

function Header(props) {
	const { match } = props;
	useEffect(() => {
		getNews(match.params.id);
	}, []);

	// getAllNews();

	return <></>;
}
export default withNamespaces('news')(withRouter(Header));
