import React from 'react';
import { Icon, withWidth } from '@material-ui/core';
// viewbox sm or xs set by Nora : "500 -100 1000 276"jocc "625 -50 570 438"

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

function Village(props) {
	return (
		<svg
			// Todo make this SVG responsive over md(1200px wide)
			xmlns='http://www.w3.org/2000/svg'
			width='100%'
			height={
				props.width === 'md'
					? 275
					: props.width === 'sm' || props.width === 'xs'
					? '45.07vw'
					: 375
			}
			viewBox={
				props.width === 'sm' || props.width === 'xs'
					? '625 0 810 375'
					: props.width === 'md'
					? '0 0 1921.414 276'
					: '0 0 1921.414 376'
			}>
			<g transform='translate(-30.317 -197.713)'>
				<g transform='translate(909.781 515.615)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M446.969,336.068a8.616,8.616,0,0,0-12.166,0h0a12.826,12.826,0,0,1-18.121,0h0a8.619,8.619,0,0,0-12.169,0h0a12.826,12.826,0,0,1-18.121,0'
						transform='translate(-386.393 -316.838)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M446.969,332.683h0a8.616,8.616,0,0,0-12.166,0h0a12.826,12.826,0,0,1-18.121,0h0a8.619,8.619,0,0,0-12.169,0h0a12.826,12.826,0,0,1-18.121,0'
						transform='translate(-386.393 -321.81)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M446.969,329.3a8.611,8.611,0,0,0-12.166,0h0a12.827,12.827,0,0,1-18.121,0h0a8.614,8.614,0,0,0-12.169,0h0a12.826,12.826,0,0,1-18.121,0'
						transform='translate(-386.393 -326.783)'
					/>
				</g>
				<path
					className='b'
					fill='#e60f37'
					d='M430.815,398.035a62.339,62.339,0,0,1,124.679.047l38.8.015a101.139,101.139,0,0,0-202.278-.077Z'
					transform='translate(531.671 145.218)'
				/>
				<g transform='translate(1106.304 198.213)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M467.837,293.013V273.948h51.538V297.5'
						transform='translate(-462.965 -86.979)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M511.652,337.8l-20.978-127.78L469.828,337.8'
						transform='translate(-460.034 -180.87)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M494.252,329.152,484.228,210.021,474.2,329.152'
						transform='translate(-453.588 -180.87)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M519.374,284.523v19.2H467.837v-19.2l4.923-4.484,4.718,4.484,16.128-35.174,16.128,35.174,4.85-4.484Z'
						transform='translate(-462.965 -123.107)'
					/>
					<g transform='translate(21.34 166.124)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='10.189'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='10.189'
							transform='translate(8.703)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='10.189'
							transform='translate(17.407)'
						/>
					</g>
					<ellipse
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						cx='4.134'
						cy='4.808'
						rx='4.134'
						ry='4.808'
						transform='translate(26.506 146.081)'
					/>
					<ellipse
						className='c'
						fill='#070c35'
						cx='2.733'
						cy='3.178'
						rx='2.733'
						ry='3.178'
						transform='translate(27.973 21.723)'
					/>
					<rect
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						width='60.123'
						height='6.356'
						transform='translate(0 180.613)'
					/>
					<g transform='translate(24.261)'>
						<line
							className='d'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							fill='#fff'
							y2='23.803'
							transform='translate(6.379)'
						/>
						<line
							className='d'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							fill='#fff'
							x1='12.757'
							transform='translate(0 9.66)'
						/>
					</g>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M399.593,307.7s71.172-40.8,158.789-10.494'
					transform='translate(542.829 128.945)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M59.323,322.5s75.176-57.546,166.469,5.666'
					transform='translate(41.679 147.334)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M294.488,382.166s43.17-72.427,124.246-92.276c52.176-12.776,113.64-4.513,153.7,35.782'
					transform='translate(388.03 126.508)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M311.861,342.537c20.927-20.587,51.988-43.657,92.525-53.581'
					transform='translate(413.617 133.276)'
				/>
				<g transform='translate(180.823 473.361)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
				</g>
				<g transform='translate(102.948 487.247)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
				</g>
				<g transform='translate(483.262 508.675)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
				</g>
				<g transform='translate(489.215 558.419)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
				</g>
				<g transform='translate(348.286 484.095)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
				</g>
				<g transform='translate(693.855 564.659)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
				</g>
				<g transform='translate(936.468 552.872)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
				</g>
				<g transform='translate(1057.932 426.447)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
				</g>
				<g transform='translate(970.358 515.609)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M580.45,336.073a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.829,12.829,0,0,1-18.123,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.015-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0h0'
						transform='translate(-410.89 -316.837)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M580.45,332.688a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.829,12.829,0,0,1-18.123,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.015-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0'
						transform='translate(-410.89 -321.808)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M580.45,329.3a12.827,12.827,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.827,12.827,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.829,12.829,0,0,1-18.123,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.827,12.827,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.827,12.827,0,0,1-18.121,0l-.015-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0h0'
						transform='translate(-410.89 -326.78)'
					/>
				</g>
				<g transform='translate(567.464 515.609)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M401.864,336.073a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.015-.012a8.592,8.592,0,0,0-12.139,0l-.015.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.015.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-9.9-1.61'
						transform='translate(-241.016 -316.837)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M404.4,332.688a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.015-.012a8.592,8.592,0,0,0-12.139,0l-.015.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.015.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.81,12.81,0,0,1-2.01,1.639'
						transform='translate(-243.553 -321.808)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M408.808,329.3a12.827,12.827,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.827,12.827,0,0,1-18.121,0l-.015-.012a8.592,8.592,0,0,0-12.139,0l-.015.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.015.012a12.827,12.827,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.787,12.787,0,0,1-9.409,3.743'
						transform='translate(-247.96 -326.78)'
					/>
				</g>
				<g transform='translate(31.024 515.436)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M180.512,334.535a8.6,8.6,0,0,0-10.2,1.457L170.3,336a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.015-.012a8.592,8.592,0,0,0-12.139,0l-.015.012A12.826,12.826,0,0,1,91.6,336l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.015.012a12.826,12.826,0,0,1-18.121,0h0'
						transform='translate(-31.024 -316.766)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M186.776,335.458a12.777,12.777,0,0,1-4.31-2.839l-.015-.015a8.6,8.6,0,0,0-12.139,0l-.015.015a12.831,12.831,0,0,1-18.121,0l-.012-.015a8.6,8.6,0,0,0-12.141,0l-.012.015a12.831,12.831,0,0,1-18.121,0l-.015-.015a8.6,8.6,0,0,0-12.139,0l-.015.015a12.831,12.831,0,0,1-18.121,0l-.012-.015a8.6,8.6,0,0,0-12.141,0l-.012.015a12.831,12.831,0,0,1-18.121,0L61.3,332.6a8.6,8.6,0,0,0-12.141,0l-.015.015a12.831,12.831,0,0,1-18.121,0'
						transform='translate(-31.024 -321.737)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M200.587,329.233a12.826,12.826,0,0,1-18.121,0l-.015-.012a8.592,8.592,0,0,0-12.139,0l-.015.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.015-.012a8.592,8.592,0,0,0-12.139,0l-.015.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.012.012a12.826,12.826,0,0,1-18.121,0l-.012-.012a8.6,8.6,0,0,0-12.141,0l-.015.012a12.826,12.826,0,0,1-18.121,0h0'
						transform='translate(-31.024 -326.71)'
					/>
				</g>
				<g transform='translate(726.906 440.965)'>
					<g transform='translate(121.452 73.881)'>
						<rect
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							width='13.717'
							height='18.226'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='16.288'
							transform='translate(6.859 0.969)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x1='12.94'
							transform='translate(0.568 9.113)'
						/>
					</g>
					<g transform='translate(50.023 73.881)'>
						<rect
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							width='13.717'
							height='18.226'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='16.288'
							transform='translate(6.859 0.969)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x1='12.94'
							transform='translate(0.568 9.113)'
						/>
					</g>
					<g transform='translate(14.489 73.881)'>
						<rect
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							width='13.717'
							height='18.226'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='16.288'
							transform='translate(6.859 0.969)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x1='12.94'
							transform='translate(0.568 9.113)'
						/>
					</g>
					<g transform='translate(156.986 73.881)'>
						<rect
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							width='13.717'
							height='18.226'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='16.288'
							transform='translate(6.859 0.969)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x1='12.94'
							transform='translate(0.568 9.113)'
						/>
					</g>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M354.6,363.44V340.816h-6V363.44'
						transform='translate(-259.182 -231.522)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='3.88'
						transform='translate(90.625 115.117)'
					/>
					<g transform='translate(83.976 102.736)'>
						<path
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							d='M346.4,367.626V342s2.312-3.836,8.257-3.836A10.812,10.812,0,0,1,363.329,342v25.63'
							transform='translate(-346.398 -338.159)'
						/>
					</g>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M313.617,323.075v66.751H491.843V323.075'
						transform='translate(-310.702 -257.578)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='13.976'
						transform='translate(72.629 25.524)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M372.7,306.883H344.585l-32.146,33.242H497.27l-32.146-33.242H411.3'
						transform='translate(-312.438 -281.359)'
					/>
					<g transform='translate(30.411 37.207)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x2='17.797'
							transform='translate(92.725 1.799)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x2='22.743'
							transform='translate(52.673 13.013)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x2='17.797'
							transform='translate(103.104 8.076)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x2='22.743'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x2='22.743'
							transform='translate(10.378 6.277)'
						/>
					</g>
					<path
						className='e'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						d='M364.555,299.565'
						transform='translate(-235.68 -292.107)'
					/>
					<g transform='translate(148.665 10.393)'>
						<path
							className='c'
							fill='#070c35'
							d='M384.912,301.749H373.055a.5.5,0,0,1,0-.995h11.857a.5.5,0,0,1,0,.995Z'
							transform='translate(-372.558 -300.754)'
						/>
					</g>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='13.973'
						transform='translate(85.747 28.566)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M362.491,305.259H346.853a.209.209,0,0,1-.151-.356l8.1-8.359,7.849,8.362A.211.211,0,0,1,362.491,305.259Z'
						transform='translate(-262.063 -296.544)'
					/>
					<g transform='translate(86.605 9.211)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='18.858'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='18.858'
							transform='translate(12.259)'
						/>
					</g>
					<g transform='translate(148.595 10.891)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x2='12.852'
						/>
						<g transform='translate(1.636 0.497)'>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								y2='13.921'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								y2='21.327'
								transform='translate(9.786)'
							/>
						</g>
					</g>
					<line
						className='f'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						fill='#070c35'
						strokeDasharray='0.1 2'
						y2='8.593'
						transform='translate(92.734 13.458)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M312.94,320.348l1.557,6.731h178.64l2.162-6.731'
						transform='translate(-311.699 -261.582)'
					/>
					<g transform='translate(60.264 10.891)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x2='12.852'
							transform='translate(0 19.852)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x2='12.852'
						/>
						<g transform='translate(1.636 0.497)'>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								y2='18.858'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								y2='18.858'
								transform='translate(9.786)'
							/>
						</g>
					</g>
					<g transform='translate(85.557 73.881)'>
						<rect
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							width='13.717'
							height='18.226'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='16.288'
							transform='translate(6.859 0.969)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x1='12.94'
							transform='translate(0.568 9.113)'
						/>
					</g>
					<g transform='translate(14.489 106.316)'>
						<g transform='translate(106.963)'>
							<rect
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								width='13.717'
								height='18.226'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								y2='16.288'
								transform='translate(6.859 0.969)'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								x1='12.94'
								transform='translate(0.568 9.113)'
							/>
						</g>
						<g transform='translate(142.497)'>
							<rect
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								width='13.717'
								height='18.226'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								y2='16.288'
								transform='translate(6.859 0.969)'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								x1='12.94'
								transform='translate(0.568 9.113)'
							/>
						</g>
						<g transform='translate(35.534)'>
							<rect
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								width='13.717'
								height='18.226'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								y2='16.288'
								transform='translate(6.859 0.969)'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeMiterlimit='10'
								strokeLinecap='round'
								x1='12.94'
								transform='translate(0.568 9.113)'
							/>
						</g>
						<rect
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							width='13.717'
							height='18.226'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							y2='16.288'
							transform='translate(6.859 0.969)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeMiterlimit='10'
							strokeLinecap='round'
							x1='12.94'
							transform='translate(0.568 9.113)'
						/>
					</g>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M267.252,284.627h-.015c0-.052.015-.1.015-.153a7.722,7.722,0,0,0-15.3-1.493,14.918,14.918,0,1,0-2.451,27.035,18.908,18.908,0,1,0,17.747-25.388Z'
					transform='translate(292.541 115.366)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M495.79,225.2a26.933,26.933,0,0,0-8.86,1.518,34.1,34.1,0,0,0-64.407,1.111,10.737,10.737,0,0,0-1.5-.153,10.849,10.849,0,1,0,0,21.7,10.541,10.541,0,0,0,1.565-.158,34.1,34.1,0,0,0,32.344,23.277h.027c0,.094-.027.18-.027.277a13.923,13.923,0,0,0,27.579,2.693A26.9,26.9,0,1,0,495.79,225.2Z'
					transform='translate(558.388 9.051)'
				/>
				<g transform='translate(1748.783 553.453)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(16.257)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(0 11.214)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(23.675 6.277)'
					/>
				</g>
				<g transform='translate(89.17 553.453)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(16.257)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(0 11.214)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(23.675 6.277)'
					/>
				</g>
				<g transform='translate(346.665 553.453)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(16.257)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(0 11.214)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(23.675 6.277)'
					/>
				</g>
				<g transform='translate(1347.843 552.827)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(16.257)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(0 11.214)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(23.675 6.277)'
					/>
				</g>
				<g transform='translate(1001.851 552.971)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(16.257)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(0 11.214)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='16.257'
						transform='translate(23.675 6.277)'
					/>
				</g>
				<g transform='translate(761.9 283.401)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M326.59,239.254s23.85,36.389,84.008,10.7'
						transform='translate(-326.59 -223.123)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M329.381,237.773s15.772,26.45,75.93.763'
						transform='translate(-322.479 -225.299)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M332.012,240.385s14.4,22.725,72.555-7.665'
						transform='translate(-318.604 -232.72)'
					/>
				</g>
				<g transform='translate(118.635 231.771)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M126.953,216.468s-17.015,25.961-59.936,7.636'
						transform='translate(-65.625 -204.959)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M121.529,215.412s-11.254,18.868-54.172.543'
						transform='translate(-65.124 -206.51)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M118.215,217.274s-10.272,16.215-51.761-5.468'
						transform='translate(-66.454 -211.806)'
					/>
				</g>
				<g transform='translate(1101.154 401.347)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M510.6,290.289h-.02c0-.064.02-.126.02-.19a9.616,9.616,0,0,0-19.048-1.861A18.579,18.579,0,1,0,488.5,321.9a23.544,23.544,0,1,0,22.1-31.614Z'
						transform='translate(-463.784 -280.496)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M478.212,308.628a11.572,11.572,0,1,1,0-23.144'
						transform='translate(-459.609 -273.17)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M482.921,287.3a16.525,16.525,0,0,1-.2,33.049'
						transform='translate(-435.898 -270.506)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='34.857'
						transform='translate(42.169 62.176)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='18.605'
						transform='translate(52.499 62.154)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='33.268'
						transform='translate(24.811 50.333)'
					/>
				</g>
				<g transform='translate(1520.774 473.808)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M657.041,319.639l.02,0c0-.067-.02-.126-.02-.193a9.616,9.616,0,0,1,19.048-1.859,18.577,18.577,0,1,1,3.051,33.663,23.544,23.544,0,1,1-22.1-31.614Z'
						transform='translate(-633.478 -309.848)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M654.418,337.98a11.572,11.572,0,1,0,0-23.144'
						transform='translate(-602.638 -302.522)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M652.663,316.649a16.526,16.526,0,0,0,.2,33.051'
						transform='translate(-629.303 -299.859)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='16.113'
						transform='translate(28.214 62.175)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='11.198'
						transform='translate(17.884 62.152)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='25.862'
						transform='translate(45.572 50.332)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M491.807,387.47c-42.174-44.289-111.034-88.9-195.972-88.444-80.9.437-161.845,44.155-204.019,88.444'
					transform='translate(89.535 148.061)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M328.617,356.868c-40.863-30.385-94.659-54.452-157.161-54.114'
					transform='translate(206.829 153.536)'
				/>
				<g transform='translate(250.103 471.369)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='35.687'
						y2='35.628'
						transform='translate(28.436)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='32.504'
						y2='32.451'
						transform='translate(21.091 3.177)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='29.203'
						y2='29.155'
						transform='translate(13.864 6.473)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='25.64'
						y2='25.598'
						transform='translate(6.899 10.03)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='22.012'
						y2='21.976'
						transform='translate(0 13.652)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M535.406,337.1c42.174-44.286,111.578-64.248,174.978-64.248,65.737,0,131.511,29.158,176.482,63.851'
					transform='translate(742.855 109.621)'
				/>
				<g transform='translate(1524.382 409.182)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='33.253'
						y2='33.198'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='30.536'
						y2='30.485'
						transform='translate(10.528 2.713)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='27.042'
						y2='26.998'
						transform='translate(21.055 6.2)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='22.69'
						y2='22.652'
						transform='translate(31.583 10.546)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='20.406'
						y2='20.372'
						transform='translate(42.111 12.826)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M562.7,299.114c35.564-15.8,76.417-23.211,115.04-23.211'
					transform='translate(783.051 114.105)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M702.424,316.02c35.566-15.8,76.417-23.213,115.04-23.213'
					transform='translate(988.839 138.932)'
				/>
				<g transform='translate(953.577 421.689)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='13.394'
						y2='13.372'
						transform='translate(30.788)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='13.412'
						y2='13.39'
						transform='translate(22.614 2.35)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='14.225'
						y2='14.202'
						transform='translate(14.419 4.679)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='14.324'
						y2='14.301'
						transform='translate(6.89 7.672)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='14.416'
						y2='14.392'
						transform='translate(0 11.304)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M162.149,294.316a9.35,9.35,0,0,0-3.964.886,11.878,11.878,0,0,0-19.572-5.182,12.768,12.768,0,1,0-.8,16.328,11.85,11.85,0,0,0,16.061,1.778,9.388,9.388,0,1,0,8.276-13.81Z'
					transform='translate(124.617 127.378)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					x2='3.256'
					y2='27.147'
					transform='translate(252.306 439.413)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					x1='1.222'
					y2='27.144'
					transform='translate(282.968 440.357)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					y2='34.218'
					transform='translate(269.916 439.581)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M349.072,231.093a12.149,12.149,0,0,0-1.625.163A17.56,17.56,0,0,0,318.3,227.02a12.411,12.411,0,1,0,0,23.152,17.574,17.574,0,0,0,23.032,2.972,12.4,12.4,0,1,0,7.74-22.051Z'
					transform='translate(398.328 33.531)'
				/>
				<g transform='translate(130.46 341.273)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='56.703'
						transform='translate(22.132 46.436)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M84.241,256.162s9.918,36.572,0,46.436c-8.155-5.774-6.709-30.558-6.709-30.558'
						transform='translate(-62.109 -256.162)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M102.564,282.247s.722,16.239-9.2,26.1c-17.794-7.579-22.132-40.3-22.132-40.3'
						transform='translate(-71.236 -238.699)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M74.841,268.734s5.265,18.96,2.752,29.81'
						transform='translate(-65.927 -237.697)'
					/>
				</g>
				<g transform='translate(208.36 527.767)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M105.739,335.018'
						transform='translate(-98.32 -326.839)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='15.163'
						y2='13.029'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='15.163'
						y2='13.029'
						transform='translate(7.745)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='15.163'
						y2='13.029'
						transform='translate(15.49)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='15.163'
						y2='13.029'
						transform='translate(23.235)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='15.163'
						y2='13.029'
						transform='translate(30.98)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='15.163'
						y2='13.029'
						transform='translate(38.725)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='15.163'
						y2='13.029'
						transform='translate(46.47)'
					/>
				</g>
				<g transform='translate(1191.513 317.285)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M573.065,302.009s-25.611,22.974-36.37,22.974-36.37-22.974-36.37-22.974'
						transform='translate(-500.325 -164.838)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M501.257,298.968s23.986,21.517,34.065,21.517,34.063-21.517,34.063-21.517'
						transform='translate(-498.952 -169.305)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M502.19,295.926s22.362,20.061,31.758,20.061S565.7,295.926,565.7,295.926'
						transform='translate(-497.578 -173.772)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M503.123,292.884s20.737,18.6,29.451,18.6,29.451-18.6,29.451-18.6'
						transform='translate(-496.204 -178.24)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M504.056,289.843s19.112,17.148,27.144,17.148,27.144-17.148,27.144-17.148'
						transform='translate(-494.83 -182.707)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M504.989,286.8s17.488,15.689,24.837,15.689S554.663,286.8,554.663,286.8'
						transform='translate(-493.456 -187.173)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M505.921,283.76s15.865,14.232,22.532,14.232,22.532-14.232,22.532-14.232'
						transform='translate(-492.083 -191.641)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M506.854,280.719s14.241,12.776,20.225,12.776S547.3,280.719,547.3,280.719'
						transform='translate(-490.709 -196.107)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M507.786,277.677S520.4,289,525.706,289s17.918-11.319,17.918-11.319'
						transform='translate(-489.336 -200.575)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M508.719,274.636s10.994,9.86,15.613,9.86,15.611-9.86,15.611-9.86'
						transform='translate(-487.962 -205.041)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M509.652,271.594S519.021,280,522.958,280s13.306-8.406,13.306-8.406'
						transform='translate(-486.588 -209.509)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M510.738,268.65s7.478,6.708,10.621,6.708,10.621-6.708,10.621-6.708'
						transform='translate(-484.989 -213.833)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M511.518,265.511s6.12,5.49,8.692,5.49,8.692-5.49,8.692-5.49'
						transform='translate(-483.84 -218.443)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M512.378,262.424s4.622,4.147,6.565,4.147,6.565-4.147,6.565-4.147'
						transform='translate(-482.573 -222.977)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M513.383,259.428s2.873,2.577,4.08,2.577,4.08-2.577,4.08-2.577'
						transform='translate(-481.093 -227.377)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M514.316,256.387s1.249,1.118,1.773,1.118,1.773-1.118,1.773-1.118'
						transform='translate(-479.719 -231.844)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='174.364'
						transform='translate(36.37)'
					/>
				</g>
				<g transform='translate(335.787 342.305)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M154.27,290.854s14.241,12.773,20.225,12.773,20.223-12.773,20.223-12.773'
						transform='translate(-154.27 -206.241)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M155.2,287.812s12.619,11.319,17.92,11.319,17.918-11.319,17.918-11.319'
						transform='translate(-152.897 -210.709)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M156.135,284.77s10.994,9.863,15.613,9.863,15.611-9.863,15.611-9.863'
						transform='translate(-151.523 -215.177)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M157.068,281.729s9.369,8.4,13.306,8.4,13.3-8.4,13.3-8.4'
						transform='translate(-150.149 -219.643)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M158.154,278.784s7.478,6.71,10.621,6.71,10.618-6.71,10.618-6.71'
						transform='translate(-148.55 -223.968)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M158.934,275.646s6.12,5.49,8.692,5.49,8.692-5.49,8.692-5.49'
						transform='translate(-147.401 -228.577)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M159.794,272.558s4.622,4.147,6.565,4.147,6.565-4.147,6.565-4.147'
						transform='translate(-146.134 -233.113)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M160.8,269.563s2.873,2.577,4.08,2.577,4.08-2.577,4.08-2.577'
						transform='translate(-144.654 -237.511)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M161.732,266.521s1.249,1.121,1.773,1.121,1.773-1.121,1.773-1.121'
						transform='translate(-143.28 -241.979)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='118.569'
						transform='translate(20.224)'
					/>
				</g>
				<g transform='translate(44.832 464.559)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='4.946'
						y2='28.093'
						transform='translate(3.834 12.344)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='1.543'
						y2='40.436'
						transform='translate(11.109)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='33.03'
						transform='translate(16.524 7.406)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='3.091'
						y2='15.455'
						transform='translate(20.954 24.982)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='4.968'
						y2='13.091'
						transform='translate(0 27.345)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M542.547,364.22c36.068-13.064,158.071-66.532,304.662-62.281,80.861,2.343,157.943,37.312,200.116,81.6'
					transform='translate(753.372 151.992)'
				/>
				<g transform='translate(1789.319 473.361)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
				</g>
				<g transform='translate(1851.416 492.52)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
				</g>
				<g transform='translate(1476.989 508.675)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
				</g>
				<g transform='translate(1678.496 547.107)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
				</g>
				<g transform='translate(1173.195 488.336)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
				</g>
				<g transform='translate(1250.091 478.954)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='4.56'
						transform='translate(9.433 1.743)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='6.303'
						transform='translate(5.953)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='4.56'
						transform='translate(0 1.743)'
					/>
				</g>
				<g transform='translate(1781.461 515.609)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M747.018,334.607a8.6,8.6,0,0,1,10.2,1.454l.012.012a12.826,12.826,0,0,0,18.121,0l.012-.012a8.6,8.6,0,0,1,12.142,0l.012.012a12.826,12.826,0,0,0,18.121,0l.015-.012a8.592,8.592,0,0,1,12.139,0l.015.012a12.826,12.826,0,0,0,18.121,0l.012-.012a8.6,8.6,0,0,1,12.141,0l.012.012a12.826,12.826,0,0,0,18.121,0l.012-.012a8.6,8.6,0,0,1,12.142,0l.015.012a12.826,12.826,0,0,0,18.121,0h0'
						transform='translate(-726.944 -316.837)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M744.485,335.527a12.74,12.74,0,0,0,4.31-2.839l.015-.012a8.6,8.6,0,0,1,12.141,0l.012.012a12.826,12.826,0,0,0,18.121,0l.012-.012a8.6,8.6,0,0,1,12.142,0l.012.012a12.826,12.826,0,0,0,18.121,0l.015-.012a8.592,8.592,0,0,1,12.139,0l.015.012a12.826,12.826,0,0,0,18.121,0l.012-.012a8.6,8.6,0,0,1,12.141,0l.012.012a12.826,12.826,0,0,0,18.121,0l.012-.012a8.6,8.6,0,0,1,12.142,0l.015.012a12.826,12.826,0,0,0,18.121,0'
						transform='translate(-730.674 -321.808)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M738.9,329.3a12.826,12.826,0,0,0,18.121,0l.015-.012a8.6,8.6,0,0,1,12.141,0l.012.012a12.827,12.827,0,0,0,18.121,0l.012-.012a8.6,8.6,0,0,1,12.142,0l.012.012a12.827,12.827,0,0,0,18.121,0l.015-.012a8.592,8.592,0,0,1,12.139,0l.015.012a12.827,12.827,0,0,0,18.121,0l.012-.012a8.6,8.6,0,0,1,12.141,0l.012.012a12.827,12.827,0,0,0,18.121,0l.012-.012a8.6,8.6,0,0,1,12.142,0l.015.012a12.827,12.827,0,0,0,18.121,0h0'
						transform='translate(-738.9 -326.78)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M746.822,237.742h.015c0-.052-.015-.1-.015-.153a7.724,7.724,0,0,1,15.3-1.494,14.921,14.921,0,1,1,2.45,27.035,18.908,18.908,0,1,1-17.75-25.388Z'
					transform='translate(1026.361 46.509)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M747.177,248.777s17.015,25.961,59.936,7.636'
					transform='translate(1054.752 74.264)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M749.168,247.721s11.254,18.868,54.172.543'
					transform='translate(1057.684 72.714)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M751.045,249.586s10.275,16.212,51.763-5.471'
					transform='translate(1060.448 67.417)'
				/>
				<g transform='translate(1667.822 473.799)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='33.253'
						y2='33.198'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='30.536'
						y2='30.485'
						transform='translate(10.528 2.713)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='27.042'
						y2='26.998'
						transform='translate(21.055 6.2)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='22.69'
						y2='22.652'
						transform='translate(31.583 10.546)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='20.406'
						y2='20.372'
						transform='translate(42.111 12.826)'
					/>
				</g>
				<g transform='translate(1720.136 338.439)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='56.703'
						transform='translate(9.206 46.435)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M720.448,255.014s-9.918,36.569,0,46.434c8.158-5.772,6.711-30.555,6.711-30.555'
						transform='translate(-711.243 -255.014)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M714.112,281.1s-.724,16.239,9.194,26.1c17.8-7.579,22.134-40.3,22.134-40.3'
						transform='translate(-714.1 -237.551)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M725.211,267.586s-5.262,18.96-2.752,29.81'
						transform='translate(-702.789 -236.549)'
					/>
				</g>
				<g transform='translate(1639.945 389.467)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='43.702'
						transform='translate(22.257 25.996)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M696.082,275.684s4.246,18.394-3.4,26c-6.286-4.449-1.872-19.8-1.872-19.8'
						transform='translate(-670.426 -275.684)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M718.9,294.047s-5.316,14.489-18.071,17.9c-13.717-5.843-17.06-31.061-17.06-31.061'
						transform='translate(-678.575 -268.051)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M728.427,299.247s-10.329,14.425-24.5,14.425c-17.06-2.479-22.258-25.262-22.258-25.262'
						transform='translate(-681.671 -256.995)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M686.552,281.407s4.058,14.612,2.122,22.974'
						transform='translate(-674.482 -267.279)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M696.977,284.645s-4.9,5.925-.1,13.556'
						transform='translate(-662.378 -262.523)'
					/>
				</g>
				<g transform='translate(1303.145 397.641)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M585.917,313.269s-14.241,12.773-20.223,12.773-20.225-12.773-20.225-12.773'
						transform='translate(-545.469 -228.656)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M582.239,310.227s-12.616,11.319-17.918,11.319-17.92-11.319-17.92-11.319'
						transform='translate(-544.096 -233.124)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M578.558,307.185s-10.992,9.863-15.611,9.863-15.613-9.863-15.613-9.863'
						transform='translate(-542.722 -237.592)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M574.879,304.144s-9.37,8.4-13.306,8.4-13.306-8.4-13.306-8.4'
						transform='translate(-541.348 -242.058)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M570.594,301.2s-7.48,6.71-10.621,6.71-10.621-6.71-10.621-6.71'
						transform='translate(-539.749 -246.383)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M567.517,298.061s-6.12,5.49-8.692,5.49-8.692-5.49-8.692-5.49'
						transform='translate(-538.6 -250.992)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M564.124,294.973s-4.624,4.147-6.565,4.147-6.565-4.147-6.565-4.147'
						transform='translate(-537.333 -255.528)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M560.158,291.978s-2.873,2.577-4.08,2.577-4.08-2.577-4.08-2.577'
						transform='translate(-535.853 -259.926)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M556.477,288.936s-1.249,1.121-1.773,1.121-1.773-1.121-1.773-1.121'
						transform='translate(-534.479 -264.394)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='118.569'
						transform='translate(20.225)'
					/>
				</g>
				<g transform='translate(1824.311 459.445)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x1='2.473'
						y2='37.967'
						transform='translate(10.218 2.469)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='40.436'
						transform='translate(6.345)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.473'
						y2='37.967'
						transform='translate(0 2.469)'
					/>
				</g>
				<g transform='translate(910.607 319.413)'>
					<path
						className='b'
						fill='#e60f37'
						d='M417.877,309.5a31.1,31.1,0,1,0-31.15-31.1A31.161,31.161,0,0,0,417.877,309.5Z'
						transform='translate(-386.727 -247.307)'
					/>
				</g>
				<g transform='translate(1888.893 410.493)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M787.156,370.367S772.715,332.9,799.733,284.2'
						transform='translate(-780.938 -284.201)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M790.43,357.552s-12.062-27.694,6.946-64.626'
						transform='translate(-775.995 -271.386)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M793.974,350.094s-11.674-16.753,3.9-52.09'
						transform='translate(-771.406 -263.928)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='0.882'
						y2='8.24'
						transform='translate(11.177 5.583)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='0.593'
						y2='5.539'
						transform='translate(15.869 25.825)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='1.702'
						y2='15.906'
						transform='translate(5.966 9.92)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='4.472'
						y2='19.654'
						transform='translate(0 18.83)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='2.505'
						x2='3.956'
						transform='translate(13.468 8.087)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='2.067'
						x2='5.36'
						transform='translate(15.689 33.043)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='0.882'
						y2='8.24'
						transform='translate(19.481 41.903)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='2.067'
						x2='5.36'
						transform='translate(24.278 37.451)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='5.351'
						x2='6.681'
						transform='translate(9.339 15.458)'
					/>
				</g>
				<g transform='translate(612.054 432.971)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M270.376,376.324c-14.406-35.063,13.667-50.389,13.667-83.018'
						transform='translate(-265.592 -293.306)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M271.365,359.068c-3.727-22.6,11.916-28.225,14.441-50.74'
						transform='translate(-258.92 -271.243)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M274,353.688c0-16.015,23.964-24.048,16.234-41.381'
						transform='translate(-254.197 -265.399)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='5.469'
						y2='5.574'
						transform='translate(11.962 5.12)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='5.315'
						y2='3.938'
						transform='translate(31.755 49.985)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='4.129'
						y2='3.355'
						transform='translate(21.634 40.01)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='7.389'
						y2='7.554'
						transform='translate(8.097 9.882)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='3.603'
						x2='8.332'
						transform='translate(17.432 7.09)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='3.938'
						x2='5.414'
						transform='translate(37.566 49.62)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='4.097'
						y2='5.189'
						transform='translate(31.044 57.006)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='2.386'
						x2='6.258'
						transform='translate(35.142 59.809)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='2.639'
						y2='6.06'
						transform='translate(28.443 62.195)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='0.717'
						x2='6.66'
						transform='translate(31.082 67.538)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='2.067'
						x2='5.36'
						transform='translate(25.764 41.299)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='5.682'
						y2='4.617'
						transform='translate(18.251 45.646)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='4.51'
						transform='translate(23.933 50.263)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='5.269'
						y2='6.308'
						transform='translate(15.485 50.263)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='4.51'
						transform='translate(20.754 56.571)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='1.971'
						x2='9.553'
						transform='translate(15.485 15.465)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='8.224'
						y2='8.616'
						transform='translate(4.807 16.451)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='2.294'
						x2='11.678'
						transform='translate(13.031 22.773)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						x2='9.562'
						y2='11.87'
						transform='translate(0 20.759)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y1='3.347'
						x2='15.148'
						transform='translate(9.562 29.281)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M579.693,297.79s36.5-12.015,74.906,24.161'
					transform='translate(808.081 143.439)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M568.235,303.98s35.257-18.5,73.66,17.678'
					transform='translate(791.206 148.397)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M659.105,342.028s55.8-26.07,95.589-.175'
					transform='translate(925.039 194.13)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M159.505,339.671s55.8-26.07,95.586-.175'
					transform='translate(189.227 190.668)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M488.915,313.5s13.229-9.719,23.7-12.741'
					transform='translate(674.383 150.604)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M815.988,281.6s-36.5-12.015-74.906,24.159'
					transform='translate(1045.775 119.665)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M120.167,305.078S91.512,279.5,41.831,297.351'
					transform='translate(15.917 136.311)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M390.254,279.951s-35.2-15.417-76.862,16.96'
					transform='translate(415.872 114.663)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M626.881,259.678c0-12.428-14.52-22.5-32.431-22.5a46.417,46.417,0,0,0-6.969.533c.012-.18.054-.351.054-.533A8.083,8.083,0,0,0,571.9,234.31a8.017,8.017,0,0,0-12.164,8.9c-.109,0-.21-.032-.319-.032a8.082,8.082,0,1,0,2.666,15.679c-.015.274-.059.546-.059.822,0,12.428,14.518,22.5,32.431,22.5a45.563,45.563,0,0,0,10.1-1.126,8.006,8.006,0,0,0,14.112-6.441,26.29,26.29,0,0,0,2.005-1.728,8.074,8.074,0,1,0,6.214-13.205Z'
					transform='translate(766.293 45.359)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M95.831,245.277c0,12.428,14.52,22.5,32.431,22.5a46.464,46.464,0,0,0,6.968-.533c-.012.18-.054.351-.054.533a8.083,8.083,0,0,0,15.64,2.869,8.021,8.021,0,0,0,12.164-8.9c.109,0,.208.032.317.032a8.082,8.082,0,1,0-2.663-15.679c.015-.274.059-.543.059-.822,0-12.427-14.52-22.5-32.431-22.5a45.56,45.56,0,0,0-10.1,1.126,8.007,8.007,0,0,0-14.112,6.441q-1.065.829-2.008,1.728a8.074,8.074,0,1,0-6.212,13.205Z'
					transform='translate(83.524 33.515)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M199.247,280.945s35.017-20.4,48.16-21.525c30.045-2.577,116.887,29.723,116.887,29.723s23.776-17.525,33.094-17.167c32.285,1.239,103.106,37.7,103.106,37.7'
					transform='translate(247.759 89.681)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					strokeLinecap='round'
					d='M118.267,301.705S200.451,247.623,231.6,249.48c31.951,1.906,109.033,67.006,109.033,67.006'
					transform='translate(128.492 75.228)'
				/>
				<g transform='translate(1147.337 502.289)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M491.659,360.737V322.524h-9.2v38.213'
						transform='translate(-482.46 -319.711)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M545.709,360.737V322.524h-9.2v38.213'
						transform='translate(-402.855 -319.711)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='38.44'
						transform='translate(21.387 2.586)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='39.356'
						transform='translate(33.861 1.671)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='40.051'
						transform='translate(46.335 0.976)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='41.026'
						transform='translate(58.81)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='41.026'
						transform='translate(71.284)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='41.003'
						transform='translate(83.758 0.023)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='40.051'
						transform='translate(96.232 0.976)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='39.627'
						transform='translate(108.707 1.4)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						y2='38.213'
						transform='translate(121.181 2.814)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeMiterlimit='10'
						strokeLinecap='round'
						d='M486.18,328.207a448.974,448.974,0,0,1,124.1,0'
						transform='translate(-476.982 -317.692)'
					/>
				</g>
			</g>
		</svg>
	);
}

export default withWidth()(Village);
