import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
	Typography,
	Box,
	makeStyles,
	Hidden,
	IconButton,
} from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage06.png';

import {
	ChevronRight,
	StraightStripes,
	HomeScreenHalfCircle,
	Culture2,
} from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'fixed',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		right: 0,
		width: 850,
		height: 640,
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%',
		},
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 116,
		height: 691,
		[theme.breakpoints.only('xs')]: {
			width: 300,
			top: 0,
			height: '100%',
		},
		[theme.breakpoints.only('md')]: {
			height: '100%',
			width: '100%',
			top: 150,
		},
		[theme.breakpoints.only('sm')]: {
			top: 0,
			height: '100%',
			width: '100%',
		},
		[theme.breakpoints.only('xl')]: {
			top: 200,
		},
	},
	mainTitleBox: {
		top: 72,
		position: 'absolute',
		background: '#E0B080',
		left: 0,
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			top: 100,
		},
		[theme.breakpoints.only('md')]: {
			left: 100,
		},
		[theme.breakpoints.only('sm')]: {
			left: 100,
		},
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		textTransform: 'uppercase',
		color: '#070C35',
		fontSize: '50px',
		letterSpacing: '6px',
		lineHeight: '54px',
		marginTop: 7,
		marginRight: 38,
		marginBottom: 3,
		marginLeft: 27,
		[theme.breakpoints.only('xs')]: {
			marginTop: 7,
			marginRight: 20,
			marginBottom: 3,
			marginLeft: 20,
			fontSize: 30,
			lineHeight: '30px',
			letterSpacing: '2px',
		},
	},
	halfStripes: {
		top: 0,
		left: 62,
		position: 'absolute',
		zIndex: 100,
		width: '400px',
		height: '252px',
		[theme.breakpoints.only('xs')]: {
			width: 300,
			opacity: 0.5,
			left: 0,
		},
		[theme.breakpoints.only('md')]: {
			left: 100,
		},
		[theme.breakpoints.only('sm')]: {
			left: 100,
		},
	},
	descriptionContainer: {
		position: 'absolute',
		top: 284,
		width: 720,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			width: 280,
			top: 250,
		},
		[theme.breakpoints.only('md')]: {
			left: 100,
		},
		[theme.breakpoints.only('sm')]: {
			left: 100,
			width: 600,
		},
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.only('xs')]: {
			width: 300,
			fontSize: 16,
		},
	},
	nextButton: {
		position: 'absolute',
		left: 0,
		bottom: 30,
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF',
		},
		transform: 'rotate(90deg)',
		margin: 20,
		[theme.breakpoints.only('xs')]: {
			display: 'none',
		},
		[theme.breakpoints.only('sm')]: {
			display: 'none',
		},
	},
	buttonContainer: {
		position: 'absolute',
		top: 591,
		left: 'auto',
		right: 77,
	},
	footerIconBox: {
		position: 'absolute',
		top: 300,
		left: 802,
		height: '320px',
		width: '230px',
		[theme.breakpoints.only('xs')]: {
			height: 100,
			left: 'auto',
			right: 0,
			top: 730,
		},
		[theme.breakpoints.only('md')]: {
			left: 'auto',
			right: 0,
			zIndex: 0,
			height: 200,
			top: 449,
		},
		[theme.breakpoints.only('sm')]: {
			left: 'auto',
			right: 0,
			zIndex: 0,
			height: 100,
			top: 530,
		},
	},
	halfCircleContainer: {
		zIndex: 99,
		top: 111,
		left: 174,
		position: 'absolute',
		width: 192,
		height: 96,
		[theme.breakpoints.only('xs')]: {
			width: 150,
			left: 130,
			opacity: 0.8,
		},
	},
	contentContainer: {
		height: 540,
		position: 'absolute',
		width: '100%',
		borderBottom: '3px solid #E0B080',
		strokeLocation: 'center',
		strokeLinecap: 'round',
		strokeLinejoin: 'miter',
		[theme.breakpoints.only('xs')]: {
			border: 'none',
		},
		[theme.breakpoints.only('sm')]: {
			border: 'none',
		},
		zIndex: 1,
	},
	dotIconContainer: {
		position: 'absolute',
		top: 195,
		right: 178,
	},
	navigateText: {
		color: '#48B3C9',
		fontFamily: 'Ubuntu',
		fontSize: 18,
		lineHeight: '18px',
		paddingRight: 17,
		paddingTop: 2,
	},
	navigateButton: {
		margin: 'auto',
		width: 'fit-content',
		display: 'inline-flex',
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));

function MadefalvaHistory1(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.contentContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.halfCircleContainer}>
					<HomeScreenHalfCircle width='100%' height='100%' fill='#48B3C9' />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						{t('madehistory1.maintitle')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='madehistory1.description' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.animatedCircles}>
					<Box className={classes.halfStripes}>
						<StraightStripes width='100%' height='100%' />
					</Box>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.footerIconBox}>
					<Culture2 height='100%' width='100%' fill='#E60F37' />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Hidden mdDown>
					<IconButton
						className={classes.nextButton}
						onClick={() => {
							props.goToNext();
						}}>
						<ChevronRight
							width='24px'
							height='24px'
							fill='#070C35'
							className={classes.arrowDown}
						/>
					</IconButton>
				</Hidden>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('madefalvaHistory')(
	withRouter(MadefalvaHistory1),
);
