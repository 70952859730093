import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Field } from 'formik';
import { Select } from '.';

// eslint-disable-next-line react/prop-types
function Languages({ t }) {
	return (
		<Field
			name='category'
			label={t('selectlanguage')}
			options={[
				{
					value: 'hu',
					label: t('hu')
				},
				{
					value: 'ro',
					label: t('ro')
				},
				{
					value: 'en',
					label: t('en')
				}
			]}
			component={Select}
			size='small'
		/>
	);
}
export default withNamespaces('common')(Languages);
