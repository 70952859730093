import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { getImages, getFiles } from '../../../../../store/file/actions';
import { FILE_TYPE } from '../../../../../store/types';

const Header = (props) => {
	const { t, type } = props;

	useEffect(() => {
		if (type === FILE_TYPE.IMAGE) {
			getImages({ limit: 18, page: 1 });
		} else {
			getFiles({ limit: 18, page: 1 });
		}
	}, [type]);

	return <Typography variant='h2'>{t('common:attachments')}</Typography>;
};
export default withNamespaces('common')(Header);
