import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Culture2(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : 102.4}
			height={props.height ? props.height : 73.6}
			viewBox='0 0 102.4 73.6'>
			<g transform='translate(-201.6 -283.5)'>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M280.4,306.5H203.7v-8.4h85.7Zm-75.6-1H280l6.9-6.4H204.8v6.4Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M276.3,302.9h-2a.5.5,0,1,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,1,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,1,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,1,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Zm-6,0h-2a.5.5,0,0,1,0-1h2a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M211.1,357h0a.536.536,0,0,1-.5-.5l2-22-.1-28.5a.5.5,0,0,1,1,0l.1,28.5-2,22C211.5,356.8,211.3,357,211.1,357Z'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M222.2,356.5a25.2,25.2,0,1,1,50.4,0h15.7a40.9,40.9,0,1,0-81.8,0Z'
				/>
				<path
					className='c'
					fill='none'
					stroke='#160e44'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M236,318.7'
				/>
				<path
					className='c'
					fill='none'
					stroke='#160e44'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M258.4,318.7'
				/>
				<path
					className='c'
					fill='none'
					stroke='#160e44'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M275.7,319.6'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M266.5,357h-38l61.6-56.6ZM231,356h34.8l21.6-51.8Z'
				/>
				<path
					className='b'
					fill={props.dotfill ? props.dotfill : '#ee2c48'}
					d='M291.4,308.7a12.6,12.6,0,1,0-12.6-12.6A12.676,12.676,0,0,0,291.4,308.7Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M225.6,357a.458.458,0,0,1-.5-.4l-2-17.1,8-33.6a.51.51,0,0,1,1,.2l-8,33.4,2,16.9c0,.4-.2.6-.5.6Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M223.6,340.1c-.1,0-.2,0-.2-.1l-10.7-6a.515.515,0,0,1,.5-.9l10.7,6a.574.574,0,0,1,.2.7C223.9,340,223.8,340.1,223.6,340.1Z'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M292.8,357.1H202.1a.5.5,0,1,1,0-1h90.7a.5.5,0,0,1,0,1Z'
				/>
			</g>
		</svg>
	);
}
