import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function OfficeLogo(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='240'
			height='194.657px'
			viewBox='0 0 240 194.657'>
			<g transform='translate(-510.641 -1451.658)'>
				<g transform='translate(583.208 1602.654)'>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M549.543,1515.712v13.008H547.5v-6.48l-1.654,4.442h-1.2l-1.653-4.442v6.48h-2.045v-13.008h1.172l3.119,7.2,3.12-7.2Z'
						transform='translate(-540.782 -1512.981)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M551.282,1526.658H548.94l-.372,2.062h-2.081l2.6-13.008h2.044l2.6,13.008h-2.081Zm-.352-2.044-.818-4.664-.818,4.664Z'
						transform='translate(-533.024 -1512.981)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M554.559,1528.722h-3.087v-13.008h3.087a3.039,3.039,0,0,1,2.16.779,3.131,3.131,0,0,1,.929,2.417v6.691a3.881,3.881,0,0,1-.223,1.356,2.759,2.759,0,0,1-.72,1.042A3.064,3.064,0,0,1,554.559,1528.722Zm-1.042-2.044h1.042a.876.876,0,0,0,1.039-1.079v-6.691a1.3,1.3,0,0,0-.278-.929,1.12,1.12,0,0,0-.761-.223h-1.042Z'
						transform='translate(-526.044 -1512.982)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M558.188,1519.349v3.26h2.3v2.044h-2.3v3.6h3.344v2.043h-5.388v-13.006h5.388v2.044Zm3.2-4.775h-2.045l-1.022,2.044h1.022Z'
						transform='translate(-519.507 -1514.574)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M562.508,1517.755v3.271h2.323v2.045h-2.323v5.649h-2.044v-13.007h5.388v2.044Z'
						transform='translate(-513.457 -1512.979)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M568.952,1526.658H566.61l-.372,2.062h-2.081l2.6-13.008H568.8l2.6,13.008h-2.08Zm-.353-2.044-.816-4.664-.818,4.664Z'
						transform='translate(-508.288 -1512.981)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M574.532,1528.72h-5.388v-13.008h2.045v10.975h3.343Z'
						transform='translate(-501.306 -1512.981)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M577.98,1515.712h2.083l-2.6,13.008h-2.045l-2.6-13.008h2.081l1.543,8.791Z'
						transform='translate(-496.17 -1512.981)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M582.015,1526.658h-2.342l-.372,2.062H577.22l2.6-13.008h2.042l2.6,13.008h-2.083Zm-.353-2.044-.818-4.664-.816,4.664Z'
						transform='translate(-490 -1512.981)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M591.478,1528.72h-2.194l-1.579-4.164-.705,1.617v2.547h-2.045v-13.008H587v5.279l2.268-5.279h2.21l-2.621,6.17Z'
						transform='translate(-479.171 -1512.981)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M592.7,1530.332a3.023,3.023,0,0,1-2.16-.763,3.2,3.2,0,0,1-.929-2.434v-6.919a3.211,3.211,0,0,1,.929-2.434,3.44,3.44,0,0,1,4.32,0,3.228,3.228,0,0,1,.931,2.434v6.915a3.222,3.222,0,0,1-.931,2.434A3.018,3.018,0,0,1,592.7,1530.332Zm-2.323-14.736a.908.908,0,0,1,1.536-.671l.014.012a.915.915,0,1,1-1.553.66Zm2.323,3.476a1.11,1.11,0,0,0-.763.223,1.246,1.246,0,0,0-.278.929v6.915a1.238,1.238,0,0,0,.278.928,1.093,1.093,0,0,0,.763.224,1.053,1.053,0,0,0,.761-.224,1.207,1.207,0,0,0,.279-.928v-6.922a1.211,1.211,0,0,0-.279-.929,1.072,1.072,0,0,0-.761-.219Zm.5-3.476a.89.89,0,0,1,.269-.66.9.9,0,0,1,1.272-.01l.012.01a.915.915,0,1,1-1.553.66Z'
						transform='translate(-472.661 -1514.442)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M600.473,1528.721h-6.187l3.384-10.947h-3.384v-2.063h6.187l-3.382,10.945h3.382Z'
						transform='translate(-466.108 -1512.982)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M602.047,1528.962a3.018,3.018,0,0,1-2.16-.764,3.2,3.2,0,0,1-.929-2.433v-1.681H601v1.681a1.227,1.227,0,0,0,.279.928,1.08,1.08,0,0,0,.76.223,1.055,1.055,0,0,0,.761-.223,1.207,1.207,0,0,0,.278-.928v-.819a1.974,1.974,0,0,0-.689-1.488,6.91,6.91,0,0,0-.78-.48,7.086,7.086,0,0,1-1.284-.818,4.089,4.089,0,0,1-1.022-1.355,3.8,3.8,0,0,1-.353-1.635v-.324a3.2,3.2,0,0,1,.929-2.434,3.433,3.433,0,0,1,4.32,0,3.23,3.23,0,0,1,.929,2.434v1.133h-2.044v-1.133a1.213,1.213,0,0,0-.278-.929,1.074,1.074,0,0,0-.761-.223,1.108,1.108,0,0,0-.761.223,1.235,1.235,0,0,0-.278.929v.316a1.9,1.9,0,0,0,.686,1.47,5.343,5.343,0,0,0,.72.446,7.618,7.618,0,0,1,1.339.854,3.9,3.9,0,0,1,1.375,3.01v.818a3.22,3.22,0,0,1-.929,2.434A3.026,3.026,0,0,1,602.047,1528.962Z'
						transform='translate(-459.58 -1513.072)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M605.776,1519.349v3.26h2.3v2.044h-2.3v3.6h3.346v2.043h-5.388v-13.006h5.388v2.044Zm3.2-4.775h-2.042l-1.022,2.044h1.022Z'
						transform='translate(-452.881 -1514.574)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M611.157,1528.96a3.1,3.1,0,0,1-2.16-.763,3.289,3.289,0,0,1-.928-2.434v-6.919a3.3,3.3,0,0,1,.928-2.434,3.113,3.113,0,0,1,2.16-.76,3.054,3.054,0,0,1,2.16.76,3.232,3.232,0,0,1,.929,2.434V1520H612.2v-1.152a1.27,1.27,0,0,0-.262-.929,1.139,1.139,0,0,0-.78-.223,1.116,1.116,0,0,0-.763.223,1.236,1.236,0,0,0-.279.929v6.914a1.232,1.232,0,0,0,.279.93,1.089,1.089,0,0,0,.763.222,1.112,1.112,0,0,0,.78-.222,1.265,1.265,0,0,0,.262-.93v-2.434h-1.023v-2.045h3.068v4.479a3.225,3.225,0,0,1-.929,2.434A3.022,3.022,0,0,1,611.157,1528.96Z'
						transform='translate(-446.817 -1513.071)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M543.97,1537.009a3.107,3.107,0,0,1-2.16-.761,3.269,3.269,0,0,1-.929-2.434V1526.9a3.276,3.276,0,0,1,.929-2.434,3.119,3.119,0,0,1,2.16-.763,3.048,3.048,0,0,1,2.16.763,3.23,3.23,0,0,1,.929,2.434v1.152h-2.045V1526.9a1.278,1.278,0,0,0-.259-.929,1.137,1.137,0,0,0-.78-.223,1.1,1.1,0,0,0-.761.223,1.23,1.23,0,0,0-.278.929v6.912a1.229,1.229,0,0,0,.278.929,1.1,1.1,0,0,0,.761.222,1.126,1.126,0,0,0,.78-.222,1.272,1.272,0,0,0,.259-.929v-1.681h2.045v1.681a3.223,3.223,0,0,1-.929,2.434A3.026,3.026,0,0,1,543.97,1537.009Z'
						transform='translate(-540.878 -1501.794)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M548.631,1537.008a3.022,3.022,0,0,1-2.16-.761,3.2,3.2,0,0,1-.928-2.434V1526.9a3.21,3.21,0,0,1,.928-2.433,3.432,3.432,0,0,1,4.32,0,3.228,3.228,0,0,1,.929,2.433v6.912a3.221,3.221,0,0,1-.929,2.434A3.025,3.025,0,0,1,548.631,1537.008Zm0-11.261a1.105,1.105,0,0,0-.761.224,1.23,1.23,0,0,0-.278.928v6.913a1.227,1.227,0,0,0,.278.928,1.094,1.094,0,0,0,.761.221,1.059,1.059,0,0,0,.764-.221,1.206,1.206,0,0,0,.278-.928V1526.9a1.208,1.208,0,0,0-.278-.928A1.064,1.064,0,0,0,548.631,1525.747Z'
						transform='translate(-534.354 -1501.793)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M558.88,1523.766v13.008h-2.045v-6.481l-1.654,4.443h-1.2l-1.653-4.443v6.481h-2.043v-13.008h1.169l3.12,7.2,3.12-7.2Z'
						transform='translate(-527.708 -1501.706)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M559.165,1536.922a3.027,3.027,0,0,1-2.16-.76,3.211,3.211,0,0,1-.931-2.434v-9.96h2.045v9.96a1.245,1.245,0,0,0,.262.929,1.122,1.122,0,0,0,.78.222,1.1,1.1,0,0,0,.764-.222,1.25,1.25,0,0,0,.278-.929v-9.96h2.045v9.96a3.309,3.309,0,0,1-.929,2.434A3.11,3.11,0,0,1,559.165,1536.922Z'
						transform='translate(-519.61 -1501.704)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M567.179,1536.774h-1.2l-3.12-6.6v6.6h-2.042v-13.008h1.2l3.12,6.578v-6.578h2.043Z'
						transform='translate(-512.963 -1501.706)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M570.269,1534.712h-2.342l-.372,2.064h-2.081l2.6-13.009h2.045l2.6,13.009h-2.083Zm-.353-2.045L569.1,1528l-.818,4.663Z'
						transform='translate(-506.444 -1501.704)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M576.33,1537.007a3.024,3.024,0,0,1-2.16-.761,3.2,3.2,0,0,1-.929-2.434v-1.68h2.045v1.68a1.238,1.238,0,0,0,.279.929,1.085,1.085,0,0,0,.763.221,1.051,1.051,0,0,0,.763-.221,1.206,1.206,0,0,0,.278-.929V1533a1.966,1.966,0,0,0-.687-1.487,6.6,6.6,0,0,0-.78-.481,7.151,7.151,0,0,1-1.284-.819,4.076,4.076,0,0,1-1.022-1.355,3.8,3.8,0,0,1-.353-1.635v-.316a3.213,3.213,0,0,1,.929-2.434,3.433,3.433,0,0,1,4.32,0,3.229,3.229,0,0,1,.929,2.434v1.132h-2.045V1526.9a1.209,1.209,0,0,0-.278-.929,1.065,1.065,0,0,0-.763-.223,1.108,1.108,0,0,0-.763.223,1.233,1.233,0,0,0-.279.929v.316a1.9,1.9,0,0,0,.687,1.469,5.521,5.521,0,0,0,.72.445,7.624,7.624,0,0,1,1.337.856,3.893,3.893,0,0,1,1.375,3.009v.816a3.224,3.224,0,0,1-.929,2.434A3.036,3.036,0,0,1,576.33,1537.007Z'
						transform='translate(-495.576 -1501.793)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M579.974,1536.774h-2.045v-13.008h2.045Z'
						transform='translate(-489.007 -1501.706)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M584.048,1537.009a3.107,3.107,0,0,1-2.16-.761,3.289,3.289,0,0,1-.929-2.434V1526.9a3.3,3.3,0,0,1,.929-2.434,3.119,3.119,0,0,1,2.16-.763,3.047,3.047,0,0,1,2.16.763,3.231,3.231,0,0,1,.929,2.434v1.152H585.1V1526.9a1.27,1.27,0,0,0-.262-.929,1.137,1.137,0,0,0-.78-.223,1.1,1.1,0,0,0-.761.223,1.23,1.23,0,0,0-.278.929v6.912a1.229,1.229,0,0,0,.278.929,1.1,1.1,0,0,0,.761.222,1.126,1.126,0,0,0,.78-.222,1.264,1.264,0,0,0,.262-.929v-1.681h2.042v1.681a3.224,3.224,0,0,1-.929,2.434A3.026,3.026,0,0,1,584.048,1537.009Z'
						transform='translate(-484.768 -1501.794)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M588.731,1536.922a3.026,3.026,0,0,1-2.16-.76,3.21,3.21,0,0,1-.929-2.434v-9.96h2.045v9.96a1.254,1.254,0,0,0,.259.929,1.132,1.132,0,0,0,.78.222,1.09,1.09,0,0,0,.761-.222,1.252,1.252,0,0,0,.278-.929v-9.96h2.047v9.96a3.3,3.3,0,0,1-.931,2.434A3.112,3.112,0,0,1,588.731,1536.922Z'
						transform='translate(-478.215 -1501.704)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M595.8,1536.774h-5.39v-13.008h2.045v10.963H595.8Z'
						transform='translate(-471.529 -1501.706)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M596.742,1525.813v3.27h2.3v2.044h-2.3v3.6h3.343v2.045h-5.39v-13.008h5.39v2.044Z'
						transform='translate(-465.536 -1501.708)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M605.424,1536.774h-1.2l-3.12-6.6v6.6H599.06v-13.008h1.2l3.12,6.578v-6.578h2.044Z'
						transform='translate(-459.425 -1501.706)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M605.971,1536.774h-2.045v-13.008h2.045Z'
						transform='translate(-452.613 -1501.706)'
					/>
				</g>
				<g transform='translate(510.641 1451.658)'>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M553.516,1512.864a61.857,61.857,0,0,1,16.8-34.309,63.593,63.593,0,0,0-27.684,11.5A59.7,59.7,0,0,0,553.516,1512.864Z'
						transform='translate(-465.855 -1414.003)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M624.756,1572.112h0a32.027,32.027,0,0,0-20.037-11.631c-.547-.479-1.087-.977-1.615-1.48l-.065-.06q-1.267-1.2-2.46-2.477l-.067-.073q-1.2-1.273-2.321-2.62l-.048-.055c-.379-.451-.749-.91-1.114-1.371a61.549,61.549,0,0,1-13.3-38.265,62.491,62.491,0,0,1,40.5-58.243,64.128,64.128,0,0,0-11.424-3.174,55.179,55.179,0,0,0-20.422,16.018c-.72-.24-1.44-.45-2.16-.654a57.213,57.213,0,0,1,18.914-15.914c-2.09-.252-4.209-.41-6.36-.456h0a59.355,59.355,0,0,0-17.2,15.257c-.768-.154-1.541-.291-2.318-.418a61.384,61.384,0,0,1,15.482-14.8h0a64.76,64.76,0,0,0-9.379,1.061,59.631,59.631,0,0,0-14.734,83.036q.813,1.162,1.682,2.288c-.48.557-.926,1.127-1.375,1.7a61.777,61.777,0,0,1,9.432-85.875,62.084,62.084,0,0,0-21.06,110.14q-1.667.086-3.36.088a63.622,63.622,0,0,1-49.314-23.215,62.931,62.931,0,0,0,44.9,47.209,62.871,62.871,0,0,0-8.121,9.583,24.811,24.811,0,0,1,5.3,2.174,62.81,62.81,0,0,1,8.486-10.466q1.31.237,2.64.415a60.861,60.861,0,0,0-9.307,11.16,24.967,24.967,0,0,1,4.56,3.957,63.258,63.258,0,0,1,37.8-28.585q2.192.151,4.425.153a64.2,64.2,0,0,0,18.091-2.588h0a5.45,5.45,0,0,1,1.421.039,5.275,5.275,0,0,1,4.059,3.256,5.076,5.076,0,0,0-.151-5.116Zm-9.192-2.2a3.244,3.244,0,0,1-3.317-3.173,3.393,3.393,0,0,1,.029-.494q2.527,1.641,5.225,3.047A3.3,3.3,0,0,1,615.565,1569.913Z'
						transform='translate(-510.641 -1451.658)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#070c35'}
						d='M550.505,1515.747a24.759,24.759,0,1,0,8.265,34.025,24.761,24.761,0,0,0-8.265-34.025Zm-2.191,3.6a20.572,20.572,0,0,1,6.857,28.234l-35.09-21.377a20.568,20.568,0,0,1,28.233-6.859Z'
						transform='translate(-507.529 -1366.997)'
					/>
				</g>
			</g>
		</svg>
	);
}
