import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Box,
	Typography,
	CircularProgress,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Image as Img } from '../../../../../components/image';

const BP360 = '@media (max-width: 360px)';

const useStyles = makeStyles((theme) => ({
	root: {
		minWidth: '300px',
		width: '300px',
		minHeight: '180px',
		maxHeight: '180px',
		// maxWidth: '460px',
		// height: '313px',
		// height: '100%',
		// overflow: 'hidden',
		position: 'relative',
		transition: 'all .3s',
		cursor: 'pointer',
		// backgroundColor: "white",
		// margin: 8,
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 30,
		},
		[BP360]: {
			minWidth: '270px',
			width: '270px',
		},
	},
	titleBox: {
		backgroundColor: '#070C35',
		height: '39px',
		position: 'absolute',
		//  marginTop: "215px",
		marginLeft: '-8px',
		// top: 0,
		marginTop: -80,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingLeft: '18px',
		paddingRight: '18px',
	},
	titleText: {
		color: theme.palette.primary.contrastText,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '16px',
	},
	img: {
		maxHeight: '180px',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			maxHeight: '180px',
		},
	},
	loaderContainer: {
		height: '180px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'gray',
	},
}));

function SightCard(props) {
	const { item, lang, history } = props;
	const classes = useStyles();

	const content = item.content.filter((data) => data.lang === lang)[0];
	const [imgReady, setImgReady] = useState(false);

	useEffect(() => {
		setImgReady(false);
		if (content.headerImage.url) {
			const img = new Image();
			img.onload = () => {
				setImgReady(true);
			};
			img.src = `${content.headerImage.url}?size=md`;
			img.alt = content.headerImage.id;
		}
	}, [content]);

	const navigateToNews = () => {
		window.scrollTo(0, 0);
		history.push(`/sight/${item.id}`);
	};
	if (item)
		return (
			<Box className={classes.root} onClick={() => navigateToNews()}>
				<Box className={classes.img}>
					{imgReady ? (
						<Img image={content.headerImage.url} size='md' />
					) : (
						<Box className={classes.loaderContainer}>
							<CircularProgress color='secondary' />
						</Box>
					)}
				</Box>
				<Box className={classes.titleBox}>
					<Typography className={classes.titleText}>{content.title}</Typography>
				</Box>
			</Box>
		);
}

export default withNamespaces('sight')(withRouter(SightCard));
