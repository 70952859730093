import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Regulations(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 86.04 86.04'>
			<title>regulamente</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className='cls-1'
						fill='#201943'
						d='M80.5,86H0V0H86V79.88ZM1,85H80.06l5-5.55V1H1Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M25.6,9.21H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H25.6a.5.5,0,0,1,.5.5A.5.5,0,0,1,25.6,9.21Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,77.83H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,77.83Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,17.79H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,17.79Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,26.37H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,26.37Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,34.94H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.51.51,0,0,1,.5.5A.5.5,0,0,1,36.17,34.94Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,43.52H8.46a.5.5,0,0,1,0-1H36.17a.5.5,0,0,1,0,1Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,52.1H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,52.1Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,60.67H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.51.51,0,0,1,.5.5A.5.5,0,0,1,36.17,60.67Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M77.13,9.21H50.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h26.7a.5.5,0,0,1,.5.5A.5.5,0,0,1,77.13,9.21Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M74.28,77.83H50.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H74.28a.5.5,0,0,1,.5.5A.5.5,0,0,1,74.28,77.83Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M77.13,17.79H50.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h26.7a.5.5,0,0,1,.5.5A.5.5,0,0,1,77.13,17.79Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M73.49,64.15h-6L64.36,49h-2.7V64.15H55.72V26.35h9A8.82,8.82,0,0,1,71,28.61a9.07,9.07,0,0,1,2.7,7v4.06A9.14,9.14,0,0,1,71,46.76a6.09,6.09,0,0,1-.86.7ZM67.71,35.63a3.71,3.71,0,0,0-.81-2.69,3.16,3.16,0,0,0-2.21-.65h-3V43h3a3.16,3.16,0,0,0,2.21-.65,3.59,3.59,0,0,0,.81-2.69Z'
					/>
				</g>
			</g>
		</svg>
	);
}
