import {
	IconButton,
	Typography,
	Hidden,
	useMediaQuery,
	Box,
	Divider,
	Slide
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { logout } from '../../store/me/actions';
import LanguageSelector from '../language/LanguageSelector';
import { TopNavigation } from '../routing/menus/TopNavigation';
import { TouchesLogo, MenuTriangle } from '../icons';

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1
	},
	appBar: {
		width: '100%',
		justifyContent: 'flex-end',
		boxShadow: 'none',
		borderBottom: 'none'
	},
	appBarMobile: {
		width: '100%',
		height: theme.spacing(8),
		justifyContent: 'center',
		boxShadow: 'none'
	},
	iconLives: {
		color: theme.palette.primary.contrastText
	},
	iconOffice: { color: theme.palette.primary.dark },
	barColorLives: {
		backgroundColor: theme.palette.primary.dark
	},
	barColorOffice: {
		backgroundColor: '#FAFAF5'
	},
	toolbar: {
		alignItems: 'flex-end',
		justifyContent: 'center',
		zIndex: 20
	},
	toolbarMobile: {
		alignItems: 'center',
		justifyContent: 'center'
	},

	menuIcon: {
		marginLeft: 'auto',
		marginTop: 'auto',
		marginBottom: 'auto'
	},
	logoBox: {
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: theme.spacing(1)
	},
	triangle: {
		position: 'absolute',
		right: 0,
		width: '732px',
		height: 36,
		zIndex: 0,
		overflow: 'visible'
	},
	triangleMobile: {
		position: 'absolute',
		top: theme.spacing(8),
		right: 0,
		width: '732px',
		height: 36,
		zIndex: 0,
		overflow: 'visible',
		// TODO egyelore display none - kell a menu aljara?
		display: 'none'
	},
	triangleTop: {
		backgroundColor: '#E60F37',
		width: 642,
		marginLeft: 120,
		marginTop: 30
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
		  display: 'none',
		},
	},
}));

function Header(props) {
	const { t, drawerOpen, toggleDrawerOpen, page, menuOpen, toggleMenuOpen } = props;
	const classes = useStyles();

	const role = useSelector((state) => state.me.role);
	const showMenu = useSelector((state) => state.layout.showMenu);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handleMenuButton = () => {
		toggleDrawerOpen(true);
	};

	const handleMenuToggle = () =>{
		toggleMenuOpen(true);
	}

	const isLives = page === 'lives';
	if (role) {
		return (
			<AppBar 
				position='fixed' 
				className={classes.appBar}
				position="fixed"
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleMenuToggle}
						className = {classes.menuButton}
					>
						<MenuIcon />
					</IconButton>
					<Typography>{t('authHeaderTitle')}</Typography>
					{/* <LanguageSelector
                        style={{ marginRight: 30, marginLeft: "auto" }}
                    /> */}
					<IconButton onClick={logout} style={{ marginLeft: 'auto' }}>
						<LogOutIcon className={classes.icon} />
					</IconButton>
				</Toolbar>
			</AppBar>
		);
	}
	return isMobile ? (
		<AppBar
			position='fixed'
			className={
				isMobile
					? clsx(
							classes.appBarMobile,
						isLives ? classes.barColorLives : classes.barColorOffice
					  )
					: clsx(
							classes.appBar,
						isLives ? classes.barColorLives : classes.barColorOffice
					  )
			}>
			<Box style={{ zIndex: 201 }}>
				<Toolbar className={isMobile ? classes.toolbarMobile : classes.toolbar}>
					<Hidden smDown>
						<Slide in={showMenu}>
							<TopNavigation t={t} page={page} />
						</Slide>
					</Hidden>
					<Hidden mdUp>
						<Box className={classes.logoBox}>
							<TouchesLogo
								fill={isLives ? '#FFFFFF' : '#070C35'}
								height='40px'
								page = {page}
							/>
						</Box>
						<IconButton
							className={clsx(
								classes.menuIcon,
								isLives ? classes.iconLives : classes.iconOffice
							)}
							onClick={handleMenuButton}>
							<MenuIcon />
						</IconButton>
					</Hidden>
				</Toolbar>
			</Box>

			{!isLives && (
				<Box className={isMobile ? classes.triangleMobile : classes.triangle}>
					{!isMobile && <Divider className={classes.triangleTop} />}
					<MenuTriangle width={732} height={36} fill='#FAFAF5' />
				</Box>
			)}
		</AppBar>
	) : (
		<Slide in={showMenu}>
			<AppBar
				position='fixed'
				className={
					isMobile
						? clsx(
							classes.appBarMobile,
								isLives ? classes.barColorLives : classes.barColorOffice
						  )
						: clsx(
							classes.appBar,
								isLives ? classes.barColorLives : classes.barColorOffice
						  )
				}>
				<Box style={{ zIndex: 201 }}>
					<Toolbar
						className={isMobile ? classes.toolbarMobile : classes.toolbar}>
						<Hidden smDown>
							<Slide in={showMenu}>
								<TopNavigation t={t} page={page} />
							</Slide>
						</Hidden>
						<Hidden mdUp>
							<Box className={classes.logoBox}>
								<TouchesLogo
									fill={isLives ? '#FFFFFF' : '#070C35'}
									height='40px'
									page = {page}
								/>
							</Box>
							<IconButton
								className={clsx(
									classes.menuIcon,
									isLives ? classes.iconLives : classes.iconOffice
								)}
								onClick={handleMenuButton}>
								<MenuIcon />
							</IconButton>
						</Hidden>
					</Toolbar>
				</Box>

				{!isLives && (
					<Box className={isMobile ? classes.triangleMobile : classes.triangle}>
						{!isMobile && <Divider className={classes.triangleTop} />}
						<MenuTriangle width={732} height={36} fill='#FAFAF5' />
					</Box>
				)}
			</AppBar>
		</Slide>
	);
}

export default withNamespaces(['common', 'navigation'])(Header);
