import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import House from '../../../../components/images/h_05.png';

import { StraightStripes } from '../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${House}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		right: 0,
		width: 750,
		height: '430px',
		top: '2px',
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 200
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 400
		}
	},

	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.up('lg')]: {
			top: 147
		}
	},
	contentContainer: {
		top: '145px',
		width: '100%',
		height: '869px',
		position: 'absolute'
	},
	mainTitleBox: {
		top: '131px',
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: 0,
		textAlign: 'left',
		zIndex: 101,
		[theme.breakpoints.only('sm')]: {
			right: 30,
			width: 'fit-content',
			top: 300,
			left: 'auto'
		},
		[theme.breakpoints.only('xs')]: {
			left: 30,
			top: 200
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#EE2C48',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: '18.5px',
		marginRight: '39px',
		marginBottom: '15.5px',
		marginLeft: '39px'
	},
	halfStripes: {
		top: '18px',
		left: 0,
		position: 'absolute',
		zIndex: 100,
		width: '400px',
		height: '252px',
		[theme.breakpoints.down('md')]: {
			left: 30
		},
		[theme.breakpoints.only('sm')]: {
			width: 500
		},
		[theme.breakpoints.only('xs')]: {
			width: 300,
			opacity: 0.4
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '440px',
		width: '100%',
		[theme.breakpoints.only('xs')]: {
			top: 300
		},
		[theme.breakpoints.only('sm')]: {
			top: 400
		}
	},
	descriptionText: {
		color: '#282D38',
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		[theme.breakpoints.down('md')]: {
			marginRight: 20,
			marginLeft: 30
		}
	},
	awesomeContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			display: 'none'
		},
		[theme.breakpoints.only('sm')]: {
			left: 30,
			top: 330
		},
		[theme.breakpoints.only('md')]: {
			marginLeft: 30,
			marginRight: 10
		}
	},
	awesomeText: {
		fontFamily: 'CLOAK',
		color: '#E0B080',
		letterSpacing: '0.68px',
		lineHeight: '92px',
		fontSize: '68px'
	}
}));

function SiculicidiumHistory7(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.contentContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageContainer} />
			</CSSTransition>

			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.awesomeContainer}>
					<Typography className={classes.awesomeText}>1764</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.halfStripes}>
					<StraightStripes width='100%' height='100%' />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						{t('megerint14.maintitle')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						{t('megerint14.description')}
					</Typography>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('siculicidiumHistory')(SiculicidiumHistory7);
