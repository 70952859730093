import React from 'react';
import { Box, makeStyles, Hidden } from '@material-ui/core';
import MonumentHistoryNotAnimated from './MonumentHistoryNotAnimated';
import MonumentHistoryAnimated from './MonumentHistoryAnimated';

const useStyles = makeStyles((theme) => ({
	container: {},
	slideContainer: {
		position: 'relative',
		height: '850px'
	}
}));

function MonumentHistory(props) {
	const classes = useStyles();

	return (
		<Box>
			<Hidden only={['xl', 'lg']}>
				<MonumentHistoryNotAnimated />
			</Hidden>
			<Hidden only={['sm', 'xs', 'md']}>
				<MonumentHistoryAnimated />
			</Hidden>
		</Box>
	);
}

export default MonumentHistory;
