import store from '../store';
import { GET_ALL_HOTELS, GET_HOTELS } from '../types';
import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';

export const getHotels = (other) => {
	api
		.get(other ? `/hotels${other}` : '/hotels?active=true')
		.then((response) => {
			store.dispatch({ type: GET_ALL_HOTELS, payload: response.data });
		});
};

export const saveHotel = async (data) =>
	api
		.post('/hotels', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
			getHotels('?active=false');
			return true;
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
			return false;
		});

export const getHotelById = async (id) =>
	api.get(`/hotels/${id}`).then((response) => {
		store.dispatch({
			type: GET_HOTELS,
			payload: response.data
		});
	});

export const updateHotel = (id, data) => {
	api.put(`/hotels/${id}`, data).then(() => getHotels('?active=false'));
};

export const deleteHotel = (id) => {
	api.delete(`/hotels/${id}`).then(() => getHotels('?active=false'));
};

export const activateHotel = (id, status) => {
	api
		.patch(`/hotels/${id}?active=${!!status}`)
		.then(() => getHotels('?active=false'));
};
