import { SET_ACCEPTED_COOKIES } from '../../types';

export default function cookies(state = { accepted: false }, action) {
	switch (action.type) {
		case SET_ACCEPTED_COOKIES:
			return { ...state, accepted: action.payload };
		default:
			return state;
	}
}
