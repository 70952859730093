import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Typography, makeStyles, Box } from '@material-ui/core';
import Search from './components/Search/Search';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 42,
		marginBottom: 26,
		transition: 'all .3s',
		paddingLeft: 8,
		paddingRight: 8,
		[theme.breakpoints.down('md')]: {
			paddingRight: 32,
			paddingLeft: 32,
		},
		[theme.breakpoints.down('sm')]: {
			paddingRight: 24,
			paddingLeft: 24,
		},
	},
	title: {
		color: theme.palette.primary.dark,
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		fontSize: '32px',
		letterSpacing: '0px',
	},
	intro: {
		color: theme.palette.primary.dark,
		marginTop: 25,
		fontFamily: 'Ubuntu',
		fontSize: '14px',
		letterSpacing: '0px',
		lineHeight: '24px',
	},
}));

function Header(props) {
	const { t } = props;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography className={classes.title}>{t('mainTitle')}</Typography>
			<Typography className={classes.intro}>{t('mainDescription')}</Typography>
			<Search />
		</Box>
	);
}
export default withNamespaces('news')(Header);
