import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Box, CardMedia, makeStyles, Paper } from '@material-ui/core';

import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles((theme) => ({
	carousel: {
		height: 500,
		[theme.breakpoints.only('xs')]: {
			height: 200,
			width: '100%'
		}
	},
	indicator: {},
	activeIndicator: {},
	media: {
		height: '460px',
		[theme.breakpoints.only('xs')]: {
			height: 200
		}
	},
	img: {
		height: '100%',
		width: '100%'
	}
}));

function SliderItem(props) {
	const classes = useStyles();

	return (
		<Paper className={classes.papper}>
			<CardMedia className={classes.media} image={props.image} />
		</Paper>
	);
}

function Slider(props) {
	const { slider } = props;
	const classes = useStyles();
	return (
		<Box className={classes.sliderContainer}>
			<Carousel
				navButtonsAlwaysVisible
				className={classes.carousel}
				indicators={false}
				indicatorProps={{ className: classes.indicator }}
				activeIndicatorProps={{ className: classes.activeIndicator }}>
				{slider.map((item, index) => (
					<SliderItem image={item} key={index} />
				))}
			</Carousel>
		</Box>
	);
}
export default withNamespaces()(Slider);
