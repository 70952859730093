import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import 'moment/min/locales';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Image } from '../../../../../components/image';

const useStyles = makeStyles((theme) => ({
	mainCard: {
		background: '#FAFAF5 0% 0% no-repeat padding-box;',
		marginTop: 60,
		height: 192,
		display: 'flex',
		flexDirection: 'row',
		marginLeft: 40,
		marginRight: 40,
		minWidth: 300,
		paddingRight: 8
	},
	descriptionContent: {
		marginLeft: 20,
		marginTop: 20,
	},
	nameText: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '22px',
		letterSpacing: '0px',
		[theme.breakpoints.only('xs')]: {
			fontSize: 18
		}
	},
	descriptionText: {
		fontFamily: 'Ubuntu',
		lineHeight: '24px',
		fontSize: '14px',
		letterSpacing: '0px',
		marginTop: theme.spacing(1)
	},
	imgRoot: {
		height: 192,
		width: 192,
		marginTop: '-20px',
		marginLeft: '-20px',
		padding: 0
	},
	imgContent: { width: 192, padding: 0, margin: 0 }
}));

function LocalCouncilCard(props) {
	const classes = useStyles();
	const { member } = props;
	const language = useSelector((state) => state.language.language);
	const localizedMember = member.content.filter(
		(item) => item.lang === language
	)[0];

	return (
		<Grid item xs={12} md={6}>
			{localizedMember && (
				<Box className={classes.mainCard}>
					<Image
						image={
							localizedMember.image && localizedMember.image.url
								? localizedMember.image.url
								: 'https://madefalva.ro/person.png'
						}
						size={
							localizedMember.image && localizedMember.image.url ? 'sm' : null
						}
						classes={{
							root: classes.imgRoot,
							image: classes.imgContent
						}}
					/>
					<Box className={classes.descriptionContent}>
						<Typography className={classes.nameText}>
							{localizedMember.name}
						</Typography>
						<Typography className={classes.descriptionText}>
							{localizedMember.shortDesc}
						</Typography>
					</Box>
				</Box>
			)}
		</Grid>
	);
}

export default withNamespaces('council')(withRouter(LocalCouncilCard));
