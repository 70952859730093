import {
	Box,
	makeStyles,
	Typography,
	useMediaQuery,
	useTheme
} from '@material-ui/core';
import React from 'react';
import OfficeLogoWithoutText from '../../../components/icons/OfficeLogoWithoutText';
import Village from '../../../components/icons/Village';
import HomeScreenHalfCircle from '../../../components/icons/HomeScreenHalfCircle';

const useStyles = makeStyles((theme) => ({
	root: {
		// height: 192,
		right: 0,
		left: 0,
		paddingTop: 200,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	logobox: {
		[theme.breakpoints.only('xs')]: {
			marginTop: 0
		},
		width: 115,
		height: 195,
		marginTop: 48,
		marginBottom: 37
	},
	contentbox: {
		[theme.breakpoints.only('xs')]: {
			width: 313,
			height: 96,
			marginBottom: 116
		},
		display: 'flex',
		flexWrap: 'wrap',
		width: 554,
		height: 159,
		marginBottom: 46
	},
	uppertextbox: {
		width: 473,
		height: 70,
		[theme.breakpoints.only('xs')]: {
			width: 286,
			height: 42
		},
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#70D6D9',
		alignItems: 'center',
		justifyContent: 'center'
	},
	halfdotbox: {
		flex: 1,
		[theme.breakpoints.only('xs')]: {
			width: 108,
			height: 54,
			marginLeft: 28
		},
		width: 178,
		height: 89,
		marginLeft: 47,
		marginTop: '-2px'
	},
	lowertextbox: {
		[theme.breakpoints.only('xs')]: {
			width: 169,
			height: 18,
			marginLeft: 7
		},
		flex: 2,
		width: 316,
		height: 34,
		marginTop: 8.5,
		marginLeft: 13
	},
	uppertext: {
		[theme.breakpoints.only('xs')]: {
			fontSize: 30
		},
		textAlign: 'left',
		fontSize: '50px',
		fontWeight: 'bold',
		letterSpacing: '-1px',
		color: '#070C35',
		opacity: 1
	},
	lowertext: {
		[theme.breakpoints.only('xs')]: {
			fontSize: 14
		},
		textAlign: 'left',
		fontSize: '28px',
		letterSpacing: '0px',
		color: '#E60F37',
		opacity: 1
	},
	vilagebox: {
		marginTop: '46px'
	}
}));

export default (props) => {
	const classes = useStyles();
	window.scroll(0, 0);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isMiddleScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box className={classes.root}>
			<Box className={classes.logobox}>
				<OfficeLogoWithoutText />
			</Box>
			<Box className={classes.contentbox}>
				<Box className={classes.uppertextbox}>
					<Typography className={classes.uppertext}>
						Fejlesztés alatt
					</Typography>
				</Box>
				<Box className={classes.halfdotbox}>
					<HomeScreenHalfCircle
						width={isMobile ? '108' : '178'}
						height={isMobile ? '54' : '89'}
					/>
				</Box>
				<Box className={classes.lowertextbox}>
					<Typography className={classes.lowertext}>
						Hamarosan találkozunk
					</Typography>
				</Box>
			</Box>

			<Village
				width={
					isMobile ? 'xs' : isSmallScreen ? 'sm' : isMiddleScreen ? 'md' : 'lg'
				}
			/>
		</Box>
	);
};
