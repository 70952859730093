import store from '../store';
import { GET_ME, LOG_OUT } from '../types';
import { api } from '../../lib/axios/api';

export const getMe = async () =>
	api.get('users/me').then((response) => {
		store.dispatch({
			type: GET_ME,
			payload: response.data
		});
	});

export const logIn = async (data) =>
	api.post('auth', data).then(async (resp) => resp.data.success);

export const logout = () => {
	api.post('auth/logout').then(() => {
		store.dispatch({ type: LOG_OUT });
	});
};
