import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Nature2(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '107.966'}
			height={props.height ? props.height : '79.15'}
			viewBox='0 0 107.966 79.15'>
			<g transform='translate(-201.5 -423.25)'>
				<path
					className='a'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M222.2,501.9a25.2,25.2,0,1,1,50.4,0h15.7a40.9,40.9,0,1,0-81.8,0Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M232.6,445.5a1.1,1.1,0,1,0-1.1-1.1A1,1,0,0,0,232.6,445.5Z'
				/>
				<path
					className='c'
					fill='none'
					stroke='#160e44'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M236,464.1'
				/>
				<path
					className='c'
					fill='none'
					stroke='#160e44'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M258.4,464.1'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M285.1,501.5c-.2,0-.3-.1-.4-.3L269,473.8,232.6,497l-8-5.1a.515.515,0,0,1,.5-.9l7.5,4.8,36.7-23.5,16.2,28.4a.527.527,0,0,1-.2.7A.31.31,0,0,1,285.1,501.5Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M232.6,481.6a.52.52,0,0,1-.4-.2.494.494,0,0,1,.1-.7L251.8,468l7.3,11.4a.515.515,0,1,1-.9.5l-6.7-10.6-18.7,12.2C232.7,481.6,232.7,481.6,232.6,481.6Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M244,474.2a.758.758,0,0,1-.4-.2l-7.7-9.9-3,2.1a.5.5,0,0,1-.6-.8l3.8-2.6,8.3,10.6a.494.494,0,0,1-.1.7A.367.367,0,0,1,244,474.2Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M292.7,502.4H202a.5.5,0,0,1,0-1h90.7a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M232.6,501.9a.472.472,0,0,1-.5-.5V447.8a.5.5,0,0,1,1,0v53.6A.536.536,0,0,1,232.6,501.9Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M232.6,455.6l-12.2-7.7,12.2-7.7,12.2,7.7Zm-10.3-7.7,10.3,6.5,10.3-6.5-10.3-6.5Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M232.6,448.5l-5.9-3.7a.515.515,0,0,1,.5-.9l5.3,3.4,5.3-3.4a.515.515,0,0,1,.5.9Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M277.4,444.6,267,447.4a.548.548,0,0,0-.4.6.664.664,0,0,0,.5.4h.1l10.4-2.8A4.4,4.4,0,0,0,277.4,444.6Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M267,435.4l10.4,2.8a3.553,3.553,0,0,0,.2-1l-10.4-2.8c-.3-.1-.5.1-.6.4C266.6,435.1,266.7,435.4,267,435.4Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M280.9,431.7l.7-.7-7.6-7.6a.495.495,0,1,0-.7.7Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M280.9,451.1l-7.6,7.6a.483.483,0,0,0,0,.7c.1.1.2.1.4.1s.3,0,.4-.1l7.6-7.6A1.853,1.853,0,0,1,280.9,451.1Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M309.3,458.8l-7.6-7.6-.7.7,7.6,7.6c.1.1.2.1.4.1s.3,0,.4-.1A.616.616,0,0,0,309.3,458.8Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M295.5,455.1a2.958,2.958,0,0,1-1,.2l2.8,10.4a.664.664,0,0,0,.5.4h.1a.548.548,0,0,0,.4-.6Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M287.1,455.1l-2.8,10.4c-.1.3.1.5.4.6h.1a.51.51,0,0,0,.5-.4l2.8-10.4C287.8,455.2,287.4,455.2,287.1,455.1Z'
				/>
				<path
					className='a'
					fill={props.dotfill ? props.dotfill : '#ee2c48'}
					d='M291.3,454a12.6,12.6,0,1,0-12.6-12.6A12.548,12.548,0,0,0,291.3,454Z'
				/>
			</g>
		</svg>
	);
}
