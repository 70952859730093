import React from 'react';
import {
	Typography,
	Box,
	makeStyles,
	IconButton,
	withWidth,
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import HomePic from '../../../../components/images/Home_02.png';
import {
	HomeScreenHalfCircle,
	ChevronRight,
	MenuTriangleReverse,
} from '../../../../components/icons';
import { News, MonumentHistory, MadefalvaEl } from '.';
import {
	SiculicidiumHistory12,
	MadefalvaHistory2,
} from '../../History/components';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		width: '100%',
		height: '548px',
		zIndex: 1,
		background: `transparent url(${HomePic}) 0% 0% no-repeat padding-box`,
		backgroundSize: 'cover',
	},
	pageContainer: {},
	divider: {
		width: '100%',
		position: 'absolute',
		bottom: 0,
		height: 58,
	},
	headerContainer: {
		height: 548,
		position: 'relative',
	},
	titleBox: {
		width: 1128,
		position: 'absolute',
		top: 226,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.only('xs')]: {
			top: 270,
		},
	},
	titleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		color: '#070C35',
		fontSize: '50px',
		letterSpacing: '3px',
		lineHeight: '57px',
		marginTop: 6,
		marginLeft: 20,
		marginBottom: 8,
		marginRight: 30,
		zIndex: 102,
		[theme.breakpoints.only('sm')]: {
			lineHeight: '57px',
			fontSize: 40,
		},
		[theme.breakpoints.only('xs')]: {
			lineHeight: '40px',
			fontSize: 25,
		},
	},
	titleContainer: {
		position: 'absolute',
		top: 0,
		width: 'fit-content',
		background: '#FEFEFE',
		left: 0,
		[theme.breakpoints.only('xs')]: {
			width: '80%',
			left: 10,
		},
	},
	titleContainer2: {
		position: 'absolute',
		top: 85,
		width: 'fit-content',
		left: 161,
		zIndex: 2,
		[theme.breakpoints.only('xs')]: {
			right: 20,
			left: 'auto',
			top: 100,
		},
	},
	titleText2: {
		color: '#FAFAF5',
		fontSize: '30px',
		letterSpacing: '0',
		lineHeight: '35px',
		[theme.breakpoints.only('xs')]: {
			lineHeight: '40px',
			fontSize: 24,
		},
	},
	halfCircle: {
		width: 192,
		height: 96,
		position: 'absolute',
		top: 70,
		left: 113,
		zIndex: 1,
		[theme.breakpoints.only('xs')]: {
			top: 94,
			left: 40,
			width: 140,
			height: 70,
		},
	},
	contentContainer: {
		// width: 1128,
		boxSizing: 'border-box',
		paddingLeft: '24px',
		paddingRight: '24px',
		height: 596,
		// marginLeft: 'auto',
		// marginRight: 'auto',
		[theme.breakpoints.only('md')]: {
			width: '100%',
			// marginLeft: 30,
			// marginRight: 30
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 1100,
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 500,
		},
	},
	newsTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		color: '#070C35',
		fontSize: '32px',
		letterSpacing: '0px',
		lineHeight: '34px',
		marginBottom: 25,
	},
	newsDescription: {
		fontFamily: 'Ubuntu',
		color: '#070C35',
		fontSize: '14px',
		letterSpacing: '0px',
		lineHeight: '24px',
	},
	newsContainer: {
		// boxSizing: 'border-box',
		width: '100%',
		[theme.breakpoints.only('xs')]: {
			// marginLeft: 20,
			// marginRight: 20,
			// width: 'auto',
			height: 700,
		},
	},
	sicHistory: {
		height: 824,
		[theme.breakpoints.only('xs')]: {
			height: 650,
		},
		[theme.breakpoints.only('sm')]: {
			height: 790,
		},
	},
	madHistory: {
		height: 780,
		[theme.breakpoints.only('xs')]: {
			height: 740,
		},
		[theme.breakpoints.only('sm')]: {
			height: 830,
		},
	},
	monHistory: {
		height: 773,
		[theme.breakpoints.only('xs')]: {
			height: 590,
		},
	},
	liveHome: {
		height: 1000,
		[theme.breakpoints.only('xs')]: {
			height: 700,
		},
	},
	arrowButton: {
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		left: 0,
		right: 0,
		marginTop: 0,
		zIndex: 100,
		position: 'absolute',
		top: 518,
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	navigationIconButton: {
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF',
		},
		transform: 'rotate(90deg)',
	},
	arrowDown: {
		background: '#070c35 0% 0% no-repeat padding-box',
		opacity: 1,
	},
	bottomDescoration: {
		height: 300,
		width: '100%',
		position: 'absolute',
		bottom: 0,
		backgroundColor:
			'transparent linear-gradient(180deg, #7993A200 0%, #3D4A51 100%) 0% 0% no-repeat padding-box',
	},
}));

function HomeNotAnimated(props) {
	const classes = useStyles();
	const { t } = props;
	return (
		<Box className={classes.pageContainer}>
			<Box className={classes.headerContainer}>
				<Box className={classes.imageContainer} />

				<Box className={classes.titleBox}>
					<Box className={classes.titleContainer}>
						<Typography className={classes.titleText}>
							{t('titleText1')}
						</Typography>
					</Box>
					<Box className={classes.titleContainer2}>
						<Typography className={classes.titleText2}>
							{t('titleText2')}
						</Typography>
					</Box>
					<Box className={classes.halfCircle}>
						<HomeScreenHalfCircle width='100%' height='100%' fill='#070C35' />
					</Box>
				</Box>
				<Box className={classes.bottomDescoration}>
					<Box className={classes.divider}>
						<MenuTriangleReverse width='100%' height='100%' fill='#F0F0EB' />
					</Box>
				</Box>
				<Box className={classes.arrowButton}>
					<IconButton className={classes.navigationIconButton}>
						<ChevronRight
							width='24px'
							height='24px'
							fill='#070C35'
							className={classes.arrowDown}
						/>
					</IconButton>
				</Box>
			</Box>
			<Box className={classes.contentContainer}>
				<Box className={classes.newsContainer}>
					<News />
				</Box>
			</Box>

			<Box className={classes.sicHistory}>
				<SiculicidiumHistory12 showAnimation />
			</Box>
			<Box className={classes.madHistory}>
				<MadefalvaHistory2 showAnimation />
			</Box>
			<Box className={classes.monHistory}>
				<MonumentHistory showAnimation />
			</Box>
			<Box className={classes.liveHome}>
				<MadefalvaEl showAnimation />
			</Box>
		</Box>
	);
}

export default withWidth()(withNamespaces('home')(HomeNotAnimated));
