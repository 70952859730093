import React from 'react';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({ root: {} }));

const Header = (props) => {
	const classes = useStyles();

	return <Container className={classes.root} />;
};

export default Header;
