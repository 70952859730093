import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import {
	FacebookSvg,
	InstagramSvg,
	YoutubeSvg
} from '../../../../icons/SocialMedia';

const useStyles = makeStyles(() => ({
	iconButton: {
		color: '#F0F0EB',
		marginRight: 24
	}
}));

const mapping = {
	fb: {
		icon: <FacebookSvg width={48} height={48} />,
		href:
			'https://www.facebook.com/Mad%C3%A9falva-K%C3%B6zs%C3%A9g-hivatalos-oldala-278092442249853'
	},
	ig: {
		icon: <InstagramSvg width={48} height={48} />,
		href: 'https://www.instagram.com/made.falva/'
	},
	yt: {
		icon: <YoutubeSvg width={48} height={48} />,
		href: 'https://www.youtube.com/channel/UC9cpfOMX4v8j2pRnL5vaYJw'
	},
	rss: {
		icon: <RssFeedIcon style={{ width: 48, height: 48 }} />,
		href: 'http://www.rss.com'
	}
};

const SocialIcon = (props) => {
	const classes = useStyles();
	const { type } = props;
	return (
		<a href={mapping[type].href} target='_blank' rel='noopener noreferrer'>
			<div className={classes.iconButton}>{mapping[type].icon}</div>
		</a>
	);
};
export default SocialIcon;
