import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function StraightStripes(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '400.707'}
			height={props.height ? props.height : '252.506'}
			viewBox='0 0 400.707 252.506'>
			<g transform='translate(341.698 -961.355)'>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='13.186'
					x2='13.186'
					transform='translate(-136.932 961.708)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='44.054'
					x2='44.054'
					transform='translate(-250.122 1044.031)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='14.469'
					x2='14.469'
					transform='translate(-311.632 1135.125)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='97.4'
					x2='97.4'
					transform='translate(-209.746 961.708)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='51.563'
					x2='51.563'
					transform='translate(-279.721 1077.521)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='42.535'
					x2='42.535'
					transform='translate(-184.951 1003.179)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='13.348'
					x2='13.348'
					transform='translate(-255.519 1102.934)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='11.867'
					x2='11.867'
					transform='translate(-328.46 1177.354)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='14.322'
					x2='14.322'
					transform='translate(-166.79 1024.631)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='10.106'
					x2='10.106'
					transform='translate(-225.031 1087.088)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='31.481'
					x2='31.481'
					transform='translate(-269.696 1110.377)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='51.785'
					x2='51.785'
					transform='translate(-341.345 1161.722)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='15.349'
					x2='15.349'
					transform='translate(-93.495 961.708)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='18.548'
					x2='18.548'
					transform='translate(-137.792 1002.807)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='28.326'
					x2='28.326'
					transform='translate(-198.171 1053.408)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='6.569'
					x2='6.569'
					transform='translate(-256.691 1133.685)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='33.624'
					x2='33.624'
					transform='translate(-329.945 1179.884)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='18.884'
					x2='18.884'
					transform='translate(-119.83 995.908)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='34.632'
					x2='34.632'
					transform='translate(-217.189 1077.521)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='29.437'
					x2='29.437'
					transform='translate(-304.279 1169.804)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='59.522'
					x2='59.522'
					transform='translate(-137.668 984.508)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='31.692'
					x2='31.692'
					transform='translate(-196.571 1071.241)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='21.648'
					x2='21.648'
					transform='translate(-307.145 1191.86)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='84.133'
					x2='84.133'
					transform='translate(-145.658 979.288)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='39.962'
					x2='39.962'
					transform='translate(-221.208 1099.008)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='49.73'
					x2='49.73'
					transform='translate(-82.276 961.708)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='14.968'
					x2='14.968'
					transform='translate(-127.314 1041.509)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='20.309'
					x2='20.309'
					transform='translate(-208.326 1117.179)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='20.706'
					x2='20.706'
					transform='translate(-261.545 1170.002)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='26.385'
					x2='26.385'
					transform='translate(-97.261 1011.438)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='26.726'
					x2='26.726'
					transform='translate(-173.771 1087.608)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='53.573'
					x2='53.573'
					transform='translate(-272.945 1159.934)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='52.425'
					x2='52.425'
					transform='translate(-104.894 1004.433)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='62.962'
					x2='62.962'
					transform='translate(-189.896 1078.896)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='28.533'
					x2='28.533'
					transform='translate(-261.545 1184.975)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='25.813'
					x2='25.813'
					transform='translate(-34.187 971.737)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='17.78'
					x2='17.78'
					transform='translate(-78.146 1023.729)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='32.177'
					x2='32.177'
					transform='translate(-169.845 1101.031)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='17.42'
					x2='17.42'
					transform='translate(-213.992 1159.934)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='47.361'
					x2='47.361'
					transform='translate(-108.886 1036.289)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='96.328'
					x2='96.328'
					transform='translate(-238.744 1117.179)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='47.858'
					x2='47.858'
					transform='translate(-75.59 1013.895)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='19.077'
					x2='19.077'
					transform='translate(-108.623 1075.709)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='20.135'
					x2='20.135'
					transform='translate(-193.906 1159.934)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='15.697'
					x2='15.697'
					transform='translate(20.158 961.708)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='22.16'
					x2='22.16'
					transform='translate(-63.55 1038.953)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='8.723'
					x2='8.723'
					transform='translate(-132.469 1121.309)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='162.884'
					x2='162.884'
					transform='translate(-115.629 961.708)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='66.876'
					x2='66.876'
					transform='translate(-204.544 1146.631)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='62.021'
					x2='62.021'
					transform='translate(-3.366 961.708)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='44.32'
					x2='44.32'
					transform='translate(-85.71 1061.753)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='33.438'
					x2='33.438'
					transform='translate(-193.144 1180.069)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='23.008'
					x2='23.008'
					transform='translate(29.944 978.812)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeMiterlimit='10'
					y1='18.299'
					x2='18.299'
					transform='translate(-119.244 1132.709)'
				/>
			</g>
		</svg>
	);
}
