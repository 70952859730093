import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import demoEn from '../public/locales/en/demo.json';
import demoHu from '../public/locales/hu/demo.json';

import homeEn from '../public/locales/en/home.json';
import homeHu from '../public/locales/hu/home.json';
import homeRo from '../public/locales/ro/home.json';

import commonEn from '../public/locales/en/common.json';
import commonHu from '../public/locales/hu/common.json';
import commonRo from '../public/locales/ro/common.json';

import authEn from '../public/locales/en/auth.json';
import authHu from '../public/locales/hu/auth.json';
import authRo from '../public/locales/ro/auth.json';

import errorEn from '../public/locales/en/error.json';
import errorHu from '../public/locales/hu/error.json';
import errorRo from '../public/locales/ro/error.json';

import newsEn from '../public/locales/en/news.json';
import newsHu from '../public/locales/hu/news.json';
import newsRo from '../public/locales/ro/news.json';

import navEn from '../public/locales/en/navigation.json';
import navHu from '../public/locales/hu/navigation.json';
import navRo from '../public/locales/ro/navigation.json';

import hotelEn from '../public/locales/en/hotel.json';
import hotelHu from '../public/locales/hu/hotel.json';
import hotelRo from '../public/locales/ro/hotel.json';

import homeMadefalvaEn from '../public/locales/en/homeMadefalva.json';
import homeMadefalvaHu from '../public/locales/hu/homeMadefalva.json';
import homeMadefalvaRo from '../public/locales/ro/homeMadefalva.json';

import slightEn from '../public/locales/en/sight.json';
import slightRo from '../public/locales/ro/sight.json';
import slightHu from '../public/locales/hu/sight.json';

import communityEn from '../public/locales/en/community.json';
import communityRo from '../public/locales/ro/community.json';
import communityHu from '../public/locales/hu/community.json';

import councilEn from '../public/locales/en/council.json';
import councilRo from '../public/locales/ro/council.json';
import councilHu from '../public/locales/hu/council.json';

import livesEn from '../public/locales/en/lives.json';
import livesRo from '../public/locales/ro/lives.json';
import livesHu from '../public/locales/hu/lives.json';

import siculicidiumHistoryEn from '../public/locales/en/siculicidiumHistory.json';
import siculicidiumHistoryRo from '../public/locales/ro/siculicidiumHistory.json';
import siculicidiumHistoryHu from '../public/locales/hu/siculicidiumHistory.json';

import staticEn from '../public/locales/en/static.json';
import staticRo from '../public/locales/ro/static.json';
import staticHu from '../public/locales/hu/static.json';

import madefalvaHistoryEn from '../public/locales/en/madefalvaHistory.json';
import madefalvaHistoryHu from '../public/locales/hu/madefalvaHistory.json';
import madefalvaHistoryRo from '../public/locales/ro/madefalvaHistory.json';

import monumentHistoryEn from '../public/locales/en/monumentHistory.json';
import monumentHistoryHu from '../public/locales/hu/monumentHistory.json';
import monumentHistoryRo from '../public/locales/ro/monumentHistory.json';

import gazetteEn from '../public/locales/en/gazette.json';
import gazetteHu from '../public/locales/hu/gazette.json';
import gazetteRo from '../public/locales/ro/gazette.json';

import imageSelectorEn from '../public/locales/en/imageSelector.json';
import imageSelectorHu from '../public/locales/hu/imageSelector.json';
import imageSelectorRo from '../public/locales/ro/imageSelector.json';

import fileSelectorEn from '../public/locales/en/fileSelector.json';
import fileSelectorHu from '../public/locales/hu/fileSelector.json';
import fileSelectorRo from '../public/locales/ro/fileSelector.json';

const resources = {
	en: {
		home: homeEn,
		demo: demoEn,
		common: commonEn,
		auth: authEn,
		error: errorEn,
		news: newsEn,
		navigation: navEn,
		hotel: hotelEn,
		homeMadefalva: homeMadefalvaEn,
		sight: slightEn,
		community: communityEn,
		council: councilEn,
		lives: livesEn,
		siculicidiumHistory: siculicidiumHistoryEn,
		static: staticEn,
		madefalvaHistory: madefalvaHistoryEn,
		monumentHistory: monumentHistoryEn,
		gazette: gazetteEn,
		imageSelector: imageSelectorEn,
		fileSelector: fileSelectorEn
	},
	hu: {
		home: homeHu,
		demo: demoHu,
		common: commonHu,
		auth: authHu,
		error: errorHu,
		news: newsHu,
		navigation: navHu,
		hotel: hotelHu,
		homeMadefalva: homeMadefalvaHu,
		sight: slightHu,
		community: communityHu,
		council: councilHu,
		lives: livesHu,
		siculicidiumHistory: siculicidiumHistoryHu,
		static: staticHu,
		madefalvaHistory: madefalvaHistoryHu,
		monumentHistory: monumentHistoryHu,
		gazette: gazetteHu,
		imageSelector: imageSelectorHu,
		fileSelector: fileSelectorHu
	},
	ro: {
		home: homeRo,
		common: commonRo,
		auth: authRo,
		error: errorRo,
		news: newsRo,
		navigation: navRo,
		hotel: hotelRo,
		homeMadefalva: homeMadefalvaRo,
		sight: slightRo,
		community: communityRo,
		council: councilRo,
		lives: livesRo,
		siculicidiumHistory: siculicidiumHistoryRo,
		static: staticRo,
		madefalvaHistory: madefalvaHistoryRo,
		monumentHistory: monumentHistoryRo,
		gazette: gazetteRo,
		imageSelector: imageSelectorRo,
		fileSelector: fileSelectorRo
	}
};

i18n
	.use(reactI18nextModule) // passes i18n down to react-i18next
	.init(
		{
			resources,
			ns: [
				'home',
				'demo',
				'common',
				'navigation',
				'hotel',
				'homeMadefalva',
				'community',
				'council',
				'lives',
				'siculicidiumHistory',
				'static',
				'madefalvaHistory',
				'monumentHistory',
				'gazette',
				'imageSelector',
				'fileSelector'
			],
			defaultNS: 'common',
			lng: 'hu',

			keySeparator: false, // we do not use keys in form messages.welcome

			interpolation: {
				escapeValue: false // react already safes from xss
			}
		},
		() => {
			i18n.t('hotel');
			i18n.t('home'); // key in moduleA namespace (defined default)
			i18n.t('common:home'); // key in common namespace
			i18n.t('homeMadefalva');
			i18n.t('community');
			i18n.t('council');
			i18n.t('lives');
			i18n.t('siculicidiumHistory');
			i18n.t('static');
			i18n.t('madefalvaHistory');
			i18n.t('monumentHistory');
			i18n.t('gazette');
			i18n.t('imageSelector');
			i18n.t('fileSelector');
		}
	);

export default i18n;
