import React from 'react';
import { Box, makeStyles, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Header } from './components/Header';
import 'moment/min/locales';
import { NewsCard } from './components/NewsCard';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		maxWidth: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	mainContainer: {
		// display: "flex",
		// flexWrap: "wrap",
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			// justifyContent: "",
		},
	},
}));

function News() {
	const data = useSelector((state) => state.news.list);
	const classes = useStyles();
	return (
		<Box className={classes.pageContainer}>
			<Header />
			{data?.items && (
				<Box className={classes.mainContainer}>
					<Grid container>
						{data.items.map((item) => (
							<Grid key={item.id} item xs={12} xl={3} md={4} sm={6} lg={3}>
								<NewsCard newsItem={item} />
							</Grid>
						))}
					</Grid>
				</Box>
			)}
		</Box>
	);
}
export default News;
