import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function RegistryOffice(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 62.77 79.9'>
			<title>anyakönyvi iroda</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M7.4,45.88l3.41-24.77a1.41,1.41,0,0,1,1.12-1.19l7.46-1.57,7.47,1.57A1.42,1.42,0,0,1,28,21.11l3.41,24.77'
					/>
					<circle
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						cx='19.39'
						cy='7.59'
						r='7.09'
					/>
					<circle
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						cx='43.37'
						cy='7.59'
						r='7.09'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M25,78V46.07H13.81V78a1.42,1.42,0,0,0,1.41,1.42h8.34A1.43,1.43,0,0,0,25,78Z'
					/>
					<line
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='19.39'
						y1='52.44'
						x2='19.39'
						y2='79.4'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M31.38,45.88l5-24a2.84,2.84,0,0,1,2-2.17l5-1.32,5,1.32a2.84,2.84,0,0,1,2,2.17l5,24'
					/>
					<line
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='46.69'
						y1='61.7'
						x2='46.69'
						y2='79.4'
					/>
					<line
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='40.06'
						y1='79.4'
						x2='40.06'
						y2='61.7'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M52.41,61.24H34.34A1.42,1.42,0,0,1,33,59.43l4.81-16.91H49l4.81,16.91A1.42,1.42,0,0,1,52.41,61.24Z'
					/>
					<line
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='19.39'
						y1='15.05'
						x2='19.39'
						y2='18.35'
					/>
					<line
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='43.37'
						y1='14.67'
						x2='43.37'
						y2='18.35'
					/>
					<polyline
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						points='13.8 24.67 13.8 43.08 24.98 43.08 24.98 24.67'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M39.56,26.44,38,37.92a1.41,1.41,0,0,0,1.4,1.61h7.93a1.41,1.41,0,0,0,1.4-1.61L47.19,26.44'
					/>
					<line
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='0.5'
						y1='79.4'
						x2='62.27'
						y2='79.4'
					/>
				</g>
			</g>
		</svg>
	);
}
