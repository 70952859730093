import store from '../store';
import { GET_COMMON } from '../types';
import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';

export const getByCategory = async (category) => {
	await api
		.get(`common?category=${category}`)
		.then((response) => {
			if (response.data) {
				store.dispatch({
					type: GET_COMMON,
					payload: response.data
				});
			}
		})
		.catch(() =>
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			})
		);
};

export const saveElement = async (data) =>
	api
		.post('/common', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
			getByCategory(data.category);
			return true;
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
			return false;
		});

export const updateElement = (id, data) => {
	api.put(`/common/${id}`, data).then(() => getByCategory(data.category));
};
