import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	cls1: { fill: '#160e44' },
	cls2: { fill: '#FFFFFF' },
	cls3: { fill: '#ee2c48' }
}));

const HonoraryCitizens = (props) => {
	const classes = useStyles();
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 104.43262 76.76318'>
			<title>HonoraryCitizens</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className={classes.cls1}
						d='M100.87109,36.603H94.61133l-4.123-8.57813a.49968.49968,0,1,1,.90039-.43359L95.24023,35.603h4.01954l-4.33789-8.76074a.50006.50006,0,1,1,.89648-.44336Z'
					/>
					<path
						className={classes.cls1}
						d='M84.96191,36.603H78.70215l5.02246-10.11719a.50028.50028,0,0,1,.89648.44434L80.31543,35.603H84.333l3.85156-8.01172a.49968.49968,0,1,1,.90039.43359Z'
					/>
					<circle
						className={classes.cls2}
						cx='89.78672'
						cy='14.64603'
						r='14.1461'
					/>
					<path
						className={classes.cls1}
						d='M89.78711,29.292a14.646,14.646,0,1,1,14.64551-14.646A14.66287,14.66287,0,0,1,89.78711,29.292Zm0-28.292a13.646,13.646,0,1,0,13.64551,13.646A13.66188,13.66188,0,0,0,89.78711,1Z'
					/>
					<path
						className={classes.cls3}
						d='M20.64445,76.23823a25.21,25.21,0,1,1,50.41992.01907l15.69032.00593A40.90029,40.90029,0,1,0,4.95412,76.2323Z'
					/>
					<path
						className={classes.cls3}
						d='M91.209,76.76318H.5a.5.5,0,0,1,0-1H91.209a.5.5,0,0,1,0,1Z'
					/>
					<circle
						className={classes.cls3}
						cx='89.78672'
						cy='14.64603'
						r='10.55528'
					/>
				</g>
			</g>
		</svg>
	);
};

export default HonoraryCitizens;
