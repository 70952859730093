import store from '../store';
import {
	SHOW_MENU,
	SHOW_LARGE_MENU,
	SHOW_FOOTER,
	SET_FOOTER_REQUIRED
} from '../types';

export const showMenu = async (show) => {
	store.dispatch({
		type: SHOW_MENU,
		payload: show
	});
};

export const showLargeMenu = async (show) => {
	store.dispatch({
		type: SHOW_LARGE_MENU,
		payload: show
	});
};

export const showFooter = (show) => {
	store.dispatch({
		type: SHOW_FOOTER,
		payload: show
	});
};

export const setFooterRequired = (payload) => {
	store.dispatch({
		type: SET_FOOTER_REQUIRED,
		payload
	});
};
