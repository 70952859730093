import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function DotIcon(props) {
	return (
		<svg
			viewBox='0 0 50 50'
			width={props.width ? props.width : '100px'}
			height={props.height ? props.height : '100px'}
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'>
			<circle
				cx='25'
				cy='25'
				r='25'
				fill={props.fill ? props.fill : '#ee2c48'}
			/>
		</svg>
	);
}
