import { GET_SIGHTS_TO_HOME, GET_SIGHTS_TO_HOME_MOBILE, GET_HOTEL_TO_HOME, LOAD_EMPTY_SECTIONS } from '../types';

const INITIAL_STATE = {
	lives: [],
	neigh: [],
	hotel: [],
	emptySections: [],
};

export default function home(state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_SIGHTS_TO_HOME: {
			return { ...state, [action.category]: { ...action.payload } };
		}
		case GET_SIGHTS_TO_HOME_MOBILE: {
			return { ...state, [`${action.category}Mobile`]: { ...action.payload } };
		}
		case GET_HOTEL_TO_HOME: {
			return { ...state, hotel: { ...action.payload } };
		}
		case LOAD_EMPTY_SECTIONS:
			return { ...state, emptySections: action.payload };
		default:
			return state;
	}
}
