import Cookies from 'js-cookie';
import i18n from '../../../lib/i18n';
import store from '../../store';
import { SET_LANGUAGE } from '../../types';

export const changeLanguage = async (lang) => {
	await i18n.changeLanguage(lang);
	Cookies.set('lang', lang, { expires: 600 });
	store.dispatch({ type: SET_LANGUAGE, payload: lang });
	return lang;
};

export const checkLanguage = () => {
	const settedLanguage = Cookies.get('lang');

	if (settedLanguage && settedLanguage !== 'undefined') {
		return true;
	}
	return false;
};
