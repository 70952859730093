import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { Login } from './login';

const Auth = (props) => {
	const { t } = props;
	//   const me = useSelector((props) => props.me);
	return (
		<>
			<Container>
				<Typography variant='h2'> {t('login')}</Typography>
				<Login />
			</Container>
		</>
	);
};
export default withNamespaces('auth')(Auth);
