import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Church1(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '102.4'}
			height={props.height ? props.height : '131.8'}
			viewBox='0 0 102.4 131.8'>
			<g transform='translate(-0.1 -513.2)'>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M60.1,608H32.4V596.5H60.1V608Zm-26.7-1H59.1v-9.5H33.4Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M57.1,584.1a.664.664,0,0,1-.5-.4L46.2,529.3,35.9,583.7a.51.51,0,1,1-1-.2L46.2,524l11.4,59.6c.1.2-.1.5-.5.5Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M41,580.3h0a.536.536,0,0,1-.5-.5l5.2-53.1h1l5.2,53.1a.453.453,0,1,1-.9.1l-4.7-48-4.7,48A.675.675,0,0,1,41,580.3Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M60.1,594.7H32.4v-9.3l3.1-2.4,2.3,1.9,8.5-16,8.5,16,2.4-1.9,3,2.4v9.3Zm-26.7-1H59.1v-7.8l-2-1.6-2.7,2.1L46.2,571,38,586.4l-2.6-2.1-2,1.6Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M41.4,592.8a.472.472,0,0,1-.5-.5v-4.5a.5.5,0,0,1,1,0v4.5A.472.472,0,0,1,41.4,592.8Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M45.9,592.8a.472.472,0,0,1-.5-.5v-4.5a.5.5,0,0,1,1,0v4.5A.472.472,0,0,1,45.9,592.8Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M50.4,592.8a.472.472,0,0,1-.5-.5v-4.5a.5.5,0,0,1,1,0v4.5A.472.472,0,0,1,50.4,592.8Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M46.2,583.6a2.582,2.582,0,0,1-2.6-2.6,2.6,2.6,0,0,1,5.2,0A2.582,2.582,0,0,1,46.2,583.6Zm0-4.3a1.6,1.6,0,1,0,1.6,1.6A1.58,1.58,0,0,0,46.2,579.3Z'
				/>
				<circle
					className='a'
					fill={props.base ? props.base : '#160e44'}
					cx='1.4'
					cy='1.4'
					r='1.4'
					transform='translate(44.9 523.3)'
				/>
				<rect
					className='b'
					fill={props.base ? props.base : '#ffff'}
					width='31.2'
					height='2.8'
					transform='translate(30.4 594.2)'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M62,597.5H29.9v-3.8H62Zm-31.1-1H61v-1.8H30.9Z'
				/>
				<line
					className='b'
					fill={props.base ? props.base : '#ffff'}
					y2='10.7'
					transform='translate(46.2 513.6)'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M46.2,524.8a.472.472,0,0,1-.5-.5V513.7a.5.5,0,0,1,1,0v10.6A.536.536,0,0,1,46.2,524.8Z'
				/>
				<line
					className='b'
					fill={props.base ? props.base : '#ffff'}
					x1='6.7'
					transform='translate(42.9 517.9)'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M49.6,518.4H43a.5.5,0,0,1,0-1h6.6a.472.472,0,0,1,.5.5A.536.536,0,0,1,49.6,518.4Z'
				/>
				<path
					className='c'
					fill={props.dotfill ? props.dotfill : '#ee2c48'}
					d='M89.9,596.6A12.6,12.6,0,1,0,77.3,584,12.676,12.676,0,0,0,89.9,596.6Z'
				/>
				<path
					className='c'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M20.7,644.5a25.2,25.2,0,1,1,50.4,0H86.8a40.9,40.9,0,1,0-81.8,0Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M71.1,645h0a.472.472,0,0,1-.5-.5,24.753,24.753,0,0,0-24.7-24.7h0a24.753,24.753,0,0,0-24.7,24.7.472.472,0,0,1-.5.5h0a.472.472,0,0,1-.5-.5,25.692,25.692,0,0,1,25.7-25.7h0a25.692,25.692,0,0,1,25.7,25.7A.472.472,0,0,1,71.1,645Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M45.9,645a.472.472,0,0,1-.5-.5V619.3a.5.5,0,0,1,1,0v25.2A.472.472,0,0,1,45.9,645Z'
				/>
				<path
					className='c'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M91.3,645H.6a.5.5,0,0,1,0-1H91.3a.5.5,0,0,1,0,1Z'
				/>
			</g>
		</svg>
	);
}
