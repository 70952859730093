import React from 'react';
import PropTypes from 'prop-types';
import {
	Typography,
	Breadcrumbs as MaterialBreadcrumbs,
	makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const BP1 = '@media (max-width: 360px)';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: 50,
		paddingLeft: 20
	},
	text: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px',
		textTransform: 'none',
		color: theme.palette.primary.dark,
		transition: 'font-size .3s',
		[BP1]: {
			fontSize: '11px'
		}
	},
	current: { color: '#93959F' },
	clickable: {
		color: theme.palette.primary.dark,
		'&:hover': {
			cursor: 'pointer'
		}
	}
}));

const Breadcrumbs = (props) => {
	const { breadcrumbs } = props;
	const classes = useStyles();

	const history = useHistory();

	const navTo = (href, clickable) => {
		if (clickable) {
			history.push(href);
		}
	};

	return (
		<MaterialBreadcrumbs className={classes.root} separator='//'>
			{breadcrumbs.map((breadcrumb, index) => {
				const rootClass = clsx(
					classes.text,
					breadcrumb.final ? classes.current : classes.clickable
				);
				return (
					<Typography
						key={index}
						color='inherit'
						variant='overline'
						classes={{ root: rootClass }}
						onClick={() => navTo(breadcrumb.href, !breadcrumb.final)}>
						{breadcrumb.name}
					</Typography>
				);
			})}
		</MaterialBreadcrumbs>
	);
};

Breadcrumbs.propTypes = {
	breadcrumbs: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			href: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
				.isRequired,
			final: PropTypes.bool
		})
	).isRequired
};

export default Breadcrumbs;
