import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import { ImageSelector } from '../../../components/modals';
import {
	Slider,
	Select,
	TextField,
	RichTextEditor
} from '../../../components/form';

import EnableRow from './EnableRow';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '70vw'
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px'
	}
}));

export default withNamespaces(['common', 'hotel'])((props) => {
	const { item, open, setOpen, onConfirm, t } = props;

	const classes = useStyles();

	return (
		<Formik
			enableReinitialize
			initialValues={{
				content: item
					? item.content
					: [
							{
								lang: 'hu'
							},
							{
								lang: 'ro'
							}
					  ],
				slider: item
					? item.slider
					: {
							content: [
								{
									num: 1,
									fileId: null
								},
								{
									num: 2,
									fileId: null
								},
								{
									num: 3,
									fileId: null
								},
								{
									num: 4,
									fileId: null
								},
								{
									num: 5,
									fileId: null
								},
								{
									num: 6,
									fileId: null
								}
							]
					  }
			}}
			onSubmit={(values) => {
				const sliderContent = values.slider.content.filter(
					(item) => item.fileId !== null || item.file.id !== null
				);

				values.content.forEach((item) => {
					item.headerImageId = item.headerImage.id;
					delete item.headerImage;
				});

				values.slider.content.forEach((item) => {
					item.fileId = item.file.id;
				});
				const tmp = values;
				tmp.slider.content = sliderContent;

				onConfirm(tmp);
				setOpen(false);
			}}>
			{({
				values,
				errors,
				touched,
				handleReset,
				setFieldValue,
				submitForm
			}) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>
						{item?.content[0]?.name ? item?.content[0]?.name : 'Új szálláshely'}
					</DialogTitle>
					<DialogContent>
						<EnableRow
							active={item.active}
							itemId={item.id}
							onFinish={() => setOpen(false)}
						/>
						<Form>
							<Typography variant='h2'>{t('headerimages')}</Typography>
							<FieldArray
								name='content'
								render={() => (
									<Box>
										{values.content.map((item, index) => (
											<Box key={index} className={classes.fieldArrayItem}>
												<Typography variant='h2'>
													{t(`common:${item.lang}`)}
												</Typography>
												<ImageSelector
													fieldName={`content[${index}].headerImageId`}
													image={item.headerImage}
													setImageId={(id) => {
														setFieldValue(
															`content[${index}].headerImageId`,
															id
														);
													}}
												/>
											</Box>
										))}
									</Box>
								)}
							/>
							<FieldArray
								name='content'
								render={() => (
									<Box>
										{values.content.map((item, index) => (
											<Box key={index} className={classes.fieldArrayItem}>
												<Typography variant='h2'>
													{t(`common:${item.lang}`)}
												</Typography>
												<Field
													name={`content[${index}].name`}
													label={t('hotel:createHotelName')}
													component={TextField}
												/>
												<Field
													name={`content[${index}].phone`}
													label={t('hotel:createHotelPhone')}
													component={TextField}
												/>
												<Field
													name={`content[${index}].email`}
													label={t('hotel:createHotelEmail')}
													component={TextField}
												/>
												<Field
													name={`content[${index}].address`}
													label={t('hotel:createHotelAddress')}
													component={TextField}
												/>
												<Field
													name={`content[${index}].page`}
													label={t('hotel:createHotelPage')}
													component={TextField}
												/>
												<Field
													name={`content[${index}].intro`}
													label={t('hotel:createHotelIntro')}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].description`}
													component={RichTextEditor}
													defaultValue={item.description}
													size='small'
													label={t('hotel:createHotelDescription')}
												/>
											</Box>
										))}
									</Box>
								)}
							/>
							<Typography variant='h2'>{t('sliderimages')}</Typography>
							<Slider values={values.slider} setFieldValue={setFieldValue} />
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='default'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='secondary'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
