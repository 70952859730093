import React from 'react';
import { Box, makeStyles, useMediaQuery, withWidth } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { MadefalvaMegerint1, MadefalvaMegerint, MadefalvaMegerint5 } from '.';
import { MadefalvaHistory, MonumentHistory } from '../../../History/components';
import SightToHome from '../../../Sights/components/Home/SightToHome';
import { SightCategories } from '../../../../../config/sight-categories.enum';
import { HotelToHome } from '../../../Hotel/components/Home';
import SightToHomeMobile from '../../../Sights/components/Home/SightToHomeMobile';

const BP680 = '@media (max-width: 680px)';

const useStyles = makeStyles((theme) => ({
	container: {},
	slideContainer: {
		position: 'relative',
		height: 900,
		zIndex: 1000,
		[theme.breakpoints.only('xs')]: {
			height: 730,
		},
	},
	madMegerit: {
		position: 'relative',
		height: 900,
		marginTop: '20px',
		[theme.breakpoints.only('xs')]: {
			height: 530,
			display: 'flex',
			flexDirection: 'column',
		},
		[theme.breakpoints.only('sm')]: {
			height: 700,
		},
	},
	madMegerit1: {
		position: 'relative',
		height: 900,
		[theme.breakpoints.only('xs')]: {
			height: 860,
			display: 'flex',
			flexDirection: 'column',
		},
		[theme.breakpoints.only('sm')]: {
			height: 930,
		},
	},
	megerint5: {
		position: 'relative',
		height: 900,
		[theme.breakpoints.only('xs')]: {
			height: 530,
			display: 'flex',
			flexDirection: 'column',
		},
		[theme.breakpoints.only('sm')]: {
			height: 680,
		},
	},
	madefTortenet: {
		position: 'relative',
		height: 900,
		[theme.breakpoints.only('xs')]: {
			height: 680,
			display: 'flex',
			flexDirection: 'column',
		},
		[theme.breakpoints.only('sm')]: {
			height: 930,
		},
	},
	monument: {
		position: 'relative',
		height: 900,
		[theme.breakpoints.only('xs')]: {
			height: 580,
			display: 'flex',
			flexDirection: 'column',
		},
	},
	sightContainer: {
		position: 'relative',
		height: 900,
		[BP680]: {
			paddingTop: '40px',
			height: '500px',
		},
	},
}));

function HomeContentWithoutScroll() {
	const classes = useStyles();
	const emptySections = useSelector((state) => state.home.emptySections);
	const isMobile = useMediaQuery('(max-width: 680px)');

	const isEmpty = (section) => emptySections.includes(section);

	const renderSight = (name) => {
		if (isEmpty(name)) {
			return <></>;
		}
		return (
			<Box className={classes.sightContainer}>
				{isMobile ? (
					<SightToHomeMobile category={SightCategories[name]} />
				) : (
					<SightToHome
						category={SightCategories[name]}
						showAnimation
						style={{ display: !isMobile ? 'block' : 'none' }}
					/>
				)}
			</Box>
		);
	};

	return (
		<Box className={classes.container}>
			<Box className={classes.madMegerit}>
				<MadefalvaMegerint showAnimation />
			</Box>
			<Box className={classes.madMegerit1}>
				<MadefalvaMegerint1 showAnimation />
			</Box>
			{renderSight('around')}
			{renderSight('neigh')}

			{!isEmpty('hotels') && (
				<Box className={classes.sightContainer}>
					<HotelToHome category={SightCategories.neigh} showAnimation />
				</Box>
			)}
			<Box className={classes.megerint5}>
				<MadefalvaMegerint5 showAnimation />
			</Box>
			<Box className={classes.slideContainer}>
				<MadefalvaHistory showAnimation />
			</Box>
			<Box className={classes.slideContainer}>
				<MonumentHistory showAnimation />
			</Box>
		</Box>
	);
}

export default withWidth()(HomeContentWithoutScroll);
