import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Monument(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 102.4 75.458'>
			<g transform='translate(-0.1 -859.142)'>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M65.9,934.6a.472.472,0,0,1-.5-.5V921.2a.5.5,0,0,1,1,0v12.9A.536.536,0,0,1,65.9,934.6Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M26,934.6a.472.472,0,0,1-.5-.5V921.2a.5.5,0,0,1,1,0v12.9A.536.536,0,0,1,26,934.6Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M52,931.3H39.9v-10a.5.5,0,0,1,1,0v9H51v-9a.5.5,0,0,1,1,0Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M66.4,921.7h-41l-1.3-4.2H67.8Zm-40.3-1H65.7l.7-2.2h-41Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M58.5,918.4a.472.472,0,0,1-.5-.5V914H33.8v3.8a.5.5,0,0,1-1,0V913H59v4.8C59,918.2,58.8,918.4,58.5,918.4Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M40.4,918.1a.472.472,0,0,1-.5-.5v-3.9a.5.5,0,0,1,1,0v3.9A.472.472,0,0,1,40.4,918.1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M51.5,918.1a.472.472,0,0,1-.5-.5v-3.9a.5.5,0,0,1,1,0v3.9A.472.472,0,0,1,51.5,918.1Z'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M20.7,934a25.2,25.2,0,1,1,50.4,0H86.8A40.9,40.9,0,1,0,5,934Z'
				/>
				<path className='c' d='M41.5,887.5' strokeLinecap='round' />
				<path
					className='d'
					d='M83,899.1'
					stroke='#160e44'
					strokeMiterlimit='10'
				/>
				<path
					className='d'
					d='M60.7,885.5'
					stroke='#160e44'
					strokeMiterlimit='10'
				/>
				<path
					className='b'
					fill={props.dotfill ? props.dotfill : '#ee2c48'}
					d='M89.9,885.2a12.6,12.6,0,1,0-12.6-12.6A12.676,12.676,0,0,0,89.9,885.2Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M45.9,868.6a3,3,0,1,1,2.9-3A2.924,2.924,0,0,1,45.9,868.6Zm0-5.1a1.923,1.923,0,0,0-1.9,2,1.9,1.9,0,1,0,3.8,0A1.923,1.923,0,0,0,45.9,863.5Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M45.9,863.5l-8.1-2.4-.1-.4a.55.55,0,0,1,.3-.5,6.239,6.239,0,0,1,7.9.8,6.381,6.381,0,0,1,7.9-.8l.3.2-.1.7-.4.1Zm-6.4-3,6.4,1.9,6.4-1.9a5.4,5.4,0,0,0-6.1,1.5l-.4.4-.4-.4A5.066,5.066,0,0,0,39.5,860.5Zm13.9.5Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M54.5,914a.472.472,0,0,1-.5-.5v-4.8L49,880l1.9-2.5L49,875.3V871l-2.2-1.9H45.2L42.9,871v4.3L41,877.5l1.9,2.5-5,28.8v4.7a.5.5,0,0,1-1,0v-4.8l5-28.4-2.1-2.8,2.1-2.5v-4.4l2.9-2.4h2.4l2.9,2.4V875l2.1,2.5-2.1,2.8,5,28.3v4.9A.634.634,0,0,1,54.5,914Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M51.5,878.1H40.3a.5.5,0,0,1,0-1H51.5a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M49.3,875.7H42.5a.5.5,0,0,1,0-1h6.8a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M49.3,871.5H42.5a.5.5,0,0,1,0-1h6.8a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M49.2,880.6H42.5a.5.5,0,0,1,0-1h6.7a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M49.8,885.3H41.9a.5.5,0,0,1,0-1h7.9a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M51.1,890.2H40.7a.5.5,0,0,1,0-1H51.1a.5.5,0,1,1,0,1Z'
				/>
				<path className='c' d='M50.3,889.6' />
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M51.3,894.9H40.5a.5.5,0,0,1,0-1H51.3a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M52.3,899.8H39.5a.5.5,0,0,1,0-1H52.3a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M53.2,904.6H38.5a.5.5,0,0,1,0-1H53.2a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M54.5,909.3h0l-17.1-.1a.5.5,0,1,1,0-1h0l17.1.1a.472.472,0,0,1,.5.5A.536.536,0,0,1,54.5,909.3Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M40.4,908.9a.472.472,0,0,1-.5-.5v-4.1a.5.5,0,0,1,1,0v4.1A.472.472,0,0,1,40.4,908.9Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M51.5,908.9a.472.472,0,0,1-.5-.5v-4.1a.5.5,0,0,1,1,0v4.1A.472.472,0,0,1,51.5,908.9Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M42.4,899.4a.472.472,0,0,1-.5-.5v-4.1a.5.5,0,0,1,1,0v4.1A.472.472,0,0,1,42.4,899.4Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M49.5,899.4a.472.472,0,0,1-.5-.5v-4.1a.5.5,0,0,1,1,0v4.1A.472.472,0,0,1,49.5,899.4Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M43.4,889.7a.472.472,0,0,1-.5-.5v-4.1a.5.5,0,0,1,1,0v4.1A.472.472,0,0,1,43.4,889.7Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M48.5,889.7a.472.472,0,0,1-.5-.5v-4.1a.5.5,0,0,1,1,0v4.1A.472.472,0,0,1,48.5,889.7Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M45.9,884.8a.472.472,0,0,1-.5-.5v-3.7a.5.5,0,0,1,1,0v3.7A.536.536,0,0,1,45.9,884.8Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M45.9,894.4a.472.472,0,0,1-.5-.5v-3.7a.5.5,0,0,1,1,0v3.7A.536.536,0,0,1,45.9,894.4Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M45.9,904a.472.472,0,0,1-.5-.5v-3.7a.5.5,0,0,1,1,0v3.7A.536.536,0,0,1,45.9,904Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M45.9,913.7a.472.472,0,0,1-.5-.5v-4.4a.5.5,0,0,1,1,0v4.4A.536.536,0,0,1,45.9,913.7Z'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M91.3,934.6H.6a.5.5,0,0,1,0-1H91.3a.5.5,0,0,1,0,1Z'
				/>
			</g>
		</svg>
	);
}
