import { SET_POST } from '../types';

export default function post(state = {}, action) {
	switch (action.type) {
		case SET_POST:
			return { ...state, data: action.payload };
		default:
			return state;
	}
}
