import React from 'react';
import { Image } from '../../../components/image';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Header } from './components/header';
import 'moment/min/locales';
import { withNamespaces } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { HotelCard } from './components/hotelCard';
import { Village } from '../../../components/icons';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 24,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	contentContainer: {
		maxWidth: 1164,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 60
	},
	content: {
		marginTop: 65
	},

	hotelTitle: {
		color: '#070C35',
		fontSize: '32px',
		fontWeight: 900,
		fontFamily: 'Gilroy',
		[theme.breakpoints.down('md')]: {
			marginLeft: 40
		}
	}
}));

function Hotel(props) {
	const classes = useStyles();
	const { items } = useSelector((state) => state.hotel.list);
	const { t } = props;

	return (
		<Box className={classes.pageContainer}>
			<Header />
			<Box className={classes.contentContainer}>
				<Typography className={classes.hotelTitle}>{t('title')}</Typography>

				{items && (
					<Grid
						container
						direction='column'
						justify='flex-start'
						alignItems='center'
						className={classes.content}>
						{items.map((hotel, index) => (
							<HotelCard key={index} hotelItem={hotel} />
						))}
					</Grid>
				)}
			</Box>
			<Village />
		</Box>
	);
}
export default withNamespaces('hotel')(Hotel);
