import { GET_SIGHT } from '../../types';

export default function sight(state = {}, action) {
	switch (action.type) {
		case GET_SIGHT:
			return action.payload;
		default:
			return state;
	}
}
