import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage10.png';

import { Nature4 } from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		backgroundSize: 'cover',
		width: '100%',
		height: '100%'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		width: 545,
		height: 734,
		top: 0,
		right: 0,

		[theme.breakpoints.only('xs')]: {
			top: 200,
			width: '100%',
			left: 0,
			height: 500
		},
		[theme.breakpoints.only('md')]: {
			width: 550,
			height: 800,
			right: 0,
			left: 'auto'
		},
		[theme.breakpoints.only('sm')]: {
			width: 350,
			height: 500
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute'
		},
		width: 1169,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 129,
		[theme.breakpoints.only('xs')]: {
			top: 10,
			width: '100%'
		},
		[theme.breakpoints.only('md')]: {
			top: 0,
			width: '100%'
		},
		[theme.breakpoints.only('lg')]: {
			top: 50
		},
		[theme.breakpoints.only('sm')]: {
			top: 0,
			width: '100%'
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},
	mainTitleBox: {
		top: 348,
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: 231,
		textAlign: 'left',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			left: 30,
			top: 630
		},
		[theme.breakpoints.only('md')]: {
			left: 140
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#E0B080',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: 19,
		marginRight: 122,
		marginBottom: 11,
		marginLeft: 86,
		[theme.breakpoints.only('xs')]: {
			marginTop: 12,
			marginRight: 40,
			marginBottom: 7,
			marginLeft: 40,
			fontSize: 30,
			lineHeight: '30px',
			letterSpacing: '0px'
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: 93,
		width: 408,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			top: 0,
			width: '100%'
		},
		[theme.breakpoints.only('md')]: {
			width: 400,
			marginLeft: 30
		},
		[theme.breakpoints.only('sm')]: {
			left: 40
		}
	},
	descriptionContainer2: {
		position: 'absolute',
		top: 486,
		width: 624,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 700
		},
		[theme.breakpoints.only('md')]: {
			width: 500,
			marginLeft: 30
		},
		[theme.breakpoints.only('sm')]: {
			left: 40
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		flexWrap: 'wrap',
		[theme.breakpoints.only('xs')]: {
			fontSize: 16,
			marginLeft: 30,
			marginRight: 10
		}
	},
	iconContainer: {
		position: 'absolute',
		top: 209,
		left: 410,
		width: 200,
		height: 141,
		[theme.breakpoints.only('xs')]: {
			height: 100,
			right: 30,
			top: 535,
			left: 'auto'
		},
		[theme.breakpoints.only('md')]: {
			left: 330
		}
	}
}));

function MadefalvaHistory2(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);

	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='vintageimage'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						{t('madehistory3.maintitle')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='madehistory3.description1'>
							<br />
							<br />
						</Trans>
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer2}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='madehistory3.description2'>
							<br />
							<br />
						</Trans>
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.iconContainer}>
					<Nature4 width='100%' height='100%' />
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('madefalvaHistory')(MadefalvaHistory2);
