import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function FriendshipTowns(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 102.38 74.6'>
			<title>Asset 3</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M53,64.74A31.19,31.19,0,0,1,44.19,72'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M40.13,71.16A29.41,29.41,0,0,0,49.85,64'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M46.83,25.74a26.46,26.46,0,0,1-11.1,44'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M49.68,71.36a27.54,27.54,0,0,0,8.76-51.78'
					/>
					<path
						className='cls-2'
						fill={props.dotfill ? props.dotfill : '#ee2c48'}
						d='M89.79,25.19A12.6,12.6,0,1,0,77.19,12.6,12.61,12.61,0,0,0,89.79,25.19Z'
					/>
					<path
						className='cls-2'
						fill={props.fill ? props.fill : '#ee2c48'}
						d='M20.64,74.07a25.21,25.21,0,0,1,50.42,0H86.75a40.9,40.9,0,1,0-81.8,0Z'
					/>
					<line
						className='cls-3'
						fill='none'
						stroke={props.fill ? props.fill : '#ee2c48'}
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='0.5'
						y1='74.1'
						x2='91.21'
						y2='74.1'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M38.7,23.49a31.19,31.19,0,0,1,8.82-7.27'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M51.58,17.08a29.39,29.39,0,0,0-9.72,7.2'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M44.88,62.5a26.47,26.47,0,0,1,11.1-44'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M42,16.88a27.54,27.54,0,0,0-8.76,51.78'
					/>
				</g>
			</g>
		</svg>
	);
}
