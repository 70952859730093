export default {
	one: [
		{
			name: 'siculicidium',
			href: '/history/siculicidium',
		},
		{
			name: 'madefalva',
			href: '/history/madefalva',
		},
		{
			name: 'monument',
			href: '/history/monument',
		},
	],
	
};
