import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage08.png';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		width: 545,
		height: 734,
		top: 0,
		right: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 500,
			top: 450
		},
		[theme.breakpoints.only('sm')]: {
			width: 400,
			height: 600,
			top: 150
		}
	},
	pageContainer: {
		position: 'fixed',
		width: 1169,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 62,
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},
	mainTitleBox: {
		top: 285,
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: 207,
		textAlign: 'left',
		zIndex: 101,
		width: 600,

		[theme.breakpoints.only('xs')]: {
			width: '100%',
			left: 0,
			top: 0
		},
		[theme.breakpoints.only('sm')]: {
			right: 40,
			left: 'auto',
			top: 150
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#48B3C9',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: 19,
		marginRight: 39,
		marginBottom: 11,
		marginLeft: 30
	},
	descriptionContainer: {
		position: 'absolute',
		top: 83,
		width: 624,
		[theme.breakpoints.only('md')]: {
			width: 500
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 180
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			left: 0,
			top: 0
		}
	},
	descriptionContainer2: {
		position: 'absolute',
		top: 433,
		width: 480,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 950
		},
		[theme.breakpoints.only('sm')]: {
			width: 400,
			top: 350
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.down('md')]: {
			marginLeft: 30,
			marginRight: 20
		}
	},
	awesomeContainer2: {
		position: 'absolute',
		top: 414,
		left: 519,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		},
		zIndex: 101
	},
	awesomeContainer1: {
		position: 'absolute',
		top: 285,
		left: 0,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	awesomeText: {
		fontFamily: 'CLOAK',
		color: '#E0B080',
		letterSpacing: '0.68px',
		lineHeight: '92px',
		fontSize: '68px'
	}
}));

function MonumentHistory3(props) {
	const classes = useStyles();
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='bttmiddle'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='bttmiddle'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						<Trans i18nKey='monumhistory3.maintitle' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='bttmiddle'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='monumhistory3.description1' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='bttmiddle'
				unmountOnExit>
				<Box className={classes.descriptionContainer2}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='monumhistory3.description2' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.awesomeContainer1}>
					<Typography className={classes.awesomeText}>1870</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.awesomeContainer2}>
					<Typography className={classes.awesomeText}>1891</Typography>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('monumentHistory')(MonumentHistory3);
