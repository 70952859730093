import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Documents(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 86.04 86.04'>
			<title>documente</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className='cls-1'
						fill='#201943'
						d='M80.5,86H0V0H86V79.88ZM1,85H80.06l5-5.55V1H1Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M25.6,9.21H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H25.6a.5.5,0,0,1,.5.5A.5.5,0,0,1,25.6,9.21Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,77.83H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,77.83Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,17.79H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,17.79Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M77.13,9.21H50.42a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5H77.13a.5.5,0,0,1,.5.5A.5.5,0,0,1,77.13,9.21Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M74.28,77.83H50.42a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5H74.28a.5.5,0,0,1,.5.5A.5.5,0,0,1,74.28,77.83Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M77.13,17.79H50.42a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5H77.13a.5.5,0,0,1,.5.5A.5.5,0,0,1,77.13,17.79Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M22,64.15H13V26.35h9a8.83,8.83,0,0,1,6.27,2.26,9.07,9.07,0,0,1,2.7,7V55.07a11.28,11.28,0,0,1-.65,4,8.09,8.09,0,0,1-2.11,3A8.92,8.92,0,0,1,22,64.15Zm-3-5.94h3a3.34,3.34,0,0,0,2.27-.6A3.29,3.29,0,0,0,25,55.07V35.63a3.71,3.71,0,0,0-.81-2.69A3.17,3.17,0,0,0,22,32.29H19Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M67.25,64.15H61.31V26.35h5.94Z'
					/>
				</g>
			</g>
		</svg>
	);
}
