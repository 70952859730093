import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
	img: {
		width: '100%',
		height: 'auto'
	}
});

function Image(props) {
	const styles = useStyles();
	const { image, size, classes } = props;
	return (
		<div className={classes.root}>
			{image.url ? (
				<img
					src={size ? `${image.url}?size=${size}` : image.url}
					// src={image}
					className={clsx(classes.image, styles.img)}
					alt={image.id}
				/>
			) : (
				<img
					src={size ? `${image}?size=${size}` : image}
					// src={image}
					className={clsx(classes.image, styles.img)}
					alt={image.id}
				/>
			)}
		</div>
	);
}

Image.defaultProps = {
	classes: {
		root: {},
		image: {}
	}
};

export default Image;
