import React, { useState, useEffect, useRef } from 'react';
import { Box, makeStyles, Hidden } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import {
	MadefalvaHistory1,
	MadefalvaHistory2,
	MadefalvaHistory3,
	MadefalvaHistory4,
	MadefalvaHistory5,
	MadefalvaHistory6,
	MadefalvaHistory7,
	MadefalvaHistory8,
	Gallery
} from '../History/components/Madefalva';
import { showFooter } from '../../../store/layout/actions';
//import Footer from '../../../components/layout/footer/Footer';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		minHeight: '100vh',
		['@media screen and (max-height: 750px) and (min-width: 1280px)' ]:{
			minHeight: '125vh'
		}
	},
	slideContainer: {
		position: 'relative',
		height: '850px'
	},
	overflow: {
		position: 'relative',
		overflow: 'visible',
		height: 'fit-content'
	},
}));

const SCROLL_DEBOUNCE = 950;
let lastScrollTimestamp;

function MadefalvaHistoryAnimated(props) {
	const classes = useStyles();
	const [galleryVisible, setGalleryVisible] = useState(false);

	const [show, setShow] = useState({
		madefalvaHistory1: false,
		madefalvaHistory2: false,
		madefalvaHistory3: false,
		madefalvaHistory4: false,
		madefalvaHistory5: false,
		madefalvaHistory6: false,
		madefalvaHistory7: false,
		madefalvaHistory8: false,
	});

	useEffect(() => {
		setShow({ ...show, madefalvaHistory1: true });
		showFooter(false);
		return () => {
			showFooter(true);
		}
	}, []);



	const isScrollVisible = () =>
		document.body.scrollHeight > document.body.clientHeight ||
		document.documentElement.scrollHeight >
			document.documentElement.clientHeight;

	const isScrolledTop = () => {
		return window.scrollY === 0;
	}

	const getHomePageElementHeight = () => {
		if (!document) {
			return 0;
		}
		const homePageElement = document.getElementById('homepage');
		if (!homePageElement) {
			return document.body.scrollHeight;
		}

		return homePageElement.clientHeight;
	};

	const isScrolledBottom = () =>
		Math.round(window.scrollY) + document.body.scrollHeight >=
		getHomePageElementHeight();

	const next = () => {
		if (show.madefalvaHistory1) {
			setShow({
				madefalvaHistory1: false,
				madefalvaHistory2: true
			});
		}
		if (show.madefalvaHistory2) {
			setShow({
				madefalvaHistory2: false,
				madefalvaHistory3: true
			});
		}
		if (show.madefalvaHistory3) {
			setShow({
				madefalvaHistory3: false,
				madefalvaHistory4: true
			});
		}
		if (show.madefalvaHistory4) {
			setShow({
				madefalvaHistory4: false,
				madefalvaHistory5: true
			});
		}
		if (show.madefalvaHistory5) {
			setShow({
				madefalvaHistory5: false,
				madefalvaHistory6: true
			});
		}
		if (show.madefalvaHistory6) {
			setShow({
				madefalvaHistory6: false,
				madefalvaHistory7: true
			});
		}
		if (show.madefalvaHistory7) {
			setShow({
				madefalvaHistory7: false,
				madefalvaHistory8: true
			});
			setTimeout(() => {
				setGalleryVisible(true);
				showFooter(true);
			}, 500);
		}
	
	};

	const previous = () => {
		if (show.madefalvaHistory2) {
			setShow({
				madefalvaHistory1: true,
				madefalvaHistory2: false
			});
		}
		if (show.madefalvaHistory3) {
			setShow({
				madefalvaHistory2: true,
				madefalvaHistory3: false,
				madefalvaHistory4: false
			});
		}
		if (show.madefalvaHistory4) {
			setShow({
				madefalvaHistory3: true,
				madefalvaHistory4: false
			});
		}
		if (show.madefalvaHistory5) {
			setShow({
				madefalvaHistory4: true,
				madefalvaHistory5: false
			});
		}
		if (show.madefalvaHistory6) {
			setShow({
				madefalvaHistory5: true,
				madefalvaHistory6: false
			});
		}
		if (show.madefalvaHistory7) {
			setShow({
				madefalvaHistory6: true,
				madefalvaHistory7: false
			});
		}
		if (show.madefalvaHistory8) {
			setShow({
				madefalvaHistory7: true,
				madefalvaHistory8: false
			});
			setGalleryVisible(false);
			showFooter(false);
		}
		/*
	if (isScrollVisible() && !isScrolledTop()) {
		return;
	}
	setTimeout(() => {
		onClick(active > 0 ? active - 1 : 0);
		window.scrollTo(0, 0);
	}, 100); */
	};

	const handleSwipe = (e, initialX, initialY) => {
		if (initialX === undefined || initialY === undefined) {
			return;
		}

		const currentX = e.touches[0].clientX;
		const currentY = e.touches[0].clientY;
		const diffX = initialX - currentX;
		const diffY = initialY - currentY;

		if (Math.abs(diffX) > Math.abs(diffY)) {
			return;
		}

		if (diffY > 0) {
			next(); // swipe up
		} else {
			previous(); // swipe down
		}
	};
	
	// tslint:disable-next-line: no-any
	const handleWheel = (event) => {
		event.stopPropagation();
		if (Date.now() - lastScrollTimestamp < SCROLL_DEBOUNCE) {
			return;
		}
		lastScrollTimestamp = Date.now();
		const delta = Math.sign(event.deltaY);
		if(show.madefalvaHistory8){
			if(window.scrollY == 0 && delta == -1){
				previous();
			}
		}
		else{
			if (delta === 1) {
				next();
			} else if (delta === -1) {
				previous();
			}
		}
	};

	// tslint:disable-next-line: no-any
	const handleKey = (e) => {
		switch (e.code) {
			case 'ArrowDown':
				next();
				break;
			case 'ArrowUp':
				previous();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		let initialX;
		let initialY;

		const startTouch = (e) => {
			initialX = e.touches[0].clientX;
			initialY = e.touches[0].clientY;
		};

		const moveTouch = (e) => {
			/* if (visible) {
			e.preventDefault();
			initialX = undefined;
			initialY = undefined;

			return;
		} */

			handleSwipe(e, initialX, initialY);
			initialX = undefined;
			initialY = undefined;
		};

		window.addEventListener('wheel', handleWheel);
		window.addEventListener('keyup', handleKey);
		window.addEventListener('touchstart', startTouch, false);
		window.addEventListener('touchmove', moveTouch, { passive: false });

		return () => {
			window.removeEventListener('wheel', handleWheel);
			window.removeEventListener('keyup', handleKey);
			window.removeEventListener('touchstart', startTouch);
			window.removeEventListener('touchmove', moveTouch);
		};
	}, [show]);

	return (
		<Box className={classes.container}>
			<MadefalvaHistory1
				showAnimation={show.madefalvaHistory1}
				goToNext={() => {
					next();
				}}
			/>
			<MadefalvaHistory2 showAnimation={show.madefalvaHistory2} />
			<MadefalvaHistory3 showAnimation={show.madefalvaHistory3} />
			<MadefalvaHistory4 showAnimation={show.madefalvaHistory4} />
			<MadefalvaHistory5 showAnimation={show.madefalvaHistory5} />
			<MadefalvaHistory6 showAnimation={show.madefalvaHistory6} />
			<MadefalvaHistory7 showAnimation={show.madefalvaHistory7} />
			<MadefalvaHistory8 showAnimation={show.madefalvaHistory8} />
			
			<Box className={classes.overflow} />
			{show.madefalvaHistory8 && galleryVisible && <Gallery />}
		</Box>
	);
	/*
    return (
        <Box className={classes.container}>
            <Box className={classes.slideContainer}>
                <MadefalvaHistory1 />
            </Box>
            <Box className={classes.slideContainer}>
                <MadefalvaHistory2 />
            </Box>
            <Box className={classes.slideContainer}>
                <MadefalvaHistory3 />
            </Box>
            <Box className={classes.slideContainer}>
                <MadefalvaHistory4 />
            </Box>
            <Box className={classes.slideContainer}>
                <MadefalvaHistory5 />
            </Box>
            <Box className={classes.slideContainer}>
                <MadefalvaHistory6 />
            </Box>
            <Box className={classes.slideContainer}>
                <MadefalvaHistory7 />
            </Box>
            <Box className={classes.slideContainer}>
                <MadefalvaHistory8 />
            </Box>

            <Gallery />
        </Box>
	);
	*/
}

export default withNamespaces('home')(MadefalvaHistoryAnimated);
