import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Horse from '../../../../components/images/h_01.png';
import BackgroundImage from '../../../../components/images/wcolor_bg_03.png';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Horse}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		left: '528px',
		width: '600px',
		height: '265px',
		top: '280px',
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 150,
			left: 0,
			top: 150
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 300,
			left: 0,
			top: 150
		},
		[theme.breakpoints.only('md')]: {
			width: 470,
			height: 210,
			left: 'auto',
			right: 0,
			top: 300
		}
	},
	imageBackContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		left: '508px',
		width: '600px',
		height: '400px',
		top: '89px',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 300,
			left: 0,
			top: 50
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 400,
			left: 0,
			top: 0
		},
		[theme.breakpoints.only('md')]: {
			width: 480,
			height: 350,
			top: 250,
			left: 'auto',
			right: 0
		}
	},
	imageBackgroundContainer: {
		position: 'absolute',
		background: `transparent url(${BackgroundImage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.only('lg')]: {
			top: 120
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},

	titleContainer: {
		position: 'absolute',
		top: 0,
		right: '170px',
		width: 440,
		[theme.breakpoints.down('sm')]: {
			left: 30,
			right: 0,
			width: '90%'
		},
		[theme.breakpoints.only('md')]: {
			right: 70
		}
	},
	titleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		color: theme.palette.primary.dark,
		fontSize: '32px',
		lineHeight: '34px'
	},
	contentContainer: {
		top: '145px',
		width: '100%',
		height: '869px',
		position: 'absolute'
	},
	mainTitleBox: {
		top: '178px',
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: '216px',
		textAlign: 'left',
		zIndex: 101,
		[theme.breakpoints.down('sm')]: {
			top: 550,
			left: 30
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#EE2C48',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: '18.5px',
		marginRight: '39px',
		marginBottom: '15.5px',
		marginLeft: '39px'
	},
	descriptionContainer1: {
		position: 'absolute',
		top: 0,
		width: '480px',
		left: 0,
		[theme.breakpoints.only('xs')]: {
			top: 300,
			width: '100%'
		},
		[theme.breakpoints.only('sm')]: {
			top: 430,
			width: '100%'
		},
		[theme.breakpoints.only('md')]: {
			left: 30
		}
	},
	descriptionContainer2: {
		position: 'absolute',
		top: '300px',
		width: '447px',
		left: '72px',
		[theme.breakpoints.down('sm')]: {
			top: 670,
			width: '100%',
			left: 0
		}
	},
	descriptionText: {
		color: '#282D38',
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 30,
			marginRight: 10
		}
	},
	awesomeContainer: {
		position: 'absolute',
		top: 202,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			display: 'none'
		},
		[theme.breakpoints.only('sm')]: {
			right: 30,
			left: 'auto',
			top: 530
		},
		[theme.breakpoints.only('md')]: {
			top: 165,
			left: 30
		}
	},
	awesomeText: {
		fontFamily: 'CLOAK',
		color: '#E0B080',
		letterSpacing: '0.68px',
		lineHeight: '92px',
		fontSize: '68px'
	}
}));

function SiculicidiumHistory5(props) {
	const classes = useStyles();
	const { t } = props;
	const [showHistory] = useState(true);
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.contentContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageBackContainerFixed}>
					<Box className={classes.imageBackgroundContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.titleContainer}>
					<Typography className={classes.titleText}>
						<Trans i18nKey='megerint12.title' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.awesomeContainer}>
					<Typography className={classes.awesomeText}>1762</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						{t('megerint12.maintitle')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer1}>
					<Typography className={classes.descriptionText}>
						{t('megerint12.description1')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer2}>
					<Typography className={classes.descriptionText}>
						{t('megerint12.description2')}
					</Typography>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('siculicidiumHistory')(SiculicidiumHistory5);
