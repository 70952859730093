import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		height: 260,
		width: 264,
		background: '#FAFAF5 0% 0% no-repeat padding-box',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 20,
		cursor: 'pointer',
		'&:hover': {
			background: '#FFFFFF 0% 0% no-repeat padding-box'
		}
	},
	title: {
		minHeight: 40,
		width: 240,
		background: '#070C35 0% 0% no-repeat padding-box',
		marginLeft: '-12px',
		marginTop: 8,
		color: '#FCFCFA',
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		fontSize: '18px',
		fontHeight: '24px',
		paddingLeft: 27,
		paddingTop: 10,
		paddingBottom: 10
	},
	iconContainer: {
		textAlign: 'center',
		marginTop: 60
	}
}));

function CategoryCard(props) {
	const classes = useStyles();

	const { type, t } = props;

	const history = useHistory();

	const navToPage = () => {
		window.scrollTo(0, 0);

		history.push(`gazette/${type.href}`);
	};

	return (
		<Box className={classes.root} onClick={navToPage}>
			<Typography component={Box} className={classes.title}>
				{t(type.name)}
			</Typography>
			<Box className={classes.iconContainer}>{type.icon}</Box>
		</Box>
	);
}
export default withNamespaces('gazette')(CategoryCard);
