import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Education(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='102.4'
			height='74.6'
			viewBox='0 0 102.4 74.6'>
			<g transform='translate(-0.5 -714.5)'>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M64.1,772.8H57.6v-8.4h6.5Zm-5.5-1h4.5v-6.4H58.6Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M60.9,772.5a.472.472,0,0,1-.5-.5v-6.6a.5.5,0,0,1,1,0V772A.642.642,0,0,1,60.9,772.5Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M63.5,769.2H58.3a.5.5,0,0,1,0-1h5.2a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M35.2,772.8H28.7v-8.4h6.5Zm-5.5-1h4.5v-6.4H29.7Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M32,772.5a.472.472,0,0,1-.5-.5v-6.6a.5.5,0,0,1,1,0V772A.642.642,0,0,1,32,772.5Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M34.7,769.2H29.5a.5.5,0,0,1,0-1h5.2a.472.472,0,0,1,.5.5A.536.536,0,0,1,34.7,769.2Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M47.5,789a.472.472,0,0,1-.5-.5v-8.7H45.6v8.7a.5.5,0,0,1-1,0v-9.7H48v9.7A.472.472,0,0,1,47.5,789Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M47.2,782.2H45.6a.5.5,0,0,1,0-1h1.6a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M49.8,789.1a.472.472,0,0,1-.5-.5V778.4a4.2,4.2,0,0,0-3-1.2,3.245,3.245,0,0,0-2.8,1.2v10.2a.5.5,0,0,1-1,0V778.1l.1-.1a4.2,4.2,0,0,1,3.8-1.8,4.659,4.659,0,0,1,3.9,1.8l.1.1v10.5A.738.738,0,0,1,49.8,789.1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M82.2,789.1a.472.472,0,0,1-.5-.5v-27a.5.5,0,0,1,1,0v27A.472.472,0,0,1,82.2,789.1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M10.1,789.1a.472.472,0,0,1-.5-.5v-27a.5.5,0,0,1,1,0v27A.472.472,0,0,1,10.1,789.1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M44,745.9H38.3a.5.5,0,0,1,0-1H44a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M84.9,759.3H7.8l14-14.5H33.4a.5.5,0,0,1,0,1H22.2l-12,12.5H82.6l-12-12.5H48.9a.5.5,0,1,1,0-1h22Z'
				/>
				<path
					className='b'
					fill='none'
					stroke='#160e44'
					strokeMiterlimit='10'
					d='M61.1,738.1'
				/>
				<path
					className='c'
					fill={props.base ? props.base : '#ee2c48'}
					d='M90.3,739.7a12.6,12.6,0,1,0-12.6-12.6A12.548,12.548,0,0,0,90.3,739.7Z'
				/>
				<path
					className='c'
					fill={props.base ? props.base : '#ee2c48'}
					d='M21.1,788.6a25.2,25.2,0,1,1,50.4,0H87.2a40.9,40.9,0,1,0-81.8,0Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M74.1,739.7H69.3a.2.2,0,0,1,0-.4h4.8a.2.2,0,0,1,0,.4Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M49.3,747.1H43.6a.5.5,0,0,1,0-1h5.7a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M49.6,739.1H42.9l-.2-.4a.614.614,0,0,1,.1-.6l3.6-3.8,3.8,4-.1.4A.664.664,0,0,1,49.6,739.1Zm-5.4-1h4.4l-2.2-2.3Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M44,746.9a.472.472,0,0,1-.5-.5v-7.6a.5.5,0,0,1,1,0v7.6A.472.472,0,0,1,44,746.9Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M48.9,746.9a.472.472,0,0,1-.5-.5v-7.6a.5.5,0,0,1,1,0v7.6A.472.472,0,0,1,48.9,746.9Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M74.3,740H69.1a.5.5,0,0,1,0-1h5.2a.472.472,0,0,1,.5.5A.536.536,0,0,1,74.3,740Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M69.7,745.8a.472.472,0,0,1-.5-.5v-5.6a.5.5,0,0,1,1,0v5.6A.472.472,0,0,1,69.7,745.8Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M73.7,748.8a.472.472,0,0,1-.5-.5v-8.6a.5.5,0,0,1,1,0v8.6A.536.536,0,0,1,73.7,748.8Z'
				/>
				<line
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					y2='3.5'
					transform='translate(46.5 740.5)'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M46.5,743.2a.472.472,0,0,1-.5-.5v-.1a.5.5,0,0,1,1,0v.1A.536.536,0,0,1,46.5,743.2Zm0-2.1a.472.472,0,0,1-.5-.5v-.1a.5.5,0,0,1,1,0v.1A.536.536,0,0,1,46.5,741.1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M82.7,762.1H9.7L9,759c-.1-.3.1-.5.4-.6s.5.1.6.4l.5,2.3H82l.8-2.4a.474.474,0,0,1,.9.3Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M38.5,748H33.3a.5.5,0,0,1,0-1h5.2a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M38.5,740H33.3a.5.5,0,0,1,0-1h5.2a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M34,747.8a.472.472,0,0,1-.5-.5v-7.6a.5.5,0,0,1,1,0v7.6A.472.472,0,0,1,34,747.8Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M38,747.8a.472.472,0,0,1-.5-.5v-7.6a.5.5,0,0,1,1,0v7.6A.536.536,0,0,1,38,747.8Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M49.6,772.8H43.1v-8.4h6.5Zm-5.5-1h4.5v-6.4H44.1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M46.3,772.5a.472.472,0,0,1-.5-.5v-6.6a.5.5,0,0,1,1,0V772A.536.536,0,0,1,46.3,772.5Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M49,769.2H43.8a.5.5,0,0,1,0-1H49a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M64.1,786H57.6v-8.4h6.5Zm-5.5-1h4.5v-6.4H58.6Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M60.9,785.6a.472.472,0,0,1-.5-.5v-6.6a.5.5,0,0,1,1,0v6.6A.536.536,0,0,1,60.9,785.6Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M63.5,782.3H58.3a.5.5,0,0,1,0-1h5.2a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M35.2,786H28.7v-8.4h6.5Zm-5.5-1h4.5v-6.4H29.7Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M32,785.6a.472.472,0,0,1-.5-.5v-6.6a.5.5,0,0,1,1,0v6.6A.536.536,0,0,1,32,785.6Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#160e44'}
					d='M34.7,782.3H29.5a.5.5,0,0,1,0-1h5.2a.472.472,0,0,1,.5.5A.536.536,0,0,1,34.7,782.3Z'
				/>
				<path
					className='c'
					fill={props.base ? props.base : '#ee2c48'}
					d='M91.7,789.1H1a.5.5,0,0,1,0-1H91.7a.5.5,0,0,1,0,1Z'
				/>
			</g>
		</svg>
	);
}
