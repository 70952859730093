import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Nature3(props) {
	// <defs><style>.a{fill:#160e44;}.b{fill:#ee2c48;}.c{fill:none;stroke:#160e44;stroke-linecap:round;stroke-miterlimit:10;}</style></defs>
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '104.4'}
			height={props.height ? props.height : '73.6'}
			viewBox='0 0 104.4 73.6'>
			<g transform='translate(-403 -428.7)'>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M472.1,460.2a.52.52,0,0,1-.4-.2l-4.6-7.1-4.5,4a.495.495,0,0,1-.7-.7l5.4-4.8,5.2,8.1a.616.616,0,0,1-.1.7Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M483.7,479.6a.55.55,0,0,1-.5-.3l-7.3-19.9-11,2.7-6.7-11-7.4-4.4-13.7,12.5-10.3-1.8-4.9,17.1a.474.474,0,1,1-.9-.3l5.2-17.9,10.7,1.9,13.9-12.7,8.3,4.9,6.4,10.6,11.1-2.8,7.6,20.8a.419.419,0,0,1-.3.6Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M430,457.9h-.2a.43.43,0,0,1-.2-.7l4.7-9.9,9.6,5a.515.515,0,1,1-.5.9l-8.7-4.5-4.3,9C430.4,457.8,430.2,457.9,430,457.9Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M420.3,500.1a5.606,5.606,0,0,1-4-1.7,3.085,3.085,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,.495.495,0,0,1-.7.7,3.085,3.085,0,0,0-4.2,0A5.606,5.606,0,0,1,420.3,500.1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M420.3,496.7a5.606,5.606,0,0,1-4-1.7,3.085,3.085,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,.495.495,0,0,1-.7.7,3.085,3.085,0,0,0-4.2,0A5.782,5.782,0,0,1,420.3,496.7Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M420.3,493.3a5.606,5.606,0,0,1-4-1.7,3.085,3.085,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,.495.495,0,0,1-.7.7,3.085,3.085,0,0,0-4.2,0A5.606,5.606,0,0,1,420.3,493.3Z'
				/>
				<path
					className='b'
					fill={props.dotfill ? props.dotfill : '#ee2c48'}
					d='M494.8,453.9a12.6,12.6,0,1,0-12.6-12.6A12.676,12.676,0,0,0,494.8,453.9Z'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M425.7,501.7a25.2,25.2,0,1,1,50.4,0h15.7a40.9,40.9,0,0,0-81.8,0Z'
				/>
				<path
					className='c'
					fill='none'
					stroke='#160e44'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M461.9,463.9'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M498.2,502.3H403.5a.5.5,0,0,1,0-1h94.7a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M457,500.1a5.606,5.606,0,0,1-4-1.7,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,3.085,3.085,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,.495.495,0,0,1,.7.7,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0A5.606,5.606,0,0,1,457,500.1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M457,496.7a5.606,5.606,0,0,1-4-1.7,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,.495.495,0,0,1,.7.7,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0A5.606,5.606,0,0,1,457,496.7Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M457,493.3a5.606,5.606,0,0,1-4-1.7,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,3.085,3.085,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,.495.495,0,0,1,.7.7,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0A5.605,5.605,0,0,1,457,493.3Z'
				/>
			</g>
		</svg>
	);
}
