import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Decisions(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 86.04 86.04'>
			<title>hotarile</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className='cls-1'
						fill='#201943'
						d='M80.5,86H0V0H86V79.88ZM1,85H80.06l5-5.55V1H1Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M25.6,9.21H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H25.6a.5.5,0,0,1,.5.5A.5.5,0,0,1,25.6,9.21Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,77.83H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,77.83Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,17.79H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,17.79Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M77.13,9.21H50.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h26.7a.5.5,0,0,1,.5.5A.5.5,0,0,1,77.13,9.21Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M74.28,77.83H50.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H74.28a.5.5,0,0,1,.5.5A.5.5,0,0,1,74.28,77.83Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M77.13,17.79H50.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h26.7a.5.5,0,0,1,.5.5A.5.5,0,0,1,77.13,17.79Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M30.72,26.35v37.8H24.78V47.73h-6V64.15H12.8V26.35h5.94V41.79h6V26.35Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M67.65,58.15h-6.8l-1.08,6H53.72l7.56-37.8h5.94l7.56,37.8h-6Zm-1-5.94L64.25,38.66,61.88,52.21Z'
					/>
				</g>
			</g>
		</svg>
	);
}
