import React, { useEffect, useState } from 'react';
import {
	makeStyles,
	Box,
	Grid,
	Typography,
	IconButton
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { getHotels } from '../../../../../store/home/actions';
import { ChevronRight } from '../../../../../components/icons';

import { HotelCard } from '../hotelCard';

const useStyles = makeStyles((theme) => ({
	container: {
		height: '100%',
		overflow: 'hidden',
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100vw'
		},
		width: 1328,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 165
	},
	navigationIconButtonRight: {
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF'
		},
		margin: 20
	},
	navigationIconButtonLeft: {
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF'
		},
		transform: 'rotate(180deg)',
		margin: 20
	},
	arrowDown: {
		background: '#070c35 0% 0% no-repeat padding-box',
		opacity: 1
	},
	sliderBox: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.up('lg')] :{
			width: '100%'
		}
	},
	root: {
		paddingBottom: 42,
		borderBottom: '1px solid #D5D4C9',
		[theme.breakpoints.only('lg')]: {
			paddingBottom: 0
		}
	},
	textContainer: {
		paddingLeft: 100,
		paddingBottom: 60,
		width: 552,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 20
		}
	},
	iconContainer: {
		flexDirection: 'column',
		justifyContent: 'center',
		display: 'flex',
		width: 100
	},
	title: {
		fontFamily: 'Gilroy',
		fontWeight: 900,
		lineHeight: '34px',
		fontSize: 32,
		paddingBottom: 25
	},
	totalText: {
		textTransform: 'uppercase',
		fontFamily: 'Bebas Neue',
		fontWeight: 'bold',
		letterSpacing: '1px',
		lineHeight: '15.6px',
		fontSize: '20px',
		paddingTop: 7
	},
	totalButton: {
		margin: 'auto',
		width: 'fit-content',
		display: 'inline-flex',
		marginRight: 103,
		marginTop: 19,
		'&:hover': {
			cursor: 'pointer'
		}
	},
	totalButtonContainer: {
		float: 'right'
	}
}));

function HotelToHome(props) {
	const classes = useStyles();
	const { category, t } = props;
	const list = useSelector((state) => state.home.hotel);
	const [page, setPage] = useState(1);
	const [showLeft, setShowLeft] = useState(false);
	const [showRight, setShowRight] = useState(true);

	useEffect(() => {
		getHotels(page);
	}, []);

	useEffect(() => {
		if (list && list.meta && list.meta.totalPages) {
			if (page === list.meta.totalPages) {
				setShowRight(false);
			}
		}
	}, [list, page]);

	const nextPage = () => {
		if (page < list.meta.totalPages) {
			const newPage = page + 1;
			setPage(newPage);
			getHotels(newPage);
			!showLeft && setShowLeft(true);
		} else {
			setShowRight(false);
		}
	};

	const prevPage = () => {
		if (page > 1) {
			const newPage = page - 1;
			setPage(newPage);
			getHotels(newPage);
			!showRight && setShowRight(true);
			newPage == 1 && setShowLeft(false);
		} else {
			setShowLeft(false);
		}
	};

	const navigateToHotel = () => {
		props.history.push('/hotels');
	};
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);

	useEffect(() => {
		// window.scrollTo(0, 4000)
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);

	return (
		<Box
			className={classes.container}
			style={{ zIndex: showAnimation ? 200 : 150 }}>
			<Box className={classes.textContainer}>
				<CSSTransition
					in={showAnimation}
					timeout={600}
					classNames='ltrshort'
					unmountOnExit>
					<Typography className={classes.title}>{t('toHomeTitle')}</Typography>
				</CSSTransition>
			</Box>
			<CSSTransition
				in={showAnimation}
				timeout={600}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.sliderBox}>
					<Box className={classes.iconContainer}>
						<IconButton
							className={classes.navigationIconButtonLeft}
							onClick={() => {
								prevPage();
							}}
							style={{visibility: showLeft ? "visible" : "hidden"}}>
							<ChevronRight
								width='24px'
								height='24px'
								fill='#070C35'
								className={classes.arrowDown}
							/>
						</IconButton>
					</Box>
					<Grid container className={classes.root} spacing={4}>
						{list &&
							list.items &&
							list.items.length > 0 &&
							list.items.map((item, index) => (
								<HotelCard key={index} hotelItem={item} />
							))}
					</Grid>
					<Box className={classes.iconContainer}>
						<IconButton
							className={classes.navigationIconButtonRight}
							onClick={() => {
								nextPage();
							}}
							style={{visibility: showRight ? "visible" : "hidden"}}>
							<ChevronRight
								width='24px'
								height='24px'
								fill='#070C35'
								className={classes.arrowDown}
							/>
						</IconButton>
					</Box>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={600}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.totalButtonContainer}>
					<Box
						className={classes.totalButton}
						onClick={() => navigateToHotel()}>
						<Typography className={classes.totalText}>{t('total')} </Typography>
						<ChevronRight fill='#070C35' width={24} height={24} />
					</Box>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('hotel')(withRouter(HotelToHome));
