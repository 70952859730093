import {
	GET_ALL_ACTIVE_COMMUNITY,
	GET_COMMUNITY,
	GET_ALL_COMMUNITY,
	GET_COMMUNITY_CAT
} from '../types';

export default function community(state = {}, action) {
	switch (action.type) {
		case GET_ALL_COMMUNITY:
			return action.payload;
		case GET_ALL_ACTIVE_COMMUNITY:
			return {
				...state,
				activeCommunities: {
					...action.payload
				}
			};
		case GET_COMMUNITY:
			return action.payload;
		case GET_COMMUNITY_CAT:
			return { ...state, [action.payload.name]: action.payload.data };
		default:
			return state;
	}
}
