import React from 'react';
import MUIRichTextEditor from 'mui-rte';

const PostBody = (props) => {
	const { content } = props;

	return <MUIRichTextEditor defaultValue={content} toolbar={false} readOnly />;
};
/*
RichTextEditor.propTypes = {
    label: PropTypes.string.isRequired,
    field: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    form: PropTypes.shape({
        touched: PropTypes.object,
        errors: PropTypes.object,
        values: PropTypes.object,
        setFieldValue: PropTypes.func,
    }).isRequired,
    theme: PropTypes.shape({ palette: PropTypes.object }),
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
}

RichTextEditor.defaultProps = {
    theme: {},
    required: false,
    fullWidth: true,
    margin: "normal"
};
*/

export default PostBody;
