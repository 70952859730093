import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Collapse } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import { TouchesLogo, Monument } from '../../../icons';
import { NavButton } from './components/NavButton';
import navigationConfig from '../../navigationConfig';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: '1129px',
		width: '100%',
		height: 'fit-content',
	},
	rootLives: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: '1129px',
		width: '100%',
	},
	logoBox: {
		// justifyContent: "center",
		// paddingBottom: 12,
		position: 'absolute',
		cursor: 'pointer',
	},
	logoBoxRelative: {
		position: 'relative',
	},
	logoBoxLives: {
		justifyContent: 'center',
		paddingBottom: 12,
		marginRight: 'auto',
		cursor: 'pointer',
	},
	buttonWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
	endLogo: {
		top: 800,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
}));

const TopNavigation = (props) => {
	const { t, page } = props;
	const classes = useStyles();
	const showLargeMenu = useSelector((state) => state.layout.showLargeMenu);
	const emptySections = useSelector((state) => state.home.emptySections);
	const [showAnimation, setShowAnimation] = useState(false);

	useEffect(() => {
		if (showLargeMenu) {
			const timeOut = setTimeout(() => {
				setShowAnimation(showLargeMenu);
			}, 1);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(showLargeMenu);
	}, [showLargeMenu]);

	const navConfig = navigationConfig(page).filter(
		({ name }) => !emptySections.includes(name),
	);

	return (
		<>
			<Box className={classes.root}>
				<Box
					className={page === 'lives' ? classes.logoBoxLives : classes.logoBox}
					style={{ top: 15 }}
					onClick={() => {
						props.history.push('/');
					}}>
					{page === 'lives' ? (
						<TouchesLogo height='70px' page={page} />
					) : showLargeMenu ? (
						<CSSTransition
							in={showAnimation}
							timeout={600}
							classNames='logoinmenu'
							/*	onEntered={setShowAnimation(showLargeMenu)} */
						>
							<Box>
								<Box style={{ height: 45 }} />
								<TouchesLogo fill='#070C35' height={195} page={page} />
							</Box>
						</CSSTransition>
					) : (
						<TouchesLogo fill='#070C35' height={70} page={page} />
					)}
				</Box>
				<Collapse in={showLargeMenu} timeout={600} collapsedHeight={100}>
					<Box
						style={{
							height: 360,
						}}
					/>
				</Collapse>
				<Box className={classes.buttonWrapper}>
					{navConfig.map((route, index) => (
						<NavButton route={route} t={t} page={page} key={index.toString()} />
					))}
					{page === 'office' && (
						<>
							<Box className={classes.endLogo}>
								<Monument width={90} height={80} dotfill='#48B3C9' />
							</Box>

							<Box
								style={{
									width: showLargeMenu ? 260 : 0,
									height: 50,
								}}
							/>
						</>
					)}
				</Box>
			</Box>
		</>
	);
};

export default withNamespaces('navigation')(withRouter(TopNavigation));
