import { GET_OFFICE } from '../types';

export default function office(state = {}, action) {
	switch (action.type) {
		case GET_OFFICE:
			return action.payload;
		default:
			return state;
	}
}
