import {
	Box,
	Card,
	CardContent,
	CardHeader,
	makeStyles,
	List,
	IconButton,
	CardActions
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { withNamespaces } from 'react-i18next';
import Pagination from '@material-ui/lab/Pagination';
import { ListItem } from './components/ListItem';
import { Create } from '../Create';
import {
	saveElement as saveItem,
	getElements as getData
} from '../../../store/council/actions';

import { transformQuery } from '../../../lib/helpers/queryTransformer';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '30px',
		maxWidth: '1500px',
		margin: 'auto'
	},
	card: {},
	pagination: {
		marginLeft: 'auto',
		marginRight: 'auto'
	}
}));
export default withNamespaces('common')((props) => {
	const classes = useStyles();
	const { t } = props;
	const data = useSelector((state) => state.council.list);

	const [open, setOpen] = useState(false);
	const createItem = async (newItem) => saveItem(newItem);

	useEffect(() => {
		getData('?active=false');
	}, []);

	const handlePageChange = (evt, value) => {
		getData(
			transformQuery({
				page: parseInt(value, 10),
				active: false
			})
		);
	};

	return (
		<Box className={classes.root}>
			<Card className={classes.card}>
				<CardHeader title={t('listCardHeaderTitleCouncil')} />
				<CardContent>
					<IconButton onClick={() => setOpen(true)}>
						<AddIcon />
					</IconButton>
					<Create open={open} setOpen={setOpen} onConfirm={createItem} />
					<List>
						{data?.items?.map((item) => (
							<ListItem item={item} />
						))}
					</List>
				</CardContent>
				<CardActions>
					<Pagination
						count={data && data.meta ? data.meta.totalPages : 1}
						page={data && data.meta ? parseInt(data.meta.currentPage) : 1}
						onChange={handlePageChange}
						className={classes.pagination}
					/>
				</CardActions>
			</Card>
		</Box>
	);
});
