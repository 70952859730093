import { GET_COUNCIL } from '../types';

export default function council(state = {}, action) {
	switch (action.type) {
		case GET_COUNCIL:
			return action.payload;
		default:
			return state;
	}
}
