import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function OnionBottomHalf(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.height ? props.height : '200'}
			height={props.width ? props.width : '77.45'}
			viewBox='0 0 200 77.45'>
			<g transform='translate(-219.792 -709.948)'>
				<path
					className='a'
					fill='#26002c'
					d='M419.782,712.406a2.241,2.241,0,0,0-2.226-2.458H222.028a2.241,2.241,0,0,0-2.226,2.458c3.161,33.467,29.771,62.314,89.425,65.341l-.006.006,8.992,8.992a2.232,2.232,0,0,0,3.157,0l8.992-8.992-.006-.006C390.01,774.72,416.621,745.873,419.782,712.406Z'
					transform='translate(0)'
				/>
				<path
					className='b'
					fill='#62006a'
					d='M595.275,787.4a2.225,2.225,0,0,0,1.579-.654l8.992-8.992-.006-.006c59.653-3.026,86.264-31.874,89.425-65.341a2.241,2.241,0,0,0-2.226-2.458H595.275Z'
					transform='translate(-275.483)'
				/>
			</g>
		</svg>
	);
}
