import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	makeStyles,
	Box,
	Typography,
	Divider,
	Grid,
	Hidden
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales';

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#FAFAF5',
		width: '100%',
		paddingLeft: '24px',
		paddingRight: '24px',
		paddingTop: '80px',
		paddingBottom: '80px',
		boxSizing: 'border-box',
		'& *': {
			boxSizing: 'border-box'
		}
	},
	titleDate: {
		color: '#E0B080',
		fontFamily: 'Ubuntu',
		fontWeight: 'medium',
		fontSize: '16px',
		fontHeight: '24px',
		marginBottom: theme.spacing(3)
	},
	title: {
		color: '#E0B080',
		fontFamily: 'Bebas Neue',
		// fontWeight: "bold",
		fontSize: '30px',
		fontHeight: '30px',
		letterSpacing: '0.3px',
		lineHeight: '100%'
	},
	boxIndent: {
		marginTop: 30,
		marginBottom: 60
	},
	keyContainer: {
		marginRight: 30
	},
	key: {
		color: '#282D38',
		fontFamily: 'Ubuntu',
		fontSize: '18px',
		marginTop: 10
	},
	value: {
		color: '#282D38',
		fontFamily: 'Ubuntu',
		fontSize: '18px',
		fontWeight: 'bold',
		marginTop: 10
	},
	detailGrid: {
		marginTop: 20
	}
}));

const tt = (s) => `navigation:${s}`;

const Description = (props) => {
	const { t } = props;
	const classes = useStyles();
	const language = useSelector((state) => state.language.language);
	const [data, setData] = useState([]);

	useEffect(() => {
		setData([
			{
				title: t('publicInterest.mayorOffice'),
				details: [
					[t(tt('address')), 'Siculeni 106, 537295, HR.'],
					[t(tt('phone')), '0266 379107'],
					[t(tt('fax')), '0266 316951'],
					[t(tt('email')), 'madefalva@yahoo.com']
				]
			},
			{
				title: t(tt('openingHours')),
				details: [
					[`${t(tt('monday'))} - ${t(tt('thursday'))}`, '08:00 - 16:00'],
					[t(tt('friday')), '08:00 - 15:00']
				]
			},
			{
				title: t(tt('auditionHours')),
				details: [
					['Szentes Csaba', `${t(tt('tuesday'))} 10:00 - 11:00`],
					['Szőcs László', `${t(tt('thursday'))} 14:00 - 15:00`]
				]
			},
			{
				title: t('publicInterest.leaders'),
				details: [
					[t('publicInterest.mayor'), 'Szentes Csaba, 0752314376'],
					[t('publicInterest.viceMayor'), 'Szőcs László, 0754200376']
				]
			},
			{
				title: t('publicInterest.responsible'),
				details: [[t('publicInterest.notary'), 'Dornescu Orsolya, 0744933834']]
			}
		]);
	}, [t]);

	const lastEdited = moment('2021-01-16T09:03:20.050Z').locale(language);

	return (
		<Box className={classes.root}>
			<Typography className={classes.titleDate}>
				{lastEdited.format('MMMM DD, YYYY')}
			</Typography>
			<Divider fullWidth />

			{data.map((item) => (
				<Box className={classes.boxIndent}>
					<Typography className={classes.title}>{item.title}</Typography>
					<Hidden xsDown>
						<Grid
							container
							direction='row'
							alignContent='start'
							className={classes.detailGrid}>
							<Box className={classes.keyContainer}>
								{item.details.map((detail) => (
									<Typography className={classes.key}>{detail[0]}:</Typography>
								))}
							</Box>
							<Box>
								{item.details.map((detail) => (
									<Typography className={classes.value}>{detail[1]}</Typography>
								))}
							</Box>
						</Grid>
					</Hidden>
					<Hidden smUp>
						<Box className={classes.detailGrid}>
							{item.details.map((detail) => (
								<Typography className={classes.key}>
									{detail[0]}:
									<Typography className={classes.value}>{detail[1]}</Typography>
								</Typography>
							))}
						</Box>
					</Hidden>
				</Box>
			))}
		</Box>
	);
};

export default withNamespaces(['static', 'navigation'])(Description);
