import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function TogetherLogo(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '156.688'}
			height={props.height ? props.height : '156.688'}
			viewBox='0 0 156.688 156.688'>
			<circle className='a' fill='#ffff' cx='78.344' cy='78.344' r='78.344' />
			<path
				className='b'
				fill='#8e3428'
				d='M44.8,46.624'
				transform='translate(-5.77 -45.289)'
			/>
			<path
				className='c'
				fill='#e60c19'
				d='M145.821,116.483c.653-1.548,1.223-3.148,1.755-4.774a89.075,89.075,0,0,1,11.052,21.372A70.064,70.064,0,0,0,41.675,68.467l-.22,5.21a62.75,62.75,0,0,0,5.237,27.838,67.335,67.335,0,0,1,96.235,63.673,70.929,70.929,0,0,0,6.974-9.745,74.922,74.922,0,0,0-9.105-29.416c.839-1.288,1.6-2.635,2.347-4a79.54,79.54,0,0,1,10.041,27.308,73.453,73.453,0,0,0,3.484-8.729A84.979,84.979,0,0,0,145.821,116.483Z'
				transform='translate(-11.515 -41.006)'
			/>
			<path
				className='d'
				fill='#53959a'
				d='M151.884,149.721a62.776,62.776,0,0,0-5.229-27.843A67.334,67.334,0,0,1,50.413,58.207a71.26,71.26,0,0,0-6.974,9.745,74.953,74.953,0,0,0,9.105,29.416c-.839,1.29-1.6,2.637-2.347,4A79.526,79.526,0,0,1,40.157,74.06a73.587,73.587,0,0,0-3.484,8.726,85.068,85.068,0,0,0,10.845,24.128c-.653,1.548-1.223,3.148-1.755,4.772A89.007,89.007,0,0,1,34.71,90.317a70.065,70.065,0,0,0,116.956,64.612Z'
				transform='translate(-25.127 -25.733)'
			/>
		</svg>
	);
}
