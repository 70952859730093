import store from '../store';
import { api } from '../../lib/axios/api';
import {
	GET_SIGHTS_TO_HOME,
	GET_HOTEL_TO_HOME,
	GET_SIGHTS_TO_HOME_MOBILE,
	LOAD_EMPTY_SECTIONS,
} from '../types';

export const get2Sight = (category, page, limit = 2) => {
	api
		.get(`/sights?category=${category}&limit=${limit}&page=${page}`)
		.then((response) => {
			store.dispatch({
				type: GET_SIGHTS_TO_HOME,
				payload: response.data,
				category,
			});
		});
};

export const get2SightMobile = (category) => {
	api.get(`/sights?category=${category}&limit=20&page=1`).then((res) => {
		store.dispatch({
			type: GET_SIGHTS_TO_HOME_MOBILE,
			payload: res.data,
			category,
		});
	});
};

export const getHotels = (page) => {
	api.get(`/hotels?limit=1&page=${page}&active=true`).then((response) => {
		store.dispatch({ type: GET_HOTEL_TO_HOME, payload: response.data });
	});
};

export const getEmptySections = async () => {
	try {
		const r = await api.get('/hotels/empty-sections');
		store.dispatch({
			type: LOAD_EMPTY_SECTIONS,
			payload: r.data,
		});
		return Promise.resolve();
	} catch (error) {
		return Promise.reject(error);
	}
};
