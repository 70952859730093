import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function MenuTriangle(props) {
	return (
		<svg
			viewBox='0 0 100 100'
			width={props.width ? props.width : '100%'}
			height={props.height ? props.height : '100px'}
			preserveAspectRatio='none'>
			<path
				fill={props.fill ? props.fill : 'red'}
				d='M 0 0 L 100 100 L 100 0 z'
			/>
			<path fill='none' d='M 100 100 L 0 100 L 0 0 z' />
		</svg>
	);
}
