import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getHotels } from '../../../../../store/hotel/actions';

function Header(props) {
	useEffect(() => {
		getHotels();
	}, []);

	return <></>;
}
export default withNamespaces('hotel')(withRouter(Header));
