import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage07.png';

import { StraightStripes } from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		width: 807,
		height: 608,
		top: 0,
		left: -100,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 300,
			left: 0
		},
		[theme.breakpoints.only('md')]: {
			left: -70,
			width: 700,
			height: 500
		},
		[theme.breakpoints.only('sm')]: {
			width: 700,
			height: 500,
			top: 100,
			right: 0,
			left: 0
		}
	},
	pageContainer: {
		position: 'fixed',
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 129,
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 0
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},
	mainTitleBox: {
		top: 53,
		position: 'absolute',
		background: theme.palette.primary.dark,
		right: 0,
		textAlign: 'left',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			top: 330,
			opacity: 1
		},
		[theme.breakpoints.only('lg')]: {
			width: 707
		},
		[theme.breakpoints.only('xl')]: {
			width: 707
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#48B3C9',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: 19,
		marginRight: 39,
		marginBottom: 11,
		marginLeft: 30,
		[theme.breakpoints.only('xs')]: {
			marginTop: 7,
			marginRight: 20,
			marginBottom: 3,
			marginLeft: 20,
			fontSize: 30,
			lineHeight: '30px',
			letterSpacing: '2px'
		}
	},
	halfStripes: {
		top: 12,
		right: 0,
		position: 'absolute',
		zIndex: 100,
		width: 400,
		height: 252,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 300,
			top: 270
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: 302,
		width: 447,
		right: 33,
		[theme.breakpoints.only('xs')]: {
			top: 570,
			width: '100%',
			right: 0
		},
		[theme.breakpoints.only('sm')]: {
			top: 600,
			width: '100%',
			right: 0
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.only('xs')]: {
			marginLeft: 30,
			marginRight: 20
		},
		[theme.breakpoints.only('sm')]: {
			marginLeft: 30,
			marginRight: 20
		}
	}
}));

function MonumentHistory2(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='vintageimage5'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						<Trans i18nKey='monumhistory2.maintitle'>
							<br />
							<br />
						</Trans>
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='monumhistory2.description'>
							<br />
							<br />
						</Trans>
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.halfStripes}>
					<StraightStripes width='100%' height='100%' />
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('monumentHistory')(MonumentHistory2);
