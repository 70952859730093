import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage11.png';

import { Nature3 } from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		width: 701,
		height: 538,
		top: 70,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 0,
			right: 10,
			left: 'auto',
			height: 290
		},
		[theme.breakpoints.only('md')]: {
			width: 550,
			height: 400
		},
		[theme.breakpoints.only('sm')]: {
			width: 550,
			top: -50,
			height: 400
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: 1181,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 108,
		[theme.breakpoints.only('xs')]: {
			top: 0,
			width: '100%'
		},
		[theme.breakpoints.only('md')]: {
			top: 0
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%'
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},
	mainTitleBox: {
		top: 139,
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: 287,
		textAlign: 'left',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			left: 0,
			top: 230
		},
		[theme.breakpoints.only('sm')]: {
			right: 50
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#E0B080',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: 12,
		marginRight: 62,
		marginBottom: 12,
		marginLeft: 39,
		[theme.breakpoints.only('xs')]: {
			marginTop: 7,
			marginRight: 20,
			marginBottom: 3,
			marginLeft: 20,
			fontSize: 30,
			lineHeight: '30px',
			letterSpacing: '0px'
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: 233,
		width: 480,
		left: 701,
		[theme.breakpoints.only('xs')]: {
			top: 320,
			left: 0,
			width: '100%'
		},
		[theme.breakpoints.only('md')]: {
			width: 430,
			left: 550
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			left: 0,
			top: 400
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.only('xs')]: {
			fontSize: 16,
			marginLeft: 30,
			marginRight: 10
		},
		[theme.breakpoints.only('sm')]: {
			marginLeft: 40,
			marginRight: 40
		}
	},
	iconContainer: {
		position: 'absolute',
		top: 0,
		left: 682,
		width: 200,
		height: 141,
		[theme.breakpoints.only('xs')]: {
			height: 100,
			right: 0,
			top: 135,
			left: 'auto'
		},
		[theme.breakpoints.only('sm')]: {
			right: 100,
			left: 'auto'
		}
	}
}));

function MadefalvaHistory4(props) {
	const classes = useStyles();
	const { t } = props;
	const [showHistory, setShowSecoundPage] = useState(true);
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);

	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='vintageimage2'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						{t('madehistory4.maintitle')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='madehistory4.description' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.iconContainer}>
					<Nature3 height='100%' width='100%' />
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('madefalvaHistory')(MadefalvaHistory4);
