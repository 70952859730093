import * as Yup from 'yup';

export const FormSchema = (t) =>
	Yup.object().shape({
		email: Yup.string().email().required(t('common:required')),
		username: Yup.string()
			.min(2, t('common:minLength', { value: 2 }))
			.max(50, t('common:maxLength', { value: 50 }))
			.required(t('common:required')),
		gender: Yup.string().required(t('common:required')),
		richText: Yup.string().required(t('common:required')),
		chipInput: Yup.string()
	});
