import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	button: {
		height: '76px',
		width: '76px',
		background: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#FFFFFF'
		},
		[theme.breakpoints.only('xs')]: {
			height: '46px',
			width: '46px'
		}
	},
	icon: {
		fontSize: 36,
		color: '#070c35',
		'&:hover': {
			fontSize: 40
		},
		[theme.breakpoints.only('xs')]: {
			fontSize: 30
		}
	}
}));

function NavigateBack(props) {
	const classes = useStyles();
	const { onButtonClick } = props;
	return (
		<IconButton className={classes.button} onClick={onButtonClick}>
			<CloseIcon className={classes.icon} />
		</IconButton>
	);
}

export default NavigateBack;
