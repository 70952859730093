export default {
	header: {
		height: 100
	},
	menu: {
		width: '220px'
	},
	navigation: {
		height: '100px'
	}
};
