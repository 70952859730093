import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withNamespaces } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { Grid, AppBar, Tabs, Tab } from '@material-ui/core';
import {
	Select,
	TextField,
	TimePicker,
	DateTimePicker,
	KeyboardDatePicker,
	KeyboardDateTimePicker,
	KeyboardTimePicker,
	ChipInput,
	Autocomplete,
	Switch
} from '../../../components/form';
import { TabPanel } from '../../../components/tabs';
import RichTextEditor from '../../../components/form/RichTextEditor';
import countries from './countries';
import { FormSchema } from './validation';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	}
}));

function Demo(props) {
	const { t } = props;
	const classes = useStyles();

	const [tabValue, setTabValue] = useState(0);
	const [fieldVariant, setFieldVariant] = useState('standard');

	const onSwitchChange = (event) => {
		if (event.target.value) {
			setFieldVariant('outlined');
		} else {
			setFieldVariant('standard');
		}
	};
	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Formik
			initialValues={{
				email: '',
				username: '',
				multipleAutocomplete: [
					{
						label: 'Algeria',
						value: 'DZ'
					}
				],
				gender: '',
				switch: false,
				richText: '',
				simpleAutocomplete: null,
				password: ''
			}}
			onSubmit={(values) => {}}
			validationSchema={FormSchema(t)}>
			{(props) => (
				<Form>
					<div className={classes.root}>
						<AppBar position='static' color='default'>
							<Tabs
								value={tabValue}
								onChange={handleTabChange}
								indicatorColor='primary'
								textColor='primary'
								variant='scrollable'
								scrollButtons='auto'
								aria-label='scrollable auto tabs example'>
								<Tab label='Item One' />
								<Tab label='Item Two' />
							</Tabs>
						</AppBar>
						<TabPanel value={tabValue} index={0}>
							<Grid item xs={12}>
								<Field
									name='switch'
									component={Switch}
									label='OutLined'
									size='small'
									variant={fieldVariant}
									onChange={onSwitchChange}
									textFieldProps={{
										label: 'OutLined',
										variant: 'outlined'
									}}
								/>
							</Grid>
							<Grid container justify='center' alignItems='center' spacing={0}>
								<Grid item xs={12}>
									<Field
										variant={fieldVariant}
										name='chipInput'
										component={ChipInput}
										label='ChipInput'
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										variant={fieldVariant}
										name='email'
										label='email'
										component={TextField}
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										variant={fieldVariant}
										name='password'
										type='password'
										label='Password'
										component={TextField}
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										variant={fieldVariant}
										name='username'
										label='Username'
										component={TextField}
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name='gender'
										label='Gender'
										options={[
											{
												value: 'Male',
												label: 'Male'
											},
											{
												value: 'Female',
												label: 'Female'
											},
											{
												value: 'Other',
												label: 'Other'
											}
										]}
										component={Select}
										size='small'
										variant={fieldVariant}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name='multipleAutocomplete'
										options={countries}
										component={Autocomplete}
										size='small'
										textFieldProps={{
											label: 'AutoComplete',
											variant: fieldVariant
										}}
										multiple
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name='simpleAutocomplete'
										options={countries}
										component={Autocomplete}
										size='small'
										textFieldProps={{
											label: 'SimpleAutocomplete',
											variant: fieldVariant
										}}
									/>
								</Grid>

								<Grid item xs={12}>
									<Field
										name='richText'
										component={RichTextEditor}
										size='small'
										label='RichText'
										variant={fieldVariant}
									/>
								</Grid>
							</Grid>
						</TabPanel>
						<TabPanel value={tabValue} index={1}>
							<Grid container justify='center' alignItems='center' spacing={0}>
								<Grid item xs={12}>
									<Field
										name='timePicker'
										component={TimePicker}
										label='Time'
										variant={fieldVariant}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name='dateTimePicker'
										component={DateTimePicker}
										label='DateTimePicker'
										variant={fieldVariant}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name='keyboardDatePicker'
										component={KeyboardDatePicker}
										label='KeyboardDatePicker'
										variant={fieldVariant}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name='keyboardDateTimePicker'
										component={KeyboardDateTimePicker}
										label='KeyboardDateTimePicker'
										variant={fieldVariant}
									/>
								</Grid>
								<Grid item xs={12}>
									<Field
										name='keyboardTimePicker'
										component={KeyboardTimePicker}
										label='KeyboardTimePicker'
										variant={fieldVariant}
									/>
								</Grid>
							</Grid>
						</TabPanel>

						<Grid item xs={12}>
							<Button
								type='button'
								className='outline'
								onClick={props.handleReset}
								disabled={!props.dirty || props.isSubmitting}>
								Reset
							</Button>
							<Button type='submit'>Submit</Button>
						</Grid>

						{/* <DisplayFormikState {...props} /> */}
					</div>
				</Form>
			)}
		</Formik>
	);
}

export default withNamespaces(['common', 'demo'])(Demo);
