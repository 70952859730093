import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Deposit from '../../../../components/images/Depositphotos_4570392_xl-2015.png';

import { StraightStripes } from '../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Deposit}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		left: '144px',
		width: '600px',
		height: '400px',
		top: '0px',
		[theme.breakpoints.only('md')]: {
			width: 450,
			height: 300
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 300,
			left: 0
		},
		[theme.breakpoints.only('sm')]: {
			width: '90%',
			height: 300,
			left: 0
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: '100%',
		[theme.breakpoints.up('lg')]: {
			top: 150
		}
	},
	contentContainer: {
		right: 0,
		left: 0,
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		height: '550px',
		position: 'absolute',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	mainTitleBox: {
		position: 'absolute',
		opacity: 1,
		zIndex: 101,
		height: '70px',
		verticalAlign: 'center',
		width: 'fit-content'
	},
	mainTitleText: {
		lineHeight: '54px',
		fontFamily: 'Bebas Neue',
		fontSize: '50px',
		textTransform: 'uppercase',
		display: 'flex',
		paddingTop: '12px',
		paddingRight: '40px',
		paddingBottom: '4px',
		paddingLeft: '40px',
		[theme.breakpoints.down('md')]: {
			paddingRight: '20px',
			paddingLeft: '20px'
		}
	},
	supText1: {
		fontSize: '30px',
		fontFamily: 'Bebas Neue',
		color: 'rgba(238, 44, 72, 0.9)'
	},
	supText2: {
		fontSize: '30px',
		fontFamily: 'Bebas Neue',
		color: 'rgba(7, 12, 53, 1)'
	},
	supText3: {
		fontSize: '30px',
		fontFamily: 'Bebas Neue',
		color: 'rgba(238, 44, 72, 1)'
	},
	animatedCircles: {
		position: 'absolute'
	},
	descriptionContainer: {
		position: 'absolute',
		top: '472px',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			top: 320,
			width: '100%'
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		[theme.breakpoints.down('md')]: {
			marginLeft: 30,
			marginRight: 20
		}
	},
	halfStripes: {
		top: '170px',
		position: 'absolute',
		zIndex: 100,
		width: '400px',
		height: '252px',
		left: '444px',
		[theme.breakpoints.down('sm')]: {
			right: 0,
			width: 350,
			left: 0,
			top: 70
		}
	}
}));

function SiculicidiumHistory3(props) {
	const classes = useStyles();
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.contentContainer}>
					<Box className={classes.imageContainerFixed}>
						<Box className={classes.imageContainer} />
					</Box>

					<Box className={classes.mainTitleBox} style={{ top: '64px' }}>
						<Typography
							className={classes.mainTitleText}
							style={{
								background: 'rgba(224, 176, 128, 0.6)',
								color: 'rgba(230, 15, 55, 0.9)'
							}}>
							SI
							<Typography className={classes.supText1}>
								<sup>(1)</sup>
							</Typography>
							C
							<Typography className={classes.supText1}>
								<sup>(100)</sup>
							</Typography>
							U
							<Typography className={classes.supText1}>
								<sup>(5)</sup>
							</Typography>
						</Typography>
					</Box>
					<Box
						className={classes.mainTitleBox}
						style={{ top: '134px', left: '40px' }}>
						<Typography
							className={classes.mainTitleText}
							style={{
								background: 'rgba(230, 15, 55, 0.8)',
								color: 'rgba(7, 12, 53, 1)'
							}}>
							L
							<Typography className={classes.supText2}>
								<sup>(50)</sup>
							</Typography>
							I
							<Typography className={classes.supText2}>
								<sup>(1)</sup>
							</Typography>
							C
							<Typography className={classes.supText2}>
								<sup>(100)</sup>
							</Typography>
							I
							<Typography className={classes.supText2}>
								<sup>(1)</sup>
							</Typography>
						</Typography>
					</Box>
					<Box
						className={classes.mainTitleBox}
						style={{ top: '204px', left: '110px' }}>
						<Typography
							className={classes.mainTitleText}
							style={{
								background: 'rgba(7, 12, 53, 1)',
								color: 'rgba(238, 44, 72, 1)'
							}}>
							D
							<Typography className={classes.supText3}>
								<sup>(500)</sup>
							</Typography>
							I
							<Typography className={classes.supText3}>
								<sup>(1)</sup>
							</Typography>
							U
							<Typography className={classes.supText3}>
								<sup>(5)</sup>
							</Typography>
							M
							<Typography className={classes.supText3}>
								<sup>(1000)</sup>
							</Typography>
						</Typography>
					</Box>
					<Box className={classes.animatedCircles}>
						<Box className={classes.halfStripes}>
							<StraightStripes width='100%' height='100%' />
						</Box>
					</Box>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionText}>
							<Trans i18nKey='megerint10.description' />
						</Typography>
					</Box>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('siculicidiumHistory')(SiculicidiumHistory3);
