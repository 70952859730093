import React, { useState, useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Box
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ImageResults } from '../../views/Files/File/components/Results';
import { getImages } from '../../store/file/actions';
import { Dropzone } from '../../views/Files/File/components/Dropzone';
import { FILE_TYPE } from '../../store/types';

import { Image } from '../image';

const useStyles = makeStyles(() => ({
	imageContainer: {
		width: '300px',
		height: 'auto'
	}
}));

function ImageSelector(props) {
	const { t, setImageId, fieldName, slider } = props;
	const [open, setOpen] = useState(false);
	const [image, setImage] = useState(props.image ? props.image : null);
	const images = useSelector((state) => state.file.images);
	const classes = useStyles();
	useEffect(() => {
		imageList();
	}, []);

	const imageList = () => {
		if (images && images.meta) {
		} else {
			getImages({ limit: 18, page: 1 });
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const selectItem = (img) => {
		setImage(img);
		setOpen(false);
		setImageId(slider ? img : img.id);
	};

	return (
		<>
			{/* <Field name={fieldName} component={TextField} disabled /> */}
			{image && (
				<Box className={classes.imageContainer}>
					<Image image={image.url} />
				</Box>
			)}
			<Button variant='outlined' color='primary' onClick={handleClickOpen}>
				{t('selectImage')}
			</Button>
			<Dropzone type={FILE_TYPE.IMAGE} selected = {(id) => {
				selectItem(id)
			}}/>
			<Dialog
				fullWidth
				maxWidth='lg'
				open={open}
				onClose={handleClose}
				aria-labelledby='max-width-dialog-title'>
				<DialogTitle id='max-width-dialog-title'>{t('title')}</DialogTitle>
				<DialogContent>
					<ImageResults selector selectItem={selectItem} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						{t('close')}
					</Button>
				</DialogActions>
			</Dialog>
			
		</>
	);
}

export default withNamespaces('imageSelector')(ImageSelector);
