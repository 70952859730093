import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Document1(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='102.3'
			height='73.6'
			viewBox='0 0 102.3 73.6'>
			<g transform='translate(-0.2)'>
				<path
					className='a'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M20.8,73.1a25.2,25.2,0,1,1,50.4,0H86.9a40.9,40.9,0,1,0-81.8,0Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M42.6,28.1H31.8a.5.5,0,0,1,0-1H42.6a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M60.2,56.5H31.8a.5.5,0,1,1,0-1H60.2a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M60.2,64.7H31.8a.5.5,0,1,1,0-1H60.2a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.dotfill ? props.dotfill : '#ee2c48'}
					d='M89.9,25.2A12.6,12.6,0,1,0,77.3,12.6,12.676,12.676,0,0,0,89.9,25.2Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M66.9,73.6a.472.472,0,0,1-.5-.5v-35L54.9,17.8H25.6V37.2a.5.5,0,0,1-1,0V16.8H55.5l11.9,21V73.1A.536.536,0,0,1,66.9,73.6Z'
				/>
				<path
					className='b'
					fill={props.base ? props.base : '#160e44'}
					d='M66.9,38.4a.367.367,0,0,1-.3-.1L48.3,27.4,54.7,17a.515.515,0,1,1,.9.5L49.7,27,67.2,37.4a.574.574,0,0,1,.2.7A.55.55,0,0,1,66.9,38.4Z'
				/>
				<path
					className='a'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M91.4,73.6H.7a.5.5,0,0,1,0-1H91.4a.472.472,0,0,1,.5.5A.536.536,0,0,1,91.4,73.6Z'
				/>
			</g>
		</svg>
	);
}
