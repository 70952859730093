import store from '../store';
import { GET_ALL_OFFICIAL_GAZETTE } from '../types';
import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';

export const saveOfficialGazette = async (data) => {
	await api
		.post('/announcements', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error',
			});
		});
};

export const getAllOfficialGazette = async (category, query) => {
	await api
		.get(
			`/announcements?category=${category}&page=${
				query?.page ? query.page : 1
			}${query?.s ? `&s=${query.s}` : ''}`,
		)
		.then((response) => {
			store.dispatch({
				type: GET_ALL_OFFICIAL_GAZETTE,
				payload: response.data,
			});
		});
};

export const getOfficialGazetteInactive = async () => {
	await api.get('/announcements?active=false').then((response) => {
		store.dispatch({
			type: GET_ALL_OFFICIAL_GAZETTE,
			payload: response.data,
		});
	});
};

export const getElements = (other) => {
	api
		.get(other ? `/announcements${other}` : '/announcements?active=true')
		.then((response) => {
			store.dispatch({
				type: GET_ALL_OFFICIAL_GAZETTE,
				payload: response.data,
			});
		});
};

export const saveElement = async (data) =>
	api
		.post('/announcements', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
			getElements('?active=false');
			return true;
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error',
			});
			return false;
		});

export const updateElement = (id, data) => {
	api
		.put(`/announcements/${id}`, data)
		.then(() => getElements('?active=false'));
};

export const deleteElement = (id) => {
	api.delete(`/announcements/${id}`).then(() => getElements('?active=false'));
};

export const activateElement = (id, status) => {
	api
		.patch(`/announcements/${id}?active=${!!status}`)
		.then(() => getElements('?active=false'));
};
