import React, { useEffect, useState } from 'react';
import {
	makeStyles,
	Box,
	Grid,
	Typography,
	IconButton,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { SightCard } from '../Results/components/SightCard';
import { get2Sight } from '../../../../../store/home/actions';
import { ChevronRight } from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	container: {
		boxSizing: 'border-box',
		// maxHeight: 596,
		height: '100%',
		overflow: 'hidden',
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100vw',
		},

		width: 1328,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 165,
	},
	navigationIconButtonRight: {
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF',
		},
		margin: 20,
	},
	navigationIconButtonLeft: {
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF',
		},
		transform: 'rotate(180deg)',
		margin: 20,
	},
	arrowDown: {
		background: '#070c35 0% 0% no-repeat padding-box',
		opacity: 1,
	},
	sliderBox: {
		display: 'inline-flex',
		height: '330',
		width: '100%',
	},
	root: {
		paddingBottom: 42,
		borderBottom: '1px solid #D5D4C9',
		height: 'fit-content',
	},
	textContainer: {
		paddingLeft: 100,
		paddingBottom: 60,
		maxWidth: 552,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 20,
			paddingRight: 20,
		},
	},
	iconContainer: {
		flexDirection: 'column',
		justifyContent: 'center',
		display: 'flex',
		width: 100,
		position: 'relative',
	},
	description: {
		boxSizing: 'border-box',
		flexWrap: 'wrap',
		fontSize: 14,
		lineHeight: '24px',
		maxWidth: 530,
		fontWeight: 'bold',
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	title: {
		fontFamily: 'Gilroy',
		fontWeight: 900,
		lineHeight: '34px',
		fontSize: 32,
		paddingBottom: 25,
	},
	totalText: {
		textTransform: 'uppercase',
		fontFamily: 'Bebas Neue',
		fontWeight: 'bold',
		letterSpacing: '1px',
		lineHeight: '15.6px',
		fontSize: '20px',
		paddingTop: 7,
	},
	totalButton: {
		margin: 'auto',
		width: 'fit-content',
		display: 'inline-flex',
		marginRight: 103,
		marginTop: 19,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	totalButtonContainer: {
		float: 'right',
		width: '100%',
		display: 'flex',
	},
}));

function SightToHome(props) {
	const classes = useStyles();
	const { category, t, style } = props;
	const list = useSelector((state) => state.home[category]);
	const lang = useSelector((state) => state.language.language);
	const [page, setPage] = useState(1);
	const [showLeft, setShowLeft] = useState(false);
	const [showRight, setShowRight] = useState(true);

	useEffect(() => {
		get2Sight(category, page);
	}, []);

	useEffect(() => {
		if (list && list.meta && list.meta.totalPages) {
			if (page === list.meta.totalPages) {
				setShowRight(false);
			}
		}
	}, [list, page]);

	const nextPage = () => {
		if (page < list.meta.totalPages) {
			const newPage = page + 1;
			setPage(newPage);
			get2Sight(category, newPage);
			!showLeft && setShowLeft(true);
		} else {
			setShowRight(false);
		}
	};

	const prevPage = () => {
		if (page > 1) {
			const newPage = page - 1;
			setPage(newPage);
			get2Sight(category, newPage);
			!showRight && setShowRight(true);
			if (newPage == 1) setShowLeft(false);
		} else {
			setShowLeft(false);
		}
	};

	const navigateToSight = () => {
		if (category) {
			window.scrollTo(0, 0);

			props.history.push(`/sights/${category}`);
		}
	};
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);

	useEffect(() => {
		setShowAnimation(props.showAnimation);
		// SightCategories.around && window.scrollTo(0, 2000);
		// SightCategories.neigh && window.scrollTo(0, 3000)
	}, [props.showAnimation]);
	return (
		<Box
			className={classes.container}
			style={{ zIndex: showAnimation ? 200 : 150 }}>
			<Box className={classes.textContainer}>
				<CSSTransition
					in={showAnimation}
					timeout={600}
					classNames='ltrshortetr'
					unmountOnExit>
					<Typography className={classes.title}>
						{t(`title${category}`)}
					</Typography>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={600}
					classNames='ltrmiddleetr'
					unmountOnExit>
					<Typography className={classes.description}>
						{t(`description${category}`)}
					</Typography>
				</CSSTransition>
			</Box>
			<CSSTransition
				in={showAnimation}
				timeout={600}
				classNames='ltrlongetor'
				unmountOnExit>
				<Box className={classes.sliderBox}>
					<Box className={classes.iconContainer}>
						<IconButton
							style={{ visibility: showLeft ? 'visible' : 'hidden' }}
							className={classes.navigationIconButtonLeft}
							onClick={() => {
								prevPage();
							}}>
							<ChevronRight
								width='24px'
								height='24px'
								fill='#070C35'
								className={classes.arrowDown}
							/>
						</IconButton>
					</Box>
					<Grid container className={classes.root} spacing={2}>
						{list &&
							list.items &&
							list.items.length > 0 &&
							list.items.map((item, index) => (
								<SightCard key={index} lang={lang} item={item} />
							))}
					</Grid>
					<Box className={classes.iconContainer}>
						<IconButton
							style={{ visibility: showRight ? 'visible' : 'hidden' }}
							className={classes.navigationIconButtonRight}
							onClick={() => {
								nextPage();
							}}>
							<ChevronRight
								width='24px'
								height='24px'
								fill='#070C35'
								className={classes.arrowDown}
							/>
						</IconButton>
					</Box>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={600}
				classNames='ltrlongetor'
				unmountOnExit>
				<Box className={classes.totalButtonContainer}>
					<Box
						className={classes.totalButton}
						onClick={() => navigateToSight()}>
						<Typography className={classes.totalText}>{t('total')} </Typography>
						<ChevronRight fill='#070C35' width={24} height={24} />
					</Box>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('sight')(withRouter(SightToHome));
