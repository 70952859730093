import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { FileCard } from '../../../FileCard';

const useStyles = makeStyles((theme) => ({
	root: {
		//	maxWidth: 1128,
		right: 0,
		left: 0,
		// marginLeft: 'auto',
		// marginRight: 'auto',
		paddingTop: 30
	}
}));

export default (props) => {
	const { data } = props;
	const classes = useStyles();

	return (
		<Grid item lg={12} md={12} sm={12} xs={12} className={classes.root}>
			{data &&
				data.items &&
				data.items.length > 0 &&
				data.items.map((item) => {
					if (item !== null) return <FileCard file={item} />;
				})}
		</Grid>
	);
};
