import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function RotatedHalfCircleStripes(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '247.027'}
			height={props.height ? props.height : '361.543'}
			viewBox='0 0 247.027 361.543'>
			<g transform='translate(705.565 -1413.615)'>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-605.568,1652.859a112.8,112.8,0,0,1-7.441-16.088'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-583.08,1513.669a113.759,113.759,0,0,1,22.74-16.142'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-583.083,1699.541a127.9,127.9,0,0,1-43.315-58.1'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-550.757,1477.691A127.02,127.02,0,0,1-513,1470.012'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-634,1604.6q-.2-3.568-.2-7.186a127.3,127.3,0,0,1,7.8-44.019'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-609.291,1695.143A142.337,142.337,0,0,1-626.4,1673.25'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-579.007,1475.525a141.071,141.071,0,0,1,72.368-19.842,142.582,142.582,0,0,1,24.752,2.154'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-647.953,1586.444a141.4,141.4,0,0,1,3.588-22.629'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-583.085,1733.324a155.858,155.858,0,0,1-79.46-135.909'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-648.38,1532.392a156.257,156.257,0,0,1,103.427-86.14'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-670.965,1641.442a169.351,169.351,0,0,1-4.737-25.328'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-575.284,1753.072a170.664,170.664,0,0,1-64.825-50.229'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-627.436,1477.686a171.128,171.128,0,0,1,21.867-18.634'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-487.821,1414.112a183.592,183.592,0,0,1,29.153,5.358'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-655.868,1489.32a185.173,185.173,0,0,1,59.2-52.7'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-557.081,1774.677a184.332,184.332,0,0,1-133.81-177.262,184.829,184.829,0,0,1,4.6-41.092'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-648.38,1736.276a199.615,199.615,0,0,1-18.5-21.805'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-640.109,1450.586a199.315,199.315,0,0,1,23.6-18.43'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-704.2,1616.115q-.863-9.234-.87-18.7,0-5.538.3-11'
				/>
			</g>
		</svg>
	);
}
