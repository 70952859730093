import React from 'react';
import { Container, Typography, Grid } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { Demo } from './Demo';

function Demos({ t }) {
	return (
		<Container>
			<div>{t('loremipsum')}</div>
			<Grid container alignItems='center' spacing={0}>
				<Grid item xs={12}>
					<Typography variant='h1'>loremipsum</Typography>
					<Demo />
				</Grid>
			</Grid>
		</Container>
	);
}
export default withNamespaces('demo')(Demos);
