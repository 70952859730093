import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Pic from '../../../../components/images/h_14.png';
import BackgroundImage from '../../../../components/images/wcolor_bg_04.png';

import { StraightStripes } from '../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Pic}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		left: 0,
		width: '696px',
		height: '279px',
		top: '268px',
		backgroundSize: 'cover',
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 300,
			top: 500
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 150,
			top: 750
		},
		[theme.breakpoints.only('md')]: {
			width: 580,
			height: 230
		}
	},
	imageBackgroundContainer: {
		position: 'absolute',
		background: `transparent url(${BackgroundImage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageBackContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		left: '89px',
		width: '600px',
		height: '239px',
		top: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 150,
			top: 0,
			left: 0
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 250,
			top: 0,
			left: 0
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.up('lg')]: {
			top: 200
		}
	},
	contentContainer: {
		top: '135px',
		width: '100%',
		height: '869px',
		position: 'absolute'
	},
	mainTitleBox: {
		top: '56px',
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: '396px',
		textAlign: 'left',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 110,
			right: 0,
			left: 'auto'
		},
		[theme.breakpoints.only('sm')]: {
			width: 500,
			top: 150,
			right: 30,
			left: 'auto'
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#EE2C48',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: '18.5px',
		marginRight: '39px',
		marginBottom: '15.5px',
		marginLeft: '39px'
	},
	halfStripes: {
		top: '13px',
		left: '360px',
		position: 'absolute',
		zIndex: 100,
		width: 400,
		height: 252,
		[theme.breakpoints.only('xs')]: {
			width: 300,
			top: 0,
			right: 0,
			left: 'auto'
		},
		[theme.breakpoints.only('sm')]: {
			width: 500,
			top: 0,
			right: 0,
			left: 'auto'
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '152px',
		width: '408px',
		right: 0,
		[theme.breakpoints.only('xs')]: {
			top: 230,
			width: '100%'
		},
		[theme.breakpoints.only('sm')]: {
			top: 270,
			width: '100%'
		}
	},
	descriptionText: {
		color: '#282D38',
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 30,
			marginRight: 20
		}
	}
}));

function SiculicidiumHistory8(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.contentContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageBackContainerFixed}>
					<Box className={classes.imageBackgroundContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.halfStripes}>
					<StraightStripes width='100%' height='100%' />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						{t('megerint17.maintitle')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						{t('megerint17.description')}
					</Typography>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('siculicidiumHistory')(SiculicidiumHistory8);
