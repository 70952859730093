import { GET_ALL_HOTELS, GET_HOTELS } from '../types';

export default function hotel(state = {}, action) {
	switch (action.type) {
		case GET_ALL_HOTELS:
			return action.payload;
		case GET_HOTELS:
			return action.payload;
		default:
			return state;
	}
}
