import React from 'react';
import CustomIcon from '../../../icons/CustomIcon';

const { ListItem, ListItemIcon, ListItemText, makeStyles } = require('@material-ui/core');
const { Link, useLocation } = require('react-router-dom');

const useStyles = makeStyles((theme) => ({
	root: {
		color: 'black',
		textDecoration: 'none',
		'&:last-child' : {
			display: "block",
			paddingBottom: 60
		}
	}
}));

export default function MenuItem(props) {
	const match = useLocation();
	const classes = useStyles();

	return (
		<>
			{props.menuDetails.visibleInMenu && (
				<Link
					to={props.menuDetails.href}
					className={classes.root}>
					<ListItem
						button
						style={
							props.menuDetails.href === match.pathname
								? { backgroundColor: '#F5F5F5' }
								: {}
						}>
						<ListItemIcon>
							<CustomIcon icon={props.menuDetails.icon} />
						</ListItemIcon>
						<ListItemText
							primary={
								props.menuDetails.dislayedName
									? props.menuDetails.dislayedName
									: props.menuDetails.name
							}
						/>
					</ListItem>
				</Link>
			)}
		</>
	);
}
