import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { changeLanguage } from '../../store/page/language/actions';
import Hungary from '../icons/Flags/Hungary';
import Romania from '../icons/Flags/Romania';

const useStyles = makeStyles((theme) => ({
	select: {
		height: 39,
		width: '100%',
		maxWidth: 282.25,
		minWidth: 155,
		backgroundColor: '#F0F0EB'
	},
	menuItem: {
		height: 39,
		width: '100%',
		backgroundColor: '#F0F0EB',
		padding: 0
	},
	menuItemText: {
		color: '#000000',
		fontSize: 16,
		fontFamily: 'Ubuntu',
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: 14,
		fontWeight: '600'
	}
}));

const LanguageSelector = (props) => {
	const classes = useStyles();
	const language = useSelector((state) => state.language.language);
	const [languages, setLanguages] = useState([
		{ value: 'ro', name: 'Română', flag: <Romania width={54} height={36} /> },
		{ value: 'hu', name: 'Magyar', flag: <Hungary width={54} height={36} /> }
	]);

	useEffect(() => {
		if (language && language !== languages[0].value) {
			setLanguages([...languages].reverse());
		}
	}, [language, languages]);

	const handleChange = (e) => {
		const { value } = e.target;
		if (value) {
			changeLanguage(value);
		}
	};

	return (
		<FormControl>
			<Select
				value={language || 'ro'}
				onChange={handleChange}
				disableUnderline
				MenuProps={{ MenuListProps: { disablePadding: true } }}
				className={classes.select}>
				{languages.map((item) => (
					<MenuItem
						key={item.value}
						value={item.value}
						className={classes.menuItem}>
						<Grid container direction='row'>
							{item.flag}
							<Typography className={classes.menuItemText}>
								{item.name}
							</Typography>
						</Grid>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default LanguageSelector;
