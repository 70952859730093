import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Romania(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 512 512'>
			<rect
				xmlns='http://www.w3.org/2000/svg'
				y='85.331'
				fill='#FFDA44'
				width='512'
				height='341.326'
			/>
			<rect
				xmlns='http://www.w3.org/2000/svg'
				y='85.331'
				fill='#0052B4'
				width='170.663'
				height='341.337'
			/>
			<rect
				xmlns='http://www.w3.org/2000/svg'
				x='341.337'
				y='85.331'
				fill='#D80027'
				width='170.663'
				height='341.337'
			/>
		</svg>
	);
}
