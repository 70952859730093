import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage03.png';

import { StraightStripes } from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		width: 807,
		height: 608,
		top: 0,
		right: 0,
		zIndex: 99,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 300,
			left: 0,
			top: 0
		},
		[theme.breakpoints.only('md')]: {
			width: 600,
			top: 150,
			height: 500
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: 1183,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 81,
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},
	mainTitleBox: {
		top: 164,
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: 0,
		textAlign: 'left',
		zIndex: 101,
		width: 700,
		[theme.breakpoints.only('md')]: {
			width: 600,
			left: 30
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 330
		},
		[theme.breakpoints.only('sm')]: {
			top: 350
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#48B3C9',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: 19,
		marginRight: 39,
		marginBottom: 11,
		marginLeft: 30
	},
	halfStripes: {
		top: 355,
		right: 0,
		position: 'absolute',
		zIndex: 100,
		width: '400px',
		height: '252px',
		[theme.breakpoints.only('xs')]: {
			width: 300,
			height: 300,
			top: 250
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: 318,
		width: 432,
		left: 0,
		zIndex: 100,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 550
		},
		[theme.breakpoints.only('sm')]: {
			top: 620,
			width: '100%'
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.down('md')]: {
			marginRight: 20,
			marginLeft: 30
		}
	},
	awesomeContainer2: {
		position: 'absolute',
		top: 544,
		left: 455,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		},
		zIndex: 101
	},
	awesomeContainer1: {
		position: 'absolute',
		top: 48,
		left: 0,
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	},
	awesomeText: {
		fontFamily: 'CLOAK',
		color: '#E0B080',
		letterSpacing: '0.68px',
		lineHeight: '92px',
		fontSize: '68px'
	}
}));

function MonumentHistory5(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='vintageimage'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						<Trans i18nKey='monumhistory5.maintitle' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrmiddle'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='monumhistory5.description' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.halfStripes}>
					<StraightStripes width='100%' height='100%' />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.awesomeContainer1}>
					<Typography className={classes.awesomeText}>1905</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.awesomeContainer2}>
					<Typography className={classes.awesomeText}>1940</Typography>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('monumentHistory')(MonumentHistory5);
