import React from 'react';

const PublicInterest = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={props.width}
		height={props.height}
		viewBox='0 0 14.94 79.56'>
		<title>Public interest</title>
		<g xmlns='http://www.w3.org/2000/svg' id='Layer_2' data-name='Layer 2'>
			<g id='Layer_1-2' data-name='Layer 1'>
				<g id='Layer_2-2' data-name='Layer 2'>
					<g id='Layer_1-2-2' data-name='Layer 1-2'>
						<circle
							fill='none'
							stroke='#160e44'
							strokeLinecap='round'
							strokeMiterlimit={10}
							cx='7.47'
							cy='7.47'
							r='6.97'
						/>
						<rect
							fill='none'
							stroke='#160e44'
							strokeLinecap='round'
							strokeMiterlimit={10}
							x='2.63'
							y='23.36'
							width='9.69'
							height='55.7'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export default PublicInterest;
