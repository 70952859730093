import React from 'react';
import { Box, Typography, withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { PostBody } from '../../../../../components/layout';
import Grid from '@material-ui/core/Grid';
import 'moment/min/locales';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Image } from '../../../../../components/image';
import { Call, Navigation } from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	headerImageBox: {
		width: 552,
		maxHeight: '360px',
		marginTop: '-20px',
		marginLeft: '-20px',
		[theme.breakpoints.down('sm')]: {
			minWidth: 0,
			marginLeft: 0,
			width: "100%"
		}
	},
	mainCard: {
		background: '#FAFAF5 0% 0% no-repeat padding-box;',
		marginBottom: '60px',
		maxWidth: '1108px',
		minHeight: '360px',
		margin: 'auto',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	hotelName: {
		color: '#070C35',
		fontSize: '22px',
		fontWeight: 'bold',
		marginLeft: '24px',
		marginBottom: '20px',
		marginTop: '35px'
	},
	tellAddress: {
		marginLeft: '24px',
		color: '#696B77',
		fontSize: '14px',
		marginTop: '8px',
		fontWeight: 'bold'
	},
	hotelDetails: {
		marginLeft: '24px',
		color: '#070C35',
		fontSize: '14px',
		marginTop: '8px',
		fontWeight: 'bold'
	},
	hotelDescription: {
		marginTop: '30px',
		marginLeft: '24px',
		color: '#070C35',
		fontSize: '14px',
		fontWeight: 'bold',
		paddingBottom: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	callNavigationContainer: {
		marginTop: '32px',
		[theme.breakpoints.only('xs')]:{
			marginTop: 0
		}
	},
	imageContainer: {
		width: '100%',
		height: '360px',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.only('xs')]: {
			height: 'fit-content',

			width: '100%',
			height: 'auto'
		},
		[theme.breakpoints.only('sm')]: {
			height: 'fit-content',

			width: '100%',
			maxHeight: 300
		}
	},
	img: {
		width: '100%'
		// height: "600px",
	},
	callNavigationGrid:{
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-end',
		[theme.breakpoints.only('xs')] : {
			justifyContent: 'flex-start',
			marginLeft:24
		}
	}
}));

function HotelCard(props) {
	const classes = useStyles();
	const { hotelItem, createdAt, id, t, width } = props;
	const language = useSelector((state) => state.language.language);
	const localizedHotelItem = hotelItem.content.filter(
		(item) => item.lang === language
	)[0];

	const navigateToNews = () => {
		props.history.push(`/hotel/${hotelItem.id}`);
		window.scrollTo(0, 0);
	};
	return (
		<>
			{localizedHotelItem && (
				<Grid
					className={classes.mainCard}
					onClick={() => navigateToNews()}
					container
					direction='row'
					justify='flex-start'
					alignItems='flex-start'>
					<Grid item xs={12} md={6} className={classes.headerImageBox}>
						<Box className={classes.imageContainer}>
							<Image size='md' image={localizedHotelItem.headerImage.url} />
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid
							container
							direction='row'
							justify='flex-start'
							alignItems='flex-start'>
							<Grid item sm={6} xs={12}>
								<Typography className={classes.hotelName}>
									{localizedHotelItem.name}
								</Typography>
							</Grid>
							<Grid item sm={6} xs={12} className={classes.callNavigationContainer}>
								<Grid
									container
									
									className ={classes.callNavigationGrid}>
									{localizedHotelItem.phone && (
										<Grid item xs={2}>
											<a href={`tel://+${localizedHotelItem.phone}`}>
												<Call height={24} width={24} />
											</a>
										</Grid>
									)}
									{localizedHotelItem.page && (
										<Grid item xs={2}>
											<a href={localizedHotelItem.page}>
												<Navigation height={24} width={24} />
											</a>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
						{localizedHotelItem.address && (
							<Grid
								container
								direction='row'
								justify='flex-start'
								alignItems='flex-start'>
								<Grid item xs={12} sm={2}>
									<Typography className={classes.tellAddress}>
										{t('address')}:
									</Typography>
								</Grid>
								<Grid item xs={12} sm={9}>
									<Typography className={classes.hotelDetails}>
										{localizedHotelItem.address}
									</Typography>
								</Grid>
							</Grid>
						)}
						{localizedHotelItem.phone && (
							<Grid
								container
								direction='row'
								justify='flex-start'
								alignItems='flex-start'>
								<Grid item xs={12} sm={2} >
									<Typography className={classes.tellAddress}>
										{t('phone')}:
									</Typography>
								</Grid>
								<Grid item xs={12} sm={9}>
									<Typography className={classes.hotelDetails}>
										{localizedHotelItem.phone}
									</Typography>
								</Grid>
							</Grid>
						)}
						{localizedHotelItem.web && (
							<Grid
								container
								direction='row'
								justify='flex-start'
								alignItems='flex-start'>
								<Grid item xs={12} sm={2}>
									<Typography className={classes.tellAddress}>
										{t('web')}:
									</Typography>
								</Grid>
								<Grid item xs={12} sm={9}>
									<Typography className={classes.hotelDetails}>
										{localizedHotelItem.page}
									</Typography>
								</Grid>
							</Grid>
						)}
						<Grid
							container
							direction='row'
							justify='flex-start'
							alignItems='flex-start'>
							<Grid item xs={12}>
								<Typography className={classes.hotelDescription}>
									{localizedHotelItem.intro}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
}

export default withNamespaces('hotel')(withRouter(HotelCard));
