import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function HomeScreenHalfCircle(props) {
	return (
		<svg width={props.width} height={props.height} viewBox='0 0 192 96'>
			<g>
				<path
					className='a'
					d='M192,0A96,96,0,0,1,96,96,96,96,0,0,1,0,0Z'
					fill={props.fill ? props.fill : '#070c35'}
					transform='translate(0 1)'
				/>
			</g>
		</svg>
	);
}
