import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import { ImageSelector } from '../../../components/modals';
import { Select, TextField } from '../../../components/form';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '70vw'
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px'
	}
}));

export default withNamespaces(['common', 'council'])((props) => {
	const { open, setOpen, onConfirm, t } = props;

	const classes = useStyles();
	return (
		<Formik
			initialValues={{
				category: 'worker',
				content: [
					{
						lang: 'hu'
					},
					{
						lang: 'ro'
					}
				]
			}}
			onSubmit={(
				values,
				{ setSubmitting, setErrors, setStatus, resetForm }
			) => {
				onConfirm(values).then((result) => {
					if (result) {
						resetForm({});
						setOpen(false);
					}
				});
			}}>
			{({
				values,
				errors,
				touched,
				handleReset,
				setFieldValue,
				submitForm
			}) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>
						{t('newelementCouncil')}
					</DialogTitle>
					<DialogContent>
						<Form>
							<Field
								name='category'
								label={t('category')}
								options={[
									{
										value: 'worker',
										label: t('worker')
									},
									{
										value: 'citizen',
										label: t('citizen')
									}
								]}
								component={Select}
								size='small'
								value={values.category}
								onChange={(evt) => setFieldValue('category', evt.target.value)}
							/>
							<FieldArray
								name='content'
								render={() => (
									<Box>
										{values.content.map((item, index) => (
											<Box key={index} className={classes.fieldArrayItem}>
												<Typography variant='h2'>
													{t(`common:${item.lang}`)}
												</Typography>
												<Field
													name={`content[${index}].name`}
													label={t('common:createCouncilName')}
													component={TextField}
												/>
												<Field
													name={`content[${index}].shortDesc`}
													label={t('common:createCouncilDesc')}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].intro`}
													label={t('common:createCouncilIntro')}
													component={TextField}
													multiline
													rows={2}
												/>

												<ImageSelector
													fieldName={`content[${index}].imageId`}
													setImageId={(id) => {
														setFieldValue(`content[${index}].imageId`, id);
													}}
												/>
											</Box>
										))}
									</Box>
								)}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='secondary'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='default'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
