import React, { useEffect } from 'react';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import LocalCouncilCard from './LocalCouncilCard';
import {
	Village,
	Council,
	HonoraryCitizens
} from '../../../../../components/icons';
import { getMembers } from '../../../../../store/council/actions';

import Breadcrumbs from '../../../../../components/Breadcrumbs';
import { transformQuery } from '../../../../../lib/helpers/queryTransformer';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	contentContainer: {
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30
	},
	titleContainer: {
		width: '100%',
		height: 25,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 30,
		borderBottom: '1px solid #D5D4C9'
	},
	categoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px'
	},
	churchCategoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		paddingTop: 89,
		paddingLeft: 10,
		paddingBottom: 50
	},
	imageContainer: {
		height: 264,
		width: 264,
		background: `transparent url(${Image}) 0% 0% no-repeat padding-box`,
		marginRight: 24
	},
	desciptionContainer: {
		width: 'calc(100% - 264px)'
	},
	content: {
		display: 'inline-flex'
	},
	descriptionTitle: {
		fontFamily: 'Ubuntu',
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '34px'
	},
	descriptionTitleContainer: {
		paddingBottom: 39
	},
	descriptionText: {
		fontFamily: 'Ubuntu',
		lineHeight: '24px',
		fontSize: '14px',
		flexWrap: 'wrap',
		fontWeight: 'bold'
	},
	cardsContainer: {
		display: 'inline-flex',
		paddingTop: 40
	},
	village2Container: {
		paddingTop: '40px'
	},
	title2Container: {
		width: '100%',
		height: 73,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 10,
		borderBottom: '1px solid #D5D4C9',
		alignItems: 'flex-end',
		paddingBottom: '10px'
	},
	pageTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '26px',
		letterSpacing: '0px',
		marginLeft: 20,
		marginBottom: theme.spacing(1)
	}
}));

function LocalCouncil(props) {
	const classes = useStyles();

	const { t } = props;

	const council = useSelector((state) => state.council.list);

	const history = useHistory();

	const queries = qs.parse(history.location.search, {
		ignoreQueryPrefix: true
	});

	useEffect(() => {
		getMembers(
			queries.category
				? transformQuery({
					...queries,
					limit: 20,
					category:
							queries.category === 'localcouncil' ? 'worker' : queries.category
				  })
				: '?category=worker&limit=20'
		);
	}, []);

	return (
		<Box className={classes.pageContainer}>
			<Box className={classes.contentContainer}>
				<Box className={classes.titleContainer}>
					{queries.category === 'citizen' ? (
						<Breadcrumbs
							breadcrumbs={[
								{
									href: 'community',
									name: t('community')
								},
								{
									href: 'localcouncil',
									name: t('citizens'),
									final: true
								}
							]}
						/>
					) : (
						<Breadcrumbs
							breadcrumbs={[
								{
									href: 'council',
									name: t('category')
								},
								{
									href: 'localcouncil',
									name: t('localcouncil'),
									final: true
								}
							]}
						/>
					)}
				</Box>
				<Box className={classes.title2Container}>
					{queries.category === 'citizen' ? (
						<HonoraryCitizens width='100px' height='73px' />
					) : (
						<Council width='75px' height='55px' />
					)}
					<Typography className={classes.pageTitle}>
						{t(queries.category === 'citizen' ? 'citizens' : 'localcouncil')}
					</Typography>
				</Box>
				<Grid className={classes.content} container spacin={0}>
					{council?.items &&
						council.items.map((item, index) => (
							<LocalCouncilCard member={item} key={index} />
						))}
				</Grid>
			</Box>
			<Box className={classes.village2Container}>
				<Village />
			</Box>
		</Box>
	);
}
export default withNamespaces('council')(LocalCouncil);
