import axios from 'axios';

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 20000,
	withCredentials: true,
});

api.defaults.headers.get.Accept = 'application/json'; // default header for all get request

api.defaults.headers.post['Media-Type'] = 'application/json';

api.interceptors.request.use(
	(request) => request,
	(error) =>
		// if (error.response) {
		// 	console.warn(
		// 		`${error.response.status} from: ${error.response.config.url}`
		// 	);
		// }
		Promise.reject(error),
);

api.interceptors.response.use(
	(response) => response,
	(error) => Promise.reject(error),
);
