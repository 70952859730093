import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';
import {
	LivesBicolorDiv,
	TogetherLogo,
	TogetherLogo2,
	Culture2,
} from '../../../../../components/icons';

const BP960 = '@media (max-width: 960px)';
const BP800 = '@media (max-width: 800px)';
const BP710 = '@media (max-width: 710px)';
const BP600 = '@media (max-width: 600px)';
const BP400 = '@media (max-width: 400px)';
const BP458 = '@media (max-width: 458px)';
const BP380 = '@media (max-width: 380px)';

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		position: 'relative',
		width: '100%',
		height: 700,
		[theme.breakpoints.only('xs')]: {
			height: 400,
		},
	},
	contentContainer: {
		position: 'relative',
		right: 0,
		left: 0,
		marginRight: 'auto',
		marginLeft: 'auto',

		width: '100%',
		[theme.breakpoints.only('lg')]: {
			width: '70%',
			right: 0,
		},
		[theme.breakpoints.only('xl')]: {
			width: 1128,
			right: 0,
			left: 0,
		},
	},
	background: {
		position: 'absolute',
		width: '100%',
		top: 0,
		height: 700,
		[theme.breakpoints.only('xs')]: {
			height: 400,
		},
	},
	content: {
		position: 'absolute',
		top: 0,
		height: 700,
		width: '100%',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 400,
		},
	},
	togetherLogo2Container: {
		position: 'absolute',
		top: 159,
		left: 700,
		width: '379px',
		height: '406px',
		transition: 'all .3s',
		[theme.breakpoints.only('xs')]: {
			left: 0,
			top: 280,
			width: 115,
			height: 115,
		},
		[theme.breakpoints.only('md')]: {
			right: 50,
			left: 'auto',
			top: 159,
			width: 350,
			height: 380,
		},
		[theme.breakpoints.only('sm')]: {
			left: 0,
			top: 500,
			width: 180,
			height: 180,
		},
		[BP400]: {
			opacity: 0,
		},
	},
	togetherLogoContainer: {
		position: 'absolute',
		top: 134,
		left: 0,
		height: '157px',
		width: '157',
		[theme.breakpoints.only('xs')]: {
			width: 100,
			height: 100,
			top: 100,
			left: 50,
		},
		[theme.breakpoints.only('sm')]: {
			left: 20,
		},
		[theme.breakpoints.only('md')]: {
			left: 100,
			top: 170,
		},
		[theme.breakpoints.only('xl')]: {
			left: -80,
		},
	},
	cultureLogoContainer: {
		position: 'absolute',
		top: 563,
		left: 508,
		width: '187px',
		height: '137px',
		[theme.breakpoints.only('xs')]: {
			width: 100,
			height: 100,
			left: 'auto',
			right: 10,
			top: 314,
			zIndex: 100,
		},
	},
	mainTitleContainer: {
		position: 'absolute',
		top: 155,
		left: 202,
		[theme.breakpoints.only('xs')]: {
			top: 10,
			left: 90,
		},
		[theme.breakpoints.only('sm')]: {
			left: 272,
		},
		[theme.breakpoints.only('md')]: {
			top: 30,
			left: 300,
		},
		[theme.breakpoints.only('lg')]: {
			left: 300,
		},
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		fontSize: '50px',
		letterSpacing: '4px',
		lineHeight: '50px',
		color: '#FFFFFF',
		[theme.breakpoints.only('xs')]: {
			fontSize: '35px',
		},
	},

	titleText: {
		fontFamily: 'Bebas Neue',
		fontSize: '50px',
		letterSpacing: '4px',
		lineHeight: '50px',
		color: '#FFFFFF',
		position: 'absolute',
		top: 30,
		left: 300,
		transition: 'all .3s',
		[BP960]: {
			top: 155,
			left: 272,
		},
		[BP600]: {
			fontSize: '35px',
			top: 10,
			left: 90,
		},
	},

	titleTextHu: {
		[BP380]: {
			left: 50,
		},
	},

	bonyadText: {
		color: '#53959a',
		fontFamily: 'Bebas Neue',
		fontSize: '40px',
		letterSpacing: '3px',
		lineHeight: '40px',
		fontWeight: 400,
		top: 100,
		left: 310,
		position: 'absolute',
		transition: 'all .3s',
		[BP960]: {
			top: 225,
			left: 282,
		},
		[BP600]: {
			top: 60,
			left: 140,
		},
		[BP380]: {
			left: 100,
			fontSize: '32px',
		},
	},

	madefalvaText: {
		color: '#ee2b24',
		fontFamily: 'Bebas Neue',
		fontSize: '40px',
		letterSpacing: '3px',
		lineHeight: '40px',
		position: 'absolute',
		top: 100,
		left: 556,
		transition: 'all .3s',
		[BP960]: {
			top: 225,
			left: 490,
		},
		[BP710]: {
			top: 270,
			left: 300,
		},
		[BP600]: {
			top: 97,
			left: 170,
		},
		[BP380]: {
			top: 93,
			fontSize: '32px',
			left: 145,
		},
	},

	madefalvaTextRo: {
		[BP960]: {
			left: 520,
		},
		[BP800]: {
			top: 270,
			left: 300,
		},
		[BP600]: {
			top: 110,
			left: 170,
			paddingRight: '20px',
			boxSizing: 'border-box',
			lineHeight: '34px',
		},
		[BP380]: {
			fontSize: '32px',
			lineHeight: '28px',
		},
	},

	subTitleContainer: {
		position: 'absolute',
		left: 202,
		top: 225,
		[theme.breakpoints.only('xs')]: {
			top: 0,
			left: 0,
		},
		[theme.breakpoints.only('sm')]: {
			left: 272,
		},
		[theme.breakpoints.only('md')]: {
			top: 100,
			left: 300,
		},
		[theme.breakpoints.only('lg')]: {
			left: 300,
		},
	},
	subTitle1: {
		color: '#53959a',
		fontFamily: 'Bebas Neue',
		fontSize: '40px',
		letterSpacing: '3px',
		lineHeight: '40px',
		paddingRight: 20,
		paddingLeft: 10,
		fontWeight: 400,
		[theme.breakpoints.only('xs')]: {
			lineHeight: '30px',
			marginTop: 5,
		},
	},
	subTitle2: {
		color: '#ee2b24',
		fontFamily: 'Bebas Neue',
		fontSize: '40px',
		letterSpacing: '3px',
		lineHeight: '40px',
		[theme.breakpoints.only('xs')]: {
			lineHeight: '30px',
			marginTop: 2,
		},
	},
	subTitleText: {
		display: 'flex',
	},
	descritpionContainer: {
		position: 'absolute',
		top: 344,
		left: 288,
		width: 264,
		textAlign: 'left',
		transition: 'all .3s',
		[theme.breakpoints.only('xs')]: {
			top: 200,
			left: 200,
			width: 240,
			position: 'absolute',
		},
		[theme.breakpoints.only('md')]: {
			left: 320,
		},
		[BP458]: {
			top: 220,
			left: 160,
			maxWidth: 200,
		},
		[BP380]: {
			left: 100,
		},
	},
	description: {
		flexWrap: 'wrap',
		fontSize: '18px',
		lineHeight: '30px',
		fontFamily: 'Ubuntu',
		letterSpacing: '0.18px',
		transition: 'all .3s',
		color: '#ffffff',
		[BP458]: {
			fontSize: '16px',
			lineHeight: '22px',
		},
	},
	madefalva: {
		[theme.breakpoints.only('xs')]: {
			top: 100,
			left: 170,
			position: 'absolute',
		},
	},
	bonyad: {
		[theme.breakpoints.only('xs')]: {
			top: 60,
			left: 130,
			position: 'absolute',
		},
	},
	subtitle2Roman: {
		[theme.breakpoints.only('xs')]: {
			paddingTop: '30px',
		},
	},
	tohetherLogo2Roman: {
		[theme.breakpoints.only('lg')]: {
			top: 260,
		},
	},
}));

function Lives(props) {
	const classes = useStyles();
	const language = useSelector((state) => state.language.language);
	const { t } = props;

	return (
		<Box className={classes.mainContainer}>
			<Box className={classes.background}>
				<LivesBicolorDiv width='100%' height='100%' />
			</Box>

			<Box className={classes.content}>
				<Box className={classes.contentContainer}>
					<Box className={classes.togetherLogoContainer}>
						<TogetherLogo height='100%' width='100%' />
					</Box>

					<Typography
						className={clsx(
							classes.titleText,
							language === 'hu' && classes.titleTextHu,
						)}>
						{t('together')}
					</Typography>
					<Typography className={classes.bonyadText}>{t('bonyhad')}</Typography>
					{/* <Typography className={language == "ro" ? [classes.subTitle2, classes.subtitle2Roman] : classes.subTitle2}> */}
					<Typography
						className={clsx(
							classes.madefalvaText,
							language === 'ro' && classes.madefalvaTextRo,
						)}>
						{t('madefalva')}
					</Typography>

					<Box className={classes.descritpionContainer}>
						<Typography className={classes.description}>
							{t('description')}
						</Typography>
					</Box>
					<Box
						className={
							language === 'ro'
								? [classes.togetherLogo2Container, classes.tohetherLogo2Roman]
								: classes.togetherLogo2Container
						}>
						<TogetherLogo2 width='100%' height='100%' />
					</Box>
					<Box className={classes.cultureLogoContainer}>
						<Culture2 width='100%' height='100%' base='#ffffff' />
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default withNamespaces('lives')(Lives);
