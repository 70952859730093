import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import layout from './layout';

const theme = createMuiTheme({
	palette,
	typography,
	layout,
	overrides
});

export default theme;
