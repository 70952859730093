import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function LivesBicolorDiv(props) {
	const leftDivStyle = {
		background: '#ee2a24',
		width: '21%',
		height: '100%',
		float: 'left'
	};
	const centerDivStyle = {
		width: '14%',
		height: '100%',
		float: 'left'
	};
	const rightDivStyle = {
		background: '#033149',
		width: '65%',
		height: '100%',
		float: 'left'
	};

	return (
		<div
			style={{
				width: props.width ? props.width : '100%',
				height: props.height ? props.height : '100%'
			}}>
			<div style={leftDivStyle} />
			<div style={centerDivStyle}>
				<svg
					width='100%'
					height='100%'
					preserveAspectRatio='none'
					viewBox='0 0 100 100'>
					<path fill='#033149' d='M 0 0 L 100 0 L 100 100 z' />
					<path fill='#ee2a24' d='M 100 100 L 0 100 L 0 0 z' />
				</svg>
			</div>
			<div style={rightDivStyle} />
		</div>
	);
}
