import React from 'react';

export const FacebookSvg = (props) => (
	<svg width={props.width} height={props.height} viewBox='0 0 48 48'>
		<g>
			<path
				className='a'
				d='M207.62,52.611v48h48v-48ZM238.855,70.56l-.5,4.825-4.813.083v15.8h-6.474v-15.8l-3.236-.083.082-4.907h3.237c0-1.94-.576-7.413,2.048-9.315,3.706-2.689,10.317-.832,10.317-.832l-.83,4.989s-2.563-.415-2.987-.415c-.637,0-2.157.25-2.157,1.746V70.56Z'
				fill='#FFFFFF'
				transform='translate(-207.62 -52.611)'
			/>
		</g>
	</svg>
);

export const InstagramSvg = (props) => (
	<svg width={props.width} height={props.height} viewBox='0 0 48 48'>
		<g>
			<path
				className='a'
				d='M-60.192,122.61q-1.815-.041-3.63,0a1.014,1.014,0,0,0-1.027,1c0,.029,0,.057,0,.086-.007,1.2-.009,2.391,0,3.588a1.024,1.024,0,0,0,.961,1.083,1.056,1.056,0,0,0,.149,0q1.711.006,3.427,0a1.045,1.045,0,0,0,1.131-.953.977.977,0,0,0,0-.195v-1.712c0-.6.007-1.2,0-1.794a1.02,1.02,0,0,0-.935-1.1ZM-95,110v48h48V110Zm38.412,33.389a5.609,5.609,0,0,1-5.558,5.544H-79.562a5.611,5.611,0,0,1-5.577-5.561q-.007-4.635,0-9.268h8.009c-.018.194-.031.39-.031.589a6.379,6.379,0,0,0,6.469,6.291,6.381,6.381,0,0,0,6.292-6.309c0-.193-.015-.382-.03-.571h7.842Zm0-12.265v.291h-8.725a6.37,6.37,0,0,0-8.738-2.192,6.37,6.37,0,0,0-2.192,2.192h-8.9v-.291s-.048-4.52.047-5.824a5.3,5.3,0,0,1,2.216-3.941,7.543,7.543,0,0,1,2.339-1.014v6.919h1.5v-7.017h1.19v7.017h1.37v-7.017h1.03v7.021h1.487v-7.021h11.755a5.6,5.6,0,0,1,5.61,5.382c.044,1.264.007,2.532,0,3.8v.018Z'
				fill='#FFFFFF'
				transform='translate(95 -110)'
			/>
		</g>
	</svg>
);

export const YoutubeSvg = (props) => (
	<svg width={props.width} height={props.height} viewBox='0 0 48 48'>
		<g>
			<path
				className='a'
				d='M2.2,246.945,11.091,252,2.2,257.055ZM30,228v48H-18V228Zm-7.318,24a44.787,44.787,0,0,0-.711-8.237,4.271,4.271,0,0,0-3.006-3.025c-2.651-.715-13.283-.715-13.283-.715s-10.632,0-13.284.715a4.271,4.271,0,0,0-3.006,3.025,48.135,48.135,0,0,0,0,16.474A4.271,4.271,0,0,0-7.6,263.262c2.652.715,13.284.715,13.284.715s10.632,0,13.283-.715a4.271,4.271,0,0,0,3.006-3.025A44.787,44.787,0,0,0,22.682,252Z'
				fill='#FFFFFF'
				transform='translate(18 -228)'
			/>
		</g>
	</svg>
);

export const LikeSvg = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={props.width}
		height={props.height}
		viewBox='0 0 24 24'>
		<path fill='none' className='a' d='M0,0H24V24H0Z' />
		<path
			fill='#FFFFFF'
			className='b'
			d='M1,19H4.6V8.2H1ZM20.8,9.1A1.805,1.805,0,0,0,19,7.3H13.321l.855-4.113L14.2,2.9a1.355,1.355,0,0,0-.4-.954L12.853,1,6.931,6.931A1.76,1.76,0,0,0,6.4,8.2v9A1.805,1.805,0,0,0,8.2,19h8.1a1.788,1.788,0,0,0,1.656-1.1l2.718-6.345A1.778,1.778,0,0,0,20.8,10.9Z'
			transform='translate(1 1)'
		/>
	</svg>
);
