import React, { useState, useEffect, useRef } from 'react';
import { Box, makeStyles, Hidden } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import {
	MonumentHistory,
	SiculicidiumHistory2,
	SiculicidiumHistory3,
	SiculicidiumHistory4,
	SiculicidiumHistory5,
	SiculicidiumHistory6,
	SiculicidiumHistory7,
	SiculicidiumHistory8,
	SiculicidiumHistory9,
	SiculicidiumHistory10,
	SiculicidiumHistory11
} from '../History/components';
import { showFooter } from '../../../store/layout/actions';
const useStyles = makeStyles((theme) => ({
	overflow: {
		position: 'relative',
		overflow: 'visible',
		height: 'fit-content'
	},
	container: {
		minHeight: '100vh',
		['@media screen and (max-height: 750px) and (min-width: 1280px)' ]:{
			height: '125vh'
		}
	}
}));

const SCROLL_DEBOUNCE = 950;
let lastScrollTimestamp;

function SiculicidiumHistoryAnimated(props) {
	const classes = useStyles();

	const [show, setShow] = useState({
		sicHistory2: false,
		sicHistory3: false,
		sicHistory4: false,
		sicHistory5: false,
		sicHistory6: false,
		sicHistory7: false,
		sicHistory8: false,
		sicHistory9: false,
		sicHistory10: false,
		sicHistory11: false
	});

	useEffect(() => {
		setShow({ ...show, sicHistory2: true });
		showFooter(false);
		return () => {
			showFooter(true);
		}
	}, []);

	const isScrollVisible = () =>
		document.body.scrollHeight > document.body.clientHeight ||
		document.documentElement.scrollHeight >
			document.documentElement.clientHeight;

	const isScrolledTop = () => window.scrollY === 0;
	const getHomePageElementHeight = () => {
		if (!document) {
			return 0;
		}
		const homePageElement = document.getElementById('homepage');
		if (!homePageElement) {
			return document.body.scrollHeight;
		}

		return homePageElement.clientHeight;
	};

	const isScrolledBottom = () =>
		Math.round(window.scrollY) + document.body.scrollHeight >=
		getHomePageElementHeight();

	const next = () => {
		if (show.sicHistory2) {
			setShow({
				sicHistory2: false,
				sicHistory3: true
			});
		}
		if (show.sicHistory3) {
			setShow({
				sicHistory3: false,
				sicHistory4: true
			});
		}
		if (show.sicHistory4) {
			setShow({
				sicHistory4: false,
				sicHistory5: true
			});
		}
		if (show.sicHistory5) {
			setShow({
				sicHistory5: false,
				sicHistory6: true
			});
		}
		if (show.sicHistory6) {
			setShow({
				sicHistory6: false,
				sicHistory7: true
			});
		}
		if (show.sicHistory7) {
			setShow({
				sicHistory7: false,
				sicHistory8: true
			});
		}
		if (show.sicHistory8) {
			setShow({
				sicHistory8: false,
				sicHistory9: true
			});
		}
		if (show.sicHistory9) {
			setShow({
				sicHistory9: false,
				sicHistory10: true
			});
		}
		if (show.sicHistory10) {
			setShow({
				sicHistory10: false,
				sicHistory11: true
			});
			showFooter(true);
		}
		if (show.sicHistory11) {
		}
	};

	const previous = () => {
		if (show.sicHistory3) {
			setShow({
				sicHistory2: true,
				sicHistory3: false
			});
		}
		if (show.sicHistory4) {
			setShow({
				sicHistory3: true,
				sicHistory5: false,
				sicHistory4: false
			});
		}
		if (show.sicHistory5) {
			setShow({
				sicHistory4: true,
				sicHistory5: false
			});
		}
		if (show.sicHistory6) {
			setShow({
				sicHistory5: true,
				sicHistory6: false
			});
		}
		if (show.sicHistory7) {
			setShow({
				sicHistory6: true,
				sicHistory7: false
			});
		}
		if (show.sicHistory8) {
			setShow({
				sicHistory7: true,
				sicHistory8: false
			});
		}
		if (show.sicHistory9) {
			setShow({
				sicHistory8: true,
				sicHistory9: false
			});
		}
		if (show.sicHistory10) {
			setShow({
				sicHistory9: true,
				sicHistory10: false
			});
		}
		if (show.sicHistory11) {
			setShow({
				sicHistory10: true,
				sicHistory11: false
			});
			showFooter(false)
		}
		/*
if (isScrollVisible() && !isScrolledTop()) {
	return;
}
setTimeout(() => {
	onClick(active > 0 ? active - 1 : 0);
	window.scrollTo(0, 0);
}, 100); */
	};

	const handleSwipe = (e, initialX, initialY) => {
		if (initialX === undefined || initialY === undefined) {
			return;
		}

		const currentX = e.touches[0].clientX;
		const currentY = e.touches[0].clientY;
		const diffX = initialX - currentX;
		const diffY = initialY - currentY;

		if (Math.abs(diffX) > Math.abs(diffY)) {
			return;
		}

		if (diffY > 0) {
			next(); // swipe up
		} else {
			previous(); // swipe down
		}
	};

	// tslint:disable-next-line: no-any
	const handleWheel = (event) => {
		event.stopPropagation();
		if (Date.now() - lastScrollTimestamp < SCROLL_DEBOUNCE) {
			return;
		}

		lastScrollTimestamp = Date.now();
		const delta = Math.sign(event.deltaY);
		if(show.sicHistory11){
			if(window.scrollY == 0 && delta == -1){
				previous();
			}
		}
		else{
		if (delta === 1) {
			next();
		} else if (delta === -1) {
			previous();
		}}
	};

	// tslint:disable-next-line: no-any
	const handleKey = (e) => {
		switch (e.code) {
			case 'ArrowDown':
				next();
				break;
			case 'ArrowUp':
				previous();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		let initialX;
		let initialY;

		const startTouch = (e) => {
			initialX = e.touches[0].clientX;
			initialY = e.touches[0].clientY;
		};

		const moveTouch = (e) => {
			/* if (visible) {
		e.preventDefault();
		initialX = undefined;
		initialY = undefined;

		return;
	} */

			handleSwipe(e, initialX, initialY);
			initialX = undefined;
			initialY = undefined;
		};

		window.addEventListener('wheel', handleWheel);
		window.addEventListener('keyup', handleKey);
		window.addEventListener('touchstart', startTouch, false);
		window.addEventListener('touchmove', moveTouch, { passive: false });

		return () => {
			window.removeEventListener('wheel', handleWheel);
			window.removeEventListener('keyup', handleKey);
			window.removeEventListener('touchstart', startTouch);
			window.removeEventListener('touchmove', moveTouch);
		};
	}, [show]);

	return (
		<Box className={classes.container}>
			<SiculicidiumHistory2 showAnimation={show.sicHistory2} />
			<SiculicidiumHistory3 showAnimation={show.sicHistory3} />
			<SiculicidiumHistory4 showAnimation={show.sicHistory4} />
			<SiculicidiumHistory5 showAnimation={show.sicHistory5} />
			<SiculicidiumHistory6 showAnimation={show.sicHistory6} />
			<SiculicidiumHistory7 showAnimation={show.sicHistory7} />
			<SiculicidiumHistory8 showAnimation={show.sicHistory8} />
			<SiculicidiumHistory9 showAnimation={show.sicHistory9} />
			<SiculicidiumHistory10 showAnimation={show.sicHistory10} />
			<SiculicidiumHistory11 showAnimation={show.sicHistory11} />
			<Box className={classes.overflow} />
		</Box>
	);
}

export default withNamespaces('home')(SiculicidiumHistoryAnimated);
