import React from 'react';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Call(props) {
	return (
		<svg width={props.width} height={props.height} viewBox='0 0 24 24'>
			<g>
				<path className='a' d='M0,0H24V24H0Z' fill='none' />
				<path
					className='b'
					fill={props.fill ? props.fill : '#070c35'}
					d='M20.01,15.38a11.443,11.443,0,0,1-3.53-.56.977.977,0,0,0-1.01.24L13.9,17.03A15.183,15.183,0,0,1,7.01,10.2L8.96,8.54A1.021,1.021,0,0,0,9.2,7.52a11.153,11.153,0,0,1-.56-3.53A1,1,0,0,0,7.65,3H4.19C3.65,3,3,3.24,3,3.99A17.152,17.152,0,0,0,20.01,21,1.049,1.049,0,0,0,21,19.82V16.37a1,1,0,0,0-.99-.99Z'
				/>
			</g>
		</svg>
	);
}
