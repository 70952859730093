import React from 'react';
import { useSelector } from 'react-redux';
import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { closeSnackbar } from '../../store/snackbar/actions';

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: 40
		}
	}
}));

export default function Snackbar() {
	const classes = useStyles();

	const {
		message,
		open,
		severity,
		vertical,
		horizontal,
		autoHideDuration
	} = useSelector((state) => state.snackbar);

	const handleClose = () => {
		closeSnackbar();
	};

	return (
		<div className={classes.root}>
			<MuiSnackbar
				anchorOrigin={{ vertical, horizontal }}
				key={vertical + horizontal}
				open={open}
				autoHideDuration={autoHideDuration}
				onClose={handleClose}>
				<Alert onClose={handleClose} severity={severity}>
					{message}
				</Alert>
			</MuiSnackbar>
		</div>
	);
}
