import React from 'react';
import { Box, makeStyles, Hidden } from '@material-ui/core';
import {
	MonumentHistory1,
	MonumentHistory2,
	MonumentHistory4,
	MonumentHistory6,
	MonumentHistory3,
	MonumentHistory5
} from '../History/components/Monument';

const useStyles = makeStyles((theme) => ({
	container: {},
	slideContainer: {
		position: 'relative',
		height: '850px',
	},
	monumentHistory1: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 650
		},
		[theme.breakpoints.only('md')]: {
			height: 750
		},
		[theme.breakpoints.only('sm')]: {
			height: 780
		}
	},
	monumentHistory2: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 950
		},
		[theme.breakpoints.only('md')]: {
			height: 650
		}
	},
	monumentHistory3: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1300
		},
		[theme.breakpoints.only('md')]: {
			height: 700
		},
		[theme.breakpoints.only('sm')]: {
			height: 780
		}
	},
	monumentHistory4: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1100
		},
		[theme.breakpoints.only('md')]: {
			height: 700
		},
		[theme.breakpoints.only('sm')]: {
			height: 750
		}
	},
	monumentHistory5: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 900
		},
		[theme.breakpoints.only('md')]: {
			height: 700
		},
		[theme.breakpoints.only('sm')]: {
			height: 780
		}
	},
	monumentHistory6: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1100
		},
		[theme.breakpoints.only('md')]: {
			height: 900
		}
	}
}));

function MonumentHistoryNotAnimated(props) {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Box className={classes.monumentHistory1}>
				<MonumentHistory1 showAnimation />
			</Box>
			<Box className={classes.monumentHistory2}>
				<MonumentHistory2 showAnimation />
			</Box>
			<Box className={classes.monumentHistory3}>
				<MonumentHistory3 showAnimation />
			</Box>
			<Box className={classes.monumentHistory4}>
				<MonumentHistory4 showAnimation />
			</Box>
			<Box className={classes.monumentHistory5}>
				<MonumentHistory5 showAnimation />
			</Box>
			<Box className={classes.monumentHistory6}>
				<MonumentHistory6 showAnimation />
			</Box>
		</Box>
	);
}

export default MonumentHistoryNotAnimated;
