export default {
	one: [
		{
			name: 'news',
			href: '/news'
		},
		{
			name: 'siculicidium',
			href: '/history/siculicidium',
			goToLivePage: true
		},
		{
			name: 'madefalva',
			href: '/history/madefalva',
			goToLivePage: true
		},
		{
			name: 'monument',
			href: '/history/monument',
			goToLivePage: true
		},
		// {
		// 	name: 'community',
		// 	href: '/community'
		// },
		{
			name: 'touches',
			href: '',
			goToLivePage: true
		}
	],
	two: [
		{
			name: 'unit_status',
			href: '/gazette/unit_status'
		},
		{
			name: 'admi_procs',
			href: '/gazette/admi_procs'
		},
		{
			name: 'auth_dec',
			href: '/gazette/auth_dec'
		},
		{
			name: 'ex_disp',
			href: '/gazette/ex_disp'
		},
		{
			name: 'fin_docs',
			href: '/gazette/fin_docs'
		},
		{
			name: 'other',
			href: '/gazette/other'
		}
	],
	three: [
		{
			name: 'localcouncil',
			href: '/localcouncil'
		},
		{
			name: 'organigram',
			href: '/orgngrm'
		},
		{
			name: 'socialoffice',
			href: '/soc_off'
		},
		{
			name: 'registryoffice',
			href: '/registryoffice'
		}
	]
};
