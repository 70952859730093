import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Button } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoginShema } from './validation';
import { TextField } from '../../../components/form';
import { logIn, getMe } from '../../../store/me/actions';
import { openSnackbar } from '../../../store/snackbar/actions';

const Login = (props) => {
	const { t } = props;

	const history = useHistory();
	const me = useSelector((state) => state.me);

	const onSubmit = async (values, { setSubmitting }) => {
		try {
			setSubmitting(true);
			const result = await logIn(values);
			if (result) {
				await getMe();
				history.push('/home');
				window.scrollTo(0, 0);
			}
		} catch (e) {
			openSnackbar({ message: t('error:general'), severity: 'error' });
		}
		setSubmitting(false);
	};

	if (me.role) {
		history.push('/home');
		window.scrollTo(0, 0);

		return <div />;
	}
	return (
		<Formik
			initialValues={{
				email: '',
				password: ''
			}}
			onSubmit={onSubmit}
			validationSchema={LoginShema(t)}>
			{(props) => (
				<Form>
					<Grid container justify='center' alignItems='center' spacing={0}>
						<Grid item xs={12}>
							<Grid item xs={6}>
								<Field
									name='email'
									label={t('email')}
									component={TextField}
									size='small'
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid item xs={6}>
								<Field
									name='password'
									type='password'
									label={t('password')}
									component={TextField}
									size='small'
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Button color='default' type='submit' variant='contained'>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};
export default withNamespaces('auth')(Login);
