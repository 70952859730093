import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Nature4(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '104.4'}
			height={props.height ? props.height : '73.6'}
			viewBox='0 0 104.4 73.6'>
			<g transform='translate(-605.3 -428.7)'>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M622.5,500.1a5.606,5.606,0,0,1-4-1.7,3.085,3.085,0,0,0-4.2,0,5.556,5.556,0,0,1-8,0,.495.495,0,0,1,.7-.7,4.589,4.589,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,.495.495,0,0,1-.7.7,3.085,3.085,0,0,0-4.2,0A5.439,5.439,0,0,1,622.5,500.1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M622.5,496.7a5.606,5.606,0,0,1-4-1.7,3.085,3.085,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,.495.495,0,0,1-.7.7,3.085,3.085,0,0,0-4.2,0A5.605,5.605,0,0,1,622.5,496.7Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M622.5,493.3a5.606,5.606,0,0,1-4-1.7,3.085,3.085,0,0,0-4.2,0,5.556,5.556,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.589,4.589,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,4.047,4.047,0,0,1,5.6,0,.495.495,0,0,1-.7.7,3.085,3.085,0,0,0-4.2,0A5.439,5.439,0,0,1,622.5,493.3Z'
				/>
				<path
					className='b'
					fill={props.dotfill ? props.dotfill : '#ee2c48'}
					d='M697.1,453.9a12.6,12.6,0,1,0-12.6-12.6A12.676,12.676,0,0,0,697.1,453.9Z'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M627.9,501.7a25.2,25.2,0,0,1,50.4,0H694a40.9,40.9,0,0,0-81.8,0Z'
				/>
				<path
					className='c'
					fill='none'
					stroke='#160e44'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M664.1,463.9'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M700.5,502.3H605.8a.5.5,0,0,1,0-1h94.7a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M671.5,500.1a5.606,5.606,0,0,1-4-1.7,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,0,1,.7-.7,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,.495.495,0,0,1,.7.7,5.673,5.673,0,0,1-8,0,3.085,3.085,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0A5.438,5.438,0,0,1,671.5,500.1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M671.5,496.7a5.606,5.606,0,0,1-4-1.7,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,.495.495,0,1,1,.7.7,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0A5.438,5.438,0,0,1,671.5,496.7Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M671.5,493.3a5.606,5.606,0,0,1-4-1.7,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,.495.495,0,1,1,.7-.7,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.589,4.589,0,0,0,6.6,0,3.866,3.866,0,0,1,5.6,0,4.708,4.708,0,0,0,6.6,0,.495.495,0,1,1,.7.7,5.673,5.673,0,0,1-8,0,3.085,3.085,0,0,0-4.2,0,5.673,5.673,0,0,1-8,0,2.9,2.9,0,0,0-4.2,0A5.438,5.438,0,0,1,671.5,493.3Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M697.1,437.1l-5.9-3.7a.515.515,0,1,1,.5-.9l5.3,3.4,5.3-3.4a.515.515,0,0,1,.5.9Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M697.1,444.2l-5.9-3.7a.515.515,0,0,1,.5-.9L697,443l5.3-3.4a.515.515,0,0,1,.5.9Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M697.1,451.3l-5.9-3.7a.515.515,0,0,1,.5-.9l5.3,3.4,5.3-3.4a.515.515,0,0,1,.5.9Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M697.1,488a.472.472,0,0,1-.5-.5V429.8a.5.5,0,1,1,1,0v57.7A.536.536,0,0,1,697.1,488Z'
				/>
			</g>
		</svg>
	);
}
