import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';

import { useSelector } from 'react-redux';
import QueryString from 'qs';
import { useHistory } from 'react-router-dom';
import { Village, PublicInterest } from '../../../../../components/icons';
import { getByCategory } from '../../../../../store/office/actions';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import Downloadables from '../SocialOffice/components/Downloadables/Downloadables';
import { transformQuery } from '../../../../../lib/helpers/queryTransformer';
import Pagination from '../../../../../components/Pagination/Pagination';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	contentContainer: {
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30
	},
	titleContainer: {
		width: '100%',
		height: 25,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 30,
		borderBottom: '1px solid #D5D4C9'
	},
	categoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px'
	},
	churchCategoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		paddingTop: 89,
		paddingLeft: 10,
		paddingBottom: 50
	},
	imageContainer: {
		height: 264,
		width: 264,
		background: `transparent url(${Image}) 0% 0% no-repeat padding-box`,
		marginRight: 24
	},
	desciptionContainer: {
		width: 'calc(100% - 264px)'
	},
	content: {
		width: '100%',
		display: 'inline-flex'
	},
	descriptionTitle: {
		fontFamily: 'Ubuntu',
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '34px'
	},
	descriptionTitleContainer: {
		paddingBottom: 39
	},
	descriptionText: {
		fontFamily: 'Ubuntu',
		lineHeight: '24px',
		fontSize: '14px',
		flexWrap: 'wrap',
		fontWeight: 'bold'
	},
	cardsContainer: {
		display: 'inline-flex',
		paddingTop: 40
	},
	village2Container: {
		paddingTop: '40px'
	},
	title2Container: {
		width: '100%',
		height: 73,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 70,
		paddingBottom: 10,
		borderBottom: '1px solid #D5D4C9',
		alignItems: 'flex-end'
	},
	pageTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '28px',
		letterSpacing: '0px',
		marginLeft: 20,
		marginBottom: theme.spacing(1)
	}
}));

export default withNamespaces('council')((props) => {
	const { t } = props;
	const classes = useStyles();

	const data = useSelector((state) => state.office.list);
	const history = useHistory();

	const queries = QueryString.parse(history.location.search, {
		ignoreQueryPrefix: true
	});

	const [page, setPage] = useState(1);

	const pushPage = (up) => {
		const nPage = page ? Number(page) : 1;
		const pageTmp = up ? nPage + 1 : nPage - 1;
		history.push({
			pathname: '/isaticf',
			search: transformQuery({
				category: 'isaticf',
				limit: 10,
				page: pageTmp
			})
		});
		updatePage(pageTmp);
		window.scrollTo(0, 0);
	};

	const handlePageChange = (e) => {
		if (e.keyCode === 13) {
			if (e.target.value > data?.meta?.totalPages || e.target.value < 1) {
				return;
			}
			history.push({
				pathname: '/isaticf',
				search: transformQuery({
					category: 'isaticf',
					limit: 10,
					page: e.target.value
				})
			});
			window.scrollTo(0, 0);
		}
	};

	useEffect(() => {
		if (
			data?.meta?.totalPages &&
			queries.page &&
			queries.page <= data.meta.totalPages
		) {
			updatePage(queries.page);
		}
	}, [data, queries.page]);

	const updatePage = (newPage) => {
		if (page !== newPage) {
			setPage(newPage);
		}
	};

	useEffect(() => {
		getByCategory(transformQuery(queries));
	}, [page]);

	return (
		<Box className={classes.pageContainer}>
			<Box className={classes.contentContainer}>
				<Box className={classes.titleContainer}>
					<Breadcrumbs
						breadcrumbs={[
							{
								href: 'council',
								name: t('category')
							},
							{
								href: 'isaticf',
								name: t('isaticf'),
								final: true
							}
						]}
					/>
				</Box>
				<Box className={classes.title2Container}>
					<PublicInterest width='75px' height='55px' />
					<Typography className={classes.pageTitle}>{t('isaticf')}</Typography>
				</Box>
				<Box className={classes.content}>
					<Downloadables data={data} />
				</Box>
				<Pagination
					page={page}
					setPage={setPage}
					handlePageChange={handlePageChange}
					currentPage={data?.meta?.currentPage}
					totalPages={data?.meta?.totalPages ? data.meta.totalPages : 1}
					t={t}
					pushPage={pushPage}
				/>
			</Box>
			<Box className={classes.village2Container}>
				<Village />
			</Box>
		</Box>
	);
});
