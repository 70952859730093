import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	cls1: {
		fill: 'none',
		stroke: '#160e44',
		strokeLinecap: 'round',
		strokeMiterlimit: 10
	}
}));

const Council = (props) => {
	const classes = useStyles();
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 89.29 80.05'>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<g id='Layer_2-2' data-name='Layer 2'>
						<g id='Layer_1-2-2' data-name='Layer 1-2'>
							<circle className={classes.cls1} cx='44.65' cy='7.47' r='6.97' />
							<path
								className={classes.cls1}
								d='M44.65,10.19a2.72,2.72,0,1,1,2.71-2.72h0A2.71,2.71,0,0,1,44.65,10.19Z'
							/>
							<circle className={classes.cls1} cx='44.65' cy='72.58' r='6.97' />
							<circle className={classes.cls1} cx='7.47' cy='72.58' r='6.97' />
							<circle className={classes.cls1} cx='81.82' cy='72.58' r='6.97' />
							<circle className={classes.cls1} cx='23.63' cy='40.54' r='6.97' />
							<circle className={classes.cls1} cx='65.67' cy='40.54' r='6.97' />
							<line
								className={classes.cls1}
								x1='14.44'
								y1='72.58'
								x2='37.68'
								y2='72.58'
							/>
							<line
								className={classes.cls1}
								x1='51.62'
								y1='72.58'
								x2='74.85'
								y2='72.58'
							/>
							<line
								className={classes.cls1}
								x1='9.02'
								y1='65.61'
								x2='19.62'
								y2='47.01'
							/>
							<line
								className={classes.cls1}
								x1='79.27'
								y1='65.61'
								x2='68.67'
								y2='47.01'
							/>
							<line
								className={classes.cls1}
								x1='27.29'
								y1='33.83'
								x2='40.95'
								y2='13.37'
							/>
							<line
								className={classes.cls1}
								x1='62.9'
								y1='34.14'
								x2='48.99'
								y2='13.37'
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Council;
