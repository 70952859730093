import React from 'react';
import { MenuItem, makeStyles, Box } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { livePage, officePage } from '../../../../../../config/locations.js';


import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		borderBottomWidth: 1,
		borderBottomColor: theme.palette.primary.dark
	},
	rootLives: {
		borderBottomWidth: 1,
		borderBottomColor: 'white'
	},
	button: {
		color: 'white',
		fontSize: 16,
		padding: theme.spacing(1, 3),
		textTransform: 'uppercase',
		letterSpacing: '0.8px',
		fontFamily: 'Bebas Neue'
	},
	subButton: {
		fontSize: 16,
		padding: theme.spacing(1, 5),
		textTransform: 'uppercase',
		letterSpacing: '0.8px',
		fontFamily: 'Bebas Neue'
	},
	colorOffice: {
		color: theme.palette.primary.dark
	},
	colorLives: {
		color: theme.palette.primary.contrastText
	},
	colorActive: {
		color: '#E0B080'
	},
	link: {
		textDecoration: 'none'
	}
}));

export default withNamespaces('navigation')((props) => {
	const { page, route, t, goToLivePage, onClick } = props;

	const classes = useStyles();

	const isMatch = useRouteMatch(route.href);

	const match = route.href === '/' ? isMatch.isExact : isMatch;

	const history = useHistory();

	const navToPage = (href) => {
		if (onClick) {
			onClick()
		}
		history.push(href);
		window.scrollTo(0, 0);
	};

	return (
		<Box
			borderBottom={1}
			className={page === 'lives' ? classes.rootLives : classes.root}
			divider>
			{route.goToLivePage != true ?
			<MenuItem
				onClick={() => navToPage(route.href)}
				className={clsx(
					classes.button,
					match
						? classes.colorActive
						: page === 'lives'
						? classes.colorLives
						: classes.colorOffice
				)}>
				{t(route.name)}
			</MenuItem>
			:
			<MenuItem
				onClick={() => navToPage(route.href)}
				className={clsx(
					classes.button,
					match
						? classes.colorActive
						: page === 'lives'
						? classes.colorLives
						: classes.colorOffice
				)}>
				<a
				href={page == "lives" ? officePage + route.href : livePage + route.href}
				className={clsx(
					classes.link,
					page === 'lives'
						? classes.colorLives
						: classes.colorOffice
				)}>
				{t(route.name)}
			</a>
			</MenuItem>

			}
			{route.subMenu?.map((subRoute) => (
				<MenuItem
					onClick={() => navToPage(subRoute.href)}
					className={clsx(
						classes.subButton,
						page === 'lives' ? classes.colorLives : classes.colorOffice
					)}>
					{t(subRoute.name)}
				</MenuItem>
			))}
		</Box>
	);
});
