import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function HalfCircleStripes(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '298.834'}
			height={props.height ? props.height : '142.752'}
			viewBox='0 0 298.834 142.752'>
			<g transform='matrix(-0.259, -0.966, 0.966, -0.259, -1501.405, -140.268)'>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-607.584,1648.5a82.239,82.239,0,0,1-5.425-11.729'
					transform='translate(-24.944 -60.332)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-583.08,1509.3a82.943,82.943,0,0,1,16.578-11.768'
					transform='translate(-33.053 -22.602)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-594.82,1683.8a93.242,93.242,0,0,1-31.578-42.358'
					transform='translate(-21.316 -61.597)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-550.757,1475.61a92.6,92.6,0,0,1,27.525-5.6'
					transform='translate(-41.812 -15.147)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-634.053,1590.726q-.145-2.6-.145-5.239a92.8,92.8,0,0,1,5.686-32.091'
					transform='translate(-19.202 -37.741)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-613.927,1689.211A103.772,103.772,0,0,1-626.4,1673.25'
					transform='translate(-21.316 -70.217)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-579.007,1470.149a102.845,102.845,0,0,1,52.759-14.466,103.946,103.946,0,0,1,18.045,1.57'
					transform='translate(-34.157 -11.264)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-647.953,1580.312a103.08,103.08,0,0,1,2.616-16.5'
					transform='translate(-15.475 -40.564)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-604.616,1696.5a113.626,113.626,0,0,1-57.929-99.083'
					transform='translate(-11.521 -49.668)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-648.38,1509.051a113.917,113.917,0,0,1,75.4-62.8'
					transform='translate(-15.36 -8.709)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-672.249,1634.579a123.463,123.463,0,0,1-3.453-18.465'
					transform='translate(-7.956 -54.735)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-592.849,1739.462a124.419,124.419,0,0,1-47.26-36.619'
					transform='translate(-17.601 -78.236)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-627.436,1472.637a124.738,124.738,0,0,1,15.942-13.585'
					transform='translate(-21.035 -12.177)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-487.821,1414.112a133.84,133.84,0,0,1,21.254,3.906'
					transform='translate(-58.865)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-655.868,1475.039a135,135,0,0,1,43.16-38.422'
					transform='translate(-13.331 -6.098)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-593.339,1715.511a134.385,134.385,0,0,1-97.552-129.231,134.749,134.749,0,0,1,3.353-29.958'
					transform='translate(-3.841 -38.534)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-653.392,1730.368a145.512,145.512,0,0,1-13.484-15.9'
					transform='translate(-10.348 -81.386)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-640.109,1445.592a145.311,145.311,0,0,1,17.207-13.436'
					transform='translate(-17.601 -4.889)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#051028'
					strokeMiterlimit='10'
					d='M-704.431,1608.067q-.629-6.732-.634-13.633,0-4.037.219-8.021'
					transform='translate(0 -46.687)'
				/>
			</g>
		</svg>
	);
}
