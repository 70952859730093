import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function TouchesLogo(props) {
	const { page } = props
	return (
		page == "office" ? 
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 121.654 141.771'>
			<g transform='translate(-124.708 -110.174)'>
				<g transform='translate(177.689 220.148)'>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M207.514,271.953v9.474h-1.489V276.7l-1.2,3.235h-.866l-1.2-3.235v4.723h-1.489v-9.474h.852l2.275,5.251,2.273-5.251Z'
						transform='translate(-201.261 -269.963)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M218.735,279.924h-1.705l-.271,1.5h-1.516l1.895-9.474h1.489l1.895,9.474h-1.516Zm-.257-1.489-.6-3.4-.6,3.4Z'
						transform='translate(-205.566 -269.963)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M230.083,281.427h-2.246v-9.474h2.246a2.208,2.208,0,0,1,1.57.568,2.272,2.272,0,0,1,.676,1.759v4.873a2.813,2.813,0,0,1-.162.988,2.007,2.007,0,0,1-.528.757A2.226,2.226,0,0,1,230.083,281.427Zm-.757-1.489h.757a.845.845,0,0,0,.569-.149.824.824,0,0,0,.189-.636V274.28a.937.937,0,0,0-.2-.677.8.8,0,0,0-.555-.162h-.757Z'
						transform='translate(-209.444 -269.963)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M241.119,272.556v2.381H242.8v1.489h-1.678v2.626h2.436v1.489H239.63v-9.474h3.925v1.489Zm2.328-3.478h-1.489l-.744,1.489h.744Z'
						transform='translate(-213.076 -269.078)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M252.031,273.442v2.381h1.691v1.489h-1.691v4.114h-1.489v-9.474h3.925v1.489Z'
						transform='translate(-216.436 -269.963)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M263.362,279.924h-1.705l-.271,1.5H259.87l1.895-9.474h1.489l1.895,9.474h-1.516Zm-.257-1.489-.6-3.4-.6,3.4Z'
						transform='translate(-219.308 -269.963)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M276.389,281.427h-3.925v-9.474h1.489v7.985h2.436Z'
						transform='translate(-223.186 -269.963)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M285.5,271.953h1.516l-1.9,9.474h-1.488l-1.895-9.474h1.516l1.123,6.4Z'
						transform='translate(-226.04 -269.963)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M296.353,279.924h-1.7l-.271,1.5h-1.515l1.894-9.474h1.489l1.9,9.474h-1.516Zm-.257-1.489-.6-3.4-.6,3.4Z'
						transform='translate(-229.467 -269.963)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M207.514,292.291v9.475h-1.489v-4.723l-1.2,3.235h-.866l-1.2-3.235v4.723h-1.489v-9.475h.852l2.275,5.251,2.273-5.251Z'
						transform='translate(-201.261 -276.226)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M217.671,293.78v2.381h1.678v1.489h-1.678v2.626h2.436v1.489h-3.925v-9.475h3.925v1.489Z'
						transform='translate(-205.855 -276.226)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M229.38,301.826a2.265,2.265,0,0,1-1.57-.555,2.392,2.392,0,0,1-.677-1.772v-5.036a2.394,2.394,0,0,1,.677-1.772,2.271,2.271,0,0,1,1.57-.555,2.214,2.214,0,0,1,1.57.555,2.343,2.343,0,0,1,.676,1.772v.84h-1.489v-.84a.918.918,0,0,0-.19-.676.824.824,0,0,0-.568-.162.8.8,0,0,0-.555.162.9.9,0,0,0-.2.676V299.5a.9.9,0,0,0,.2.676.8.8,0,0,0,.555.163.821.821,0,0,0,.568-.163.916.916,0,0,0,.19-.676v-1.774h-.744v-1.488h2.233V299.5a2.343,2.343,0,0,1-.676,1.772A2.208,2.208,0,0,1,229.38,301.826Z'
						transform='translate(-209.228 -276.178)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M240.845,292.9v2.381h1.678v1.489h-1.678v2.626h2.437v1.489h-3.925v-9.475h3.925V292.9Zm2.327-3.479h-1.488l-.745,1.489h.745Z'
						transform='translate(-212.991 -275.34)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M255.132,301.766h-1.516l-.771-3.8h-.677v3.8h-1.489v-9.475h2.247a2.209,2.209,0,0,1,1.57.569,2.272,2.272,0,0,1,.676,1.759v1.015a2.291,2.291,0,0,1-.676,1.773,1.453,1.453,0,0,1-.217.176Zm-1.449-7.146a.936.936,0,0,0-.2-.677.8.8,0,0,0-.555-.162h-.758v2.693h.758a.8.8,0,0,0,.555-.162.908.908,0,0,0,.2-.677Z'
						transform='translate(-216.478 -276.226)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M264.254,301.766h-1.489v-9.475h1.489Z'
						transform='translate(-220.199 -276.226)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M275.352,301.766h-.866l-2.274-4.8v4.8h-1.488v-9.475h.866l2.274,4.791v-4.791h1.488Z'
						transform='translate(-222.65 -276.226)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M287.154,293.78h-1.516v7.985h-1.489V293.78h-1.516v-1.489h4.521Z'
						transform='translate(-226.317 -276.226)'
					/>
				</g>
				<g transform='translate(124.708 110.174)'>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M213.428,203.1a45.056,45.056,0,0,1,12.227-24.985,46.3,46.3,0,0,0-20.162,8.372A43.493,43.493,0,0,0,213.428,203.1Z'
						transform='translate(-149.583 -131.093)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M207.821,197.9h0a23.316,23.316,0,0,0-14.6-8.471c-.4-.353-.792-.71-1.178-1.077l-.048-.046q-.921-.878-1.791-1.8l-.049-.053q-.871-.93-1.69-1.909l-.035-.041q-.412-.494-.811-1a44.842,44.842,0,0,1-9.684-27.868,45.514,45.514,0,0,1,29.5-42.418,46.384,46.384,0,0,0-8.319-2.313,40.169,40.169,0,0,0-14.873,11.665c-.521-.166-1.046-.327-1.574-.475a41.669,41.669,0,0,1,13.776-11.591q-2.284-.277-4.631-.333h0a43.214,43.214,0,0,0-12.523,11.111c-.559-.112-1.123-.212-1.689-.3a44.706,44.706,0,0,1,11.276-10.78h0a47.152,47.152,0,0,0-6.832.773,43.43,43.43,0,0,0-9.5,62.14q-.513.61-1,1.24a44.992,44.992,0,0,1,6.87-62.543,45.217,45.217,0,0,0-15.338,80.214c-.809.042-1.622.064-2.442.064a46.342,46.342,0,0,1-35.915-16.9,45.835,45.835,0,0,0,32.7,34.383,45.911,45.911,0,0,0-5.915,6.98,17.89,17.89,0,0,1,3.863,1.583,45.827,45.827,0,0,1,6.18-7.623q.954.171,1.923.3a44.456,44.456,0,0,0-6.779,8.128,18.106,18.106,0,0,1,3.325,2.882,45.823,45.823,0,0,1,8.856-10.713A46.286,46.286,0,0,1,187.536,201c1.065.072,2.14.111,3.224.111a46.786,46.786,0,0,0,13.176-1.885h0a3.888,3.888,0,0,1,1.035.028,3.833,3.833,0,0,1,2.956,2.372,3.692,3.692,0,0,0-.109-3.726Zm-6.694-1.6a2.363,2.363,0,0,1-2.394-2.671q1.843,1.2,3.8,2.219A2.423,2.423,0,0,1,201.126,196.3Z'
						transform='translate(-124.708 -110.174)'
					/>
					<path
						className='a'
						fill={props.fill ? props.fill : '#001b1d'}
						d='M157.74,265.541a18.032,18.032,0,1,0,6.019,24.781A18.031,18.031,0,0,0,157.74,265.541Zm-1.6,2.622a14.979,14.979,0,0,1,4.994,20.562l-25.556-15.568A14.979,14.979,0,0,1,156.143,268.162Z'
						transform='translate(-126.437 -157.203)'
					/>
				</g>
			</g>
		</svg>
		:
		<svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 112 40">
			<rect clasName="a" fill="none" width={props.width} height={props.height} />
			<g transform="translate(-192.919 -224.677)">
				<g transform="translate(192.919 248.278)">
					<path clasName="b" fill={props.fill ? props.fill: "#fff"} d="M208.348,336.712h-3.527v-8.971l-3.989,6.546h-.4l-3.989-6.546v8.971h-3.527V321.283h3.527l4.188,6.855,4.188-6.855h3.527Z" transform="translate(-192.919 -320.622)" />
					<path clasName="b" fill={props.fill ? props.fill: "#fff"} d="M275.859,337.916v11.021h-3.306V347.9a4.14,4.14,0,0,1-3.284,1.345,5.848,5.848,0,0,1,0-11.638,4.14,4.14,0,0,1,3.284,1.345v-1.036Zm-3.306,5.51a2.624,2.624,0,1,0-2.623,2.689A2.517,2.517,0,0,0,272.552,343.426Z" transform="translate(-246.148 -332.846)" />
					<path clasName="b" fill={props.fill ? props.fill: "#fff"} d="M332.285,321.283v15.429h-3.306v-1.036a4.14,4.14,0,0,1-3.284,1.345,5.848,5.848,0,0,1,0-11.638,4.14,4.14,0,0,1,3.284,1.345v-5.444Zm-3.306,9.919a2.624,2.624,0,1,0-2.623,2.689A2.517,2.517,0,0,0,328.978,331.2Z" transform="translate(-288.403 -320.622)" />
					<path clasName="b" fill={props.fill ? props.fill: "#fff"} d="M383,333.739a2.878,2.878,0,0,0,2.116-.838l2.645,1.521a5.592,5.592,0,0,1-4.8,2.27c-3.769,0-6.106-2.535-6.106-5.819a5.651,5.651,0,0,1,5.863-5.819,5.565,5.565,0,0,1,5.6,5.819,6.411,6.411,0,0,1-.132,1.323h-7.825A2.509,2.509,0,0,0,383,333.739Zm2.028-4.012a2.483,2.483,0,0,0-4.739,0Zm-1.256-5.8H380.6l1.763-3.086h3.967Z" transform="translate(-330.658 -320.294)" />
					<path clasName="b" fill={props.fill ? props.fill: "#fff"} d="M433.093,324.718h2.05v3.174h-2.05v7.847h-3.306v-7.847H428.31v-3.174h1.477c0-3.086,1.7-4.937,5.356-4.717v3.174C433.909,323.065,433.093,323.44,433.093,324.718Z" transform="translate(-369.193 -319.649)" />
					<path clasName="b" fill={props.fill ? props.fill: "#fff"} d="M472.725,337.916v11.021h-3.306V347.9a4.14,4.14,0,0,1-3.284,1.345,5.848,5.848,0,0,1,0-11.638,4.14,4.14,0,0,1,3.284,1.345v-1.036Zm-3.306,5.51a2.624,2.624,0,1,0-2.623,2.689A2.517,2.517,0,0,0,469.418,343.426Z" transform="translate(-393.572 -332.846)" />
					<path clasName="b" fill={props.fill ? props.fill: "#fff"} d="M519.488,318.65h3.306v16.09h-3.306Z" transform="translate(-437.472 -318.65)" />
					<path clasName="b" fill={props.fill ? props.fill: "#fff"} d="M551.135,338.836l-4.078,11.021H543.31l-4.078-11.021h3.659l2.292,6.987,2.292-6.987Z" transform="translate(-452.257 -333.766)" />
					<path clasName="b" fill={props.fill ? props.fill: "#fff"} d="M601.111,337.916v11.021h-3.306V347.9a4.14,4.14,0,0,1-3.285,1.345,5.848,5.848,0,0,1,0-11.638,4.14,4.14,0,0,1,3.285,1.345v-1.036Zm-3.306,5.51a2.624,2.624,0,1,0-2.623,2.689A2.517,2.517,0,0,0,597.805,343.426Z" transform="translate(-489.715 -332.846)" />
				</g>
				<g transform="translate(192.919 228.214)">
					<path clasName="c" fill={props.fill ? props.fill: "#7dcac7"} d="M204.821,254.57l-4.078,11.021H197l-4.078-11.021h3.659l2.292,6.987,2.292-6.987Z" transform="translate(-192.919 -250.6)" />
					<path clasName="c" fill={props.fill ? props.fill: "#7dcac7"} d="M251.521,254.57h3.306v11.021h-3.306Z" transform="translate(-236.803 -250.6)" />
					<path clasName="c" fill={props.fill ? props.fill: "#7dcac7"} d="M324.64,257.968l3.306-1.866v10.636H324.64Z" transform="translate(-291.559 -251.747)" />
					<path clasName="c" fill={props.fill ? props.fill: "#7dcac7"} d="M353.656,245.906v3.9c0,.948.815,1.036,2.27.948v3c-4.32.441-5.576-.859-5.576-3.946v-3.9h-1.763v-3.174h1.763v-2.094l3.306-1.876v3.97h2.27v3.174Z" transform="translate(-309.491 -238.762)" />
					<path clasName="c" fill={props.fill ? props.fill: "#7dcac7"} d="M280.891,256.868c0-.4.353-.617.926-.617a1.539,1.539,0,0,1,1.374.8l2.782-1.57a4.834,4.834,0,0,0-4.156-2.136c-2.292,0-4.32,1.213-4.32,3.615,0,3.813,5.422,3.174,5.422,4.386,0,.441-.4.683-1.19.683a1.8,1.8,0,0,1-1.746-1l-2.846,1.606a4.88,4.88,0,0,0,4.591,2.343c2.38,0,4.585-1.08,4.585-3.615C286.313,257.286,280.891,258.124,280.891,256.868Z" transform="translate(-255.986 -249.68)" />
				</g>
				<g transform="translate(222.827 224.677)">
					<path clasName="d" fill="#d00044" d="M325.576,241.892a2.673,2.673,0,1,0-4.656,2.628Z" transform="translate(-318.422 -236.55)" />
					<path clasName="d" fill="#d00044" d="M334.16,226.037a2.673,2.673,0,1,0-4.656,2.628Z" transform="translate(-324.851 -224.677)" />
					<path clasName="d" fill="#d00044" d="M312.007,226.037a2.673,2.673,0,1,1,4.656,2.628Z" transform="translate(-312.007 -224.677)" />
				</g>
			</g>
		</svg>

	);
}
