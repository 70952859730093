import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Button } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import {
	ChevronRight,
	HomeScreenHalfCircle,
	RotatedHalfCircleStripes,
	Nature4,
} from '../../../../../components/icons';
import Ozike from '../../../../../components/images/Ozike.png';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Ozike}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover',
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		left: 290,
		width: 838,
		height: 631,
		top: 66,
		[theme.breakpoints.only('md')]: {
			right: 0,
			top: 0,
			left: 'auto',
		},
		[theme.breakpoints.only('xs')]: {
			width: 300,
			height: 300,
			left: 'auto',
			right: 0,
			top: 70,
			zIndex: 1,
		},
		[theme.breakpoints.only('sm')]: {
			right: 0,
			top: 0,
			left: 'auto',
		},
	},
	pageContainer: {
		position: 'fixed',
		width: 1128,
		height: '100vh',
		marginLeft: 'auto',
		marginRight: 'auto',
		boxSizing: 'border-box',
		right: 0,
		left: 0,
		top: 0,
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
		},
		[theme.breakpoints.only('xs')]: {
			maxWidth: '100%',
		},
		[theme.breakpoints.only('md')]: {
			width: '100%',
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
		},
	},
	titleContainer: {
		zIndex: 101,
		background: '#FFFFFF',
		position: 'absolute',
		top: '388px',
	},
	titleText: {
		paddingLeft: '20px',
		paddingBottom: '6px',
		paddingTop: '8px',
		paddingRight: '46px',
		fontFamily: 'Gilroy',
		fontWeight: 'bold',
	},
	mainTitleBox: {
		top: '254px',
		position: 'absolute',
		width: '580px',
		height: '70px',
		background: '#070C35 0% 0% no-repeat padding-box',
		opacity: 1,
		textAlign: 'right',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			top: 310,
			maxWidth: 300,
			height: '50px',
		},
		[theme.breakpoints.only('md')]: {
			left: 30,
			width: 500,
		},
		[theme.breakpoints.only('sm')]: {
			left: 40,
			top: 400,
		},
	},
	mainTitleText: {
		fontFamily: 'Gilroy',
		color: '#FAFAF5',
		fontSize: '50px',
		lineHeight: '56px',
		letterSpacing: '1px',
		marginTop: '6px',
		marginRight: '37px',
		marginBottom: '8px',
		[theme.breakpoints.only('xs')]: {
			fontSize: 30,
			lineHeight: '35px',
			marginTop: '10px',
		},
	},
	animatedCircles: {
		position: 'absolute',
		top: '136px',
		height: '361px',
		width: '246px',
		left: '187px',
		zIndex: 100,
		[theme.breakpoints.only('xs')]: {
			left: 0,
			top: 250,
		},
		[theme.breakpoints.only('sm')]: {
			top: 270,
			left: 50,
		},
	},
	halfCircle: {
		position: 'absolute',
		top: '187px',
		left: '95px',

		width: '192px',
		height: '96px',
		[theme.breakpoints.only('xs')]: {
			height: 50,
			width: 100,
			left: 60,
			right: 'auto',
			top: 100,
		},
	},
	descriptionContainer: {
		position: 'absolute',
		top: 552,
		width: 610,
		flexWrap: 'wrap',
		[theme.breakpoints.only('xs')]: {
			top: 450,
			width: '100%',
			boxSizing: 'border-box',
			paddingLeft: '20px',
			paddingRight: '20px',
		},
		[theme.breakpoints.only('md')]: {
			left: 30,
		},
		[theme.breakpoints.only('sm')]: {
			left: 40,
			top: 600,
			height: 'fit-content',
			backgroundColor: '#DDE9E6',
			zIndex: 200,
		},
	},
	descriptionText: {
		// fontWeight: 'bold',
		// fontSize: '14px',
		// lineHeight: '24px',

		// color: 'blue',
		fontSize: '18px',
		lineHeight: '24px',
		fontFamily: 'Roboto',
		fontWeight: 500,
		letterSpacing: '0.18px',
		[theme.breakpoints.only('xs')]: {
			letterSpacing: '0.1px',
			fontSize: '16px',
		},
	},
	nextButton: {
		paddingLeft: '60px',
		paddingRight: '60px',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 40,
			paddingRight: 40,
			height: 50,
		},
	},
	buttonContainer: {
		position: 'absolute',
		top: '685px',
		[theme.breakpoints.only('md')]: {
			left: 30,
		},
		[theme.breakpoints.only('sm')]: {
			left: 40,
			top: 750,
		},
		[theme.breakpoints.only('xs')]: {
			marginLeft: '20px',
			top: 720,
			// backgroundColor: 'red',
		},
	},
	natureBox: {
		position: 'absolute',
		top: 'auto',
		bottom: '0px',
		right: '0',
		width: '248px',
		height: '175px',
		[theme.breakpoints.only('xs')]: {
			left: 'auto',
			right: 0,
			width: 130,
			height: 130,
			top: 660,
		},
		[theme.breakpoints.only('sm')]: {
			right: 50,
			top: 650,
			left: 'auto',
		},

		'@media screen and (max-height: 750px) and (min-width: 1280px)': {
			bottom: '-33vh',
		},
	},
	halfStripes: {
		width: 247,
		height: 361,
		[theme.breakpoints.only('xs')]: {
			width: 150,
			height: 200,
		},
	},
}));

function MadefalvaMegerint1(props) {
	const classes = useStyles();
	const { t, history } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);

	useEffect(() => {
		// window.scrollTo(0, 1000);
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);

	return (
		<Box
			className={classes.pageContainer}
			style={{ zIndex: showAnimation ? 200 : 150 }}>
			<CSSTransition
				in={showAnimation}
				timeout={600}
				classNames='ozike'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='content'
				unmountOnExit>
				<Box>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							{t('megerint1.title')}
						</Typography>
					</Box>

					<Box className={classes.animatedCircles}>
						<Box className={classes.halfStripes}>
							<RotatedHalfCircleStripes width='100%' height='100%' />
						</Box>
						<Box className={classes.halfCircle}>
							<HomeScreenHalfCircle width='100%' height='100%' fill='#E60F37' />
						</Box>
					</Box>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='livedescription'
				unmountOnExit
				onEnter={() => {}}>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText} varinat='paragraph'>
						<Trans i18nKey='megerint1.description' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='livefootercontent'
				unmountOnExit
				onEnter={() => {}}>
				<Box className={classes.buttonContainer}>
					<Button
						type='button'
						className={classes.nextButton}
						onClick={() => {
							setShowAnimation(false);
							window.scrollTo(0, 0);
							history.push('/lives');
						}}>
						<Typography variant='button'>{t('megerint1.button')}</Typography>
						<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
					</Button>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='livefootercontent'
				unmountOnExit
				onEnter={() => {}}>
				<Box className={classes.natureBox}>
					<Nature4 width='100%' height='100%' />
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withRouter(withNamespaces('homeMadefalva')(MadefalvaMegerint1));
