import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Button } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import Vintage from '../../../../../components/images/vintage05.png';

import {
	ChevronRight,
	StraightStripes,
	HomeScreenHalfCircle,
	Monument
} from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) `,
		mixBlendMode: 'multiply',
		/* left: "368px",
        width: "850px",
        height: "640px",
		top: "45px", */
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		left: 368,
		width: 850,
		height: 640,
		top: 45,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 270,
			left: 0,
			top: 0
		},
		[theme.breakpoints.only('md')]: {
			left: 'auto',
			right: 0,
			width: 700,
			height: 550,
			top: 200
		},
		[theme.breakpoints.only('lg')]: {
			top: -50
		},
		[theme.breakpoints.only('sm')]: {
			left: 'auto',
			right: 0,
			width: 550,
			height: 450,
			top: 0
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0
	},
	contentContainer: {
		top: '0px',
		width: '100%',
		height: 'calc(100vh-100px)',
		position: 'absolute',
		[theme.breakpoints.only('xs')]: {
			height: 500
		},
		[theme.breakpoints.only('md')]: {
			top: 150
		}
	},
	mainTitleBox: {
		top: '277px',
		position: 'absolute',
		background: '#FEFEFE 0% 0% no-repeat padding-box',
		textAlign: 'right',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			top: 180
		},
		[theme.breakpoints.only('md')]: {
			left: 30
		},
		[theme.breakpoints.only('lg')]: {
			top: 177
		},
		[theme.breakpoints.only('sm')]: {
			left: 40
		}
	},
	mainTitleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		color: theme.palette.primary.dark,
		fontSize: '50px',
		lineHeight: '56px',
		letterSpacing: '1px',
		marginTop: '6px',
		marginRight: '37px',
		marginBottom: '8px',
		marginLeft: '20px',
		[theme.breakpoints.only('xs')]: {
			fontSize: '34px',
			lineHeight: 'unset',
			marginRight: 20,
			marginLeft: 20
		}
	},
	animatedCircles: {
		position: 'absolute',
		[theme.breakpoints.only('md')]: {
			left: 30
		}
	},
	halfCircle: {
		position: 'absolute',
		top: '345px',
		left: '95px',
		zIndex: 95,
		width: '192px',
		height: '96px',
		[theme.breakpoints.only('xs')]: {
			top: 200,
			width: 130,
			height: 65,
			left: 90,
			opacity: 0.5
		},
		[theme.breakpoints.only('lg')]: {
			top: 245
		},
		[theme.breakpoints.only('sm')]: {
			left: 134
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '496px',
		width: 386,
		[theme.breakpoints.only('xs')]: {
			top: 300,
			width: '100%',
			left: 0,
			boxSizing: 'border-box',
			paddingRight: '24px',
		},
		[theme.breakpoints.only('md')]: {
			left: 30
		},
		[theme.breakpoints.only('lg')]: {
			top: 396
		},
		[theme.breakpoints.only('sm')]: {
			left: 40
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 20,
			fontSize: '16px',
			lineHeight: '20px'
		}
	},
	nextButton: {
		paddingLeft: '60px',
		paddingRight: '60px',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 40,
			paddingRight: 40,
			height: 50
		},
		[theme.breakpoints.only('sm')]: {
			left: 40
		}
	},
	buttonContainer: {
		position: 'absolute',
		top: '627px',
		[theme.breakpoints.only('xs')]: {
			top: 420,
			left: 20
		},
		[theme.breakpoints.only('md')]: {
			left: 30
		},
		[theme.breakpoints.only('lg')]: {
			top: 527
		}
	},
	footerIconBox: {
		position: 'absolute',
		bottom: 0,
		right: 0,
		height: '191px',
		[theme.breakpoints.only('xs')]: {
			bottom: 0,
			height: 100,
			width: 200
		},
		[theme.breakpoints.up('lg')]: {
			top: 'calc(100vh - 100px - 191px)'
		},
		[theme.breakpoints.only('sm')]: {
			top: 500
		},
		['@media screen and (max-height: 750px) and (min-width: 1280px)' ]:{
			top: 'calc(133vh - 100px - 191px)',
			bottom: '-33vh'
		}
	},
	descriptionTitle: {
		color: '#E0B080',
		fontSize: '30px',
		letterSpacing: '2px',
		lineHeight: '30px',
		textTransform: 'uppercase',
		font: 'Bold 30px/30px Bebas Neue',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 30
		}
	},
	halfStripes: {
		top: '206px',
		position: 'absolute',
		zIndex: 100,
		width: '400px',
		height: '252px',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 300,
			zIndex: 0,
			opacity: 0.7,
			left: 30,
			top: 0
		},
		[theme.breakpoints.only('lg')]: {
			top: 106
		},
		[theme.breakpoints.only('sm')]: {
			left: 40
		}
	}
}));

function SiculicidiumHistory(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);

	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);

	return (
		<Box
			className={classes.pageContainer}
			style={{ zIndex: showAnimation ? 200 : 150 }}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='vintageimage'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<Box className={classes.contentContainer}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrmiddle'
					unmountOnExit>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							{t('megerint5.title')}
						</Typography>
					</Box>
				</CSSTransition>
				<Box className={classes.animatedCircles}>
					<CSSTransition
						in={showAnimation}
						timeout={300}
						classNames='diagonallyrtl'
						unmountOnExit>
						<Box className={classes.halfStripes}>
							<StraightStripes width='100%' height='100%' />
						</Box>
					</CSSTransition>
					<CSSTransition
						in={showAnimation}
						timeout={300}
						classNames='ttbshort'
						unmountOnExit>
						<Box className={classes.halfCircle}>
							<HomeScreenHalfCircle width='100%' height='100%' fill='#E0B080' />
						</Box>
					</CSSTransition>
				</Box>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='history1description'
					unmountOnExit>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionTitle}>
							Siculicidium
						</Typography>
						<Typography className={classes.descriptionText} varinat='paragraph'>
							<Trans i18nKey='megerint5.description'>
								<br />
								<br />
							</Trans>
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.buttonContainer}>
						<Button
							type='button'
							className={classes.nextButton}
							onClick={() => {
								window.scrollTo(0,0)
								props.history.push('/history/siculicidium');
							}}>
							<Typography variant='button'>{t('megerint5.button')}</Typography>
							<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
						</Button>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='bttmiddle'
					unmountOnExit>
					<Box className={classes.footerIconBox}>
						<Monument
							height='100%'
							width='100%'
							fill='#FFFFFF'
							dotfill='#FFFFFF'
						/>
					</Box>
				</CSSTransition>
			</Box>
		</Box>
	);
}

export default withNamespaces('homeMadefalva')(withRouter(SiculicidiumHistory));
