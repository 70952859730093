import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Church2(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '102.4'}
			height={props.height ? props.height : '73.6'}
			viewBox='0 0 102.4 73.6'>
			<g transform='translate(-202.2 -571.4)'>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M227.6,639.6a.472.472,0,0,1-.5-.5V635a.5.5,0,0,1,1,0v4.1A.642.642,0,0,1,227.6,639.6Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M235.8,639.6a.472.472,0,0,1-.5-.5V635a.5.5,0,0,1,1,0v4.1A.536.536,0,0,1,235.8,639.6Z'
				/>
				<path
					className='b'
					fill={props.dotfill ? props.dotfill : '#ee2c48'}
					d='M292,596.6A12.6,12.6,0,1,0,279.4,584,12.676,12.676,0,0,0,292,596.6Z'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M222.8,644.5a25.2,25.2,0,1,1,50.4,0h15.7a40.9,40.9,0,1,0-81.8,0Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M272.4,626.8H239.3l17.4-21.4L273,625.5a.94.94,0,0,1,.1.9A.669.669,0,0,1,272.4,626.8Zm-30.9-1H272L256.7,607Z'
				/>
				<line
					className='c'
					fill='#fff'
					y2='6.2'
					transform='translate(256.7 600)'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M256.7,606.7a.472.472,0,0,1-.5-.5V600a.5.5,0,0,1,1,0v6.2A.472.472,0,0,1,256.7,606.7Z'
				/>
				<line
					className='c'
					fill='#fff'
					x1='3.9'
					transform='translate(254.8 602.1)'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M258.7,602.6h-3.9a.5.5,0,1,1,0-1h3.9a.5.5,0,0,1,0,1Z'
				/>
				<line
					className='a'
					fill={props.base ? props.base : '#160e44'}
					x2='4.5'
					transform='translate(254.5 617.4)'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M254.5,617.9a.5.5,0,0,1,0-1h0a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M256.8,617.9h-.1a.5.5,0,0,1,0-1h.1a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M259,617.9a.5.5,0,0,1,0-1h.1a.472.472,0,0,1,.5.5A.56.56,0,0,1,259,617.9Z'
				/>
				<line
					className='a'
					fill={props.base ? props.base : '#160e44'}
					x2='4.5'
					transform='translate(254.5 619.7)'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M254.5,620.2a.5.5,0,0,1,0-1h0a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M256.8,620.2h-.1a.5.5,0,0,1,0-1h.1a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M259,620.2a.5.5,0,0,1,0-1h.1a.472.472,0,0,1,.5.5A.56.56,0,0,1,259,620.2Z'
				/>
				<line
					className='a'
					fill={props.base ? props.base : '#160e44'}
					x2='4.5'
					transform='translate(254.5 621.9)'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M254.5,622.4a.5.5,0,0,1,0-1h0a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M256.8,622.4h-.1a.5.5,0,0,1,0-1h.1a.5.5,0,0,1,0,1Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M259,622.4a.5.5,0,0,1,0-1h.1a.472.472,0,0,1,.5.5A.56.56,0,0,1,259,622.4Z'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M270.3,645a.472.472,0,0,1-.5-.5v-14l1-3.6H231.3a.5.5,0,1,1,0-1h40.8l-1.3,4.8v13.8A.536.536,0,0,1,270.3,645Z'
				/>
				<line
					className='c'
					fill='#fff'
					x1='42.7'
					transform='translate(227.6 630.6)'
				/>
				<path
					className='a'
					fill={props.base ? props.base : '#160e44'}
					d='M270.3,631.1H227.6a.5.5,0,1,1,0-1h42.7a.472.472,0,0,1,.5.5A.536.536,0,0,1,270.3,631.1Z'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#ee2c48'}
					d='M293.4,645H202.7a.5.5,0,0,1,0-1h90.7a.5.5,0,0,1,0,1Z'
				/>
			</g>
		</svg>
	);
}
