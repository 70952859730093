import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Statutum(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 86.04 86.04'>
			<title>Asset 1</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className='cls-1'
						fill='#201943'
						d='M80.5,86H0V0H86V79.88ZM1,85H80.06l5-5.55V1H1Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M25.6,9.21H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H25.6a.5.5,0,0,1,.5.5A.5.5,0,0,1,25.6,9.21Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,77.83H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,77.83Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,17.79H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,17.79Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,26.37H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,26.37Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,34.94H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.51.51,0,0,1,.5.5A.5.5,0,0,1,36.17,34.94Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,43.52H8.46a.5.5,0,0,1,0-1H36.17a.5.5,0,0,1,0,1Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,52.1H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.5.5,0,0,1,.5.5A.51.51,0,0,1,36.17,52.1Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M36.17,60.67H8.46a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H36.17a.51.51,0,0,1,.5.5A.5.5,0,0,1,36.17,60.67Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M77.13,9.21H50.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h26.7a.5.5,0,0,1,.5.5A.5.5,0,0,1,77.13,9.21Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M74.28,77.83H50.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H74.28a.5.5,0,0,1,.5.5A.5.5,0,0,1,74.28,77.83Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M77.13,17.79H50.43a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h26.7a.5.5,0,0,1,.5.5A.5.5,0,0,1,77.13,17.79Z'
					/>
					<path
						className='cls-1'
						fill='#201943'
						d='M64.28,64.58A8.79,8.79,0,0,1,58,62.36a9.32,9.32,0,0,1-2.7-7.07V50.38h5.94v4.91a3.6,3.6,0,0,0,.81,2.7,3.17,3.17,0,0,0,2.21.65A3.07,3.07,0,0,0,66.49,58a3.51,3.51,0,0,0,.81-2.7V52.91a5.67,5.67,0,0,0-2-4.31A17.46,17.46,0,0,0,63,47.19a20.85,20.85,0,0,1-3.73-2.38,12.1,12.1,0,0,1-3-3.94,11.2,11.2,0,0,1-1-4.75V35.2A9.35,9.35,0,0,1,58,28.13a8.79,8.79,0,0,1,6.26-2.22,8.79,8.79,0,0,1,6.26,2.22,9.35,9.35,0,0,1,2.7,7.07v3.3H67.3V35.2a3.51,3.51,0,0,0-.81-2.7,3.07,3.07,0,0,0-2.21-.65,3.17,3.17,0,0,0-2.21.65,3.63,3.63,0,0,0-.81,2.7v.92a5.53,5.53,0,0,0,2,4.27,16.77,16.77,0,0,0,2.11,1.29,21.71,21.71,0,0,1,3.89,2.49,11.26,11.26,0,0,1,4,8.74v2.38a9.32,9.32,0,0,1-2.7,7.07A8.79,8.79,0,0,1,64.28,64.58Z'
					/>
				</g>
			</g>
		</svg>
	);
}
