import React, { useEffect } from 'react';
import {
	Container,
	makeStyles,
	Box,
	Typography,
	Button
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { withNamespaces, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ChevronRight, Village } from '../../../components/icons';
import Emblema from '../../../components/icons/Emblema';
import { showFooter, showMenu } from '../../../store/layout/actions';
import {
	changeLanguage,
	checkLanguage
} from '../../../store/page/language/actions';
import { acceptCookies } from '../../../store/page/cookies/actions';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		justifyContent: 'center',
		alignContent: 'center',
		textAlign: 'center'
	},
	footer: {
		marginTop: '100vw'
	},
	title: {
		marginTop: '55px',
		color: '#070C35',
		fontSize: '32px',
		fontWeight: 'bold'
	},
	subTitle: {
		marginTop: '25px',
		color: '#070C35',
		fontSize: '14px',
		fontWeight: 'bold',
		marginBottom: '40px',
		width: 520,
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	footelVillage: {},
	emblema: {
		marginTop: 148,
		marginBottom: 112,
		[theme.breakpoints.down('sm')]: {
			marginTop: 80,
			marginBottom: 60
		}
	},
	buttonContainer: {
		flexDirection: 'row',
		width: '100%',
		textAlign: 'center'
	},
	nextButton: {
		paddingLeft: '60px',
		paddingRight: '60px',
		marginLeft: 25,
		marginRight: 25,
		[theme.breakpoints.only('xs')]: {
			marginBottom: 30
		}
	}
}));

const Language = (props) => {
	const classes = useStyles();
	const { t } = props;

	const lang = useSelector((state) => state.language.language);

	useEffect(() => {
		if (checkLanguage()) {
			props.history.push('/');
		} else {
			showMenu(false);
			showFooter(false);
		}
	}, []);

	const selectLanguage = async (value) => {
		await changeLanguage(value);
		showMenu(true);
		showFooter(true);
		if (props.history.length > 1) {
			props.history.go(0);
		} else {
			props.history.push('/');
		}
	};

	return (
		<>
			<Container className={classes.root}>
				<Box className={classes.emblema}>
					<Emblema width={115} height={195} />
				</Box>
			</Container>
			<Box className={classes.buttonContainer}>
				<Button
					type='button'
					className={classes.nextButton}
					onClick={() => {
						selectLanguage('hu');
					}}>
					<Typography variant='button'>MAGYAR</Typography>
					<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
				</Button>
				<Button
					type='button'
					className={classes.nextButton}
					onClick={() => {
						selectLanguage('ro');
					}}>
					<Typography variant='button'>ROMÂNĂ</Typography>
					<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
				</Button>
			</Box>
			<Box className={classes.footelVillage}>
				<Village />
			</Box>
		</>
	);
};

export default withNamespaces('common')(withRouter(Language));
