import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';
import store from '../store';
import { GET_COUNCIL } from '../types';

export const getMembers = async (queries) =>
	api
		.get(`council${queries}`)
		.then((res) => store.dispatch({ type: GET_COUNCIL, payload: res.data }));

export const getElements = (other) => {
	api
		.get(other ? `/council${other}` : '/council?active=true')
		.then((response) => {
			store.dispatch({ type: GET_COUNCIL, payload: response.data });
		});
};

export const saveElement = async (data) =>
	api
		.post('/council', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
			getElements('?active=false');
			return true;
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
			return false;
		});

export const updateElement = (id, data) => {
	api.put(`/council/${id}`, data).then(() => getElements('?active=false'));
};

export const deleteElement = (id) => {
	api.delete(`/council/${id}`).then(() => getElements('?active=false'));
};

export const activateElement = (id, status) => {
	api
		.patch(`/council/${id}?active=${!!status}`)
		.then(() => getElements('?active=false'));
};
