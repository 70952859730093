import React from 'react';
import {Toolbar, Hidden, Drawer, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { menuItems, MenuItem } from './menuItem';

const useStyles = makeStyles((theme) => ({
	drawerPaper: {
		width: 'inherit',
		position: 'relative'
	},
	drawer: {
		width: theme.layout.menu.width,
		position: 'fixed',
		height: '100%'
	}
}));

function Menu(props) {
	const {menuOpen, toggleMenuOpen} = props;
	const classes = useStyles();
	const me = useSelector((props) => props.me);

	return (
		<>
			{me && me.role && (
				<nav
				className={classes.drawer}
				aria-label="mailbox folders"
			  	> 
					<Hidden smUp implementation="css">
						<Drawer
							variant="temporary"
							open={menuOpen}
							onClose={() => toggleMenuOpen(false)}
							ModalProps={{
							keepMounted: true, // Better open performance on mobile.
							}}
							anchor='left'
							className={classes.drawer}
							classes={{ paper: classes.drawerPaper }}>
							{menuItems.map((item, index) => (
								<MenuItem menuDetails={item} key={index} />
							))}
						</Drawer>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Drawer
							variant="permanent"
							open
							anchor='left'
							className={classes.drawer}
							classes={{ paper: classes.drawerPaper }}>
							{menuItems.map((item, index) => (
								<MenuItem menuDetails={item} key={index} />
							))}	
						</Drawer>
					</Hidden>
				</nav>
			)}
		</>
		
	);
}

export default Menu;

/** */
