import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
	clsFill: {
		fill: 'none',
		stroke: '#160e44',
		strokeLinecap: 'round',
		strokeMiterlimit: 10
	},
	clsStroke: {
		fill: '#160e44'
	}
}));

const Report = (props) => {
	const classes = useStyle();
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 86.03937 86.03937'>
			<g xmlns='http://www.w3.org/2000/svg' id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<polygon
						className={classes.clsFill}
						points='80.279 85.539 85.539 79.685 85.539 0.5 0.5 0.5 0.5 85.539 80.279 85.539'
					/>
					<line
						className={classes.clsFill}
						x1='8.45942'
						y1='8.71175'
						x2='25.6039'
						y2='8.71175'
					/>
					<line
						className={classes.clsFill}
						x1='8.45942'
						y1='77.32762'
						x2='36.1677'
						y2='77.32762'
					/>
					<line
						className={classes.clsFill}
						x1='8.45942'
						y1='17.28874'
						x2='36.1677'
						y2='17.28874'
					/>
					<line
						className={classes.clsFill}
						x1='50.42539'
						y1='8.71175'
						x2='77.13367'
						y2='8.71175'
					/>
					<line
						className={classes.clsFill}
						x1='50.42539'
						y1='77.32762'
						x2='74.27928'
						y2='77.32762'
					/>
					<line
						className={classes.clsFill}
						x1='50.42539'
						y1='17.28874'
						x2='77.13367'
						y2='17.28874'
					/>
					<path
						className={classes.clsStroke}
						d='M19.27617,64.1466H13.33672V26.3468h8.96387a8.81447,8.81447,0,0,1,6.26367,2.26757,9.06322,9.06322,0,0,1,2.7002,7.02v4.104a9.13053,9.13053,0,0,1-2.7002,7.07422,8.81651,8.81651,0,0,1-6.26367,2.21386H19.27617Zm0-21.06006h3.02442a3.18891,3.18891,0,0,0,2.21387-.648A3.61088,3.61088,0,0,0,25.325,39.7384v-4.104a3.7253,3.7253,0,0,0-.81054-2.6997,3.18891,3.18891,0,0,0-2.21387-.648H19.27617Z'
					/>
					<path
						className={classes.clsStroke}
						d='M68.7625,26.3468h6.04785L67.24981,64.1466H61.31035L53.74981,26.3468h6.04785l4.48242,25.542Z'
					/>
				</g>
			</g>
		</svg>
	);
};

export default Report;
