import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../components/images/vintage05.png';

import { StraightStripes } from '../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		left: '-120px',
		width: '807px',
		height: '608px',
		top: '62px',
		[theme.breakpoints.only('md')]: {
			width: 700,
			height: 550,
			left: -80
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 300,
			left: 0,
			top: 80
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 500,
			left: 0,
			top: 50
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.only('lg')]: {
			top: 120
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},

	titleContainer: {
		position: 'absolute',
		top: 0,
		width: 500,
		left: 30,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			left: 0
		}
	},
	titleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		color: theme.palette.primary.dark,
		fontSize: '32px',
		lineHeight: '34px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 30
		}
	},
	contentContainer: {
		top: '109px',
		width: '100%',
		height: '869px',
		position: 'absolute'
	},
	mainTitleBox: {
		top: '431px',
		position: 'absolute',
		background: theme.palette.primary.dark,
		right: 0,
		left: 'auto',
		textAlign: 'right',
		zIndex: 101,
		width: 700,
		[theme.breakpoints.only('xs')]: {
			top: 900,
			left: 30,
			width: 'auto'
		},
		[theme.breakpoints.only('sm')]: {
			top: 850,
			left: 30,
			right: 30,
			width: 'auto'
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#EE2C48',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: '18.5px',
		marginRight: '39px',
		marginBottom: '15.5px',
		marginLeft: '39px'
	},
	halfStripes: {
		top: '390px',
		right: '0px',
		left: 'auto',
		position: 'absolute',
		zIndex: 100,
		[theme.breakpoints.only('xs')]: {
			top: 870
		},
		[theme.breakpoints.only('sm')]: {
			top: 780
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '0px',
		width: '447px',
		right: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 370
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			top: 550
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 30,
			marginRight: 20
		}
	}
}));

function SiculicidiumHistory4(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.contentContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.titleContainer}>
					<Typography className={classes.titleText}>
						<Trans i18nKey='megerint11.title' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						<Trans i18nKey='megerint11.maintitle' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						{t('megerint11.description')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.animatedCircles}>
					<Box className={classes.halfStripes}>
						<StraightStripes width='400px' height='252px' />
					</Box>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('siculicidiumHistory')(SiculicidiumHistory4);
