import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { livePage, officePage } from '../../../../../config/locations';

const useStyles = makeStyles((theme) => ({
	root: {
		color: '#F0F0EB',
		fontSize: 14,
		fontFamily: 'Ubuntu',
		marginTop: 18,
		display: 'flex',
		'&:hover': {
			textDecorationLine: 'underline',
			cursor: 'pointer'
		}
	},
	ahref: {
		textDecoration: 'none'
	}
}));

export default (props) => {
	const { t, href, name, goToLivePage, page } = props;

	const classes = useStyles();
	const history = useHistory();

	const navToPage = () => {
		history.push(href);
		window.scrollTo(0, 0);
	};

	if (goToLivePage) {
		return (
			<a href={`${page=="lives" ? officePage : livePage}${href}`} className={classes.ahref}>
				<Typography className={classes.root}>{name}</Typography>
			</a>
		);
	}
	return (
		<Typography className={classes.root} onClick={navToPage}>
			{name}
		</Typography>
	);
};
