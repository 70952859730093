import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Header } from './File/components/Header';
import { File } from './File';
import { Dropzone } from './File/components/Dropzone';

const useStyles = makeStyles((theme) => ({
	root: {
		//width: '1500px',
		maxWidth: 'calc(100vw - 240px)',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100vw'
		},
		margin: "auto"
	}
}));

function Files(props) {
	const { type } = props;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Header type={type} />
			<Dropzone type={type} />
			<File type={type} />
		</Box>
	);
}
export default Files;
