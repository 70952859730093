import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { MadefalvaHistoryAnimated, MadefalvaHistoryNotAnimated } from '.';

function MadefalvaHistory(props) {
	return (
		<Box>
			<Hidden only={['xl', 'lg']}>
				<MadefalvaHistoryNotAnimated />
			</Hidden>
			<Hidden only={['sm', 'xs', 'md']}>
				<MadefalvaHistoryAnimated />
			</Hidden>
		</Box>
	);
}

export default withNamespaces('home')(MadefalvaHistory);
