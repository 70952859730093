import {
	SHOW_MENU,
	SHOW_LARGE_MENU,
	SHOW_FOOTER,
	SET_FOOTER_REQUIRED
} from '../types';

const initialState = {
	showMenu: true,
	showLargeMenu: false,
	showFooter: true,
	footerRequired: true
};

export default function layout(state = { ...initialState }, action) {
	switch (action.type) {
		case SHOW_MENU:
			return { ...state, showMenu: action.payload };
		case SHOW_LARGE_MENU: {
			return { ...state, showLargeMenu: action.payload };
		}
		case SHOW_FOOTER: {
			return { ...state, showFooter: action.payload };
		}
		case SET_FOOTER_REQUIRED: {
			return { ...state, footerRequired: action.payload };
		}
		default:
			return state;
	}
}
