import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typographyMadefalva';
import overrides from './overrides';
import layout from './layout';

const themeMadefalva = createMuiTheme({
	palette,
	typography,
	layout,
	overrides
});

export default themeMadefalva;
