import React, { useState } from 'react';
import { makeStyles, Box, Typography, Divider } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/min/locales';

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#FAFAF5',
		width: '100%',
		padding: theme.spacing(10, 9)
	},
	// content: {
	// 	maxWidth: 1128,
	// 	right: 0,
	// 	left: 0,
	// 	marginLeft: "auto",
	// 	marginRight: "auto",
	// 	marginTop: theme.spacing(4),
	// },
	// title: {
	// 	color: "#E0B080",
	// 	fontFamily: "Ubuntu",
	// 	fontWeight: "medium",
	// 	fontSize: "16px",
	// 	fontHeight: "24px",
	// 	marginBottom: theme.spacing(3),
	// },
	titledate: {
		color: '#E0B080',
		fontFamily: 'Ubuntu',
		fontWeight: 'medium',
		fontSize: '16px',
		fontHeight: '24px',
		marginBottom: theme.spacing(3)
	},
	title: {
		color: '#E0B080',
		fontFamily: 'Bebas Neue',
		fontWeight: 'bold',
		fontSize: '30px',
		fontHeight: '30px',
		letterSpacing: '0.3px',
		marginTop: theme.spacing(4)
	},
	subtitle: {
		color: '#070C35',
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		fontSize: '18px',
		fontHeight: '30px',
		letterSpacing: '0px',
		marginTop: theme.spacing(5)
	},
	description: {
		color: '#070C35',
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		fontSize: '14px',
		fontHeight: '30px',
		letterSpacing: '0px',
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(10)
	}
}));

export default withNamespaces('static')((props) => {
	const { t, selected } = props;
	const classes = useStyles();
	const language = useSelector((state) => state.language.language);

	const lastEdited = moment('2020-08-19T09:03:20.050Z').locale(language);

	return (
		<Box className={classes.root}>
			<Typography className={classes.titledate}>
				{lastEdited.format('MMMM DD, YYYY')}
			</Typography>
			<Divider fullWidth />
			<Typography className={classes.title}>
				{t(selected === 0 ? 'marriage.title' : 'divorce.title')}
			</Typography>
			<Typography className={classes.subtitle}>
				{t(selected === 0 ? 'marriage.subtitle' : 'divorce.subtitle')}
			</Typography>
			<Typography className={classes.description}>
				<Trans
					i18nKey={
						selected === 0 ? 'marriage.description' : 'divorce.description'
					}>
					<br />
					<br />
				</Trans>
			</Typography>
		</Box>
	);
});
