import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import {
	StraightStripes,
	HomeScreenHalfCircle,
	Monument,
	DotIcon
} from '../../../../components/icons';

const BP370 = '@media (max-width: 370px)';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%',
			height: '100vh'
		},
		[theme.breakpoints.up('lg')]: {
			marginTop: '-100px',
			top: 150
		},
		width: '100%'
	},
	contentContainer: {
		right: 0,
		left: 0,
		top: 100,
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		height: '433px',
		position: 'absolute',
		borderBottom: '3px solid #EE2C48',
		marginBottom: '32px',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			border: 'none',
			height: 478
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			border: 'none'
		},
		[theme.breakpoints.only('md')]: {
			width: '100%',
			border: 'none'
		}
	},
	mainTitleBox: {
		position: 'absolute',
		display: 'flex',
		textAlign: 'right',
		zIndex: 101,
		width: 'fit-content',
		left: 0,
		top: 107,
		[theme.breakpoints.only('md')]: {
			left: 30
		},
		[theme.breakpoints.only('sm')]: {
			width: '90%',
			left: 30
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: theme.palette.primary.dark,
		fontSize: '50px',
		lineHeight: '60px',
		letterSpacing: 0,
		textTransform: 'uppercase',
		paddingTop: '7px',
		paddingRight: '45px',
		paddingBottom: '3px',
		paddingLeft: '27px',
		background: '#EE2C48 0% 0% no-repeat padding-box',
		[theme.breakpoints.down('sm')]: {
			fontSize: '35px',
			lineHeight: '40px'
		}
	},
	animatedCircles: {
		position: 'absolute'
	},
	halfCircle: {
		position: 'absolute',
		top: '145px',
		left: 174,
		zIndex: 95,
		width: 192,
		height: 96,
		[theme.breakpoints.only('md')]: {
			left: 125
		},
		[theme.breakpoints.only('sm')]: {
			left: 125
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '287px',
		left: '302px',
		width: '432px',
		[theme.breakpoints.down('sm')]: {
			left: 0,
			width: '100%'
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		color: '#070C35',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 30,
			marginRight: 20
		}
	},
	footerIconBox: {
		position: 'absolute',
		// top: 323px
		top: '208px',
		right: 0,
		height: '228px',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		},
		bottom: 0
	},
	halfStripes: {
		top: '35px',
		position: 'absolute',
		zIndex: 100,
		width: 400,
		height: 252,
		left: 62,
		[theme.breakpoints.only('xs')]: {
			width: 350,
			left: 0
		},
		[theme.breakpoints.only('md')]: {
			left: 30
		},
		[theme.breakpoints.only('sm')]: {
			left: 30
		}
	},
	navigateIconContainer: {
		position: 'absolute',
		width: '100px',
		top: '727px'
	},
	navigationIconButton: {
		height: '60px',
		width: '60px',
		'&:hover': {
			background: '#FFFFFF'
		},
		position: 'absolute',
		top: '60px',
		transform: 'rotate(90deg)'
	},
	dotIconContaner: {
		left: '958px',
		top: 0,
		position: 'absolute',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	noteText: {
		fontFamily: 'Ubuntu',
		letterSpacing: '-0.22px',
		fontSize: '11px',
		paddingTop: '4px',
		paddingBottom: '4px',
		paddingRight: '20px',
		paddingLeft: '18px',
		color: '#070C35'
	},
	noteContainer: {
		width: 'fit-content',
		bottom: -60,
		left: '288px',
		background: '#E0B080 0% 0% no-repeat padding-box',
		position: 'absolute',
		[theme.breakpoints.only('xs')]: {
			left: 0,
			top: 480,
			bottom: -30,
			width: '100%',
			height: 'fit-content'
		},
		[theme.breakpoints.only('sm')]: {
			right: 0,
			top: 433,
			left: 'auto',
			height: 'fit-content'
		},
		[BP370]: {
			left: 0,
			top: 510,
			bottom: -30,
			width: '100%',
			height: 'fit-content'
		}
	}
}));

function SiculicidiumHistory2(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	const isSmall = useMediaQuery('(max-width: 370px)');
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer} height={showAnimation && '100vh'}>
			<Box
				className={classes.contentContainer}
				style={{
					borderBottom: showAnimation && !isSmall ? '3px solid #EE2C48' : 'none'
				}}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.dotIconContaner}>
						<DotIcon width='50px' height='50px' />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							{t('megerint18.maintitle')}
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.animatedCircles}>
						<Box className={classes.halfStripes}>
							<StraightStripes width='100%' height='100%' />
						</Box>
						<Box className={classes.halfCircle}>
							<HomeScreenHalfCircle width='100%' height='100%' fill='#E0B080' />
						</Box>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionText} varinat='paragraph'>
							{t('megerint18.description')}
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.footerIconBox}>
						<Monument height='100%' fill='#EE2C48' dotfill='none' />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.noteContainer}>
						<Typography className={classes.noteText}>
							{t('megerint18.note')}
						</Typography>
					</Box>
				</CSSTransition>
			</Box>

			{/* <Box className={classes.navigateIconContainer}>
                    <IconButton className={classes.navigationIconButton}>
                        <ChevronRight
                            width={"24px"}
                            height={"24px"}
                            fill={"#070C35"}
                        />
                    </IconButton>
	</Box> */}
		</Box>
	);
}

export default withNamespaces('siculicidiumHistory')(SiculicidiumHistory2);
