import React from 'react';
import { Box, Hidden, withWidth } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';

import HomeContentWithScroll from './components/HomeContentWithScroll';
import HomeContentWithoutScroll from './components/HomeContentWithoutScroll';

function Home() {
	return (
		<Box>
			<Hidden only={['xl', 'lg']}>
				<HomeContentWithoutScroll />
			</Hidden>
			<Hidden only={['sm', 'xs', 'md']}>
				<HomeContentWithScroll />
			</Hidden>
		</Box>
	);
}

export default withWidth()(withNamespaces('home')(Home));
