import { GET_ALL_OFFICIAL_GAZETTE } from '../types';

export default function gazette(state = {}, action) {
	switch (action.type) {
		case GET_ALL_OFFICIAL_GAZETTE:
			return action.payload;
		default:
			return state;
	}
}
