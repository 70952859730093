import { SET_LANGUAGE } from '../../types';

export default function language(state = { language: '' }, action) {
	switch (action.type) {
		case SET_LANGUAGE:
			return { ...state, language: action.payload };
		default:
			return state;
	}
}
