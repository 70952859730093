import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

import { useSelector } from 'react-redux';
import moment from 'moment';
import { api } from '../../../../../lib/axios/api';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: 0
	},
	text: {
		color: '#F0F0EB',
		fontSize: 14,
		fontFamily: 'Ubuntu',
		display: 'flex'
	},
	nameText: {
		color: '#F0F0EB',
		fontSize: 14,
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		marginLeft: 4,
		marginRight: 4
	}
}));

export default (props) => {
	const { t } = props;

	const classes = useStyles();

	const lang = useSelector((state) => state.language.language);

	const [names, setNames] = useState(null);

	useEffect(() => {
		const getNamedays = async () => {
			if (lang === 'hu') {
				await api
					.get('namedays')
					.then((res) => setNames(res.data.names))
					.catch((e) => setNames(null));
			}
		};
		getNamedays();
	}, [lang]);

	const today = moment();
	today.locale(lang);

	return (
		<Box className={classes.root}>
			<Typography className={classes.text}>
				{today.format('MMMM DD, YYYY, dddd')}
			</Typography>
			{lang === 'hu' && names && (
				<Typography className={classes.text}>
					Ma
					<Typography className={classes.nameText}>{names}</Typography>
					napja van
				</Typography>
			)}
		</Box>
	);
};
