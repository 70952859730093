import React, { useState, useEffect, useRef } from 'react';
import { Box, makeStyles, Hidden, withWidth } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import {
	MadefalvaHistory1,
	MadefalvaHistory2,
	MadefalvaHistory3,
	MadefalvaHistory4,
	MadefalvaHistory5,
	MadefalvaHistory6,
	MadefalvaHistory7,
	MadefalvaHistory8,
	Gallery
} from '../History/components/Madefalva';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%'
	},
	slideContainer: {
		position: 'relative',
		height: 900,
		[theme.breakpoints.only('xs')]: {
			height: 700
		}
	},
	madefalvaHistory1: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 780
		},
		[theme.breakpoints.only('md')]: {
			height: 700
		},
		[theme.breakpoints.only('sm')]: {
			height: 630
		}
	},
	madefalvaHistory2: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 700
		},
		[theme.breakpoints.only('md')]: {
			height: 780
		},
		[theme.breakpoints.only('sm')]: {
			height: 730
		}
	},
	madefalvaHistory3: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 950
		},
		[theme.breakpoints.only('md')]: {
			height: 790
		},
		[theme.breakpoints.only('sm')]: {
			height: 610
		}
	},
	madefalvaHistory4: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 660
		},
		[theme.breakpoints.only('md')]: {
			height: 600
		},
		[theme.breakpoints.only('sm')]: {
			height: 700
		}
	},
	madefalvaHistory5: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1300
		},
		[theme.breakpoints.only('md')]: {
			height: 600
		}
	},
	madefalvaHistory6: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1300
		},
		[theme.breakpoints.only('md')]: {
			height: 870
		}
	},
	madefalvaHistory7: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 900
		},
		[theme.breakpoints.only('md')]: {
			height: 870
		},
		[theme.breakpoints.only('sm')]: {
			height: 920
		}
	},
	madefalvaHistory8: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 900
		},
		[theme.breakpoints.only('md')]: {
			height: 870
		}
	}
}));

function MadefalvaHistoryNotAnimated(props) {
	const classes = useStyles();
	return (
		<Box className={classes.container}>
			<Box className={classes.madefalvaHistory1}>
				<MadefalvaHistory1 showAnimation />
			</Box>
			<Box className={classes.madefalvaHistory2}>
				<MadefalvaHistory2 showAnimation />
			</Box>
			<Box className={classes.madefalvaHistory3}>
				<MadefalvaHistory3 showAnimation />
			</Box>
			<Box className={classes.madefalvaHistory4}>
				<MadefalvaHistory4 showAnimation />
			</Box>
			<Box className={classes.madefalvaHistory5}>
				<MadefalvaHistory5 showAnimation />
			</Box>
			<Box className={classes.madefalvaHistory6}>
				<MadefalvaHistory6 showAnimation />
			</Box>
			<Box className={classes.madefalvaHistory7}>
				<MadefalvaHistory7 showAnimation />
			</Box>
			<Box className={classes.madefalvaHistory8}>
				<MadefalvaHistory8 showAnimation />
			</Box>
			<Gallery />
		</Box>
	);
}

export default withWidth()(withNamespaces('home')(MadefalvaHistoryNotAnimated));
