import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import 'moment/min/locales';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Call, Navigation } from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	headerImageBox: {
		width: '100%',
		height: '100%',
		marginTop: '-20px',
		marginLeft: '-20px',
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
			backgroundColor: 'none'
		},
		[theme.breakpoints.only('xs')]: {
			marginTop: 0,
			marginLeft: 0
		}
	},
	mainCard: {
		background: '#FAFAF5 0% 0% no-repeat padding-box;',
		marginTop: 50,
		width: 'calc(100% - (28 + 8))',
		height: 299,
		marginRight: 8,
		[theme.breakpoints.up('sm')]: {
			marginLeft: '28px'
		},
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			paddingBottom: 20
		},
		[theme.breakpoints.only('xs')]: {
			marginLeft: 8
		}
	},
	communityName: {
		color: '#070C35',
		fontSize: '22px',
		fontWeight: 'bold',
		marginLeft: '8px',
		marginBottom: '20px',
		marginTop: '35px',
		[theme.breakpoints.down('sm')]: {
			marginTop: 20
		}
	},
	tellAddress: {
		marginLeft: 10,
		color: '#696B77',
		fontSize: '14px',
		marginTop: '8px',
		fontWeight: 'bold'
	},
	communityDetails: {
		marginLeft: 10,
		color: '#070C35',
		fontSize: '14px',
		marginTop: '8px',
		fontWeight: 'bold'
	},
	communityDescription: {
		marginTop: '30px',
		marginLeft: '24px',
		color: '#070C35',
		fontSize: '14px',
		fontWeight: 'bold'
	},
	callNavigationContainer: {
		marginTop: '32px',
		paddingRight: '32px',
		[theme.breakpoints.down('sm')]: {
			marginTop: 20
		}
	},
	img: {
		width: '100%',
		height: '100%'
	},
	infoRow: {
		flex: 1,
		display: 'inline-flex',
		margin: 'auto'
	}
}));

function CommunityCard(props) {
	const classes = useStyles();
	const { communityItem, category, t } = props;
	const language = useSelector((state) => state.language.language);
	const localizedCommunityItem = communityItem.content.filter(
		(item) => item.lang === language
	)[0];

	return (
		<>
			{localizedCommunityItem && (
				<Box className={classes.mainCard}>
					<Grid
						container
						direction='row'
						justify='flex-start'
						alignItems='flex-start'>
						<Grid item lg={6} xl={6} md={6} xs={12} sm={6}>
							<Box className={classes.headerImageBox}>
								<img
									className={classes.img}
									src={localizedCommunityItem.image.url}
									alt={localizedCommunityItem.name}
								/>
							</Box>
						</Grid>
						<Grid item lg={6} xl={6} md={6} xs={12} sm={6}>
							<Grid
								container
								direction='row'
								justify='flex-start'
								alignItems='flex-start'>
								<Grid item xs={9}>
									<Typography className={classes.communityName}>
										{localizedCommunityItem.name}
									</Typography>
								</Grid>
								<Grid item xs={3} className={classes.callNavigationContainer}>
									<Grid
										container
										direction='row'
										justify='flex-end'
										justifyContent='flex-end'
										alignItems='flex-end'
										spacing={4}>
										{localizedCommunityItem.phone && (
											<Grid item>
												<a href={`tel://+${localizedCommunityItem.phone}`}>
													<Call height={24} width={24} />
												</a>
											</Grid>
										)}
										{localizedCommunityItem.web && (
											<Grid item>
												<a
													href={`http://${localizedCommunityItem.web}`}
													target='_blank'
													rel='noopener noreferrer'>
													<Navigation height={24} width={24} />
												</a>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
							{localizedCommunityItem.intro &&
								localizedCommunityItem.intro.length > 10 && (
								<Grid
									container
									direction='row'
									justify='flex-start'
									alignItems='flex-start'>
									<Box className={classes.infoRow}>
										<Typography className={classes.tellAddress}>
											{localizedCommunityItem.intro}
										</Typography>
									</Box>
								</Grid>
							)}
							{localizedCommunityItem.contactperson1 && (
								<Grid
									container
									direction='row'
									justify='flex-start'
									alignItems='flex-start'>
									<Box className={classes.infoRow}>
										<Typography className={classes.tellAddress}>
											{t(`${category}.contactperson1`)}
										</Typography>
										<Typography className={classes.communityDetails}>
											{localizedCommunityItem.contactperson1}
										</Typography>
									</Box>
								</Grid>
							)}
							{localizedCommunityItem.contactperson2 && (
								<Grid
									container
									direction='row'
									justify='flex-start'
									alignItems='flex-start'>
									<Box className={classes.infoRow}>
										<Typography className={classes.tellAddress}>
											{t(`${category}.contactperson2`)}:
										</Typography>
										<Typography className={classes.communityDetails}>
											{localizedCommunityItem.contactperson2}
										</Typography>
									</Box>
								</Grid>
							)}
							{localizedCommunityItem.address && (
								<Grid
									container
									direction='row'
									justify='flex-start'
									alignItems='flex-start'>
									<Box className={classes.infoRow}>
										<Typography className={classes.tellAddress}>
											{t('address')}:
										</Typography>
										<Typography className={classes.communityDetails}>
											{localizedCommunityItem.address}
										</Typography>
									</Box>
								</Grid>
							)}
							{localizedCommunityItem.phone && (
								<Grid
									container
									direction='row'
									justify='flex-start'
									alignItems='flex-start'>
									<Box className={classes.infoRow}>
										<Typography className={classes.tellAddress}>
											{`${t('phone')}:`}
										</Typography>

										<Typography className={classes.communityDetails}>
											{localizedCommunityItem.phone}
										</Typography>
									</Box>
								</Grid>
							)}
							{localizedCommunityItem.web && (
								<Grid
									container
									direction='row'
									justify='flex-start'
									alignItems='flex-start'>
									<Box className={classes.infoRow}>
										<Typography className={classes.tellAddress}>
											{t('web')}:
										</Typography>
										<a href={`http://${localizedCommunityItem.web}`}>
											<Typography className={classes.communityDetails}>
												{localizedCommunityItem.web}
											</Typography>
										</a>
									</Box>
								</Grid>
							)}
							{localizedCommunityItem.email && (
								<Grid
									container
									direction='row'
									justify='flex-start'
									alignItems='flex-start'>
									<Box className={classes.infoRow}>
										<Typography className={classes.tellAddress}>
											{`${t('email')}:`}
										</Typography>

										<Typography className={classes.communityDetails}>
											{localizedCommunityItem.email}
										</Typography>
									</Box>
								</Grid>
							)}
							{localizedCommunityItem.facebookname && (
								<Grid
									container
									direction='row'
									justify='flex-start'
									alignItems='flex-start'>
									<Box className={classes.infoRow}>
										<Typography className={classes.tellAddress}>
											{`${t('facebookname')}:`}
										</Typography>
										<a
											href={localizedCommunityItem.facebooklink}
											target='_blank'>
											<Typography className={classes.communityDetails}>
												{localizedCommunityItem.facebookname}
											</Typography>
										</a>
									</Box>
								</Grid>
							)}
							{localizedCommunityItem.program && (
								<Grid
									container
									direction='row'
									justify='flex-start'
									alignItems='flex-start'>
									<Box className={classes.infoRow}>
										<Typography className={classes.tellAddress}>
											{`${t('program')}:`}
										</Typography>

										<Typography className={classes.communityDetails}>
											{localizedCommunityItem.program}
										</Typography>
									</Box>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Box>
			)}
		</>
	);
}

export default withNamespaces('community')(withRouter(CommunityCard));
