import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';

import { useSelector } from 'react-redux';
import QueryString from 'qs';
import { useHistory } from 'react-router-dom';
import { Village2, RegistryOffice } from '../../../../../components/icons';
import { getByCategory } from '../../../../../store/office/actions';
import Description from './components/Description/Description';
import Downloadables from '../SocialOffice/components/Downloadables/Downloadables';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import { transformQuery } from '../../../../../lib/helpers/queryTransformer';
import Search from '../Search/Search';
import Pagination from '../../../../../components/Pagination/Pagination';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	contentContainer: {
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30
	},
	titleContainer: {
		width: '100%',
		height: 25,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 30,
		borderBottom: '1px solid #D5D4C9'
	},
	categoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px'
	},
	churchCategoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		paddingTop: 89,
		paddingLeft: 10,
		paddingBottom: 50
	},
	imageContainer: {
		height: 264,
		width: 264,
		background: `transparent url(${Image}) 0% 0% no-repeat padding-box`,
		marginRight: 24
	},
	desciptionContainer: {
		width: 'calc(100% - 264px)'
	},
	content: {
		display: 'flex',
		width: '100%'
	},
	content2: {
		width: '100%'
	},
	descriptionTitle: {
		fontFamily: 'Ubuntu',
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '34px'
	},
	descriptionTitleContainer: {
		paddingBottom: 39
	},
	descriptionText: {
		fontFamily: 'Ubuntu',
		lineHeight: '24px',
		fontSize: '14px',
		flexWrap: 'wrap',
		fontWeight: 'bold'
	},
	cardsContainer: {
		display: 'inline-flex',
		paddingTop: 40
	},
	village2Container: {
		paddingTop: '40px'
	},
	title2Container: {
		width: '100%',
		height: 73,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 70,
		paddingBottom: 10,
		borderBottom: '1px solid #D5D4C9',
		alignItems: 'flex-end',
		justifyContent: 'space-between'
	},
	pageTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		marginLeft: 20,
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			fontSize: '25px'
		}
	},
	tabButton: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		cursor: 'pointer',
		width: '50%'
	}
}));

export default withNamespaces(['council'])((props) => {
	const { t } = props;
	const classes = useStyles();

	const [selectedTab, setSelectedTab] = useState(0);

	const data = useSelector((state) => state.office.list);
	const history = useHistory();

	const queries = QueryString.parse(history.location.search, {
		ignoreQueryPrefix: true
	});

	const [page, setPage] = useState(1);

	const pushPage = (up) => {
		const nPage = page ? Number(page) : 1;
		const pageTmp = up ? nPage + 1 : nPage - 1;
		history.push({
			pathname: '/registryoffice',
			search: transformQuery({
				category: selectedTab === 0 ? 'marry' : 'divorce',
				limit: 10,
				page: pageTmp
			})
		});
		updatePage(pageTmp);
		window.scrollTo(0, 0);
	};

	const handlePageChange = (e) => {
		if (e.keyCode === 13) {
			if (e.target.value > data?.meta?.totalPages || e.target.value < 1) {
				return;
			}
			history.push({
				pathname: '/registryoffice',
				search: transformQuery({
					category: selectedTab === 0 ? 'marry' : 'divorce',
					limit: 10,
					page: e.target.value
				})
			});
			window.scrollTo(0, 0);
		}
	};

	useEffect(() => {
		if (
			data?.meta?.totalPages &&
			queries.page &&
			queries.page <= data.meta.totalPages
		) {
			updatePage(queries.page);
		}
	}, [data, queries.page]);

	const updatePage = (newPage) => {
		if (page !== newPage) {
			setPage(newPage);
		}
	};

	useEffect(() => {
		getByCategory(
			transformQuery({
				...queries,
				category: selectedTab === 0 ? 'marry' : 'divorce'
			})
		);
	}, [page, selectedTab]);

	return (
		<Box className={classes.pageContainer}>
			<Box className={classes.contentContainer}>
				<Box className={classes.titleContainer}>
					<Breadcrumbs
						breadcrumbs={[
							{
								href: 'council',
								name: t('category')
							},
							{
								href: 'registryoffice',
								name: t('registryoffice'),
								final: true
							}
						]}
					/>
				</Box>
				<Box className={classes.title2Container}>
					<Box className={classes.tabButton} onClick={() => setSelectedTab(0)}>
						<RegistryOffice
							width='75px'
							height='55px'
							dotfill={selectedTab === 0 ? false : '#93959F'}
							fill={selectedTab === 0 ? false : '#93959F'}
						/>
						<Typography className={classes.pageTitle}>
							{t('marriage')}
						</Typography>
					</Box>
					<Box className={classes.tabButton} onClick={() => setSelectedTab(1)}>
						<RegistryOffice
							width='75px'
							height='55px'
							dotfill={selectedTab === 1 ? false : '#93959F'}
							fill={selectedTab === 1 ? false : '#93959F'}
						/>
						<Typography className={classes.pageTitle}>
							{t('divorce')}
						</Typography>
					</Box>
				</Box>
				<Box className={classes.content}>
					<Description selected={selectedTab} />
				</Box>
				<Box className={classes.content2}>
					<Search
						page='registryoffice'
						category={selectedTab === 0 ? 'marry' : 'divorce'}
					/>
					<Downloadables data={data} />
				</Box>
				<Pagination
					page={page}
					setPage={setPage}
					handlePageChange={handlePageChange}
					currentPage={data?.meta?.currentPage}
					totalPages={data?.meta?.totalPages ? data.meta.totalPages : 1}
					t={t}
					pushPage={pushPage}
				/>
			</Box>
			<Box className={classes.village2Container}>
				<Village2 />
			</Box>
		</Box>
	);
});
