import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Hidden } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage15.png';
import { showFooter } from '../../../../../store/layout/actions';
import Footer from '../../../../../components/layout/footer/Footer';
import { Nature2 } from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover',
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		width: 783,
		height: 590,
		top: 0,
		right: -80,
		transform: 'rotate(12deg)',
		[theme.breakpoints.only('md')]: {
			width: 600,
			height: 550,
			right: 0,
			top: 30,
		},
		[theme.breakpoints.only('xs')]: {
			right: 0,
			width: '100%',
			top: 60,
			height: 300,
		},
		[theme.breakpoints.only('sm')]: {
			top: 50,
			right: 0,
			width: '80%',
			left: 50,
		},
	},
	pageContainer: {
		position: 'relative',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%',
			height: '100vh',
		},
		width: 1189,
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.up('lg')]: {
			marginTop: '-100px',
		},
		right: 0,
		left: 0,
		top: 92,
		[theme.breakpoints.only('md')]: {
			top: 0,
			width: '100%',
		},

		[theme.breakpoints.only('xl')]: {
			top: 200,
		},
		'@media screen and (max-height: 750px) and (min-width: 1280px)': {
			height: '125vh',
		},
	},
	mainTitleBox: {
		top: 348,
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: 231,
		textAlign: 'left',
		zIndex: 101,
		[theme.breakpoints.only('md')]: {
			left: 80,
			top: 300,
		},
		[theme.breakpoints.only('xs')]: {
			top: 430,
			left: 10,
		},
		[theme.breakpoints.only('sm')]: {
			top: 398,
		},
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#E0B080',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: 19,
		marginRight: 129,
		marginBottom: 11,
		marginLeft: 129,
		[theme.breakpoints.only('xs')]: {
			marginRight: 100,
			marginLeft: 100,
		},
	},
	descriptionContainer: {
		position: 'absolute',
		top: 150,
		width: 408,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			top: 0,
			width: '100%',
		},
		[theme.breakpoints.only('sm')]: {
			top: 50,
			width: '100%',
		},
	},
	descriptionContainer2: {
		position: 'absolute',
		top: 476,
		width: 624,
		left: 0,
		[theme.breakpoints.only('md')]: {
			top: 430,
			width: 550,
		},
		[theme.breakpoints.only('xs')]: {
			top: 500,
			width: '100%',
		},
		[theme.breakpoints.only('sm')]: {
			top: 650,
			width: '100%',
		},
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.down('md')]: {
			marginLeft: 30,
			marginRight: 20,
		},
		[theme.breakpoints.only('xs')]: {
			fontSize: '16px',
		},
	},
	iconContainer: {
		position: 'absolute',
		top: 209,
		left: 410,
		[theme.breakpoints.only('md')]: {
			top: 170,
			left: 300,
		},
		[theme.breakpoints.only('xs')]: {
			left: 100,
			top: 300,
		},
		[theme.breakpoints.only('sm')]: {
			top: 259,
		},
	},
}));

function MadefalvaHistory8(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);

	return (
		<>
			<Box className={classes.pageContainer} height={showAnimation && '100vh'}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='vintageimage4'
					unmountOnExit>
					<Box className={classes.imageContainerFixed}>
						<Box className={classes.imageContainer} />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='rtlmiddle'
					unmountOnExit>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							{t('madehistory8.maintitle')}
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrmiddle'
					unmountOnExit>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionText}>
							<Trans i18nKey='madehistory8.description1' />
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.descriptionContainer2}>
						<Typography className={classes.descriptionText}>
							<Trans i18nKey='madehistory8.description2' />
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='rtlshort'
					unmountOnExit>
					<Box className={classes.iconContainer}>
						<Nature2 width={200} height={147} />
					</Box>
				</CSSTransition>
			</Box>
			{/*	<Hidden only={['sm', 'xs', 'md']}>
				<Footer />
			</Hidden> */}
		</>
	);
}

export default withNamespaces('madefalvaHistory')(MadefalvaHistory8);
