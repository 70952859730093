import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getAllActiveCommunity } from '../../../../../store/community/actions';

function Header(props) {
	useEffect(() => {
		getAllActiveCommunity();
	}, []);

	return <></>;
}
export default withNamespaces('community')(withRouter(Header));
