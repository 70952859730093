import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Pic from '../../../../components/images/h_10.png';
import BackgroundImage from '../../../../components/images/wcolor_bg_03.png';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Pic}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		right: 0,
		width: '567px',
		height: '540px',
		top: '30px',
		backgroundSize: 'cover',
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 700,
			top: 450
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 370,
			top: 750
		}
	},
	imageBackgroundContainer: {
		position: 'absolute',
		background: `transparent url(${BackgroundImage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		backgroundSize: 'cover',
		width: '100%',
		height: '100%'
	},
	imageBackContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		right: '20px',
		width: '600px',
		height: '400px',
		top: '158px',

		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 200,
			top: 750
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 500,
			top: 420
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.only('lg')]: {
			top: 120
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},
	contentContainer: {
		top: '131px',
		width: '100%',
		height: '869px',
		position: 'absolute'
	},
	mainTitleBox: {
		top: '112px',
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: 0,
		textAlign: 'left',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			top: 200
		},
		[theme.breakpoints.only('sm')]: {
			top: 130
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#EE2C48',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: '18.5px',
		marginRight: '39px',
		marginBottom: '15.5px',
		marginLeft: '39px'
	},
	descriptionContainer1: {
		position: 'absolute',
		top: 0,
		width: '100%'
	},
	descriptionContainer2: {
		position: 'absolute',
		top: '208px',
		width: '408px',
		left: '72px',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			left: 0,
			top: 310
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			left: 0,
			top: 240
		}
	},
	descriptionText: {
		color: '#282D38',
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		[theme.breakpoints.down('md')]: {
			marginLeft: 30,
			marginRight: 20
		}
	},
	awesomeContainer: {
		position: 'absolute',
		top: 60,
		left: 506,
		[theme.breakpoints.only('xs')]: {
			display: 'none'
		},
		[theme.breakpoints.only('sm')]: {
			left: 500,
			top: 90
		},
		[theme.breakpoints.only('md')]: {
			top: 95,
			left: 536
		}
	},
	awesomeText: {
		fontFamily: 'CLOAK',
		color: 'red',
		letterSpacing: '0.68px',
		lineHeight: '92px',
		fontSize: '68px',
		[theme.breakpoints.down('md')]: {
			display: 'none'
		}
	}
}));

function SiculicidiumHistory9(props) {
	const classes = useStyles();
	const { t } = props;
	const [showHistory] = useState(true);
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.contentContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageBackContainerFixed}>
					<Box className={classes.imageBackgroundContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						{t('megerint16.maintitle')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.awesomeContainer}>
					<Typography className={classes.awesomeText}>1764</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer1}>
					<Typography className={classes.descriptionText}>
						{t('megerint16.description1')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer2}>
					<Typography className={classes.descriptionText}>
						{t('megerint16.description2')}
					</Typography>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('siculicidiumHistory')(SiculicidiumHistory9);
