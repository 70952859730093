import { GET_SIGHTS, GET_SIGHT } from '../types';

export default function sights(state = {}, action) {
	switch (action.type) {
		case GET_SIGHTS:
			return { ...state, ...action.payload };
		case GET_SIGHT:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
