export default [
	{
		label: 'Afghanistan',
		value: 'AF'
	},
	{
		label: 'Åland Islands',
		value: 'AX'
	},
	{
		label: 'Albania',
		value: 'AL'
	},
	{
		label: 'Algeria',
		value: 'DZ'
	},
	{
		label: 'American Samoa',
		value: 'AS'
	}
];
