import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Organizations(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 102.38 74.6'>
			<title>Civil szervezetek</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M60,61.93a23.65,23.65,0,0,1-6.63,5.46'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M50.34,66.75a22.08,22.08,0,0,0,7.31-5.42'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M55.38,32.61A19.89,19.89,0,0,1,47,65.66'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M57.52,66.9A20.69,20.69,0,0,0,64.1,28'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M31.68,61.93a23.51,23.51,0,0,0,6.63,5.46'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M41.37,66.75a22.08,22.08,0,0,1-7.31-5.42'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M36.33,32.61a19.88,19.88,0,0,0,8.34,33.05'
					/>
					<path
						className='cls-1'
						fill='none'
						stroke='#160e44'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M34.18,66.9A20.7,20.7,0,0,1,27.6,28'
					/>
					<path
						className='cls-2'
						fill={props.dotfill ? props.dotfill : '#ee2c48'}
						d='M89.79,25.19A12.6,12.6,0,1,0,77.19,12.6,12.61,12.61,0,0,0,89.79,25.19Z'
					/>
					<path
						className='cls-2'
						fill={props.fill ? props.fill : '#ee2c48'}
						d='M20.64,74.07a25.21,25.21,0,0,1,50.42,0H86.75a40.9,40.9,0,1,0-81.8,0Z'
					/>
					<line
						className='cls-3'
						fill='none'
						stroke={props.fill ? props.fill : '#ee2c48'}
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='0.5'
						y1='74.1'
						x2='91.21'
						y2='74.1'
					/>
				</g>
			</g>
		</svg>
	);
}
