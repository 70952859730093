import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Pagination from '@material-ui/lab/Pagination';
import { Image } from '.';
import { getImages } from '../../../../../store/file/actions';

const useStyles = makeStyles((theme) => ({
	gridContainer: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 20
	},
	pagination: {
		'& > *': {
			marginTop: theme.spacing(2)
		},
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

const ImageResults = (props) => {
	const { selector, selectItem } = props;
	const images = useSelector((state) => state.file.images);
	const classes = useStyles();

	const onPageChange = (event, value) => {
		getImages({ limit: 18, page: value });
	};

	return (
		<>
			{images && images.meta && (
				<div>
					<Grid container spacing={4} className={classes.gridContainer}>
						{images.items &&
							images.items.map((file) => (
								<Grid item xs={12} sm={6} md={2} key={file.id}>
									<Image
										image={file}
										selector={selector}
										selectItem={selectItem}
									/>
								</Grid>
							))}
					</Grid>
					<div className={classes.pagination}>
						<Pagination
							count={images.meta.totalPages}
							color='primary'
							onChange={onPageChange}
						/>
					</div>
				</div>
			)}
		</>
	);
};
export default withNamespaces()(ImageResults);
