import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import {
	makeStyles,
	Box,
	Grid,
	IconButton,
	InputBase,
	Select,
	MenuItem,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';

import qs from 'qs';

import { getAllNews } from '../../../../../../../store/news/actions';
import { transformQuery } from '../../../../../../../lib/helpers/queryTransformer';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#FAFAF5',
		flexDirection: 'row',
		justifyContent: 'space-around',
		paddingBottom: 20,
		paddingLeft: 25,
		paddingRight: 25,
		// marginLeft: 8,
		// width: '100%',
		// width: 'calc(100% - 50px)',
		marginTop: 16,
		// marginRight: 'auto',
		// marginLeft: 'auto',
		[theme.breakpoints.down('sm')]: {
			height: 150,
		},
	},
	searchBox: {
		// maxWidth: 816,
		width: '100%',
		height: 40,
		backgroundColor: '#F0F0EB',
		marginTop: 20,
		// marginRight: 20,
		//	marginLeft: 20
	},
	filterBox: {
		width: '100%',
		height: 40,

		padding: 1,
	},
	iconButton: {
		borderRadius: 0,
		width: 38,
		height: 38,
		backgroundColor: '#FFFFFF',
		color: theme.palette.primary.dark,
	},
	input: {
		fontFamily: 'Ubuntu',
		marginLeft: 10,
		fontSize: '14px',
		letterSpacing: '0px',
		width: '80%',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		[theme.breakpoints.only('xs')]: {
			fontSize: 0,
		},
	},
	select: {
		// height: 38,
		paddingBottom: 0,
		marginLeft: 10,
		// marginTop: 4,
		width: '100%',
	},
	filterInner: {
		height: 38,
		display: 'flex',
		backgroundColor: '#F0F0EB',

		marginTop: 20,
		marginLeft: 20,
		[theme.breakpoints.down('md')]: {
			marginLeft: 0,
		},
	},
}));

export default withNamespaces('news')((props) => {
	const { t } = props;
	const classes = useStyles();

	const history = useHistory();

	const queries = qs.parse(history.location.search, {
		ignoreQueryPrefix: true,
	});

	const [search, setSearch] = useState(queries.s ? queries.s : null);
	const [cat, setCat] = useState(queries.category ? queries.category : 0);

	const handleCatChange = (event) => {
		event.preventDefault();
		setCat(event.target.value);
	};

	const handleSearch = () => {
		window.scrollTo(0, 0);

		history.push({
			pathname: '/news',
			search: transformQuery({ ...queries, s: search, category: cat }),
		});
	};
	useEffect(() => {
		window.scrollTo(0, 0);

		getAllNews(transformQuery({ ...queries, s: search, category: cat }));
	}, [history.location.search]);

	return (
		<Grid
			container
			xs={12}
			lg={12}
			md={12}
			xl={12}
			sm={12}
			className={classes.root}>
			<Grid
				item
				xs={12}
				lg={9}
				md={9}
				xl={9}
				sm={12}
				className={classes.searchBox}>
				<Box>
					<IconButton className={classes.iconButton} onClick={handleSearch}>
						<SearchIcon />
					</IconButton>
					<InputBase
						onChange={(evt) => setSearch(evt.target.value)}
						value={search}
						className={classes.input}
						placeholder={t('search')}
						inputProps={{ 'aria-label': 'search news' }}
					/>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				lg={3}
				md={3}
				xl={3}
				sm={12}
				className={classes.filterBox}>
				<Box className={classes.filterInner}>
					<IconButton className={classes.iconButton} onClick={handleSearch}>
						<FilterListIcon />
					</IconButton>
					<Select
						labelId='label'
						id='select'
						value={cat}
						disableUnderline
						onChange={handleCatChange}
						className={classes.select}>
						<MenuItem value={0}>{t('all-types')}</MenuItem>
						<MenuItem value='events'>{t('events')}</MenuItem>
						<MenuItem value='announcements'>{t('announcements')}</MenuItem>
					</Select>
				</Box>
			</Grid>
		</Grid>
	);
});
