import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Image1 from '../../../../../components/images/IMG_7586_.png';
import Image2 from '../../../../../components/images/IMG_8034_.png';
import Image3 from '../../../../../components/images/IMG_7429.png';
import Image4 from '../../../../../components/images/IMG_8208.png';
import Image5 from '../../../../../components/images/DSC05238.png';
import Image6 from '../../../../../components/images/IMG_8530.png';
import Image7 from '../../../../../components/images/IMG_8304.png';

const useStyles = makeStyles((theme) => ({
	imageContainer1: {
		position: 'relative',
		background: `transparent url(${Image1}) 0% 0% no-repeat padding-box`,
		width: '100%',
		height: 978,
		top: 0,
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			height: 190
		},
		[theme.breakpoints.only('sm')]: {
			height: 400
		}
	},
	imageContainer2: {
		position: 'relative',
		background: `transparent url(${Image2}) 0% 0% no-repeat padding-box`,
		width: '100%',
		height: 978,
		top: -130,
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			height: 190,
			top: -30
		},
		[theme.breakpoints.only('sm')]: {
			height: 400,
			top: -58
		}
	},
	imageContainer3: {
		position: 'relative',
		background: `transparent url(${Image3}) 0% 0% no-repeat padding-box`,
		width: '100%',
		height: 978,
		top: -330,
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			height: 190,
			top: -60
		},
		[theme.breakpoints.only('sm')]: {
			height: 400,
			top: -115
		}
	},
	imageContainer4: {
		position: 'relative',
		background: `transparent url(${Image4}) 0% 0% no-repeat padding-box`,
		width: '100%',
		height: 978,
		top: -459,
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			height: 190,
			top: -90
		},
		[theme.breakpoints.only('sm')]: {
			height: 400,
			top: -176
		}
	},
	imageContainer5: {
		position: 'relative',
		background: `transparent url(${Image5}) 0% 0% no-repeat padding-box`,
		width: '100%',
		height: 978,
		top: -655,
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			height: 190,
			top: -120
		},
		[theme.breakpoints.only('sm')]: {
			height: 400,
			top: -231
		}
	},
	imageContainer6: {
		position: 'relative',
		background: `transparent url(${Image6}) 0% 0% no-repeat padding-box`,
		width: '100%',
		height: 978,
		top: -800,
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			height: 190,
			top: -150
		},
		[theme.breakpoints.only('sm')]: {
			height: 400,
			top: -290
		}
	},
	imageContainer7: {
		position: 'relative',
		background: `transparent url(${Image7}) 0% 0% no-repeat padding-box`,
		width: '100%',
		height: 978,
		top: -1000,
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			height: 190,
			top: -180
		},
		[theme.breakpoints.only('sm')]: {
			height: 400,
			top: -345
		}
	},
	pageContainer: {
		position: 'relative',
		width: '100%',
		height: 978 * 7 - 1009,
		overflow: 'hidden',
		[theme.breakpoints.only('xs')]: {
			height: 190 *7 - 180,
		},
		[theme.breakpoints.only('sm')]: {
			height: 400 * 7 - 345,
		}
	}

}));

function Gallery(props) {
	const classes = useStyles();
	const { t } = props;
	const [showHistory, setShowSecoundPage] = useState(true);

	return (
		<CSSTransition
			in={showHistory}
			timeout={300}
			classNames='history'
			unmountOnExit>
			<Box className={classes.pageContainer}>
				<Box className={classes.imageContainer1} />
				<Box className={classes.imageContainer2} />
				<Box className={classes.imageContainer3} />
				<Box className={classes.imageContainer4} />
				<Box className={classes.imageContainer5} />
				<Box className={classes.imageContainer6} />
				<Box className={classes.imageContainer7} />
			</Box>
		</CSSTransition>
	);
}

export default withNamespaces('homeMadefalva')(Gallery);
