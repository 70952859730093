import React, { useEffect, useState } from 'react';
import { Button, makeStyles, Typography, Box } from '@material-ui/core';
import { ChevronRight } from '../icons';
import { acceptCookies } from '../../store/page/cookies/actions';

const useStyles = makeStyles((theme) => ({
	modal: {
		width: '100vw',
		height: '100vh',
		['@media screen and (max-height: 750px) and (min-width: 1280px)' ]:{
			height: '135vh',
			width: '135vw'
		},
		background: 'rgba(121, 147, 162, 0.36)',
		position: 'fixed',
		top: 0,
		zIndex: 1330
	},
	text: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px',
		textTransform: 'none',
		color: theme.palette.primary.dark
	},
	clickable: {
		color: theme.palette.primary.dark,
		'&:hover': {
			cursor: 'pointer'
		}
	},
	buttonContainer: {
		flexDirection: 'row',
		width: '100%',
		textAlign: 'center'
	},
	nextButton: {
		paddingLeft: '65px',
		paddingRight: '65px',
		[theme.breakpoints.down('md')]: {
			marginLeft: 40,
			marginRight: 40,
			marginBottom: 40
		}
	},
	container: {
		background: 'rgba(54, 198, 215, 1)',
		height: 274,
		bottom: 0,
		position: 'absolute',
		width: '100vw',
		[theme.breakpoints.down('md')]: {
			height: 'fit-content'
		},
		['@media screen and (max-height: 750px) and (min-width: 1280px)' ]:{
			width: '135vw'
		}
	},
	modalContent: {
		maxWidth: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	modatTitle: {
		color: '#FFFFFF',
		fontSize: '30px',
		letterSpacing: '0.3px',
		lineHeight: '30px',
		textTransform: 'uppercase',
		fontFamily: 'Bebas Neue',
		marginBottom: 18,
		marginTop: 32,
		[theme.breakpoints.down('md')]: {
			marginLeft: 40,
			marginRight: 40
		}
	},
	modalDescription: {
		color: '#FFFFFF',
		fontSize: '14px',
		letterSpacing: '0px',
		lineHeight: '24px',
		fontFamily: 'Ubuntu',
		marginBottom: 32,
		[theme.breakpoints.down('md')]: {
			marginLeft: 40,
			marginRight: 40
		}
	}
}));

const AcceptCookies = () => {
	const classes = useStyles();
	const [display, setDisplay] = useState(true);

	useEffect(() => {
		if (display) {
			document.body.style.overflow = 'hidden';
		}
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [display]);

	const preventScroll = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		document.body.addEventListener('touchmove', preventScroll, {
			passive: false
		});
		return () => {
			document.body.removeEventListener('touchmove', preventScroll);
		};
	}, []);

	return display ? (
		<Box className={classes.modal}>
			<Box className={classes.container}>
				<Box className={classes.modalContent}>
					<Typography className={classes.modatTitle}>
						Acest website folosește cookie-uri
					</Typography>
					<Typography className={classes.modalDescription}>
						Pentru a asigura cea mai bună experiență a utilizatorului, folosim
						cookie-uri, care ne furnizează informații despre obiceiurile dvs. de
						navigare, dar nu stochează informații personale. Prin utilizarea
						acestui site, acceptați utilizarea cookie-urilor.
					</Typography>
					<Button
						type='button'
						className={classes.nextButton}
						onClick={() => {
							acceptCookies(true);
							setDisplay(false);
						}}>
						<Typography variant='button'>ACCEPT</Typography>
						<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
					</Button>
				</Box>
			</Box>
		</Box>
	) : (
		<></>
	);
};

export default AcceptCookies;
