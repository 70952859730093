import React, { useState, useRef } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { makeStyles, Box, Menu, MenuItem } from '@material-ui/core';
import { livePage, officePage } from '../../../../../../config/locations';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: 0,
		cursor: 'pointer'
	},
	rootActive: {
		marginBottom: -15,
		cursor: 'pointer'
	},
	button: {
		fontSize: 16,
		padding: theme.spacing(0, 1),
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		letterSpacing: '0.8px',
		paddingBottom: 11,
		fontFamily: 'Bebas Neue'
	},
	buttonActive: {
		paddingBottom: 11,
		fontSize: 16,
		padding: theme.spacing(0, 1),
		textTransform: 'uppercase',
		letterSpacing: '0.8px',
		fontFamily: 'Bebas Neue'
	},
	activeIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#E0B080',
		width: 30,
		height: 15,
		borderBottomLeftRadius: 15,
		borderBottomRightRadius: 15
	},
	popover: {
		zIndex: 222
	},
	popoverText: {
		color: theme.palette.primary.dark,
		fontSize: 16,
		padding: theme.spacing(1, 3),
		textTransform: 'uppercase',
		letterSpacing: '0.8px',
		fontFamily: 'Bebas Neue',
		backgroundColor: 'white',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	},
	paperCont: {
		['@media screen and (max-height: 750px) and (min-width: 1280px)' ]:{
			marginLeft: '12vw'
		}
	},
	colorLives: {
		color: theme.palette.primary.contrastText
	},
	colorOffice: {
		color: theme.palette.primary.dark
	},
	activeLives: {
		color: '#E0B080',
		zIndex: 20
	},
	activeOffice: {
		color: '#48B3C9',
		zIndex: 20
	},
	bgOffice: {
		backgroundColor: theme.palette.primary.dark
	},
	bgLives: {
		backgroundColor: '#E0B080'
	},
	link: {
		textDecoration: "none"
	}
}));

const NavButton = (props) => {
	const { route, t, page } = props;

	const classes = useStyles();
	const history = useHistory();

	const navToPage = (href) => {
		history.push(href);
		window.scrollTo(0, 0);
		document.body.style.overflow = 'visible';
	};
	const isMatch = useRouteMatch(route.href);

	const location = useLocation();
	const match = route.href === '/' ? isMatch.isExact : isMatch && (isMatch.isExact );
	const [popoverOpen, setPopoverOpen] = useState(false);
	const buttonRef = useRef(null);

	const handleClickListItem = (event) => {
		setPopoverOpen(true);
	};

	const handleMenuItemClick = (event, index) => {
		setPopoverOpen(false);
	};

	const handleClose = () => {
		setPopoverOpen(false);
	};

	const isLives = page === 'lives';
	
	return (
		<Box className={match && !route.goToLivePage ? classes.rootActive : classes.root} ref={buttonRef}>
			{route.subMenu ? (
				<Box
					variant='text'
					className={
						match
							? clsx(
									classes.buttonActive,
									isLives ? classes.activeLives : classes.activeOffice
							  )
							: clsx(
									classes.button,
									isLives ? classes.colorLives : classes.colorOffice
							  )
					}
					onMouseEnter={handleClickListItem}>
					{match ? t(location.pathname.split('/').pop()) : t(route.name)}
				</Box>
			) : (
				!route.goToLivePage ?
				<Box
					variant='text'
					className={
						match && !route.goToLivePage
							? clsx(
									classes.buttonActive,
									isLives ? classes.activeLives : classes.activeOffice
							  )
							: clsx(
									classes.button,
									isLives ? classes.colorLives : classes.colorOffice
							  )
					}
					onClick={() => navToPage(route.href)}>
					{t(route.name)}
				</Box> :
				<Box
				variant='text'
				className={
					match && !route.goToLivePage
						? clsx(
								classes.buttonActive,
								isLives ? classes.activeLives : classes.activeOffice
						  )
						: clsx(
								classes.button,
								isLives ? classes.colorLives : classes.colorOffice
						  )
				}>
					<a
						href={page == "lives" ? officePage + route.href : livePage + route.href}
						className={clsx(
							classes.link,
							page === 'lives'
								? classes.colorLives
								: classes.colorOffice
						)}>
						{t(route.name)}
					</a>
			</Box>

			)}
			{match && !route.goToLivePage && (
				<Box
					className={clsx(
						classes.activeIndicator,
						isLives ? classes.bgLives : classes.bgOffice
					)}
				/>
			)}
			{route.subMenu && (
				<Menu
					id='lock-menu'
					anchorEl={buttonRef.current}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
					PaperProps={{ className: classes.paperCont,
						style: {
							width: 'fit-content',
							marginTop: '48px',
							boxShadow: 'none',
							borderRadius: 0,
							position: 'fixed',
							display: 'flex',
							flexDirection: 'column',
							
						},
						onMouseLeave: handleClose
					}}
					keepMounted
					open={popoverOpen}
					onClose={handleClose}>
					{route.subMenu.map((option, index) => (
						<MenuItem
							key={option}
							selected={false}
							onClick={(event) => {
								handleMenuItemClick(event, index);
								navToPage(option.href);
							}}
							className={classes.popoverText}>
							{t(option.name)}
						</MenuItem>
					))}
				</Menu>
			)}
		</Box>
	);
};

export default NavButton;
