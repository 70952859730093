import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Squares(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '415.863'}
			height={props.height ? props.height : '140.266'}
			viewBox='0 0 415.863 140.266'>
			<g transform='translate(-265.787 -1008.189)'>
				<g transform='translate(428.516 1008.189)'>
					<path
						className='a'
						fill='#4fbab1'
						d='M523.648,1024.079l14.34,14.341a5.29,5.29,0,0,0,7.481,0l14.341-14.341-14.341-14.341a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-523.648 -1008.189)'
					/>
					<path
						className='b'
						fill='#8edad4'
						d='M552.3,1039.969a5.273,5.273,0,0,0,3.741-1.549l14.341-14.341-14.341-14.341a5.275,5.275,0,0,0-3.74-1.549Z'
						transform='translate(-534.218 -1008.189)'
					/>
				</g>
				<g transform='translate(320.03 1062.432)'>
					<path
						className='c'
						fill='#96999c'
						d='M351.741,1110.033l14.341,14.34a5.289,5.289,0,0,0,7.481,0l14.341-14.34-14.341-14.34a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-351.741 -1094.143)'
					/>
					<path
						className='d'
						fill='#ffff'
						d='M380.391,1125.923a5.275,5.275,0,0,0,3.741-1.549l14.341-14.341-14.341-14.341a5.275,5.275,0,0,0-3.74-1.549Z'
						transform='translate(-362.311 -1094.143)'
					/>
				</g>
				<g transform='translate(265.787 1008.189)'>
					<path
						className='a'
						fill='#4fbab1'
						d='M265.787,1024.079l14.34,14.341a5.29,5.29,0,0,0,7.481,0l14.341-14.341-14.341-14.341a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-265.787 -1008.189)'
					/>
					<path
						className='b'
						fill='#8edad4'
						d='M294.437,1039.969a5.273,5.273,0,0,0,3.741-1.549l14.341-14.341-14.341-14.341a5.271,5.271,0,0,0-3.739-1.549Z'
						transform='translate(-276.357 -1008.189)'
					/>
				</g>
				<g transform='translate(320.03 1008.189)'>
					<path
						className='e'
						fill='#b54820'
						d='M351.741,1024.079l14.341,14.341a5.29,5.29,0,0,0,7.481,0l14.341-14.341-14.341-14.341a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-351.741 -1008.189)'
					/>
					<path
						className='b'
						fill='#b97545'
						d='M380.391,1039.969a5.274,5.274,0,0,0,3.741-1.549l14.341-14.341-14.341-14.341a5.275,5.275,0,0,0-3.74-1.549Z'
						transform='translate(-362.311 -1008.189)'
					/>
				</g>
				<g transform='translate(292.909 1035.31)'>
					<path
						className='f'
						fill='#ffb62c'
						d='M308.764,1067.056,323.1,1081.4a5.289,5.289,0,0,0,7.481,0l14.34-14.341-14.34-14.341a5.289,5.289,0,0,0-7.481,0Z'
						transform='translate(-308.764 -1051.165)'
					/>
					<path
						className='b'
						fill='#ffce55'
						d='M337.414,1082.946a5.27,5.27,0,0,0,3.741-1.549l14.341-14.341-14.341-14.341a5.271,5.271,0,0,0-3.74-1.549Z'
						transform='translate(-319.334 -1051.166)'
					/>
				</g>
				<g transform='translate(347.151 1035.31)'>
					<path
						className='a'
						fill='#4fbab1'
						d='M394.717,1067.056l14.34,14.341a5.29,5.29,0,0,0,7.481,0l14.34-14.341-14.34-14.341a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-394.717 -1051.165)'
					/>
					<path
						className='b'
						fill='#8edad4'
						d='M423.367,1082.946a5.27,5.27,0,0,0,3.742-1.549l14.341-14.341-14.341-14.341a5.274,5.274,0,0,0-3.74-1.549Z'
						transform='translate(-405.287 -1051.166)'
					/>
				</g>
				<g transform='translate(374.273 1008.189)'>
					<path
						className='f'
						fill='#ffb62c'
						d='M437.694,1024.079l14.341,14.341a5.29,5.29,0,0,0,7.481,0l14.34-14.341-14.34-14.341a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-437.694 -1008.189)'
					/>
					<path
						className='b'
						fill='#ffce55'
						d='M466.344,1039.969a5.274,5.274,0,0,0,3.741-1.549l14.341-14.341-14.341-14.341a5.272,5.272,0,0,0-3.739-1.549Z'
						transform='translate(-448.264 -1008.189)'
					/>
				</g>
				<g transform='translate(347.151 1089.554)'>
					<path
						className='f'
						fill='#ffb62c'
						d='M430.879,1153.01l-14.341-14.34a5.289,5.289,0,0,0-7.481,0l-14.34,14.34,14.34,14.34a5.291,5.291,0,0,0,7.481,0Z'
						transform='translate(-394.717 -1137.12)'
					/>
					<path
						className='b'
						fill='#ffce55'
						d='M423.368,1168.9a5.274,5.274,0,0,0,3.741-1.549l14.341-14.341-14.341-14.34a5.271,5.271,0,0,0-3.736-1.549Z'
						transform='translate(-405.287 -1137.12)'
					/>
				</g>
				<g transform='translate(374.273 1062.432)'>
					<path
						className='e'
						fill='#b54820'
						d='M473.856,1110.033l-14.34-14.34a5.29,5.29,0,0,0-7.481,0l-14.341,14.34,14.341,14.34a5.289,5.289,0,0,0,7.481,0Z'
						transform='translate(-437.694 -1094.143)'
					/>
					<path
						className='b'
						fill='#b97545'
						d='M466.344,1125.923a5.274,5.274,0,0,0,3.741-1.549l14.341-14.341-14.341-14.341a5.273,5.273,0,0,0-3.74-1.549Z'
						transform='translate(-448.264 -1094.143)'
					/>
				</g>
				<g transform='translate(401.394 1089.554)'>
					<path
						className='c'
						fill='#96999c'
						d='M516.833,1153.01l-14.34-14.34a5.289,5.289,0,0,0-7.481,0l-14.341,14.34,14.341,14.34a5.29,5.29,0,0,0,7.481,0Z'
						transform='translate(-480.671 -1137.12)'
					/>
					<path
						className='d'
						fill='#ffff'
						d='M509.321,1168.9a5.273,5.273,0,0,0,3.741-1.549l14.34-14.341-14.34-14.34a5.274,5.274,0,0,0-3.739-1.549Z'
						transform='translate(-491.241 -1137.12)'
					/>
				</g>
				<g transform='translate(401.394 1035.31)'>
					<path
						className='c'
						fill='#96999c'
						d='M480.671,1067.056l14.341,14.341a5.289,5.289,0,0,0,7.481,0l14.34-14.341-14.34-14.341a5.289,5.289,0,0,0-7.481,0Z'
						transform='translate(-480.671 -1051.165)'
					/>
					<path
						className='d'
						fill='#ffff'
						d='M509.32,1082.946a5.271,5.271,0,0,0,3.742-1.549l14.34-14.341-14.34-14.341a5.274,5.274,0,0,0-3.74-1.549Z'
						transform='translate(-491.24 -1051.166)'
					/>
				</g>
				<g transform='translate(428.516 1116.675)'>
					<path
						className='a'
						fill='#4fbab1'
						d='M523.648,1195.987l14.34,14.34a5.29,5.29,0,0,0,7.481,0l14.341-14.34-14.341-14.341a5.289,5.289,0,0,0-7.481,0Z'
						transform='translate(-523.648 -1180.096)'
					/>
					<path
						className='b'
						fill='#8edad4'
						d='M552.3,1211.877a5.276,5.276,0,0,0,3.741-1.549l14.341-14.34-14.341-14.341a5.27,5.27,0,0,0-3.74-1.549Z'
						transform='translate(-534.218 -1180.097)'
					/>
				</g>
				<g transform='translate(428.516 1062.432)'>
					<path
						className='f'
						fill='#ffb62c'
						d='M523.648,1110.033l14.34,14.34a5.289,5.289,0,0,0,7.481,0l14.341-14.34-14.341-14.34a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-523.648 -1094.143)'
					/>
					<path
						className='b'
						fill='#ffce55'
						d='M552.292,1125.923a5.272,5.272,0,0,0,3.745-1.549l14.34-14.341-14.34-14.341a5.274,5.274,0,0,0-3.738-1.549Z'
						transform='translate(-534.216 -1094.143)'
					/>
				</g>
				<g transform='translate(482.757 1062.432)'>
					<path
						className='f'
						fill='#ffb62c'
						d='M609.6,1110.033l14.341,14.34a5.289,5.289,0,0,0,7.481,0l14.34-14.34-14.34-14.34a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-609.598 -1094.143)'
					/>
					<path
						className='b'
						fill='#ffce55'
						d='M638.243,1125.923a5.27,5.27,0,0,0,3.744-1.549l14.341-14.341-14.341-14.341a5.274,5.274,0,0,0-3.738-1.549Z'
						transform='translate(-620.166 -1094.143)'
					/>
				</g>
				<g transform='translate(537.001 1062.432)'>
					<path
						className='e'
						fill='#b54820'
						d='M695.554,1110.033l14.341,14.34a5.289,5.289,0,0,0,7.481,0l14.341-14.34-14.341-14.34a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-695.554 -1094.143)'
					/>
					<path
						className='b'
						fill='#b97545'
						d='M724.2,1125.923a5.272,5.272,0,0,0,3.745-1.549l14.34-14.341-14.34-14.341a5.274,5.274,0,0,0-3.738-1.549Z'
						transform='translate(-706.122 -1094.143)'
					/>
				</g>
				<g transform='translate(509.88 1089.554)'>
					<path
						className='c'
						fill='#96999c'
						d='M666.919,1138.669l-14.341,14.34,14.341,14.34a5.29,5.29,0,0,0,7.481,0l14.34-14.34-14.34-14.34A5.289,5.289,0,0,0,666.919,1138.669Z'
						transform='translate(-652.578 -1137.12)'
					/>
					<path
						className='d'
						fill='#ffff'
						d='M681.228,1137.12a5.272,5.272,0,0,1,3.74,1.549l14.341,14.34-14.341,14.341a5.275,5.275,0,0,1-3.742,1.549Z'
						transform='translate(-663.147 -1137.12)'
					/>
				</g>
				<g transform='translate(591.245 1008.189)'>
					<path
						className='e'
						fill='#b54820'
						d='M781.508,1024.079l14.341,14.341a5.291,5.291,0,0,0,7.481,0l14.34-14.341-14.34-14.341a5.291,5.291,0,0,0-7.481,0Z'
						transform='translate(-781.508 -1008.189)'
					/>
					<path
						className='b'
						fill='#b97545'
						d='M810.159,1039.969a5.273,5.273,0,0,0,3.741-1.549l14.341-14.341L813.9,1009.738a5.273,5.273,0,0,0-3.74-1.549Z'
						transform='translate(-792.078 -1008.189)'
					/>
				</g>
				<g transform='translate(591.245 1062.432)'>
					<path
						className='c'
						fill='#96999c'
						d='M817.67,1110.033l-14.341-14.34a5.29,5.29,0,0,0-7.481,0l-14.341,14.34,14.341,14.34a5.29,5.29,0,0,0,7.481,0Z'
						transform='translate(-781.508 -1094.143)'
					/>
					<path
						className='d'
						fill='#ffff'
						d='M810.159,1125.923a5.275,5.275,0,0,0,3.741-1.549l14.341-14.341L813.9,1095.692a5.273,5.273,0,0,0-3.74-1.549Z'
						transform='translate(-792.078 -1094.143)'
					/>
				</g>
				<g transform='translate(564.123 1089.554)'>
					<path
						className='f'
						fill='#ffb62c'
						d='M774.693,1153.01l-14.34-14.34a5.289,5.289,0,0,0-7.481,0l-14.341,14.34,14.341,14.34a5.29,5.29,0,0,0,7.481,0Z'
						transform='translate(-738.531 -1137.12)'
					/>
					<path
						className='b'
						fill='#ffce55'
						d='M767.183,1137.12a5.272,5.272,0,0,1,3.74,1.549l14.34,14.34-14.34,14.341a5.274,5.274,0,0,1-3.741,1.549Z'
						transform='translate(-749.101 -1137.12)'
					/>
				</g>
				<g transform='translate(618.366 1035.31)'>
					<path
						className='f'
						fill='#ffb62c'
						d='M824.485,1067.056l14.34,14.341a5.289,5.289,0,0,0,7.481,0l14.34-14.341-14.34-14.341a5.289,5.289,0,0,0-7.481,0Z'
						transform='translate(-824.485 -1051.165)'
					/>
					<path
						className='b'
						fill='#ffce55'
						d='M853.135,1082.946a5.27,5.27,0,0,0,3.741-1.549l14.341-14.341-14.341-14.341a5.271,5.271,0,0,0-3.74-1.549Z'
						transform='translate(-835.055 -1051.166)'
					/>
				</g>
				<g transform='translate(564.123 1035.31)'>
					<path
						className='a'
						fill='#4fbab1'
						d='M738.531,1067.056l14.341,14.341a5.289,5.289,0,0,0,7.481,0l14.34-14.341-14.34-14.341a5.289,5.289,0,0,0-7.481,0Z'
						transform='translate(-738.531 -1051.165)'
					/>
					<path
						className='b'
						fill='#8edad4'
						d='M767.182,1082.946a5.27,5.27,0,0,0,3.741-1.549l14.34-14.341-14.34-14.341a5.273,5.273,0,0,0-3.74-1.549Z'
						transform='translate(-749.101 -1051.166)'
					/>
				</g>
				<g transform='translate(482.759 1116.675)'>
					<path
						className='a'
						fill='#4fbab1'
						d='M623.942,1181.646,609.6,1195.987l14.341,14.34a5.29,5.29,0,0,0,7.481,0l14.341-14.34-14.341-14.341A5.289,5.289,0,0,0,623.942,1181.646Z'
						transform='translate(-609.601 -1180.096)'
					/>
					<path
						className='b'
						fill='#8edad4'
						d='M638.252,1180.1a5.269,5.269,0,0,1,3.74,1.549l14.341,14.341-14.341,14.34a5.274,5.274,0,0,1-3.741,1.549Z'
						transform='translate(-620.171 -1180.097)'
					/>
				</g>
				<g transform='translate(645.488 1008.189)'>
					<path
						className='a'
						fill='#4fbab1'
						d='M903.624,1024.079l-14.341-14.341a5.29,5.29,0,0,0-7.481,0l-14.34,14.341,14.34,14.341a5.29,5.29,0,0,0,7.481,0Z'
						transform='translate(-867.462 -1008.189)'
					/>
					<path
						className='b'
						fill='#8edad4'
						d='M896.112,1039.969a5.273,5.273,0,0,0,3.741-1.549l14.34-14.341-14.34-14.341a5.274,5.274,0,0,0-3.74-1.549Z'
						transform='translate(-878.032 -1008.189)'
					/>
				</g>
				<g transform='translate(537.002 1008.189)'>
					<path
						className='f'
						fill='#ffb62c'
						d='M695.555,1024.079,709.9,1038.42a5.29,5.29,0,0,0,7.481,0l14.341-14.341-14.341-14.341a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-695.555 -1008.189)'
					/>
					<path
						className='b'
						fill='#ffce55'
						d='M724.2,1039.969a5.274,5.274,0,0,0,3.741-1.549l14.341-14.341-14.341-14.341a5.275,5.275,0,0,0-3.74-1.549Z'
						transform='translate(-706.125 -1008.189)'
					/>
				</g>
				<g transform='translate(482.759 1008.189)'>
					<path
						className='a'
						fill='#4fbab1'
						d='M609.6,1024.079l14.341,14.341a5.29,5.29,0,0,0,7.481,0l14.341-14.341-14.341-14.341a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-609.601 -1008.189)'
					/>
					<path
						className='b'
						fill='#8edad4'
						d='M638.252,1008.189a5.273,5.273,0,0,1,3.74,1.549l14.341,14.341-14.341,14.341a5.273,5.273,0,0,1-3.742,1.549Z'
						transform='translate(-620.17 -1008.189)'
					/>
				</g>
				<g transform='translate(455.638 1035.31)'>
					<path
						className='e'
						fill='#b54820'
						d='M566.625,1067.056l14.34,14.341a5.29,5.29,0,0,0,7.481,0l14.341-14.341-14.341-14.341a5.29,5.29,0,0,0-7.481,0Z'
						transform='translate(-566.625 -1051.165)'
					/>
					<path
						className='b'
						fill='#b97545'
						d='M595.275,1051.166a5.274,5.274,0,0,1,3.74,1.549l14.34,14.341-14.34,14.341a5.271,5.271,0,0,1-3.742,1.549Z'
						transform='translate(-577.194 -1051.166)'
					/>
				</g>
				<g transform='translate(509.88 1035.31)'>
					<path
						className='c'
						fill='#96999c'
						d='M652.578,1067.056l14.341,14.341a5.289,5.289,0,0,0,7.481,0l14.34-14.341-14.34-14.341a5.289,5.289,0,0,0-7.481,0Z'
						transform='translate(-652.578 -1051.165)'
					/>
					<path
						className='d'
						fill='#ffff'
						d='M681.229,1051.166a5.273,5.273,0,0,1,3.739,1.549l14.34,14.341-14.34,14.341a5.271,5.271,0,0,1-3.742,1.549Z'
						transform='translate(-663.147 -1051.166)'
					/>
				</g>
				<g transform='translate(455.637 1089.554)'>
					<path
						className='e'
						fill='#b54820'
						d='M602.786,1153.01l-14.341-14.341a5.29,5.29,0,0,0-7.481,0l-14.341,14.341,14.341,14.341a5.29,5.29,0,0,0,7.481,0Z'
						transform='translate(-566.624 -1137.12)'
					/>
					<path
						className='b'
						fill='#b97545'
						d='M595.275,1137.12a5.27,5.27,0,0,1,3.74,1.549l14.341,14.34-14.341,14.341a5.273,5.273,0,0,1-3.741,1.549Z'
						transform='translate(-577.194 -1137.12)'
					/>
				</g>
			</g>
		</svg>
	);
}
