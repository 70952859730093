import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
	Village2,
	Statutum,
	Regulations,
	Decisions,
	Provisions,
	Documents,
	OtherDocuments,
} from '../../../components/icons';
import { CategoryCard } from './components/CategoryCard';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Report from '../../../components/icons/Report';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 24,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	contentContainer: {
		maxWidth: 1164,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30,
	},
	titleContainer: {
		width: '100%',
		height: 25,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 30,
		borderBottom: '1px solid #D5D4C9',
	},
	categoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px',
	},
	cardsContainer: {
		maxWidth: 1164,
		paddingTop: 40,
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	village2Container: {
		paddingTop: '40px',
	},
	pageTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		marginLeft: 20,
		marginBottom: theme.spacing(1),
	},
	archiveText: {
		fontFamily: 'Ubuntu',
		fontSize: 18,
		marginTop: 16,
	},
	archiveLink: {
		fontFamily: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'bold',
		textDecoration: 'none',
	},
}));

const menu = [
	{
		name: 'unit_status',
		href: 'unit_status',
		icon: <Statutum width='120px' height='80px' />,
	},
	{
		name: 'admi_procs',
		href: 'admi_procs',
		icon: <Regulations width='120px' height='80px' />,
	},
	{
		name: 'auth_dec',
		href: 'auth_dec',
		icon: <Decisions width='120px' height='80px' />,
	},
	{
		name: 'ex_disp',
		href: 'ex_disp',
		icon: <Provisions width='120px' height='80px' />,
	},
	{
		name: 'fin_docs',
		href: 'fin_docs',
		icon: <Documents width='120px' height='80px' />,
	},
	{
		name: 'report',
		href: 'report',
		icon: <Report width={120} height={80} />,
	},
	{
		name: 'other',
		href: 'other',
		icon: <OtherDocuments width='120px' height='80px' />,
	},
];

const ARCHIVE_URL =
	'https://drive.google.com/drive/folders/1_5OYHNPXLwKwXYs38Bk7Bv5MMGA6sOZR?usp=sharing';

export default withNamespaces('gazette')((props) => {
	const { t } = props;
	const classes = useStyles();
	const language = useSelector((state) => state.language.language);

	// const data = useSelector((state) => state.office.list);

	const renderArchiveLink = () => {
		if (language === 'ro') {
			return (
				<Typography className={classes.archiveText}>
					<a
						className={classes.archiveLink}
						href={ARCHIVE_URL}
						target='_blank'
						rel='noopener noreferrer'>
						Clic aici
					</a>
					&#160;pentru a descărca documentele de arhivă.
				</Typography>
			);
		}
		return (
			<Typography className={classes.archiveText}>
				Az archív dokumentumok letöltéséhez&#160;
				<a
					className={classes.archiveLink}
					href={ARCHIVE_URL}
					target='_blank'
					rel='noopener noreferrer'>
					kattintson ide.
				</a>
			</Typography>
		);
	};

	return (
		<Box className={classes.pageContainer}>
			<Box className={classes.contentContainer}>
				<Box className={classes.titleContainer}>
					<Breadcrumbs
						breadcrumbs={[
							{
								href: 'gazette',
								name: `${t('title')}\xa0  //`,
							},
						]}
					/>
				</Box>
				<Grid container>
					{menu.map((item) => (
						<Grid key={item.name} item xs={12} xl={3} md={4} sm={6} lg={3}>
							<CategoryCard type={item} />
						</Grid>
					))}
				</Grid>
				{renderArchiveLink()}
			</Box>
			<Box className={classes.village2Container}>
				<Village2 />
			</Box>
		</Box>
	);
});
