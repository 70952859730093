import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function ChevronRight(props) {
	return (
		<svg width={props.width} height={props.height} viewBox='0 0 24 24'>
			<g>
				<path
					className='a'
					d='M0,0H24V24H0Z'
					fill='none'
					transform='translate(0 1)'
				/>
				<path
					className='b'
					fill={props.fill ? props.fill : '#f0f0eb'}
					d='M10.235,6,8.59,7.645,13.933,13,8.59,18.355,10.235,20l7-7Z'
				/>
			</g>
		</svg>
	);
}
