import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function OnionTopHalf(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '200'}
			height={props.height ? props.height : '149.753'}
			viewBox='0 0 200 149.753'>
			<g transform='translate(-584 -2158)'>
				<g transform='translate(584 2158)'>
					<path
						className='a'
						fill='#26002c'
						d='M346.567,370.336a.78.78,0,0,0-1.436-.208c-2.874,5.013-11.312,16.99-26.355,17-15.043-.009-23.482-11.986-26.355-17a.78.78,0,0,0-1.436.208c-5.075,22.893-70.157,35.235-72.207,83.105a2.234,2.234,0,0,0,2.23,2.327H416.544a2.234,2.234,0,0,0,2.23-2.327C416.724,405.57,351.642,393.229,346.567,370.336Z'
						transform='translate(-218.776 -306.015)'
					/>
					<path
						className='b'
						fill='#62006a'
						d='M595.276,455.768h97.768a2.234,2.234,0,0,0,2.23-2.327c-2.05-47.87-67.132-60.212-72.207-83.105a.78.78,0,0,0-1.436-.208c-2.874,5.013-11.312,16.99-26.355,17Z'
						transform='translate(-495.276 -306.015)'
					/>
					<path
						className='c'
						fill='#cc9025'
						d='M540.661,166.115a23.666,23.666,0,0,1,2.738,3.446,50.453,50.453,0,0,1,6.6,16.118,50.446,50.446,0,0,1,6.595-16.118,23.573,23.573,0,0,1,2.976-3.685c-.423-11.087.784-24.256,6.883-34.359a1.114,1.114,0,0,0-1.724-1.377c-12.9,12.136-14.61,38.572-14.607,51.76,0-13.188-1.7-39.625-14.607-51.76a1.114,1.114,0,0,0-1.724,1.377C539.931,141.693,541.111,154.979,540.661,166.115Z'
						transform='translate(-449.994 -129.826)'
					/>
					<path
						className='d'
						fill='#b54820'
						d='M541.613,238.271c-4.386-.429-15.783.761-22.4,10.878a50.446,50.446,0,0,0-6.595,16.118,50.453,50.453,0,0,0-6.6-16.118c-6.615-10.117-18.012-11.307-22.4-10.878a1.134,1.134,0,0,0-.589,1.9c10.881,11.6,11.378,23.078,7.854,32.407,3.073,4.869,10.15,13.486,21.731,13.493s18.652-8.626,21.725-13.493c-3.524-9.329-3.027-20.8,7.854-32.407A1.134,1.134,0,0,0,541.613,238.271Z'
						transform='translate(-412.62 -209.413)'
					/>
					<path
						className='b'
						fill='#d78758'
						d='M595.279,286.073c11.577-.007,18.652-8.626,21.725-13.493-3.524-9.329-3.027-20.8,7.854-32.407a1.134,1.134,0,0,0-.589-1.9c-4.386-.429-15.783.761-22.4,10.878a50.447,50.447,0,0,0-6.595,16.118Z'
						transform='translate(-495.276 -209.413)'
					/>
				</g>
			</g>
		</svg>
	);
}
