import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

const useStyles = makeStyles((theme) => ({
	root: {
		'& > span': {
			margin: theme.spacing(2)
		}
	}
}));

export default function CustomIcon(props) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Icon color={props.color} style={props.style} fontSize={props.fontSize}>
				{props.icon}
			</Icon>
		</div>
	);
}
