import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import {
	Box,
	Grid,
	Hidden,
	makeStyles,
	Typography,
	withWidth
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import QueryString from 'qs';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import {
	Village2,
	PublicInterest as PubIntIcon,
	Document1,
	SocialOffice
} from '../../../../../components/icons';
import Description from './components/Description';
import { Downloadables } from '../SocialOffice/components/Downloadables';
import { transformQuery } from '../../../../../lib/helpers/queryTransformer';
import Search from '../Search/Search';
import Pagination from '../../../../../components/Pagination/Pagination';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	contentContainer: {
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30
	},
	titleContainer: {
		width: '100%',
		height: 25,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 30,
		borderBottom: '1px solid #D5D4C9'
	},
	content: {
		display: 'flex',
		width: '100%',
		flexDirection: 'column'
	},
	village2Container: {
		paddingTop: '40px'
	},
	title2Container: {
		width: '100%',
		height: 73,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 70,
		paddingBottom: 10,
		borderBottom: '1px solid #D5D4C9',
		alignItems: 'flex-end',
		transition: 'all .3s',
		[theme.breakpoints.only('xs')]: {
			paddingBottom: 0,
			marginTop: 52
		}
	},
	pageTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		marginLeft: 20,
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			fontSize: '25px'
		}
	},
	pageTitleVertical: {
		fontFamily: 'Ubuntu',
		fontSize: 18,
		letterSpacing: 0,
		marginBottom: theme.spacing(1),
		textAlign: 'center'
	},
	tabButton: {
		cursor: 'pointer',
		width: '50%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end'
	},
	tabButtonVertical: {
		cursor: 'pointer',
		width: '50%',
		marginBottom: 8
	},
	fixHeight: {
		height: 50,
		maxHeight: 50,
		marginTop: 8
	}
}));

const PublicInterest = (props) => {
	const { t } = props;
	const classes = useStyles();

	const [selectedTab, setSelectedTab] = useState(0);
	const data = useSelector((state) => state.office.list);
	const history = useHistory();

	const queries = QueryString.parse(history.location.search, {
		ignoreQueryPrefix: true
	});

	const [page, setPage] = useState(1);

	const pushPage = (up) => {
		const nPage = page ? Number(page) : 1;
		const pageTmp = up ? nPage + 1 : nPage - 1;
		history.push({
			...queries,
			pathname: '/pub_int',
			search: transformQuery({
				category: 'pub_int',
				limit: 10,
				page: pageTmp
			})
		});
		updatePage(pageTmp);
		window.scrollTo(0, 0);
	};

	const handlePageChange = (e) => {
		if (e.keyCode === 13) {
			if (e.target.value > data?.meta?.totalPages || e.target.value < 1) {
				return;
			}
			history.push({
				pathname: '/pub_int',
				search: transformQuery({
					...queries,
					category: 'pub_int',
					limit: 10,
					page: e.target.value
				})
			});
			window.scrollTo(0, 0);
		}
	};

	useEffect(() => {
		if (
			data?.meta?.totalPages &&
			queries.page &&
			queries.page <= data.meta.totalPages
		) {
			updatePage(queries.page);
		}
	}, [data, queries.page]);

	const updatePage = (newPage) => {
		if (page !== newPage) {
			setPage(newPage);
		}
	};

	return (
		<Box className={classes.pageContainer}>
			<Box className={classes.contentContainer}>
				<Box className={classes.titleContainer}>
					<Breadcrumbs
						breadcrumbs={[
							{
								href: 'council',
								name: t('category')
							},
							{
								href: 'pub_int',
								name: t('pub_int'),
								final: true
							}
						]}
					/>
				</Box>

				<Box className={classes.title2Container}>
					<Hidden smUp>
						<Grid
							container
							direction='column'
							alignContent='center'
							alignItems='center'
							className={classes.tabButtonVertical}
							onClick={() => setSelectedTab(0)}>
							<PubIntIcon width={60} height={44} />
							<Box className={classes.fixHeight}>
								<Typography className={classes.pageTitleVertical}>
									{t('pub_int')}
								</Typography>
							</Box>
						</Grid>
						<Grid
							container
							direction='column'
							alignContent='center'
							alignItems='center'
							className={classes.tabButtonVertical}
							onClick={() => setSelectedTab(1)}>
							<Document1
								width={60}
								height={44}
								dotfill={selectedTab === 1 ? false : '#93959F'}
								fill={selectedTab === 1 ? false : '#93959F'}
							/>
							<Box className={classes.fixHeight}>
								<Typography className={classes.pageTitleVertical}>
									{t('downloadable')}
								</Typography>
							</Box>
						</Grid>
					</Hidden>
					<Hidden xsDown>
						<Box
							className={classes.tabButton}
							onClick={() => setSelectedTab(0)}>
							<SocialOffice width={75} height={55} />
							<Typography className={classes.pageTitle}>
								{t('pub_int')}
							</Typography>
						</Box>
						<Box
							className={classes.tabButton}
							onClick={() => setSelectedTab(1)}>
							<Document1
								width={75}
								height={55}
								dotfill={selectedTab === 1 ? false : '#93959F'}
								fill={selectedTab === 1 ? false : '#93959F'}
							/>
							<Typography className={classes.pageTitle}>
								{t('downloadable')}
							</Typography>
						</Box>
					</Hidden>
				</Box>
				<Box className={classes.content}>
					{selectedTab === 1 && <Search category='pub_int' />}
					{selectedTab === 0 ? <Description /> : <Downloadables data={data} />}
					{selectedTab === 1 && (
						<Pagination
							page={page}
							setPage={setPage}
							handlePageChange={handlePageChange}
							currentPage={data?.meta?.currentPage}
							totalPages={data?.meta?.totalPages ? data.meta.totalPages : 1}
							t={t}
							pushPage={pushPage}
						/>
					)}
				</Box>
			</Box>

			<Box className={classes.village2Container}>
				<Village2 />
			</Box>
		</Box>
	);
};

export default withNamespaces(['council', 'gazette'])(
	withWidth()(PublicInterest)
);
