import palette from '../palette';

export default {
	root: {
		color: palette.icon,
		'&:hover': {
			backgroundColor: palette.primary.light
		}
	}
};
