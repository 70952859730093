import React from 'react';
import { Typography, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import Onion from '../../../../../components/images/Depositphotos_63267181_xl-2015.png';
import {
	RotatedHalfCircleStripes,
	OnionTopHalf,
	OnionBottomHalf,
	Squares,
	Culture2,
	PanWithSquares
} from '../../../../../components/icons';

const BP1 = '@media (max-width: 420px)';

const useStyles = makeStyles((theme) => ({
	onionFest: {
		width: 1156,
		height: 672,
		paddingTop: 28,
		position: 'relative',
		right: 0,
		left: 0,
		marginRight: 'auto',
		marginLeft: 'auto',
		paddingLeft: '24px',
		paddingRight: '24px',
		boxSizing: 'border-box',
		overflow: 'hidden',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 770
		},
		[theme.breakpoints.only('md')]: {
			width: '100%'
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 630
		}
	},
	onionFestContainer: {
		position: 'absolute',
		top: 0,
		height: '100%',
		width: '100%'
	},
	onionPicConteiner: {
		position: 'absolute',
		background: `transparent url(${Onion}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		right: 0,
		width: 532,
		height: 364,
		top: 59,
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			width: 200,
			height: 200,
			top: 550,
			right: 0,
			opacity: 0.9
		},
		[theme.breakpoints.only('sm')]: {
			width: 400,
			height: 300,
			right: 0,
			top: 100
		}
	},
	decoratedTitleContainer: {
		width: 639,
		height: 378,
		top: 0,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		}
	},

	titleTextContainer2: {
		position: 'absolute',
		background: 'rgb(254 204 1)',
		opacity: 1,
		textAlign: 'left',
		zIndex: 101,
		top: 150,
		left: 0
	},
	titleText: {
		fontFamily: 'Bebas Neue',
		color: 'rgb(39 1 44)',
		fontSize: '50px',
		letterSpacing: '3px',
		lineHeight: '50px',
		marginTop: 12,
		marginLeft: 23,
		marginBottom: 7,
		marginRight: 116,
		zIndex: 102,
		[theme.breakpoints.only('xs')]: {
			marginRight: 20
		}
	},
	titleTextContainer1: {
		position: 'absolute',
		top: 97,
		left: 176,
		transition: 'all .3s',
		[theme.breakpoints.only('xs')]: {
			left: 140
		},
		[theme.breakpoints.only('md')]: {
			left: 80
		},
		[theme.breakpoints.only('sm')]: {
			left: 80
		},
		[BP1]: {
			top: 93,
			left: 0
		}
	},
	halfCirleStripes: {
		top: 0,
		left: 51,
		position: 'absolute',
		transition: 'all .3s',
		[BP1]: {
			left: 201
		}
	},
	halfOnionTop: {
		top: 0,
		position: 'absolute',
		left: 439,
		width: '200px',
		height: '150px',
		[theme.breakpoints.only('xs')]: {
			left: 0,
			width: 160,
			top: 30
		},
		[theme.breakpoints.only('md')]: {
			left: 300,
			width: 160,
			top: 30
		},
		[theme.breakpoints.only('sm')]: {
			left: 300,
			width: 160,
			top: 30
		},
		[BP1]: {
			display: 'none'
		}
	},
	halfOnionBottom: {
		top: 207,
		left: 208,
		position: 'absolute',
		width: '200px',
		height: '150px',
		transition: 'all .3s',
		[theme.breakpoints.only('xs')]: {
			width: 160,
			top: 175
		},
		[theme.breakpoints.down('md')]: {
			width: 160,
			top: 175
		},
		[BP1]: {
			top: 225,
			left: 65
		}
	},
	squaresContainer: {
		position: 'absolute',
		top: 271,
		left: 446,
		width: '416px',
		height: '140px',
		[theme.breakpoints.down('xs')]: {
			top: 550,
			left: 0,
			width: '100%'
		},
		[theme.breakpoints.only('sm')]: {
			left: 'auto',
			right: 50,
			width: '300'
		}
	},
	panWithSquaresContainer: {
		position: 'absolute',
		bottom: 69,
		left: 548,
		width: '432px',
		height: '71px',
		[theme.breakpoints.only('xs')]: {
			top: 730,
			left: 0,
			width: '100%'
		},
		[theme.breakpoints.only('sm')]: {
			left: 'auto',
			right: 50,
			width: '300',
			top: 500
		}
	},
	cultureContainer: {
		bottom: 0,
		position: 'absolute',
		width: '191px',
		height: '137px',
		[theme.breakpoints.only('xs')]: {
			display: 'none'
		},
		[theme.breakpoints.only('sm')]: {
			height: 120
		}
	},
	descriptionTitleContainer: {
		top: 397,
		left: 144,
		position: 'absolute',
		[theme.breakpoints.only('xs')]: {
			left: 30
		}
	},
	descriptionTitle: {
		fontFamily: 'Bebas Neue',
		fontSize: '30px',
		lineHeight: '30px',
		letterSpacing: '0.30px',
		textTransform: 'uppercase',
		color: '#E0B080'
	},
	description: {
		boxSizing: 'border-box',
		fontFamily: 'Ubuntu',
		fontSize: '18px',
		letterSpacing: '0.18px',
		color: '#282D38',
		textAlign: 'left'
	},
	descriptionContainer: {
		position: 'absolute',
		maxWidth: 'calc(100% - 144px)',
		paddingRight: '40px',
		boxSizing: 'border-box',
		top: 448,
		left: 144,
		[theme.breakpoints.only('xs')]: {
			maxWidth: 'calc(100% - 30px)',
			left: 30
		}
	}
}));

function OnionFest(props) {
	const classes = useStyles();
	const { t } = props;
	const breakTitle = useMediaQuery('(max-width: 420px)');

	return (
		<Box className={classes.onionFest}>
			<Box className={classes.onionFestContainer}>
				<Box className={classes.decoratedTitleContainer}>
					<Box className={classes.titleTextContainer1}>
						<Typography className={classes.titleText}>
							{t('titleRow1')}
						</Typography>
					</Box>
					<Box className={classes.titleTextContainer2}>
						<Typography className={classes.titleText}>
							{breakTitle ? (
								<>
									{t('titleRow2Part1')}
									<br />
									{t('titleRow2Part2')}
								</>
							) : (
								<>{t('titleRow2')}</>
							)}
							{/* {`${t('titleRow2Part1')}\n${t('titleRow2Part2')}`} */}
							{/* {t('titleRow2')} */}
						</Typography>
					</Box>
					<Box className={classes.halfCirleStripes}>
						<RotatedHalfCircleStripes width='254px' height='371px' />
					</Box>
					<Box className={classes.halfOnionTop}>
						<OnionTopHalf width='100%' height='100%' />
					</Box>
					<Box className={classes.halfOnionBottom}>
						<OnionBottomHalf width='100%' height='100%' />
					</Box>
					<Box className={classes.descriptionTitleContainer}>
						<Typography className={classes.descriptionTitle}>
							{t('onionFestDescriptionTitle')}
						</Typography>
					</Box>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.description}>
							<Trans i18nKey='onionFestDescription'>
								<br />
								<br />
							</Trans>
						</Typography>
					</Box>
					<Box className={classes.squaresContainer}>
						<Squares width='100%' height='100%' />
					</Box>
					<Box className={classes.panWithSquaresContainer}>
						<PanWithSquares width='100%' height='100%' />
					</Box>
					<Box className={classes.cultureContainer}>
						<Culture2 width='100%' height='100%' />
					</Box>
				</Box>
				<Box className={classes.onionPicConteiner} />
			</Box>
		</Box>
	);
}

export default withNamespaces('lives')(OnionFest);
