import React, { useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import {
	Box,
	Grid,
	CardMedia,
	makeStyles,
	Button,
	Paper
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import { ImageSelector } from '../modals';

import palette from '../../themes/palette';

function Item(image) {
	const useStyles = makeStyles({
		root: {
			height: '100%',
			width: '100%'
		},
		media: {
			height: 200
		}
	});
	const classes = useStyles();
	return (
		<Paper className={classes.papper}>
			<CardMedia
				className={classes.media}
				image={image.item.url}
				title='Contemplative Reptile'
			/>
		</Paper>
	);
}

const useStyles = makeStyles({
	slider: {
		marginTop: '20px'
	},
	sliderImageSelectorsBox: {
		border: `1px solid ${palette.black}`,
		padding: '20px'
	},
	btn: {
		color: 'white'
	}
});

function Slider(props) {
	const image = useSelector((state) => state.file.view);

	const { setFieldValue, values } = props;
	const classes = useStyles();

	return (
		<Box className={classes.sliderImageSelectorsBox}>
			<Grid container spacing={4}>
				<FieldArray
					name='slider.content'
					render={() =>
						values.content.map((item, index) => (
							<Grid item md={image && image.url ? 6 : 12}>
								<ImageSelector
									slider
									fieldName={`slider.content[${index}]`}
									image={item.file}
									setImageId={(img) => {
										setFieldValue(`slider.content[${index}]`, {
											num: index + 1,
											fileId: img.id
										});
									}}
								/>
							</Grid>
						))
					}
				/>
				<Button
					className={classes.btn}
					onClick={() =>
						setFieldValue(`slider.content[${values.content.length}]`, undefined)
					}>
					+ Új kép
				</Button>
			</Grid>
			<Grid className={classes.slider}>
				<Carousel>
					{values.content.map(
						(item, i) => item?.file && <Item key={i} item={item.file} />
					)}
				</Carousel>
			</Grid>
		</Box>
	);
}
export default Slider;
