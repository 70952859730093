import React, { useEffect, useState } from 'react';
import {
	Typography,
	Box,
	makeStyles,
	withWidth,
	IconButton,
	useMediaQuery,
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import qs from 'qs';
import { NewsCard } from '../../News/components/NewsCard';
import { ChevronRight } from '../../../../components/icons';

import { getAllNews } from '../../../../store/news/actions';

import { transformQuery } from '../../../../lib/helpers/queryTransformer';

const BP1280 = '@media (max-width: 1280px)';

const useStyles = makeStyles((theme) => ({
	contentContainer: {
		zIndex: 3,
		height: 'fit-content',
		marginBottom: 100,
		paddingBottom: 100,
		paddingTop: 40,
		boxSizing: 'border-box',
	},
	description: {
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '24px',
		color: theme.palette.primary.dark,
		paddingTop: '25px',
		paddingBottom: '25px',
	},
	descriptionText: {
		marginLeft: 12,
		fontWeight: 'bold',
	},
	newsGrid: {
		width: '100%',
		paddingTop: 32,
		paddingBottom: 30,
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 30,
		},
		[theme.breakpoints.only('sm')]: {
			paddingLeft: 30,
		},
	},
	mainTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 700,
		color: theme.palette.primary.dark,
		fontSize: '50px',
		lineHeight: '57px',
		letterSpacing: '-1px',
	},
	newsLine: {
		background: '#D5D4C9 0% 0% no-repeat padding-box',
		width: '100%',
		height: 1,
	},
	totalText: {
		textTransform: 'uppercase',
		fontFamily: 'Bebas Neue',
		fontWeight: 'bold',
		letterSpacing: '1px',
		lineHeight: '15.6px',
		fontSize: '20px',
		paddingTop: 7,
	},
	totalButton: {
		margin: 'auto',
		width: 'fit-content',
		display: 'inline-flex',
		marginRight: 103,
		marginTop: 19,
		cursor: 'pointer',
		[theme.breakpoints.only('xs')]: {
			marginRight: 50,
		},
	},
	totalButtonContainer: {
		float: 'right',
	},
	title: {
		marginLeft: 12,
	},
	newsContainer: {
		position: 'relative',
	},
	gridContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: '48px',
		paddingTop: '32px',
		// columnGap: '8px',
	},
	arrowContainer: {
		flexBasis: '100px',
		width: '100px',
		height: '50px',
		// backgroundColor: 'green',
	},
	grid: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		flexWrap: 'wrap',
		height: '320px',
		gridColumnGap: '16px',
		overflowY: 'hidden',
		overflowX: 'visible',
		maxWidth: 1128,
		paddingLeft: '12px',
		paddingRight: '12px',
		marginLeft: '10px',
		marginRight: '10px',
		// marginLeft: '6px',
		// gridTemplateColumns: 'repeat(4, 290px)',
	},
	gridItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	arrowDown: {
		width: '100px',
		background: '#070c35',
		opacity: 1,
	},
	arrowDownDisabled: {
		background: '#aaaaaa',
	},
	arrowLeft: {
		position: 'relative',
		marginLeft: '-60px',
		// marginRight: '8px',
		height: 50,
		width: 50,
		'&:hover': {
			background: '#FFFFFF',
		},
		transform: 'rotate(180deg)',
		[BP1280]: {
			marginLeft: 0,
		},
	},
	arrowRight: {
		position: 'relative',
		marginRight: '-60px',
		// marginLeft: '8px',
		height: 50,
		width: 50,
		'&:hover': {
			background: '#FFFFFF',
		},
		[BP1280]: {
			marginRight: 0,
		},
	},
	smallContainer: {
		boxSizing: 'border-box',
		paddingTop: '20px',
		paddingBottom: '20px',
	},
}));

function Home(props) {
	const classes = useStyles();
	const history = useHistory();
	const { t } = props;
	const news = useSelector((state) => state.news.list.items);
	const is3 = useMediaQuery('(max-width: 1280px)');
	const is2 = useMediaQuery('(max-width: 1015px)');
	const is1 = useMediaQuery('(max-width: 735px)');
	const isSmall = useMediaQuery('(max-width: 480px)');
	const [visibleCount, setVisibleCount] = useState(0);
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		// eslint-disable-next-line no-nested-ternary
		const getVisibleCount = () => (is1 ? 1 : is2 ? 2 : is3 ? 3 : 4);
		setVisibleCount(getVisibleCount);
	}, [is3, is2, is1]);

	const queries = qs.parse(history.location.search, {
		ignoreQueryPrefix: true,
	});

	useEffect(() => {
		// const limit = width === 'xl' || width === 'lg' || width === 'md' ? 4 : 2;
		getAllNews(transformQuery({ ...queries, page: 1, limit: 20 }));
	}, [history.location.search]);

	const getVisibleNews = () => {
		const n = [...news];
		const r = [];
		const part = n.splice(offset, 4);
		part.forEach((item) => {
			r.push(
				<Box key={item.id}>
					<NewsCard
						key={item.id}
						newsItem={item}
						createdAt={item.createdAt}
						id={item.id}
					/>
				</Box>,
			);
		});
		for (let i = 0; i < 4 - part.length; i += 1) {
			r.push(<Box style={{ width: '264px' }} />);
		}
		return r;
	};

	const getSmallNews = () => {
		const n = [...news];
		const r = [];
		n.splice(0, 2).forEach((item) => {
			r.push(
				<Box key={item.id}>
					<NewsCard
						key={item.id}
						newsItem={item}
						createdAt={item.createdAt}
						id={item.id}
					/>
				</Box>,
			);
		});
		return r;
	};

	return (
		<Box className={classes.contentContainer}>
			<Typography variant='h2' className={classes.title}>
				{t('homenews.title')}
			</Typography>
			<Box className={classes.description}>
				<Typography className={classes.descriptionText}>
					{t('homenews.description')}
				</Typography>
			</Box>

			<Box className={classes.newsContainer}>
				{news && !isSmall && (
					<Box className={classes.gridContainer}>
						<IconButton
							className={classes.arrowLeft}
							onClick={() => setOffset((state) => state - 1)}
							disabled={!offset}>
							<ChevronRight
								width='24px'
								height='24px'
								fill='#070C35'
								className={classes.arrowDown}
							/>
						</IconButton>
						<Box className={classes.grid}>{getVisibleNews()}</Box>
						<IconButton
							className={classes.arrowRight}
							onClick={() => setOffset((state) => state + 1)}
							disabled={offset >= news.length - visibleCount}>
							<ChevronRight
								width='24px'
								height='24px'
								fill='#070C35'
								className={classes.arrowDown}
							/>
						</IconButton>
					</Box>
				)}
				{news && isSmall && (
					<Box className={classes.smallContainer}>{getSmallNews()}</Box>
				)}
				<Box className={classes.newsLine} />
				<Box className={classes.totalButtonContainer}>
					<Box
						className={classes.totalButton}
						onClick={() => {
							props.history.push('/news');
						}}>
						<Typography className={classes.totalText}>{t('total')} </Typography>
						<ChevronRight fill='#070C35' width={24} height={24} />
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default withWidth()(withNamespaces('home')(withRouter(Home)));
