import React, { useState, useEffect, useRef } from 'react';
import { Box, makeStyles, Hidden } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import {
	MonumentHistory2,
	MonumentHistory3,
	MonumentHistory4,
	MonumentHistory5,
	MonumentHistory6,
	MonumentHistory1
} from '../History/components/Monument';
import { showFooter } from '../../../store/layout/actions';

const useStyles = makeStyles((theme) => ({
	overflow: {
		position: 'relative',
		overflow: 'visible',
		height: 'fit-content'
	},
	container: {
		minHeight: '100vh',
		['@media screen and (max-height: 750px) and (min-width: 1280px)' ]:{
			height: '125vh'
		}
	},
	
}));

const SCROLL_DEBOUNCE = 950;
let lastScrollTimestamp;

function MadefalvaHistoryAnimated(props) {
	const classes = useStyles();

	const [show, setShow] = useState({
		monumentHistory1: false,
		monumentHistory2: false,
		monumentHistory3: false,
		monumentHistory4: false,
		monumentHistory5: false,
		monumentHistory6: false
	});

	useEffect(() => {
		setShow({ ...show, monumentHistory1: true });
		showFooter(false);
		return () => {
			showFooter(true);
		}
	}, []);

	const isScrollVisible = () =>
		document.body.scrollHeight > document.body.clientHeight ||
		document.documentElement.scrollHeight >
			document.documentElement.clientHeight;

	const isScrolledTop = () => window.scrollY === 0;
	const getHomePageElementHeight = () => {
		if (!document) {
			return 0;
		}
		const homePageElement = document.getElementById('homepage');
		if (!homePageElement) {
			return document.body.scrollHeight;
		}

		return homePageElement.clientHeight;
	};

	const isScrolledBottom = () =>
		Math.round(window.scrollY) + document.body.scrollHeight >=
		getHomePageElementHeight();

	const next = () => {
		if (show.monumentHistory1) {
			setShow({
				monumentHistory1: false,
				monumentHistory2: true
			});
		}
		if (show.monumentHistory2) {
			setShow({
				monumentHistory2: false,
				monumentHistory3: true
			});
		}
		if (show.monumentHistory3) {
			setShow({
				monumentHistory3: false,
				monumentHistory4: true
			});
		}
		if (show.monumentHistory4) {
			setShow({
				monumentHistory4: false,
				monumentHistory5: true
			});
		}
		if (show.monumentHistory5) {
			setShow({
				monumentHistory5: false,
				monumentHistory6: true
			});
			showFooter(true);
		}
		if (show.monumentHistory6) {
		}
	};

	const previous = () => {
		if (show.monumentHistory2) {
			setShow({
				monumentHistory1: true,
				monumentHistory2: false
			});
		}
		if (show.monumentHistory3) {
			setShow({
				monumentHistory2: true,
				monumentHistory3: false,
				monumentHistory4: false
			});
		}
		if (show.monumentHistory4) {
			setShow({
				monumentHistory3: true,
				monumentHistory4: false
			});
		}
		if (show.monumentHistory5) {
			setShow({
				monumentHistory4: true,
				monumentHistory5: false
			});
		}
		if (show.monumentHistory6) {
			setShow({
				monumentHistory5: true,
				monumentHistory6: false
			});
			showFooter(false);
		}
		/*
if (isScrollVisible() && !isScrolledTop()) {
return;
}
setTimeout(() => {
onClick(active > 0 ? active - 1 : 0);
window.scrollTo(0, 0);
}, 100); */
	};

	const handleSwipe = (e, initialX, initialY) => {
		if (initialX === undefined || initialY === undefined) {
			return;
		}

		const currentX = e.touches[0].clientX;
		const currentY = e.touches[0].clientY;
		const diffX = initialX - currentX;
		const diffY = initialY - currentY;

		if (Math.abs(diffX) > Math.abs(diffY)) {
			return;
		}

		if (diffY > 0) {
			next(); // swipe up
		} else {
			previous(); // swipe down
		}
	};

	// tslint:disable-next-line: no-any
	const handleWheel = (event) => {
		event.stopPropagation();
		if (Date.now() - lastScrollTimestamp < SCROLL_DEBOUNCE) {
			return;
		}

		lastScrollTimestamp = Date.now();
		const delta = Math.sign(event.deltaY);
		if(show.monumentHistory6){
			if(window.scrollY == 0 && delta == -1){
				previous();
			}
		}
		else{
			if (delta === 1) {
				next();
			} else if (delta === -1) {
				previous();
			}
		}
	};

	// tslint:disable-next-line: no-any
	const handleKey = (e) => {
		switch (e.code) {
			case 'ArrowDown':
				next();
				break;
			case 'ArrowUp':
				previous();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		let initialX;
		let initialY;

		const startTouch = (e) => {
			initialX = e.touches[0].clientX;
			initialY = e.touches[0].clientY;
		};

		const moveTouch = (e) => {
			/* if (visible) {
	e.preventDefault();
	initialX = undefined;
	initialY = undefined;

	return;
} */

			handleSwipe(e, initialX, initialY);
			initialX = undefined;
			initialY = undefined;
		};

		window.addEventListener('wheel', handleWheel);
		window.addEventListener('keyup', handleKey);
		window.addEventListener('touchstart', startTouch, false);
		window.addEventListener('touchmove', moveTouch, { passive: false });

		return () => {
			window.removeEventListener('wheel', handleWheel);
			window.removeEventListener('keyup', handleKey);
			window.removeEventListener('touchstart', startTouch);
			window.removeEventListener('touchmove', moveTouch);
		};
	}, [show]);

	return (
		<Box className={classes.container}>
			<MonumentHistory1 showAnimation={show.monumentHistory1} />
			<MonumentHistory2 showAnimation={show.monumentHistory2} />
			<MonumentHistory3 showAnimation={show.monumentHistory3} />
			<MonumentHistory4 showAnimation={show.monumentHistory4} />
			<MonumentHistory5 showAnimation={show.monumentHistory5} />
			<MonumentHistory6
				showAnimation={show.monumentHistory6}
				prev={() => {
					previous();
				}}
			/>

			<Box className={classes.overflow} />
		</Box>
	);
}

export default withNamespaces('home')(MadefalvaHistoryAnimated);
