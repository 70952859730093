import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { HomeAnimated, HomeNotAnimated } from './components';

function Home(props) {
	return (
		<Box>
			<Hidden only={['xl', 'lg']}>
				<HomeNotAnimated />
			</Hidden>
			<Hidden only={['sm', 'xs', 'md']}>
				<HomeAnimated />
			</Hidden>
		</Box>
	);
}

export default Home;
