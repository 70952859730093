import React from 'react';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Navigation(props) {
	return (
		<svg width={props.width} height={props.height} viewBox='0 0 24 24'>
			<g>
				<path className='a' d='M0,0H24V24H0Z' fill='none' />
				<path
					className='b'
					fill={props.fill ? props.fill : '#070c35'}
					d='M12,2,4.5,20.29l.71.71L12,18l6.79,3,.71-.71Z'
				/>
			</g>
		</svg>
	);
}
