import { SNACKBAR_OPEN, SNACKBAR_CLEAR } from '../types';

const initialState = {
	vertical: 'bottom',
	horizontal: 'left',
	autoHideDuration: 5000,
	open: false
};

export default function snackbar(state = { ...initialState }, action) {
	switch (action.type) {
		case SNACKBAR_OPEN:
			return {
				...state,
				open: true,
				...action.payload
			};
		case SNACKBAR_CLEAR:
			return {
				...state,
				open: false
			};
		default:
			return state;
	}
}
