import store from '../../store';
import { GET_NEWS } from '../../types';
import { api } from '../../../lib/axios/api';
import { openSnackbar } from '../../snackbar/actions';

export const saveNews = async (data) => {
	await api
		.post('/news', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
		});
};

export const getNews = async (id) => {
	await api.get(`/news/${id}`).then((response) => {
		store.dispatch({
			type: GET_NEWS,
			payload: response.data
		});
	});
};
