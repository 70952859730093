import React, { useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
	Council,
	Organigram,
	SocialOffice,
	RegistryOffice,
	PublicInterest
} from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	categoryCardContainer: {
		height: 260,
		width: 264,
		background: '#FAFAF5 0% 0% no-repeat padding-box',
		cursor: 'pointer',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 30
	},
	title: {
		height: 'fit-content',
		minHeight: 40,
		width: 264,
		background: '#070C35 0% 0% no-repeat padding-box',
		marginLeft: '-12px',
		marginTop: 8,
		position: 'absolute'
	},
	cardTitle: {
		color: '#FCFCFA',
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		fontSize: '18px',
		fontHeight: '24px',
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 27,
		paddingRight: 27
	},
	iconContainer: {
		textAlign: 'center',

		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%'
	}
}));

function CategoryCard(props) {
	const classes = useStyles();

	const { type, t } = props;

	const history = useHistory();

	const navToPage = () => {
		history.push(`${type}?page=1&limit=10&category=${type}`);
		window.scrollTo(0, 0);
	};

	return (
		<Box className={classes.categoryCardContainer} onClick={navToPage}>
			<Box className={classes.title}>
				<Typography className={classes.cardTitle}>{t(type)}</Typography>
			</Box>
			<Box className={classes.iconContainer}>
				{type === 'pub_int' && <PublicInterest width={120} height={80} />}
				{type === 'localcouncil' && <Council width={120} height={80} />}
				{type === 'orgngrm' && <Organigram width={120} height={80} />}
				{type === 'soc_off' && <SocialOffice width={120} height={80} />}
				{type === 'registryoffice' && (
					<RegistryOffice width={120} height={80} />
				)}
				{type === 'decl_avr' && <PublicInterest width={120} height={80} />}
				{type === 'isaticf' && <PublicInterest width={120} height={80} />}

				{type === 'council_meetings' && (
					<PublicInterest width={120} height={80} />
				)}
				{type === 'decision_plans' && (
					<PublicInterest width={120} height={80} />
				)}
				{type === 'anticorruption_startegy' && (
					<PublicInterest width={120} height={80} />
				)}
			</Box>
		</Box>
	);
}
export default withNamespaces('council')(CategoryCard);
