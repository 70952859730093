import {
	Typography,
	Box,
	makeStyles,
	IconButton,
	withWidth,
	Hidden
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import {
	ChevronRight,
	Monument,
	TouchesLogo,
	HomeScreenHalfCircle,
	HalfCircleStripes,
	StraightStripes,
	DotIcon
} from '../../../../../components/icons';
import HomePicSm from '../../../../../components/images/IMG_2409.png';
import { showMenu } from '../../../../../store/layout/actions';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${HomePicSm})`,
		mixBlendMode: 'multiply',
		backgroundSize: 'cover',
		width: '100%',
		height: '100%'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		left: 274,
		width: 760,
		height: 816,
		[theme.breakpoints.only('md')]: {
			left: 'auto',
			right: 0
		},
		[theme.breakpoints.only('lg')]: {
			top: -100
		}
	},
	imageContainerFixedmdUp: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		right: 0,
		width: 500,
		height: 520,
		[theme.breakpoints.only('xs')]: {
			width: 300,
			height: 300,
			// TODO itt melyik legyen?
			// top: -100
			top: 150,
			opacity: 0.9
		},
		[theme.breakpoints.only('sm')]: {
			// width: 760,
			// height: 816,
			// right: -200,
			left: 'auto',
			right: 0,
			top: -60
		}
	},
	firstPageContainer: {
		top: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		height: 969,
		position: 'fixed',
		right: 0,
		width: 1128,
		left: 0,
		[theme.breakpoints.down('md')]: {
			width: '95%',
			position: 'absolute',
			marginBottom: 30
		}
	},
	titleBox: {
		zIndex: 101,
		top: 262,
		maxWidth: 530,
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		},
		[theme.breakpoints.only('lg')]: {
			top: 162
		}
	},
	titleContainer: {
		zIndex: 101,
		background: '#FFFFFF',
		position: 'absolute',
		top: 388,

		[theme.breakpoints.only('sm')]: {
			top: 365,
			left: 100
		},
		[theme.breakpoints.only('xs')]: {
			top: 200,
			left: 20
		},
		[theme.breakpoints.only('lg')]: {
			top: 288
		}
	},
	titleText: {
		fontFamily: 'Gilroy',
		fontWeight: 'bold',
		paddingLeft: 40,
		paddingBottom: 6,
		paddingTop: 8,
		paddingRight: 40,
		whiteSpace: 'nowrap',
		fontSize: 50,
		/* [theme.breakpoints.down("sm")]: {
            paddingLeft: 10,
            paddingBottom: 3,
            paddingTop: 4,
            paddingRight: 23,
            fontSize: 40,
        }, */
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 10,
			paddingRight: 23,
			fontSize: 25
		}
	},
	description: {
		fontWeight: 600,
		fontSize: 14,
		lineHeight: 24,
		color: theme.palette.primary.dark,
		paddingTop: 25,
		paddingBottom: 25
	},
	navigationIconButton: {
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF'
		},
		position: 'absolute',
		top: 60,
		transform: 'rotate(90deg)',
		[theme.breakpoints.only('lg')]: {
			top: -40
		}
	},
	arrowDown: {
		background: '#070c35 0% 0% no-repeat padding-box',
		opacity: 1
	},
	iconContainer: {
		zIndex: 100,
		position: 'absolute',

		top: 264,
		left: 330,

		[theme.breakpoints.down('sm')]: {
			left: 250
		},
		[theme.breakpoints.only('xs')]: {
			top: 105,
			left: 130
		},
		[theme.breakpoints.only('lg')]: {
			top: 164
		}
	},
	bottomcircles: {
		position: 'relative',

		width: 300,
		left: 171,
		top: 457,
		[theme.breakpoints.only('sm')]: {
			top: 420,
			left: 270
		},
		[theme.breakpoints.only('lg')]: {
			top: 357
		},
		[theme.breakpoints.only('xs')]: {
			width: 100,
			left: 80,
			top: 340
		}
	},
	halfStripes: {
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			left: 65,
			top: -12
		},
		[theme.breakpoints.only('xs')]: {
			top: -70,
			left: -30
		}
	},
	halfCircle: {
		position: 'relative',
		width: 192,
		height: 96,
		top: -147,
		left: 49,
		
		[theme.breakpoints.down('sm')]: {
			left: 120,
			top: -160

		},
		[theme.breakpoints.only('xs')]: {
			width: 70,
			height: 65,
			left: -15,
			top: -147
		},
		[theme.breakpoints.only('lg')]: {
			top: -147
		}
	},
	logo: {
		position: 'absolute',
		top: 65,
		[theme.breakpoints.only('md')]: {
			top: 120,
			left: 20
		},
		[theme.breakpoints.only('xs')]: {
			top: 0,
			left: 20
		},
		[theme.breakpoints.only('lg')]: {
			top: 40
		},
		[theme.breakpoints.only('sm')]: {
			left: 100
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: 640,
		display: 'inline-flex',
		[theme.breakpoints.only('xs')]: {
			top: 350
		},
		[theme.breakpoints.only('lg')]: {
			top: 540
		},
		[theme.breakpoints.only('sm')]: {
			top: 500
		}
	},
	descriptionTitle: {
		color: '#E60F37',
		fontFamily: 'Gilroy',
		whiteSpace: 'nowrap',
		fontWeight: 800,
		textTransform: 'uppercase',
		fontSize: 30,
		letterSpacing: '0.3px',
		[theme.breakpoints.only('xs')]: {
			fontSize: 25
		}
	},
	descriptionText: {
		width: 594,
		fontSize: '18px',
		lineHeight: '30px',
		color: '#282D38',
		fontFamily: 'Roboto',
		fontWeight: 500,
		letterSpacing: '0.18px',
		//paddingTop: 15,
		[theme.breakpoints.only('xs')]: {
			letterSpacing: '0.1px',
			width: 300,
			fontSize: '16px',
			zIndex: 100
		},
		[theme.breakpoints.up('sm')]: {
			width: 560
		},
		[theme.breakpoints.only('sm')]: {
			width: 400
		}
	},
	descriptionWrapper: {
		paddingTop: 15
	},
	navigateIconContainer: {
		position: 'relative',
		paddingRight: 64,
		float: 'left',
		width: 100,
		marginTop: 'auto',
		marginBottom: 20,
		[theme.breakpoints.only('lg')]: {
			position: 'absolute',
			top: 110
		}
	},
	descriptionBox: {
		position: 'absolute',
		width: '100%',
		left: 144,
		[theme.breakpoints.only('xs')]: {
			left: 10,
			width: 300
		},
		[theme.breakpoints.only('sm')]: {
			left: 40
		}
	},
	straightStripesContainer: {
		width: 400,
		position: 'absolute',
		top: 548,
		left: 728,

		[theme.breakpoints.only('xs')]: {
			width: 300,
			height: 200,
			left: 0,
			zIndex: 0,
			top: 370,
			opacity: 0.2
		},
		[theme.breakpoints.only('md')]: {
			top: 548,
			left: 'auto',
			right: 0,
			width: 300
		},
		[theme.breakpoints.only('lg')]: {
			top: 448
		},
		[theme.breakpoints.only('sm')]: {
			right: 0,
			left: 'auto',
			opacity: 0.5,
			top: 450,
			width: 300,
			height: 250
		}
	},
	dotIconContainer: {
		position: 'absolute',

		top: 278,
		left: 488,

		[theme.breakpoints.down('sm')]: {
			top: 150,
			left: 400
		},
		[theme.breakpoints.only('lg')]: {
			top: 178
		}
	}
}));

function MadefalvaMegerint(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);

	useEffect(() => {
		// window.scrollTo(0, 0);
		setShowAnimation(props.showAnimation);
		props.showAnimation ? showMenu(false) : showMenu(true);
		(props.width === 'sm' || props.width === 'xs' || props.width === 'md') &&
			showMenu(true);
	}, [props.showAnimation]);

	const goToNext = () => {
		props.width === 'sm' || props.width === 'xs' || props.width === 'md'
			? window.scrollTo(0, 900)
			: props.showNext();
	};

	const descriptions = t('megerint.description').split("<br>");
	return (
		<Box>
			<Box
				className={classes.firstPageContainer}
				style={{ zIndex: showAnimation ? 200 : 150 }}>
				<Hidden smDown>
					<CSSTransition
						in={showAnimation}
						timeout={600}
						classNames='bird'
						unmountOnExit>
						<Box className={classes.imageContainerFixed}>
							<Box className={classes.imageContainer} />
						</Box>
					</CSSTransition>
				</Hidden>
				<Hidden mdUp>
					<CSSTransition
						in={showAnimation}
						timeout={600}
						classNames='bird'
						unmountOnExit>
						<Box className={classes.imageContainerFixedmdUp}>
							<Box className={classes.imageContainer} />
						</Box>
					</CSSTransition>
				</Hidden>

				<CSSTransition
					in={showAnimation}
					timeout={600}
					classNames='stripes'
					unmountOnExit>
					<Box className={classes.straightStripesContainer}>
						<StraightStripes width='100%' height='100%' />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={0}
					classNames='description'
					unmountOnExit>
					<Hidden xsUp>
						<Box className={classes.dotIconContainer}>
							<DotIcon width='34px' height='34px' fill='#E0B080' />
						</Box>
					</Hidden>
				</CSSTransition>
				<Box className={classes.infoContainer}>
					<CSSTransition
						in={showAnimation}
						timeout={600}
						classNames='logo'
						unmountOnExit>
						<Box className={classes.logo}>
							<Hidden smDown>
								<TouchesLogo height='142px' fill='#070C35'/>
							</Hidden>
							<Hidden mdUp>
								<TouchesLogo height='80px' fill='#070C35'/>
							</Hidden>
						</Box>
					</CSSTransition>

					<CSSTransition
						in={showAnimation}
						timeout={600}
						classNames='maintitle'
						unmountOnExit>
						<Box className={classes.titleBox}>
							<Hidden only={['sm', 'xs']}>
								<Box className={classes.iconContainer}>
									<Monument fill='#48B3C9' height='126px' dotfill='none' />
								</Box>
							</Hidden>
							<Hidden only={['xl', 'lg', 'md']}>
								<Box className={classes.iconContainer}>
									<Monument fill='#48B3C9' height='100px' dotfill='none' />
								</Box>
							</Hidden>

							<Box className={classes.titleContainer}>
								<Typography variant='h1' className={classes.titleText}>
									{t('megerint.title')}
								</Typography>
							</Box>
							<Box className={classes.bottomcircles}>
								<Box className={classes.halfStripes}>
									<HalfCircleStripes width='100%' height='100%' />
								</Box>
								<Box className={classes.halfCircle}>
									<HomeScreenHalfCircle
										width='100%'
										height='100%'
										fill='#E60F37'
									/>
								</Box>
							</Box>
						</Box>
					</CSSTransition>

					<CSSTransition
						in={showAnimation}
						timeout={0}
						classNames='description'
						unmountOnExit>
						<Box className={classes.descriptionContainer}>
							<Hidden mdDown>
								<Box className={classes.navigateIconContainer}>
									<IconButton
										className={classes.navigationIconButton}
										onClick={goToNext}>
										<ChevronRight
											width='24px'
											height='24px'
											fill='#070C35'
											className={classes.arrowDown}
										/>
									</IconButton>
								</Box>
							</Hidden>
							<Box className={classes.descriptionBox}>
								<Typography className={classes.descriptionTitle}>
									{t('megerint.descriptiontitle')}
								</Typography>
								<Box className={classes.descriptionWrapper}>
								{
									descriptions.map(desc =>{
										return <Typography className={classes.descriptionText}>
													{desc}
												</Typography>
									})
								
								}
								</Box>
							</Box>
						</Box>
					</CSSTransition>
				</Box>
			</Box>
		</Box>
	);
}

export default withWidth()(withNamespaces('homeMadefalva')(MadefalvaMegerint));
