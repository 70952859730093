import React from 'react';
import { Container, makeStyles, Grid } from '@material-ui/core';
import { SightCard } from './components/SightCard';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 8
	}
}));

const Results = (props) => {
	const { list, lang } = props;
	const classes = useStyles();

	return (
		<Grid container className={classes.root}>
			{list.items.map((item) => (
				<SightCard item={item} lang={lang} />
			))}
		</Grid>
	);
};

export default Results;
