import store from '../store';
import { api } from '../../lib/axios/api';
import { GET_SIGHTS, GET_SIGHT } from '../types';
import { openSnackbar } from '../snackbar/actions';

export const getSights = (type, other) => {
	api
		.get(type ? `/sights?category=${type}&active=true` : `/sights${other}`)
		.then((response) => {
			store.dispatch({ type: GET_SIGHTS, payload: response.data });
		});
};

export const saveSight = (data) =>
	api
		.post('/sights', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
			getSights('?active=false');
			return true;
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
			return false;
		});

export const getSight = (id) => {
	api.get(`/sights/${id}`).then((response) => {
		store.dispatch({ type: GET_SIGHT, payload: response.data });
	});
};

export const updateSight = (id, data) => {
	api.put(`/sights/${id}`, data).then(() => getSights(null, '?active=false'));
};

export const deleteSight = (id) => {
	api.delete(`/sights/${id}`).then(() => getSights(null, '?active=false'));
};

export const activateSight = (id, status) => {
	api
		.patch(`/sights/${id}?active=${!!status}`)
		.then(() => getSights(null, '?active=false'));
};
