import store from '../store';
import { SNACKBAR_OPEN, SNACKBAR_CLEAR } from '../types';

export const openSnackbar = (data) => {
	store.dispatch({ type: SNACKBAR_OPEN, payload: data });
};

export const closeSnackbar = () => {
	store.dispatch({ type: SNACKBAR_CLEAR });
};
