import React, { useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { get2SightMobile } from '../../../../../store/home/actions';
import SightCardMobile from './SightCardMobile';
import { ChevronRight } from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	root: {
		boxSizing: 'border-box',
		width: '100vw',
		overflow: 'hidden',
		'& *': {
			boxSizing: 'border-box',
			MsOverflowStyle: 'none',
			scrollbarWidth: 'none',
		},
	},
	content: {
		overflowX: 'scroll',
		display: 'flex',
		flexDirection: 'row',
		columnGap: '20px',
		paddingLeft: '20px',
		paddingRight: '20px',
	},
	textContainer: {
		paddingLeft: 100,
		paddingBottom: 60,
		maxWidth: 552,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 24,
			paddingRight: 24,
		},
	},
	title: {
		fontFamily: 'Gilroy',
		fontWeight: 900,
		lineHeight: '34px',
		fontSize: 32,
		paddingBottom: 25,
	},
	description: {
		boxSizing: 'border-box',
		flexWrap: 'wrap',
		fontSize: 14,
		lineHeight: '24px',
		maxWidth: 530,
		fontWeight: 'bold',
		[theme.breakpoints.down('md')]: {
			display: 'block',
		},
	},
	totalText: {
		textTransform: 'uppercase',
		fontFamily: 'Bebas Neue',
		fontWeight: 'bold',
		letterSpacing: '1px',
		lineHeight: '15.6px',
		fontSize: '20px',
		paddingTop: 7,
	},
	totalButton: {
		margin: 'auto',
		width: 'fit-content',
		display: 'inline-flex',
		marginRight: 24,
		marginTop: 30,
		cursor: 'pointer',
	},
	totalButtonContainer: {
		float: 'right',
		width: '100%',
		display: 'flex',
	},
}));

const SightToHomeMobile = ({ t, category, style }) => {
	const list = useSelector((state) => state.home[`${category}Mobile`]);
	const lang = useSelector((state) => state.language.language);
	const history = useHistory();
	const classes = useStyles();

	useEffect(() => {
		get2SightMobile(category);
	}, [category]);

	const navigateToSight = () => {
		if (category) {
			window.scrollTo(0, 0);
			history.push(`/sights/${category}`);
		}
	};

	return (
		<Box className={classes.root} style={style}>
			<Box className={classes.textContainer}>
				<Typography className={classes.title}>
					{t(`title${category}`)}
				</Typography>
				<Typography className={classes.description}>
					{t(`description${category}`)}
				</Typography>
			</Box>
			<Box className={classes.content}>
				{list?.items?.length &&
					list.items.map((item, i) => (
						<SightCardMobile key={i.toString()} lang={lang} item={item} />
					))}
			</Box>
			<Box className={classes.totalButtonContainer}>
				<Box className={classes.totalButton} onClick={() => navigateToSight()}>
					<Typography className={classes.totalText}>{t('total')} </Typography>
					<ChevronRight fill='#070C35' width={24} height={24} />
				</Box>
			</Box>
		</Box>
	);
};

export default withNamespaces('sight')(SightToHomeMobile);
