import React, { useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';

import { useSelector } from 'react-redux';
import { MadefalvaMegerint1, MadefalvaMegerint, MadefalvaMegerint5 } from '.';
import SightToHome from '../../../Sights/components/Home/SightToHome';
import { SightCategories } from '../../../../../config/sight-categories.enum';
import { HotelToHome } from '../../../Hotel/components/Home';
import { MadefalvaHistory, MonumentHistory } from '../../../History/components';
import { showFooter } from '../../../../../store/layout/actions';

const SCROLL_DEBOUNCE = 950;
let lastScrollTimestamp;
const useStyles = makeStyles(() => ({
	slideContainer: {
		position: 'relative',
		height: '850px',
	},
	overflow: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		overflow: 'hidden',
	},
	container: {
		minHeight: '100vh',
		'@media screen and (max-height: 750px) and (min-width: 1280px)': {
			height: '125vh',
		},
	},
}));
function HomeContentWithScroll() {
	const classes = useStyles();
	const emptySections = useSelector((state) => state.home.emptySections);

	const [show, setShow] = useState({
		madefalvaMegerint: false,
		madefalvaMegerint1: false,
		madefalvaMegerint2: false,
		madefalvaMegerint3: false,
		madefalvaMegerint4: false,
		madefalvaMegerint5: false,
		madefalvaMegerint6: false,
		madefalvaMegerint7: false,
	});

	useEffect(() => {
		setShow({ ...show, madefalvaMegerint: true });
		showFooter(false);
		return () => {
			showFooter(true);
		};
	}, []);

	const isEmpty = (section) => emptySections.includes(section);

	const next = () => {
		if (show.madefalvaMegerint) {
			setShow({
				madefalvaMegerint: false,
				madefalvaMegerint1: true,
			});
		}
		if (show.madefalvaMegerint1) {
			if (!isEmpty('neigh')) {
				setShow({ madefalvaMegerint2: true, madefalvaMegerint1: false });
			} else if (!isEmpty('around')) {
				setShow({ madefalvaMegerint3: true, madefalvaMegerint1: false });
			} else if (!isEmpty('hotels')) {
				setShow({ madefalvaMegerint4: true, madefalvaMegerint1: false });
			} else {
				setShow({ madefalvaMegerint5: true, madefalvaMegerint1: false });
			}
		}
		if (show.madefalvaMegerint2) {
			if (!isEmpty('around')) {
				setShow({ madefalvaMegerint3: true, madefalvaMegerint2: false });
			} else if (!isEmpty('hotels')) {
				setShow({ madefalvaMegerint4: true, madefalvaMegerint2: false });
			} else {
				setShow({ madefalvaMegerint5: true, madefalvaMegerint2: false });
			}
		}
		if (show.madefalvaMegerint3) {
			if (!isEmpty('hotels')) {
				setShow({ madefalvaMegerint4: true, madefalvaMegerint3: false });
			} else {
				setShow({ madefalvaMegerint5: true, madefalvaMegerint3: false });
			}
		}
		if (show.madefalvaMegerint4) {
			setShow({
				madefalvaMegerint5: true,
				madefalvaMegerint4: false,
			});
		}
		if (show.madefalvaMegerint5) {
			setShow({
				madefalvaMegerint6: true,
				madefalvaMegerint5: false,
			});
		}
		if (show.madefalvaMegerint6) {
			setShow({
				madefalvaMegerint7: true,
				madefalvaMegerint6: false,
			});
			showFooter(true);
		}
		if (show.madefalvaMegerint7) {
		}
	};

	const previous = () => {
		if (show.madefalvaMegerint1) {
			setShow({
				madefalvaMegerint: true,
				madefalvaMegerint1: false,
			});
		}
		if (show.madefalvaMegerint2) {
			setShow({
				madefalvaMegerint1: true,
				madefalvaMegerint2: false,
			});
		}
		if (show.madefalvaMegerint3) {
			if (!isEmpty('neigh')) {
				setShow({ madefalvaMegerint2: true, madefalvaMegerint3: false });
			} else {
				setShow({ madefalvaMegerint1: true, madefalvaMegerint3: false });
			}
		}
		if (show.madefalvaMegerint4) {
			if (!isEmpty('around')) {
				setShow({ madefalvaMegerint3: true, madefalvaMegerint4: false });
			} else if (!isEmpty('neigh')) {
				setShow({ madefalvaMegerint2: true, madefalvaMegerint4: false });
			} else {
				setShow({ madefalvaMegerint1: true, madefalvaMegerint4: false });
			}
		}
		if (show.madefalvaMegerint5) {
			if (!isEmpty('hotels')) {
				setShow({ madefalvaMegerint4: true, madefalvaMegerint5: false });
			} else if (!isEmpty('around')) {
				setShow({ madefalvaMegerint3: true, madefalvaMegerint5: false });
			} else if (!isEmpty('neigh')) {
				setShow({ madefalvaMegerint2: true, madefalvaMegerint5: false });
			} else {
				setShow({ madefalvaMegerint1: true, madefalvaMegerint5: false });
			}
		}
		if (show.madefalvaMegerint6) {
			setShow({
				madefalvaMegerint5: true,
				madefalvaMegerint6: false,
			});
		}
		if (show.madefalvaMegerint7) {
			setShow({
				madefalvaMegerint6: true,
				madefalvaMegerint7: false,
			});
			showFooter(false);
		}
		/*
        if (isScrollVisible() && !isScrolledTop()) {
            return;
        }
        setTimeout(() => {
            onClick(active > 0 ? active - 1 : 0);
            window.scrollTo(0, 0);
        }, 100); */
	};

	const handleSwipe = (e, initialX, initialY) => {
		if (typeof initialX === 'undefined' || typeof initialY === 'undefined') {
			return;
		}

		const currentX = e.touches[0].clientX;
		const currentY = e.touches[0].clientY;
		const diffX = initialX - currentX;
		const diffY = initialY - currentY;

		if (Math.abs(diffX) > Math.abs(diffY)) {
			return;
		}

		if (diffY > 0) {
			next(); // swipe up
		} else {
			previous(); // swipe down
		}
	};

	// tslint:disable-next-line: no-any
	const handleWheel = (event) => {
		event.stopPropagation();
		if (Date.now() - lastScrollTimestamp < SCROLL_DEBOUNCE) {
			return;
		}

		lastScrollTimestamp = Date.now();
		const delta = Math.sign(event.deltaY);

		if (show.madefalvaMegerint7) {
			if (window.scrollY == 0 && delta == -1) {
				previous();
			}
		} else if (delta === 1) {
			next();
		} else if (delta === -1) {
			previous();
		}
	};

	// tslint:disable-next-line: no-any
	const handleKey = (e) => {
		switch (e.code) {
			case 'ArrowDown':
				next();
				break;
			case 'ArrowUp':
				previous();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		let initialX;
		let initialY;

		const startTouch = (e) => {
			initialX = e.touches[0].clientX;
			initialY = e.touches[0].clientY;
		};

		const moveTouch = (e) => {
			/* if (visible) {
                e.preventDefault();
                initialX = undefined;
                initialY = undefined;

                return;
            } */

			handleSwipe(e, initialX, initialY);
			initialX = undefined;
			initialY = undefined;
		};

		window.addEventListener('wheel', handleWheel);
		window.addEventListener('keyup', handleKey);
		window.addEventListener('touchstart', startTouch, false);
		window.addEventListener('touchmove', moveTouch, { passive: false });

		return () => {
			window.removeEventListener('wheel', handleWheel);
			window.removeEventListener('keyup', handleKey);
			window.removeEventListener('touchstart', startTouch);
			window.removeEventListener('touchmove', moveTouch);
		};
	}, [show]);

	return (
		<Box className={classes.container}>
			<MadefalvaMegerint
				showAnimation={show.madefalvaMegerint}
				showNext={() => {
					setShow({
						...show,
						madefalvaMegerint: false,
						madefalvaMegerint1: true,
					});
				}}
			/>
			<MadefalvaMegerint1 showAnimation={show.madefalvaMegerint1} />
			<SightToHome
				category={SightCategories.neigh}
				showAnimation={show.madefalvaMegerint2}
			/>
			<SightToHome
				category={SightCategories.around}
				showAnimation={show.madefalvaMegerint3}
			/>

			<HotelToHome showAnimation={show.madefalvaMegerint4} />

			<MadefalvaMegerint5 showAnimation={show.madefalvaMegerint5} />

			<MadefalvaHistory showAnimation={show.madefalvaMegerint6} />

			<MonumentHistory showAnimation={show.madefalvaMegerint7} />
			<Box className={classes.overflow} id='homepage' />
		</Box>
	);
}

export default withNamespaces('home')(HomeContentWithScroll);
