import { GET_ME, LOG_OUT } from '../types';

const initialState = { role: '' };

export default function me(state = { ...initialState }, action) {
	switch (action.type) {
		case GET_ME:
			return { ...state, ...action.payload };
		case LOG_OUT:
			return { ...initialState };
		default:
			return state;
	}
}
