import React from 'react';
import { Icon, withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

function Village2(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='100%'
			height={
				props.width === 'md' || props.width === 'sm' || props.width === 'xs'
					? 275
					: 375
			}
			viewBox={
				props.width === 'sm' || props.width === 'xs'
					? '500 0 1000 276'
					: props.width === 'md'
					? '0 0 1921.414 276'
					: '0 0 1921.414 376'
			}>
			<g transform='translate(-923.341 -203.782)'>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M952.347,322.455s75-57.427,166.077,5.654'
					transform='translate(41.515 137.94)'
				/>
				<g transform='translate(1073.495 463.909)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
				</g>
				<g transform='translate(995.801 477.767)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
				</g>
				<g transform='translate(1375.225 499.151)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
				</g>
				<g transform='translate(1460.464 423.63)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
				</g>
				<g transform='translate(924.048 505.898)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1073.186,334.533a8.578,8.578,0,0,0-10.179,1.454l-.012.012a12.8,12.8,0,0,1-18.081,0l-.012-.012a8.573,8.573,0,0,0-12.113,0l-.012.012a12.794,12.794,0,0,1-18.078,0l-.012-.012a8.573,8.573,0,0,0-12.113,0l-.012.012a12.794,12.794,0,0,1-18.078,0l-.015-.012a8.57,8.57,0,0,0-12.111,0l-.015.012a12.794,12.794,0,0,1-18.078,0l-.012-.012a8.573,8.573,0,0,0-12.113,0l-.012.012a12.794,12.794,0,0,1-18.078,0h0'
						transform='translate(-924.048 -316.8)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1079.432,335.447a12.8,12.8,0,0,1-4.3-2.833l-.012-.015a8.577,8.577,0,0,0-12.113,0l-.012.015a12.8,12.8,0,0,1-18.081,0l-.012-.015a8.577,8.577,0,0,0-12.113,0l-.012.015a12.8,12.8,0,0,1-18.078,0l-.012-.015a8.577,8.577,0,0,0-12.113,0l-.012.015a12.8,12.8,0,0,1-18.078,0l-.015-.015a8.574,8.574,0,0,0-12.111,0l-.015.015a12.8,12.8,0,0,1-18.078,0l-.012-.015a8.577,8.577,0,0,0-12.113,0l-.012.015a12.8,12.8,0,0,1-18.078,0'
						transform='translate(-924.048 -321.755)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1093.211,329.228a12.794,12.794,0,0,1-18.078,0l-.012-.012a8.573,8.573,0,0,0-12.113,0l-.012.012a12.8,12.8,0,0,1-18.081,0l-.012-.012a8.573,8.573,0,0,0-12.113,0l-.012.012a12.794,12.794,0,0,1-18.078,0l-.012-.012a8.573,8.573,0,0,0-12.113,0l-.012.012a12.794,12.794,0,0,1-18.078,0l-.015-.012a8.57,8.57,0,0,0-12.111,0l-.015.012a12.794,12.794,0,0,1-18.078,0l-.012-.012a8.573,8.573,0,0,0-12.113,0l-.012.012a12.794,12.794,0,0,1-18.078,0h0'
						transform='translate(-924.048 -326.71)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1241.93,215.953h-.015c0-.052.015-.1.015-.15a7.7,7.7,0,0,0-15.261-1.493,14.889,14.889,0,1,0-2.447,26.982,18.866,18.866,0,1,0,17.708-25.338Z'
					transform='translate(411.307 5.597)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1430.753,225.065a26.839,26.839,0,0,0-8.837,1.515,34.018,34.018,0,0,0-64.256,1.109,10.709,10.709,0,0,0-1.5-.153,10.826,10.826,0,1,0,0,21.653,10.54,10.54,0,0,0,1.562-.158,34.023,34.023,0,0,0,32.266,23.229h.027c0,.094-.027.18-.027.276a13.892,13.892,0,0,0,27.517,2.688,26.837,26.837,0,1,0,13.245-50.159Z'
					transform='translate(618.012 0)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					x2='154.687'
					transform='translate(2033.395 479.773)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					x2='21.678'
					transform='translate(1868.626 505.441)'
				/>
				<line
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					x2='181.695'
					transform='translate(1462.225 494.913)'
				/>
				<g transform='translate(1361.245 545.091)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='16.219'
						transform='translate(16.219)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='16.219'
						transform='translate(0 11.191)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='16.219'
						transform='translate(23.62 6.264)'
					/>
				</g>
				<g transform='translate(1924.092 531.221)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='17.755'
						transform='translate(92.508 1.795)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='22.689'
						transform='translate(52.55 12.986)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='17.755'
						transform='translate(102.862 8.059)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='22.689'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='22.689'
						transform='translate(10.354 6.264)'
					/>
				</g>
				<g transform='translate(1508.327 519.872)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='17.755'
						transform='translate(92.508 1.795)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='22.689'
						transform='translate(52.55 12.986)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='17.755'
						transform='translate(102.862 8.059)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='22.689'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='22.689'
						transform='translate(10.354 6.264)'
					/>
				</g>
				<g transform='translate(1739.734 490.055)'>
					<g transform='translate(0 15.386)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='53.668'
						/>
					</g>
					<g transform='translate(4.934 7.693)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='43.8'
						/>
					</g>
					<g transform='translate(9.868)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='33.932'
						/>
					</g>
				</g>
				<g transform='translate(982.056 543.837)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='16.219'
						transform='translate(16.219 0)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='16.219'
						transform='translate(0 11.191)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='16.219'
						transform='translate(23.62 6.264)'
					/>
				</g>
				<g transform='translate(2366.625 543.211)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='16.219'
						transform='translate(16.219 0)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='16.219'
						transform='translate(0 11.191)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='16.219'
						transform='translate(23.62 6.264)'
					/>
				</g>
				<g transform='translate(1282.268 268.671)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1069.252,236.952s23.794,36.314,83.812,10.68'
						transform='translate(-1069.252 -220.855)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1072.043,235.472s15.735,26.4,75.752.761'
						transform='translate(-1065.157 -223.021)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1074.674,238.068s14.365,22.678,72.385-7.649'
						transform='translate(-1061.298 -230.418)'
					/>
				</g>
				<g transform='translate(1880.708 258.932)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1396.432,233s-23.8,36.316-83.814,10.682'
						transform='translate(-1310.671 -216.902)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1388.849,231.519s-15.735,26.4-75.755.761'
						transform='translate(-1309.973 -219.068)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1384.211,234.115s-14.363,22.678-72.382-7.65'
						transform='translate(-1311.829 -226.465)'
					/>
				</g>
				<g transform='translate(975.083 281.869)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1005.092,240.437s-16.975,25.907-59.793,7.62'
						transform='translate(-943.907 -228.952)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M999.683,239.381s-11.225,18.829-54.045.542'
						transform='translate(-943.41 -230.497)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M996.377,241.232s-10.248,16.181-51.642-5.457'
						transform='translate(-944.735 -235.775)'
					/>
				</g>
				<g transform='translate(2410.306 464.356)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1550.009,319.619l.02,0c0-.067-.02-.126-.02-.192a9.593,9.593,0,0,1,19-1.855,18.539,18.539,0,1,1,3.047,33.594,23.492,23.492,0,1,1-22.05-31.549Z'
						transform='translate(-1526.501 -309.848)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1547.441,337.932a11.548,11.548,0,1,0,0-23.1'
						transform='translate(-1495.782 -302.547)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1545.646,316.649a16.492,16.492,0,0,0,.2,32.983'
						transform='translate(-1522.34 -299.894)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='16.08'
						transform='translate(28.148 62.047)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='11.175'
						transform='translate(17.843 62.024)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='25.809'
						transform='translate(45.466 50.228)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1383.895,387.288c-42.074-44.2-110.776-88.717-195.515-88.262-80.7.436-161.466,44.064-203.541,88.262'
					transform='translate(89.181 138.664)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1221.271,356.757c-40.767-30.322-94.437-54.34-156.791-54'
					transform='translate(206.015 144.12)'
				/>
				<g transform='translate(1142.612 461.922)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='35.603'
						y2='35.554'
						transform='translate(28.37)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='32.428'
						y2='32.384'
						transform='translate(21.041 3.171)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='29.135'
						y2='29.095'
						transform='translate(13.832 6.459)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='25.58'
						y2='25.545'
						transform='translate(6.883 10.009)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='21.961'
						y2='21.93'
						transform='translate(0 13.624)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1430.429,336.966c42.077-44.195,111.317-64.116,174.568-64.116,65.583,0,131.2,29.1,176.071,63.719'
					transform='translate(742.866 100.357)'
				/>
				<g transform='translate(2418.84 399.863)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='33.175'
						y2='33.129'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='30.464'
						y2='30.422'
						transform='translate(10.503 2.707)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='26.979'
						y2='26.942'
						transform='translate(21.006 6.187)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='22.636'
						y2='22.605'
						transform='translate(31.509 10.524)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='20.358'
						y2='20.33'
						transform='translate(42.012 12.799)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1455.721,299.066c35.483-15.77,76.238-23.163,114.771-23.163'
					transform='translate(779.97 104.826)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1595.447,315.972c35.483-15.772,76.238-23.165,114.77-23.165'
					transform='translate(984.949 129.567)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1343.194,273.906s71.005-40.719,158.417-10.475'
					transform='translate(614.891 70.214)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1247.02,319.613c20.4-22.505,54.723-51.928,101.922-63.487,52.054-12.747,113.374-4.5,153.342,35.71'
					transform='translate(473.803 67.785)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1255.462,308.676c20.878-20.547,51.866-43.569,92.308-53.473'
					transform='translate(486.188 74.529)'
				/>
				<g transform='translate(2073.325 333.939)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
				</g>
				<g transform='translate(1969.214 329.191)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='13.363'
						y2='13.344'
						transform='translate(30.716)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='13.38'
						y2='13.362'
						transform='translate(22.561 2.345)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='14.192'
						y2='14.172'
						transform='translate(14.385 4.669)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='14.291'
						y2='14.271'
						transform='translate(6.874 7.657)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='14.382'
						y2='14.363'
						transform='translate(0 11.281)'
					/>
				</g>
				<g transform='translate(1132.784 402.992)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1055.066,294.3a9.311,9.311,0,0,0-3.955.884,11.848,11.848,0,0,0-19.526-5.171,12.743,12.743,0,1,0-.8,16.294,11.819,11.819,0,0,0,16.023,1.774,9.367,9.367,0,1,0,8.26-13.781Z'
						transform='translate(-1008.659 -284.94)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='3.248'
						y2='27.091'
						transform='translate(12.026 27.04)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='1.219'
						y2='27.088'
						transform='translate(42.616 27.982)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='34.147'
						transform='translate(29.594 27.207)'
					/>
				</g>
				<g transform='translate(1517.909 426.446)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1211.176,303.819a9.3,9.3,0,0,0-3.954.882,11.848,11.848,0,0,0-19.526-5.171,12.741,12.741,0,1,0-.8,16.3,11.827,11.827,0,0,0,16.023,1.774,9.367,9.367,0,1,0,8.259-13.781Z'
						transform='translate(-1164.769 -294.46)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='3.248'
						y2='27.091'
						transform='translate(12.026 27.04)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='1.219'
						y2='27.088'
						transform='translate(42.615 27.982)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='34.147'
						transform='translate(29.594 27.208)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1095.11,227.172a12.123,12.123,0,0,0-1.621.163,17.516,17.516,0,0,0-29.079-4.228,12.385,12.385,0,1,0,0,23.1,17.525,17.525,0,0,0,22.978,2.964,12.369,12.369,0,1,0,7.722-22Z'
					transform='translate(181.296 18.824)'
				/>
				<g transform='translate(1023.249 332.094)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='56.586'
						transform='translate(22.081 46.34)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M977.25,256.162s9.9,36.5,0,46.341c-8.139-5.762-6.7-30.495-6.7-30.495'
						transform='translate(-955.168 -256.162)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M995.513,282.218s.723,16.206-9.172,26.05c-17.755-7.563-22.083-40.216-22.083-40.216'
						transform='translate(-964.259 -238.76)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M967.865,268.734s5.25,18.921,2.746,29.748'
						transform='translate(-958.969 -237.761)'
					/>
				</g>
				<g transform='translate(1393.207 321.025)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='47.921'
						transform='translate(18.702 39.245)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1125.223,251.669s8.378,30.909,0,39.245c-6.893-4.88-5.669-25.824-5.669-25.824'
						transform='translate(-1106.521 -251.669)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1140.692,273.734s.609,13.725-7.769,22.062c-15.036-6.405-18.7-34.057-18.7-34.057'
						transform='translate(-1114.221 -236.93)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1117.275,262.316s4.448,16.023,2.326,25.193'
						transform='translate(-1109.74 -236.086)'
					/>
				</g>
				<g transform='translate(1100.967 518.203)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M998.762,335.018'
						transform='translate(-991.361 -326.856)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='15.128'
						y2='13.002'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='15.128'
						y2='13.002'
						transform='translate(7.727)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='15.128'
						y2='13.002'
						transform='translate(15.454)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='15.128'
						y2='13.002'
						transform='translate(23.18)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='15.128'
						y2='13.002'
						transform='translate(30.907)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='15.128'
						y2='13.002'
						transform='translate(38.634)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='15.128'
						y2='13.002'
						transform='translate(46.361)'
					/>
				</g>
				<g transform='translate(1228.097 333.124)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1047.294,290.854S1061.5,303.6,1067.469,303.6s20.175-12.747,20.175-12.747'
						transform='translate(-1047.294 -206.416)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1048.226,287.812s12.587,11.3,17.876,11.3,17.876-11.3,17.876-11.3'
						transform='translate(-1045.927 -210.868)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1049.158,284.77s10.969,9.842,15.577,9.842,15.577-9.842,15.577-9.842'
						transform='translate(-1044.559 -215.32)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1050.091,281.729s9.348,8.386,13.275,8.386,13.275-8.386,13.275-8.386'
						transform='translate(-1043.191 -219.771)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1051.177,278.784s7.46,6.7,10.6,6.7,10.6-6.7,10.6-6.7'
						transform='translate(-1041.597 -224.082)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1051.957,275.646s6.106,5.479,8.672,5.479,8.672-5.479,8.672-5.479'
						transform='translate(-1040.453 -228.674)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1052.817,272.558s4.613,4.139,6.55,4.139,6.55-4.139,6.55-4.139'
						transform='translate(-1039.191 -233.194)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1053.822,269.563s2.866,2.572,4.071,2.572,4.07-2.572,4.07-2.572'
						transform='translate(-1037.717 -237.578)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1054.755,266.521s1.246,1.118,1.769,1.118,1.769-1.118,1.769-1.118'
						transform='translate(-1036.348 -242.03)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='118.324'
						transform='translate(20.175)'
					/>
				</g>
				<g transform='translate(1839.385 377.31)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1295.079,292.271s13.721,8.389,19.485,8.389,19.487-8.389,19.487-8.389'
						transform='translate(-1295.079 -248.528)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1296.673,289.327s10.951,6.694,15.552,6.694,15.552-6.694,15.552-6.694'
						transform='translate(-1292.741 -252.837)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1297.818,286.189s8.963,5.479,12.728,5.479,12.729-5.479,12.729-5.479'
						transform='translate(-1291.061 -257.43)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1299.08,283.1s6.77,4.139,9.614,4.139,9.614-4.139,9.614-4.139'
						transform='translate(-1289.209 -261.949)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1300.555,280.1s4.208,2.572,5.975,2.572,5.975-2.572,5.975-2.572'
						transform='translate(-1287.046 -266.334)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1301.925,277.064a3.573,3.573,0,0,0,5.193,0'
						transform='translate(-1285.036 -270.785)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='63.172'
						transform='translate(19.485)'
					/>
				</g>
				<g transform='translate(937.822 455.126)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='4.934'
						y2='28.035'
						transform='translate(3.825 12.318)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='1.539'
						y2='40.353'
						transform='translate(11.083)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='32.962'
						transform='translate(16.485 7.391)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='3.083'
						y2='15.423'
						transform='translate(20.905 24.93)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='4.957'
						y2='13.064'
						transform='translate(0 27.288)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1512.291,319.46c35.984-13.037,77.074-17.664,114.677-17.664,80.706,0,157.575,37.235,199.65,81.432'
					transform='translate(862.958 142.723)'
				/>
				<g transform='translate(2366.625 499.151)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
				</g>
				<g transform='translate(2567.659 537.504)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
				</g>
				<g transform='translate(2130.014 456.149)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
				</g>
				<g transform='translate(1865.142 438.698)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1639.8,237.726h.01c.005-.052-.01-.1-.01-.153a7.706,7.706,0,0,1,15.263-1.49,14.89,14.89,0,1,1,2.445,26.979,18.865,18.865,0,1,1-17.708-25.336Z'
					transform='translate(1022.323 37.464)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1640.2,248.777s16.976,25.907,59.8,7.62'
					transform='translate(1050.602 65.124)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1642.192,247.721s11.225,18.829,54.042.542'
					transform='translate(1053.525 63.578)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1644.069,249.574s10.248,16.179,51.64-5.459'
					transform='translate(1056.278 58.3)'
				/>
				<g transform='translate(2557.01 464.347)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='33.175'
						y2='33.129'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='30.464'
						y2='30.422'
						transform='translate(10.503 2.707)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='26.979'
						y2='26.942'
						transform='translate(21.006 6.187)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='22.636'
						y2='22.605'
						transform='translate(31.509 10.524)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='20.358'
						y2='20.33'
						transform='translate(42.012 12.799)'
					/>
				</g>
				<g transform='translate(2609.202 329.266)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='56.586'
						transform='translate(9.185 46.339)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1613.462,255.014s-9.9,36.494,0,46.338c8.136-5.76,6.693-30.492,6.693-30.492'
						transform='translate(-1604.276 -255.014)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1607.135,281.07s-.72,16.206,9.175,26.05c17.752-7.563,22.08-40.216,22.08-40.216'
						transform='translate(-1607.123 -237.612)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1618.226,267.586s-5.25,18.921-2.746,29.748'
						transform='translate(-1595.856 -236.613)'
					/>
				</g>
				<g transform='translate(2529.201 380.189)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='43.612'
						transform='translate(22.203 25.943)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1589.088,275.684s4.236,18.356-3.39,25.942c-6.271-4.439-1.865-19.761-1.865-19.761'
						transform='translate(-1563.495 -275.684)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1611.846,294.019s-5.307,14.459-18.032,17.859c-13.682-5.831-17.017-31-17.017-31'
						transform='translate(-1571.611 -268.078)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1621.339,299.224s-10.3,14.395-24.441,14.395c-17.017-2.473-22.2-25.21-22.2-25.21'
						transform='translate(-1574.695 -257.059)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1579.576,281.407s4.046,14.582,2.114,22.926'
						transform='translate(-1567.534 -267.308)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1589.995,284.645s-4.887,5.913-.1,13.528'
						transform='translate(-1555.479 -262.568)'
					/>
				</g>
				<g transform='translate(2204.33 412.322)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1483.362,323s-14.207,12.747-20.175,12.747S1443.009,323,1443.009,323'
						transform='translate(-1443.009 -238.563)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1479.7,319.959s-12.589,11.3-17.876,11.3-17.878-11.3-17.878-11.3'
						transform='translate(-1441.642 -243.015)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1476.025,316.917s-10.968,9.842-15.574,9.842-15.576-9.842-15.576-9.842'
						transform='translate(-1440.273 -247.468)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1472.354,313.876s-9.347,8.386-13.273,8.386-13.275-8.386-13.275-8.386'
						transform='translate(-1438.904 -251.919)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1468.082,310.931s-7.46,6.7-10.594,6.7-10.6-6.7-10.6-6.7'
						transform='translate(-1437.311 -256.229)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1465.013,307.793s-6.106,5.479-8.669,5.479-8.671-5.479-8.671-5.479'
						transform='translate(-1436.167 -260.822)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1461.63,304.706s-4.611,4.136-6.548,4.136-6.55-4.136-6.55-4.136'
						transform='translate(-1434.905 -265.34)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1457.677,301.71s-2.864,2.572-4.068,2.572-4.071-2.572-4.071-2.572'
						transform='translate(-1433.431 -269.725)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1454.009,298.668s-1.246,1.119-1.769,1.119-1.769-1.119-1.769-1.119'
						transform='translate(-1432.062 -274.177)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='118.324'
						transform='translate(20.177)'
					/>
				</g>
				<g transform='translate(2135.306 378.404)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1450.784,301.074s-12.589,11.3-17.878,11.3-17.876-11.3-17.876-11.3'
						transform='translate(-1415.03 -236.738)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1447.114,298.033s-10.968,9.842-15.577,9.842-15.574-9.842-15.574-9.842'
						transform='translate(-1413.661 -241.189)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1443.443,294.992s-9.347,8.386-13.275,8.386-13.272-8.386-13.272-8.386'
						transform='translate(-1412.292 -245.64)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1439.171,292.047s-7.46,6.694-10.6,6.694-10.593-6.694-10.593-6.694'
						transform='translate(-1410.699 -249.95)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1436.1,288.909s-6.106,5.479-8.672,5.479-8.671-5.479-8.671-5.479'
						transform='translate(-1409.557 -254.543)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1432.721,285.821s-4.611,4.139-6.55,4.139-6.55-4.139-6.55-4.139'
						transform='translate(-1408.295 -259.062)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1428.767,282.825s-2.867,2.572-4.071,2.572-4.071-2.572-4.071-2.572'
						transform='translate(-1406.82 -263.447)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1425.1,279.784s-1.246,1.119-1.769,1.119-1.769-1.119-1.769-1.119'
						transform='translate(-1405.452 -267.898)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='86.093'
						transform='translate(17.876)'
					/>
				</g>
				<g transform='translate(1792.325 232.801)'>
					<path
						className='b'
						fill='#e60f37'
						d='M1307.08,277.926A31.034,31.034,0,1,0,1276,246.892,31.091,31.091,0,0,0,1307.08,277.926Z'
						transform='translate(-1276.003 -215.858)'
					/>
				</g>
				<g transform='translate(2678.223 463.909)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
				</g>
				<g transform='translate(2740.174 483.029)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
				</g>
				<g transform='translate(2670.385 506.07)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1640.041,334.6a8.578,8.578,0,0,1,10.179,1.451l.012.012a12.794,12.794,0,0,0,18.078,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.015.012a12.794,12.794,0,0,0,18.078,0l.013-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.794,12.794,0,0,0,18.078,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.8,12.8,0,0,0,18.081,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.794,12.794,0,0,0,18.078,0h0'
						transform='translate(-1620.016 -316.871)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1637.508,335.516a12.745,12.745,0,0,0,4.3-2.833l.012-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.794,12.794,0,0,0,18.078,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.015.012a12.794,12.794,0,0,0,18.078,0l.013-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.794,12.794,0,0,0,18.078,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.8,12.8,0,0,0,18.081,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.794,12.794,0,0,0,18.078,0'
						transform='translate(-1623.732 -321.826)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1631.924,329.3a12.794,12.794,0,0,0,18.078,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.794,12.794,0,0,0,18.078,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.015.012a12.794,12.794,0,0,0,18.078,0l.013-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.794,12.794,0,0,0,18.078,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.8,12.8,0,0,0,18.081,0l.012-.012a8.573,8.573,0,0,1,12.113,0l.012.012a12.794,12.794,0,0,0,18.078,0h0'
						transform='translate(-1631.924 -326.78)'
					/>
				</g>
				<g transform='translate(2713.133 450.023)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='37.889'
						transform='translate(10.194 2.464)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='40.353'
						transform='translate(6.33)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='37.889'
						transform='translate(0 2.464)'
					/>
				</g>
				<g transform='translate(2777.564 401.172)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1680.17,370.189s-14.408-37.385,12.547-85.988'
						transform='translate(-1673.967 -284.201)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1683.445,357.419s-12.034-27.637,6.93-64.493'
						transform='translate(-1669.044 -271.431)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1686.986,349.986s-11.644-16.718,3.893-51.982'
						transform='translate(-1664.471 -263.999)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='0.88'
						y2='8.223'
						transform='translate(11.15 5.571)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='0.591'
						y2='5.528'
						transform='translate(15.831 25.772)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='1.698'
						y2='15.873'
						transform='translate(5.952 9.899)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='4.462'
						y2='19.613'
						transform='translate(0 18.791)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y1='2.5'
						x2='3.947'
						transform='translate(13.437 8.071)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y1='2.062'
						x2='5.347'
						transform='translate(15.652 32.975)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='0.88'
						y2='8.223'
						transform='translate(19.435 41.817)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y1='2.062'
						x2='5.347'
						transform='translate(24.221 37.373)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y1='5.34'
						x2='6.666'
						transform='translate(9.318 15.426)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1567.183,265.28s36.418-11.99,74.731,24.112'
					transform='translate(943.486 86.482)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1461.258,302.3s36.418-11.99,74.731,24.109'
					transform='translate(788.093 140.668)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M984.278,282.6s60.148-30.687,89.742,1.621'
					transform='translate(88.358 95.346)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1708.834,281.6s-36.418-11.99-74.728,24.109'
					transform='translate(1041.662 110.366)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1159.387,282.241s-36.418-11.99-74.731,24.109'
					transform='translate(235.614 111.306)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1013.006,305.049s-28.588-25.523-78.152-7.711'
					transform='translate(15.853 126.955)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1489.038,268.867c0-12.4-14.486-22.453-32.355-22.453a46.3,46.3,0,0,0-6.952.532c.012-.18.055-.35.055-.532a8.064,8.064,0,0,0-15.6-2.863,8,8,0,0,0-12.135,8.881c-.109,0-.21-.032-.318-.032a8.065,8.065,0,1,0,2.66,15.646c-.015.273-.059.542-.059.82,0,12.4,14.486,22.453,32.355,22.453a45.446,45.446,0,0,0,10.075-1.126,7.987,7.987,0,0,0,14.079-6.425,26.254,26.254,0,0,0,2-1.725,8.057,8.057,0,1,0,6.2-13.178Z'
					transform='translate(718.257 49.86)'
				/>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M968.092,241.546c0,12.4,14.486,22.453,32.355,22.453a45.973,45.973,0,0,0,6.952-.535c-.012.18-.054.352-.054.535a8.064,8.064,0,0,0,15.6,2.863,8,8,0,0,0,12.135-8.881c.109,0,.21.032.318.032a8.065,8.065,0,1,0-2.659-15.646c.015-.276.059-.544.059-.82,0-12.4-14.484-22.453-32.355-22.453a45.439,45.439,0,0,0-10.075,1.123,7.989,7.989,0,0,0-14.079,6.428,26.235,26.235,0,0,0-2,1.725,8.056,8.056,0,1,0-6.2,13.178Z'
					transform='translate(52.768 19.128)'
				/>
				<g transform='translate(1345.036 267.625)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1272.86,247.32s-81.586-35.745-178.165,39.327'
						transform='translate(-1094.695 -217.083)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1142.1,247.746a239.418,239.418,0,0,0-41.742,24.033'
						transform='translate(-1086.393 -204.011)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1152.4,251.618s34.935-20.354,48.047-21.478c29.974-2.574,116.613,29.662,116.613,29.662s23.72-17.492,33.016-17.134c32.207,1.237,102.864,37.619,102.864,37.619'
						transform='translate(-1010.04 -229.994)'
					/>
				</g>
				<g transform='translate(2240.514 300.263)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1630.1,268.882s81.589-35.745,178.165,39.327'
						transform='translate(-1204.73 -218.162)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1897.852,281.195s-96.023-36.506-113.013-37.787c-38.838-2.932-151.1,33.8-151.1,33.8s-30.734-19.928-42.778-19.522c-41.734,1.409-133.285,42.862-133.285,42.862'
						transform='translate(-1457.676 -243.241)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1011.29,301.6s81.991-53.97,113.068-52.118c31.876,1.9,108.778,66.867,108.778,66.867'
					transform='translate(127.985 66.084)'
				/>
				<g transform='translate(1430.59 450.951)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(8.222)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(16.444)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(24.666)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(32.888)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(41.11)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(49.331)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(57.553)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(65.775)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(73.997)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='8.222'
						y2='15.016'
						transform='translate(82.219)'
					/>
				</g>
				<g transform='translate(1582.257 458.245)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(82.219)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(73.997)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(65.775)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(57.553)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(49.331)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(41.109)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(32.888)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(24.666)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(16.444)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
						transform='translate(8.222)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.222'
						y2='24.068'
					/>
				</g>
				<g transform='translate(2033.395 474.915)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='154.687'
						transform='translate(0 30.526)'
					/>
					<g transform='translate(4.903)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(8.878)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(17.755)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(26.633)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(35.511)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(44.388)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(53.266)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(62.144)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(71.021)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(79.899)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(88.776)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(97.654)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(106.532)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(115.409)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(124.287)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(133.165)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y2='35.07'
							transform='translate(142.042)'
						/>
					</g>
				</g>
				<g transform='translate(1866.159 474.915)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='24.145'
						transform='translate(0 4.859)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='35.07'
						transform='translate(3.195)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='35.07'
						transform='translate(12.073)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='35.07'
						transform='translate(20.95)'
					/>
				</g>
				<g transform='translate(1886.349 391.076)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1451.646,306.3V361.93H1317.559V313.692'
						transform='translate(-1309.064 -241.759)'
					/>
					<g transform='translate(35.826 78.377)'>
						<rect
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							width='26.567'
							height='19.934'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='23.742'
							transform='translate(1.413 9.967)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y1='18.805'
							transform='translate(13.284 0.826)'
						/>
						<g transform='translate(51.639)'>
							<rect
								className='a'
								fill='none'
								stroke='#070c35'
								strokeLinecap='round'
								strokeMiterlimit='10'
								width='26.567'
								height='19.934'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeLinecap='round'
								strokeMiterlimit='10'
								x1='23.742'
								transform='translate(1.412 9.967)'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeLinecap='round'
								strokeMiterlimit='10'
								y1='18.805'
								transform='translate(13.284 0.826)'
							/>
						</g>
					</g>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1421.592,286.529h-88.36l-19.116,48.711h151.078l-19.116-48.711h-14.173'
						transform='translate(-1314.115 -270.698)'
					/>
					<g transform='translate(13.795 23.457)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='10.22'
							y2='25.846'
							transform='translate(0 6.633)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='10.22'
							y2='25.846'
							transform='translate(7.219)'
						/>
					</g>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='14.301'
						transform='translate(107.463 37.205)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='17.708'
						transform='translate(107.463 42.022)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1357.676,284.1v20.717h10.313V284.1'
						transform='translate(-1250.212 -274.252)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1356.184,287.878l9.017-7.775,9.022,7.775a.349.349,0,0,1-.227.613h-17.585A.349.349,0,0,1,1356.184,287.878Z'
						transform='translate(-1252.581 -280.103)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='120.303'
						transform='translate(13.795 106.672)'
					/>
				</g>
				<g transform='translate(2109.32 367.977)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1437.212,274.725v13.326h10.313V274.725'
						transform='translate(-1356.502 -264.876)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1435.719,278.5l9.017-7.775,9.024,7.775a.35.35,0,0,1-.229.613h-17.582A.35.35,0,0,1,1435.719,278.5Z'
						transform='translate(-1358.871 -270.727)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='30.579'
						transform='translate(57.146 42.931)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1444.289,277.315l-39.792.424,8.877,26.276h21.711'
						transform='translate(-1404.497 -261.084)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='32.777'
						y2='0.432'
						transform='translate(47.933 15.804)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='44.341'
						y2='0.468'
						transform='translate(91.023 15.227)'
					/>
				</g>
				<g transform='translate(1572.41 365.91)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1292.341,295.632V338.35H1190.3V292.2'
						transform='translate(-1181.809 -237.228)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='41.424'
						transform='translate(128.543 53.759)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1237.817,275.43h-31.84l-19.116,41.32,116.27.077,8.943-41.4H1248.88'
						transform='translate(-1186.861 -261.776)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='8.122'
						y2='18.103'
						transform='translate(15.893 26.055)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='10.22'
						y2='22.777'
						transform='translate(21.014 20.209)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='14.301'
						transform='translate(50.956 35.028)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='17.708'
						transform='translate(50.956 39.845)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1207.516,273.886V294.6h10.313V273.886'
						transform='translate(-1156.56 -264.036)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1206.024,277.663l9.017-7.775,9.022,7.775a.348.348,0,0,1-.227.613h-17.583A.349.349,0,0,1,1206.024,277.663Z'
						transform='translate(-1158.928 -269.888)'
					/>
					<g transform='translate(27.118 64.766)'>
						<rect
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							width='18.552'
							height='19.934'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='16.579'
							transform='translate(0.987 9.967)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							y1='18.805'
							transform='translate(9.276 0.826)'
						/>
						<g transform='translate(49.749)'>
							<rect
								className='a'
								fill='none'
								stroke='#070c35'
								strokeLinecap='round'
								strokeMiterlimit='10'
								width='18.552'
								height='19.934'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeLinecap='round'
								strokeMiterlimit='10'
								x1='16.579'
								transform='translate(0.987 9.967)'
							/>
							<line
								className='a'
								fill='none'
								stroke='#070c35'
								strokeLinecap='round'
								strokeMiterlimit='10'
								y1='18.805'
								transform='translate(9.276 0.826)'
							/>
						</g>
					</g>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1240.684,275.43l9.9,36.567H1244.5l-8.974,1.978'
						transform='translate(-1115.471 -261.776)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='4.554'
						y2='17.861'
						transform='translate(124.874 26.458)'
					/>
				</g>
				<g transform='translate(1658.418 534.34)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
				</g>
				<g transform='translate(1726.44 485.047)'>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.467'
						y2='4.551'
						transform='translate(0 1.739)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y2='6.29'
						transform='translate(5.939)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='2.467'
						y2='4.551'
						transform='translate(9.411 1.739)'
					/>
				</g>
				<g transform='translate(1973.672 365.848)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1448.091,272.85h-86.816l-11.763,33.7'
						transform='translate(-1349.512 -265.491)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1371.057,327.924h90.573l-19.116-55.074h-15.067'
						transform='translate(-1317.905 -265.491)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y1='40.982'
						transform='translate(133.775 63.254)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1389.473,269.863v17.6h10.313v-17.6'
						transform='translate(-1290.89 -269.863)'
					/>
					<g transform='translate(100.41 69.791)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='26.719'
							transform='translate(9.624)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='36.342'
							transform='translate(0 6.204)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='26.719'
							transform='translate(9.624 12.407)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='31.53'
							transform='translate(4.812 18.611)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='17.095'
							transform='translate(19.248 24.814)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='12.283'
							transform='translate(24.059 31.018)'
						/>
					</g>
					<g transform='translate(115.265 22.006)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='9.039'
							y2='22.86'
							transform='translate(7.218 6.633)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x2='8.831'
							y2='22.334'
						/>
					</g>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='17.691'
						transform='translate(94.742)'
					/>
				</g>
				<path
					className='a'
					fill='none'
					stroke='#070c35'
					strokeLinecap='round'
					strokeMiterlimit='10'
					d='M1538.475,273.093s30.174-16,89.355-24.316'
					transform='translate(901.371 65.124)'
				/>
				<g transform='translate(1492.843 340.385)'>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1199.757,265.91h75.273l-6.8,23.454'
						transform='translate(-1088.375 -250.185)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1245.181,304.507h-90.573l19.115-42.756h53.5L1223.651,272h30.478'
						transform='translate(-1154.609 -256.272)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						y1='40.982'
						transform='translate(9.95 51.52)'
					/>
					<path
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M1205.674,259.99V279.5h-10.313V259.99'
						transform='translate(-1094.824 -258.85)'
					/>
					<g transform='translate(6.972 58.057)'>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='26.719'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='36.342'
							transform='translate(0 6.204)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='26.719'
							transform='translate(0 12.407)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='31.53'
							transform='translate(0 18.611)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='17.095'
							transform='translate(0 24.814)'
						/>
						<line
							className='a'
							fill='none'
							stroke='#070c35'
							strokeLinecap='round'
							strokeMiterlimit='10'
							x1='12.283'
							transform='translate(0 31.018)'
						/>
					</g>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='2.723'
						y2='9.835'
						transform='translate(73.069 5.479)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='17.813'
						transform='translate(96.787)'
					/>
					<line
						className='a'
						fill='none'
						stroke='#070c35'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x2='5.06'
						y2='19.65'
						transform='translate(186.655 15.725)'
					/>
				</g>
				<path
					className='b'
					fill='#e60f37'
					d='M1262.841,388.893a62.193,62.193,0,0,1,124.386.047l38.708.015a100.9,100.9,0,0,0-201.8-.076Z'
					transform='translate(440.225 122.755)'
				/>
			</g>
		</svg>
	);
}

export default withWidth()(Village2);
