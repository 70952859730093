import store from '../store';
import {
	GET_ALL_COMMUNITY,
	GET_COMMUNITY,
	GET_ALL_ACTIVE_COMMUNITY,
	GET_COMMUNITY_CAT
} from '../types';
import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';

export const saveCommunity = async (data) => {
	await api
		.post('/community', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
		});
};

export const getAllByCategory = async (category) => {
	await api
		.get(`community?active=true&category=${category}`)
		.then((response) => {
			store.dispatch({
				type: GET_COMMUNITY_CAT,
				payload: { name: category, data: response.data.items }
			});
		});
};

export const getAllActiveCommunity = async () => {
	await api.get('community?active=true').then((response) => {
		if (response.data) {
			const news = response.data;
			const oldItems = news.items;
			const newItems = oldItems.filter(
				(newsLine) => newsLine.content.length >= 1
			);
			news.items = newItems;
			store.dispatch({
				type: GET_ALL_ACTIVE_COMMUNITY,
				payload: news
			});
		}
	});
};

export const getCommunity = async (id) => {
	await api.get(`/community/${id}`).then((response) => {
		store.dispatch({
			type: GET_COMMUNITY,
			payload: response.data
		});
	});
};

export const activateCommunity = async (id) => {
	await api
		.patch(`/community/${id}`)
		.then(() => {
			openSnackbar({ message: 'Sikeres aktivalas!', severity: 'success' });
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
		});
};

export const getElements = async (other) =>
	api
		.get(other ? `/community${other}` : '/community?active=false')
		.then((response) => {
			store.dispatch({ type: GET_ALL_COMMUNITY, payload: response.data });
		});

export const saveElement = async (data) =>
	api
		.post('/community', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
			getElements('?active=false');
			return true;
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
			return false;
		});

export const updateElement = (id, data) => {
	api.put(`/community/${id}`, data).then(() => getElements('?active=false'));
};

export const deleteElement = (id) => {
	api.delete(`/community/${id}`).then(() => getElements('?active=false'));
};

export const activateElement = (id, status) => {
	api
		.patch(`/community/${id}?active=${!!status}`)
		.then(() => getElements('?active=false'));
};
