import React, { useState, useEffect, useRef } from 'react';
import { Box, makeStyles, Hidden } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import {
	MonumentHistory,
	SiculicidiumHistory2,
	SiculicidiumHistory3,
	SiculicidiumHistory4,
	SiculicidiumHistory5,
	SiculicidiumHistory6,
	SiculicidiumHistory7,
	SiculicidiumHistory8,
	SiculicidiumHistory9,
	SiculicidiumHistory10,
	SiculicidiumHistory11
} from '../History/components';

const useStyles = makeStyles((theme) => ({
	container: {},
	slideContainer: {
		position: 'relative',
		height: '800px'
	},
	sicHistory1: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 680
		},
		[theme.breakpoints.only('sm')]: {
			height: 560
		},
		[theme.breakpoints.only('md')]: {
			height: 630
		}
	},
	sicHistory2: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 790
		},
		[theme.breakpoints.only('sm')]: {
			height: 610
		},
		[theme.breakpoints.only('md')]: {
			height: 730
		}
	},
	sicHistory3: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1180
		},
		[theme.breakpoints.only('sm')]: {
			height: 1130
		},
		[theme.breakpoints.only('md')]: {
			height: 730
		}
	},
	sicHistory4: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1130
		},
		[theme.breakpoints.only('md')]: {
			height: 680
		},
		[theme.breakpoints.only('sm')]: {
			height: 930
		}
	},
	sicHistory5: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1030
		},
		[theme.breakpoints.only('md')]: {
			height: 680
		},
		[theme.breakpoints.only('sm')]: {
			height: 930
		}
	},
	sicHistory6: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 680
		},
		[theme.breakpoints.only('sm')]: {
			height: 660
		},
		[theme.breakpoints.only('md')]: {
			height: 680
		}
	},
	sicHistory7: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1030
		},
		[theme.breakpoints.only('sm')]: {
			height: 1060
		},
		[theme.breakpoints.only('md')]: {
			height: 680
		}
	},
	sicHistory8: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 1160
		},
		[theme.breakpoints.only('sm')]: {
			height: 1210
		},
		[theme.breakpoints.only('md')]: {
			height: 680
		}
	},
	sicHistory9: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 820
		},
		[theme.breakpoints.only('sm')]: {
			height: 830
		},
		[theme.breakpoints.only('md')]: {
			height: 680
		}
	},
	sicHistory10: {
		height: 900,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			height: 930
		},
		[theme.breakpoints.only('md')]: {
			height: 680
		}
	}
}));

function SiculicidiumHistoryNotAnimated(props) {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Box className={classes.sicHistory1}>
				<SiculicidiumHistory2 showAnimation />
			</Box>
			<Box className={classes.sicHistory2}>
				<SiculicidiumHistory3 showAnimation />
			</Box>
			<Box className={classes.sicHistory3}>
				<SiculicidiumHistory4 showAnimation />
			</Box>
			<Box className={classes.sicHistory4}>
				<SiculicidiumHistory5 showAnimation />
			</Box>
			<Box className={classes.sicHistory5}>
				<SiculicidiumHistory6 showAnimation />
			</Box>
			<Box className={classes.sicHistory6}>
				<SiculicidiumHistory7 showAnimation />
			</Box>
			<Box className={classes.sicHistory7}>
				<SiculicidiumHistory8 showAnimation />
			</Box>
			<Box className={classes.sicHistory8}>
				<SiculicidiumHistory9 showAnimation />
			</Box>
			<Box className={classes.sicHistory9}>
				<SiculicidiumHistory10 showAnimation />
			</Box>
			<Box className={classes.sicHistory10}>
				<SiculicidiumHistory11 showAnimation />
			</Box>
		</Box>
	);
}

export default withNamespaces('home')(SiculicidiumHistoryNotAnimated);
