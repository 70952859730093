import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function HealthCare(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 106.62 78.24'>
			<title>Asset 2</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className='cls-1'
						stroke='#160e44'
						fill='none'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M59.05,43.32c-.28-3.4.62-6.4,4.05-8.57,12-7.62,20.43,17.62,31.95,11,9.22-5.32,3.36-16.87,3.36-16.87'
					/>
					<path
						className='cls-1'
						stroke='#160e44'
						fill='none'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M63.66,66.86c2-7.06-4-16.24-4.61-23.54'
					/>
					<path
						className='cls-2'
						fill='#ee2c48'
						d='M89.79,28.83a12.6,12.6,0,1,0-12.6-12.59A12.61,12.61,0,0,0,89.79,28.83Z'
					/>
					<path
						className='cls-2'
						fill='#ee2c48'
						d='M20.64,77.71a25.21,25.21,0,0,1,50.42,0H86.75a40.9,40.9,0,1,0-81.8,0Z'
					/>
					<line
						className='cls-3'
						stroke='#ee2c48'
						fill='none'
						strokeLinecap='round'
						strokeMiterlimit='10'
						x1='0.5'
						y1='77.74'
						x2='91.21'
						y2='77.74'
					/>
					<path
						className='cls-1'
						stroke='#160e44'
						fill='none'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M76.45,6.94A16.39,16.39,0,0,1,85.35.5'
					/>
					<path
						className='cls-1'
						stroke='#160e44'
						fill='none'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M93.87,31.85a16.2,16.2,0,0,1-17.33-6.48'
					/>
					<path
						className='cls-1'
						stroke='#160e44'
						fill='none'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M98.41,2.28a16.26,16.26,0,0,1,7.25,17.63'
					/>
					<path
						className='cls-1'
						stroke='#160e44'
						fill='none'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M86.61,29.75a14,14,0,1,1,16.9-10.34A14,14,0,0,1,86.61,29.75Z'
					/>
					<path
						className='cls-2'
						fill='#ee2c48'
						d='M86.94,28.38a12.6,12.6,0,1,0-9.3-15.2A12.63,12.63,0,0,0,86.94,28.38Z'
					/>
					<path
						className='cls-1'
						stroke='#160e44'
						fill='none'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M35.39,53.33S42.83,82.47,58.58,73a10.43,10.43,0,0,0,5.08-6.18'
					/>
					<path
						className='cls-1'
						stroke='#160e44'
						fill='none'
						strokeLinecap='round'
						strokeMiterlimit='10'
						d='M30.2,13.11c-17.79,21-7.75,46.77,5.19,40.22,15.57-7.86-2.71-38.24-18.27-33'
					/>
					<path
						className='cls-4'
						fill='#160e44'
						d='M28.72,12a1.88,1.88,0,1,1,.2,2.65A1.89,1.89,0,0,1,28.72,12Z'
					/>
					<path
						className='cls-4'
						fill='#160e44'
						d='M16.51,18.55a1.88,1.88,0,1,0,2.45,1A1.88,1.88,0,0,0,16.51,18.55Z'
					/>
				</g>
			</g>
		</svg>
	);
}
