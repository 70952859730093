import React, { useState } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import { withNamespaces } from 'react-i18next';
import { saveFile } from '../../../../../store/file/actions';
import { FILE_TYPE } from '../../../../../store/types';
// Documentation
// https://yuvaleros.github.io/material-ui-dropzone/

const Dropzone = (props) => {
	// const { t } = props;
	const { type, t, selected } = props;
	const [open, setOpen] = useState(false);
	const [fileObjects, setFileObjects] = useState([]);
	return (
		<>
			<Button variant='contained' color='primary' onClick={() => setOpen(true)}>
				{t('addAttachment')}
			</Button>

			<DropzoneDialog
				acceptedFiles={
					type === FILE_TYPE.IMAGE ? ['image/*'] : ['application/*']
				}
				fileObjects={fileObjects}
				maxFileSize='33554432'
				cancelButtonText={t('cancel')}
				submitButtonText={t('submit')}
				open={open}
				filesLimit={1}
				onChange={(newFileObjs) => {
					setFileObjects(newFileObjs[0]);
				}}
				onDelete={(deleteFileObj) => {
					setFileObjects([]);
				}}
				onClose={() => setOpen(false)}
				onSave={() => {
					setOpen(false);
					saveFile({ file: fileObjects, type, selected });
				}}
				showPreviews
				showFileNamesInPreview
			/>
		</>
	);
};
export default withNamespaces('common')(Dropzone);
