import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withNamespaces } from 'react-i18next';
import { ImageSelector } from '../../../components/modals';
import { Select, TextField } from '../../../components/form';
import EnableRow from './EnableRow';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: '70vw'
	},
	fieldArrayItem: {
		border: '1px solid gray',
		margin: '30px',
		padding: '30px'
	}
}));

export default withNamespaces(['common', 'sight'])((props) => {
	const { item, open, setOpen, onConfirm, t } = props;
	const classes = useStyles();

	return (
		<Formik
			enableReinitialize
			initialValues={{
				content: item ? item.content : [{ lang: 'hu' }, { lang: 'ro' }],
				category: item ? item.category : 'ngos'
			}}
			onSubmit={(values) => {
				values.content.forEach((element) => {
					// eslint-disable-next-line no-param-reassign
					element.imageId = element.image.id;
					delete item.image;
				});

				onConfirm(values);
				setOpen(false);
			}}>
			{({ values, setFieldValue, submitForm }) => (
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					classes={{ paper: classes.root }}
					aria-labelledby='edit-dialog'>
					<DialogTitle id='confirm-dialog'>
						{item?.content[0]?.name
							? item?.content[0]?.name
							: 'Új közösségteremtők elem'}
					</DialogTitle>
					<DialogContent>
						<EnableRow
							active={item.active}
							itemId={item.id}
							onFinish={() => setOpen(false)}
						/>
						<Form>
							<Field
								name='category'
								label={t('category')}
								options={[
									{
										value: 'ngos',
										label: t('community:ngos')
									},
									{
										value: 'churches',
										label: t('community:churches')
									},
									{
										value: 'educationalInstitutions',
										label: t('community:educationalInstitutions')
									},
									{
										value: 'health',
										label: t('community:health')
									},
									{
										value: 'brotherSettlements',
										label: t('community:brotherSettlements')
									}
								]}
								component={Select}
								size='small'
								value={values.category}
								onChange={(evt) => setFieldValue('category', evt.target.value)}
							/>
							<FieldArray
								name='content'
								render={() => (
									<Box>
										{values.content.map((cont, index) => (
											<Box
												key={`it-${cont.lang}`}
												className={classes.fieldArrayItem}>
												<Typography variant='h2'>
													{t(`common:${cont.lang}`)}
												</Typography>
												<Field
													name={`content[${index}].name`}
													label={t('community:name')}
													component={TextField}
												/>
												<Field
													name={`content[${index}].intro`}
													label={t('community:intro')}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].web`}
													label={t('community:web')}
													component={TextField}
												/>
												<Field
													name={`content[${index}].phone`}
													label={t('community:phone')}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].address`}
													label={t('community:address')}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].email`}
													label={`${t('community:email')} (${t(
														'notRequired'
													)})`}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].facebooklink`}
													label={`${t('community:facebooklink')} (${t(
														'notRequired'
													)})`}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].facebookname`}
													label={`${t('community:facebookname')} (${t(
														'notRequired'
													)})`}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].contactperson1`}
													label={`${t('community:contactperson1')} (${t(
														'notRequired'
													)})`}
													component={TextField}
													multiline
													rows={2}
												/>
												<Field
													name={`content[${index}].contactperson2`}
													label={`${t('community:contactperson2')} (${t(
														'notRequired'
													)})`}
													component={TextField}
													multiline
													rows={2}
												/>

												<ImageSelector
													fieldName={`content[${index}].imageId`}
													image={cont.image}
													setImageId={(id) => {
														setFieldValue(`content[${index}].imageId`, id);
													}}
												/>
											</Box>
										))}
									</Box>
								)}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							onClick={() => setOpen(false)}
							color='default'>
							{t('cancel')}
						</Button>
						<Button
							variant='contained'
							type='submit'
							color='secondary'
							onClick={submitForm}>
							{t('save')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
});
