import store from '../store';
import { GET_OFFICE } from '../types';
import { api } from '../../lib/axios/api';
import { openSnackbar } from '../snackbar/actions';

export const getByCategory = async (params) => {
	await api
		.get(`office${params}`)
		.then((response) => {
			if (response.data) {
				store.dispatch({
					type: GET_OFFICE,
					payload: response.data
				});
			}
		})
		.catch((e) => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
		});
};

export const getElements = (other) => {
	api
		.get(other ? `/office${other}` : '/office?active=true')
		.then((response) => {
			store.dispatch({ type: GET_OFFICE, payload: response.data });
		});
};

export const saveElement = async (data) =>
	api
		.post('/office', data)
		.then(() => {
			openSnackbar({ message: 'Sikeres mentes!', severity: 'success' });
			getElements('?active=false');
			return true;
		})
		.catch(() => {
			openSnackbar({
				message: 'Sajnos hiba tortent! Probalja ujra!',
				severity: 'error'
			});
			return false;
		});

export const updateElement = (id, data) => {
	api.put(`/office/${id}`, data).then(() => getElements('?active=false'));
};

export const deleteElement = (id) => {
	api.delete(`/office/${id}`).then(() => getElements('?active=false'));
};

export const activateElement = (id, status) => {
	api
		.patch(`/office/${id}?active=${!!status}`)
		.then(() => getElements('?active=false'));
};
