import Cookies from 'js-cookie';
import store from '../../store';
import { SET_ACCEPTED_COOKIES } from '../../types';

export const acceptCookies = (value) => {
	Cookies.set('ac', value, { expires: 600 });
	store.dispatch({ type: SET_ACCEPTED_COOKIES, payload: true });
};

export const cookiesAccepted = () => {
	const accepted = Cookies.get('ac');
	store.dispatch({ type: SET_ACCEPTED_COOKIES, payload: accepted });
};
