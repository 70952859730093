import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Button } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import {
	ChevronRight,
	StraightStripes,
	HomeScreenHalfCircle,
	Church2
} from '../../../../components/icons';
import Vintage from '../../../../components/images/vintage04.png';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		left: '-50px',
		width: '630px',
		height: '475px',
		top: '89px',
		[theme.breakpoints.only('xs')]: {
			width: 320,
			height: 270,
			left: 0,
			top: 70
		},
		[theme.breakpoints.only('md')]: {
			right: 0
		},
		[theme.breakpoints.only('lg')]: {
			top: -20
		},
		[theme.breakpoints.only('sm')]: {
			left: 40
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0
	},
	contentContainer: {
		top: '0px',
		width: '100%',
		height: '869px',
		position: 'absolute',
		[theme.breakpoints.only('xs')]: {
			height: 700
		}
	},
	mainTitleBox: {
		top: '287px',
		position: 'absolute',
		background: '#FEFEFE 0% 0% no-repeat padding-box',
		opacity: 1,
		right: 0,
		left: 'auto',
		textAlign: 'right',
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			top: 250
		},
		[theme.breakpoints.only('lg')]: {
			top: 187
		},
		[theme.breakpoints.only('md')]: {
			right: 40
		},
		[theme.breakpoints.only('sm')]: {
			right: 40,
			top: 350
		}
	},
	mainTitleText: {
		fontFamily: 'Ubuntu',
		fontWeight: 700,
		color: theme.palette.primary.dark,
		fontSize: '50px',
		lineHeight: '56px',
		letterSpacing: '1px',
		marginTop: '6px',
		marginRight: '37px',
		marginBottom: '8px',
		marginLeft: '20px',
		[theme.breakpoints.only('xs')]: {
			fontSize: '34px',
			lineHeight: 'unset',
			marginRight: 20,
			marginLeft: 20
		}
	},
	halfCircle: {
		position: 'absolute',
		top: '352px',
		left: 'auto',
		right: '48px',
		zIndex: 95,
		width: '192px',
		height: '96px',
		[theme.breakpoints.only('xs')]: {
			top: 270,
			width: 130,
			height: 65,
			right: 30,
			opacity: 0.5
		},
		[theme.breakpoints.only('lg')]: {
			top: 252
		},
		[theme.breakpoints.only('md')]: {
			right: 88
		},
		[theme.breakpoints.only('sm')]: {
			right: 100,
			top: 400
		}
	},
	halfStripes: {
		top: '196px',
		right: '0px',
		left: 'auto',
		position: 'absolute',
		zIndex: 100,
		width: '400px',
		height: '252px',
		[theme.breakpoints.only('xs')]: {
			width: 300,
			opacity: 0.3,
			top: 100
		},
		[theme.breakpoints.only('lg')]: {
			top: 96
		},
		[theme.breakpoints.only('md')]: {
			right: 40
		},
		[theme.breakpoints.only('sm')]: {
			right: 40,
			top: 250
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '491px',
		textAlign: 'right',
		right: 360,
		width: 786,
		[theme.breakpoints.only('xs')]: {
			top: 355,
			width: '100%',
			textAlign: 'left',
			left: 0,
			right: 0,
			boxSizing: 'border-box',
			paddingRight: '24px',
		},
		[theme.breakpoints.only('md')]: {
			width: 450,
			flexWrap: 'wrap',
			right: 250
		},
		[theme.breakpoints.only('lg')]: {
			top: 391,
			width: 450
		},
		[theme.breakpoints.only('sm')]: {
			left: 40,
			right: 'auto',
			width: '90%',
			marginLeft: 0,
			marginRight: 0,
			top: 570
		}
	},
	descriptionText: {
		textAlign: 'right',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',

		[theme.breakpoints.only('xs')]: {
			fontSize: '16px',
			lineHeight: '20px',
			textAlign: 'left',
			paddingTop: 20,
			paddingLeft: 20,
		}
	},
	buttonContainer: {
		position: 'absolute',
		top: '591px',
		left: 'auto',
		right: '77px',
		[theme.breakpoints.only('xs')]: {
			top: 615,
			right: 0,
			marginRight: '24px',
		},
		[theme.breakpoints.only('md')]: {
			top: 550,
			right: 40
		},
		[theme.breakpoints.only('lg')]: {
			top: 491
		},
		[theme.breakpoints.only('sm')]: {
			top: 850
		}
	},
	footerIconBox: {
		position: 'absolute',
		bottom: '0px',
		left: 0,
		height: '208px',
		width: '300px',
		[theme.breakpoints.only('xs')]: {
			top: 585,
			height: 100,
			width: 200,
			left: 0,
		},
		[theme.breakpoints.up('lg')]: {
			top: 'calc(100vh - 100px - 208px)'
		},
		[theme.breakpoints.only('sm')]: {
			top: 750,
			left: 40
		},
		['@media screen and (max-height: 750px) and (min-width: 1280px)' ]:{
			top: 'calc(133vh - 100px - 191px)',
			bottom: '-33vh'
		}
	},
	descriptionTitle: {
		color: '#E0B080',
		fontSize: '30px',
		letterSpacing: '2px',
		lineHeight: '30px',
		textTransform: 'uppercase',
		font: 'Bold 30px/30px Bebas Neue',
		[theme.breakpoints.only('xs')]: {
			zIndex: 200,
			paddingLeft: 20
		}
	},
	nextButton: {
		paddingLeft: '60px',
		paddingRight: '60px',
		[theme.breakpoints.only('xs')]: {
			paddingLeft: 40,
			paddingRight: 40,
			height: 50
		}
	}
}));

function MadefalvaHistory(props) {
	const { t } = props;
	const classes = useStyles();
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);

	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);

	return (
		<Box
			className={classes.pageContainer}
			style={{ zIndex: showAnimation ? 200 : 150 }}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='vintageimage2'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>

			<Box className={classes.contentContainer}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='rtlmiddle'
					unmountOnExit>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							{t('madefalvahistory.title')}
						</Typography>
					</Box>
				</CSSTransition>
				<Box className={classes.animatedCircles}>
					<CSSTransition
						in={showAnimation}
						timeout={300}
						classNames='ttbmiddle'
						unmountOnExit>
						<Box className={classes.halfStripes}>
							<StraightStripes width='100%' height='100%' />
						</Box>
					</CSSTransition>
					<CSSTransition
						in={showAnimation}
						timeout={300}
						classNames='diagonallyttbrtl'
						unmountOnExit>
						<Box className={classes.halfCircle}>
							<HomeScreenHalfCircle width='100%' height='100%' fill='#E0B080' />
						</Box>
					</CSSTransition>
				</Box>
				<Box className={classes.descriptionContainer}>
					<CSSTransition
						in={showAnimation}
						timeout={300}
						classNames='rtlshort'
						unmountOnExit>
						<Typography className={classes.descriptionTitle}>
							{t('madefalvahistory.descriptiontitle')}
						</Typography>
					</CSSTransition>
					<CSSTransition
						in={showAnimation}
						timeout={300}
						classNames='ltrlong'
						unmountOnExit>
						<Typography className={classes.descriptionText} varinat='paragraph'>
							<Trans i18nKey='madefalvahistory.description'>
								<br />
								<br />
							</Trans>
						</Typography>
					</CSSTransition>
				</Box>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='rtlmiddle'
					unmountOnExit>
					<Box className={classes.buttonContainer}>
						<Button
							type='button'
							className={classes.nextButton}
							onClick={() => {
								window.scrollTo(0,0);
								props.history.push('/history/madefalva');
							}}>
							<Typography variant='button'>
								{t('madefalvahistory.button')}
							</Typography>
							<ChevronRight width='25px' height='25px' fill='#FAFAF5' />
						</Button>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='bttmiddle'
					unmountOnExit>
					<Box className={classes.footerIconBox}>
						<Church2
							height='100%'
							width='100%'
							fill='#48B3C9'
							dotfill='#48B3C9'
						/>
					</Box>
				</CSSTransition>
			</Box>
		</Box>
	);
}

export default withRouter(withNamespaces('homeMadefalva')(MadefalvaHistory));
