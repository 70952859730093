import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Hidden } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CSSTransition } from 'react-transition-group';
import {
	StraightStripes,
	HomeScreenHalfCircle,
	Monument,
	DotIcon
} from '../../../../components/icons';
import { lang } from 'moment';

const useStyles = makeStyles((theme) =>{ 
	return ({
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: '100%',
		[theme.breakpoints.up('lg')]: {
			top: 130
		}
	},
	contentContainer: {
		right: 0,
		left: 0,
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		height: '550px',
		position: 'absolute',
		borderBottom: '3px solid #EE2C48',
		strokeLocation: 'center',
		strokeLinecap: 'round',
		strokeLinejoin: 'miter',
		opacity: 1,
		[theme.breakpoints.only('xs')]: {
			border: 'none',
			width: '100%'
		},
		[theme.breakpoints.only('sm')]: {
			height: 497
		},
		[theme.breakpoints.down('md')]: {
			width: '100%'
		}
	},
	mainTitleBox: {
		top: '224px',
		position: 'absolute',
		display: 'flex',
		textAlign: 'right',
		zIndex: 101,
		[theme.breakpoints.down('md')]: {
			left: 30
		},
		[theme.breakpoints.down('sm')]: {
			top: 130,
			left: 30,
			height: ''
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		fontWeight: 700,
		color: theme.palette.primary.dark,
		fontSize: '50px',
		lineHeight: '60px',
		letterSpacing: '25px',
		textTransform: 'uppercase',
		paddingRight: '50px',
		paddingBottom: '3px',
		paddingLeft: '27px',
		background: '#EE2C48 0% 0% no-repeat padding-box',
		width: '552px',
		[theme.breakpoints.only('xs')]: {
			width: '90%',
			lineHeight: '40px',
			fontSize: '40px',
			letterSpacing: '6px'
		},
		[theme.breakpoints.only('sm')]: {
			width: '33%',
			paddingRight: '10px',
			paddingLeft: '10px',
			paddingTop: 7,
			letterSpacing: '4px',
			fontSize: '40px',
			height: 'fit-content'
		},
		[theme.breakpoints.only('md')]: {
			paddingTop: 23
		}
	},
	titleNote: {
		fontFamily: 'Ubuntu',
		color: theme.palette.primary.dark,
		fontSize: '22px',
		lineHeight: '34px',
		textAlign: 'left',
		width: '100%',
		marginLeft: '24px',
		[theme.breakpoints.only('xs')]: {
			width: '90%',
			fontSize: '20px',
			lineHeight: '24px'
		}
	},
	animatedCircles: {
		position: 'absolute'
	},
	halfCircle: {
		position: 'absolute',
		top: '263px',
		left: '95px',
		zIndex: 95,
		width: '192px',
		height: '96px',
		[theme.breakpoints.down('sm')]: {
			width: 150,
			top: 150
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '400px',
		left: '302px',
		width: 380,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 530,
			left: 0
		},
		[theme.breakpoints.only('sm')]: {
			top: 300,
			left: 100
		}
	},
	descriptionContainer2: {
		position: 'absolute',
		top: 280
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		[theme.breakpoints.only('xs')]: {
			marginLeft: 30,
			marginRight: 20
		}
	},
	footerIconBox: {
		position: 'absolute',
		// top: 323px
		top: '325px',
		right: 0,
		height: 228,
		[theme.breakpoints.only('xs')]: {
			height: 150,
			top: 360
		},
		[theme.breakpoints.only('sm')]: {
			top: 300,
			height: 200
		}
	},
	halfStripes: {
		top: '152px',
		position: 'absolute',
		zIndex: 100,
		width: '400px',
		height: '252px',
		[theme.breakpoints.only('md')]: {
			left: 30
		},
		[theme.breakpoints.down('sm')]: {
			top: 30,
			width: 320
		}
	},
	navigateIconContainer: {
		position: 'absolute',
		width: '100px',
		top: '727px'
	},
	navigationIconButton: {
		height: '60px',
		width: '60px',
		'&:hover': {
			background: '#FFFFFF'
		},
		position: 'absolute',
		top: '60px',
		transform: 'rotate(90deg)'
	},
	dotIconContaner: {
		left: '958px',
		top: '117px',
		position: 'absolute',
		[theme.breakpoints.only('xs')]: {
			left: 70,
			top: 430
		},
		[theme.breakpoints.only('sm')]: {
			right: 30,
			left: 'auto',
			top: 250
		}
	}
})});

function SiculicidiumHistory2(props) {
	
	let language = useSelector((state) => state.language.language)
	const { t } = props;
	const classes = useStyles();
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<CSSTransition
			in={showAnimation}
			timeout={300}
			classNames='ltrlong'
			unmountOnExit>
			<Box className={classes.pageContainer}>
				<Box className={classes.contentContainer}>
					<Box className={classes.dotIconContaner}>
						<DotIcon width='50px' height='50px' />
					</Box>
					<Hidden only={['xs']}>
						<Box className={classes.mainTitleBox}>
							<Typography style={{paddingTop: language == 'ro' ? 23 : 7}} className={classes.mainTitleText}>
								Siculicidium
							</Typography>
							{language == 'ro' ? 
							<Typography className={classes.titleNote}>
								
								{t('megerint9.titlenote1')} 
								<br/>
								<b>{t('megerint9.titlenote2')}</b>
								<br />
								{t('megerint9.titlenote3')} <b>{t('megerint9.titlenote4')}</b>
							</Typography>
							:
							<Typography className={classes.titleNote}>
									<b>{t('megerint9.titlenote1')}</b> {t('megerint9.titlenote2')}
									<br />
									<b>{t('megerint9.titlenote3')}</b> {t('megerint9.titlenote4')}
									
							</Typography>
						}
						</Box>
					</Hidden>
					<Hidden only={['xl', 'lg', 'md', 'sm']}>
						<Box className={classes.mainTitleBox}>
							<Typography className={classes.mainTitleText}>
								Siculicidium
							</Typography>
						</Box>
						<Box className={classes.descriptionContainer2}>
							<Typography className={classes.titleNote}>
								<b>{t('megerint9.titlenote1')}</b> {t('megerint9.titlenote2')}
								<br />
								<b>{t('megerint9.titlenote3')}</b> {t('megerint9.titlenote4')}
							</Typography>
						</Box>
					</Hidden>

					<Box className={classes.animatedCircles}>
						<Box className={classes.halfStripes}>
							<StraightStripes width='100%' height='100%' />
						</Box>
						<Box className={classes.halfCircle}>
							<HomeScreenHalfCircle width='100%' height='100%' fill='#E0B080' />
						</Box>
					</Box>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionText} varinat='paragraph'>
							<Trans i18nKey='megerint9.description'>
								<br />
								<br />
							</Trans>
						</Typography>
					</Box>
					<Box className={classes.footerIconBox}>
						<Monument height='100%' fill='#EE2C48' dotfill='none' />
					</Box>
					{/* TODO visszatenni <Box className={classes.navigateIconContainer}>
                    <IconButton className={classes.navigationIconButton}>
                        <ChevronRight
                            width={"24px"}
                            height={"24px"}
                            fill={"#070C35"}
                        />
                    </IconButton>
	</Box> */}
				</Box>
			</Box>
		</CSSTransition>
	);
}

export default withNamespaces('siculicidiumHistory')(SiculicidiumHistory2);
