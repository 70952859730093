import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function SocialOffice(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 69.58 86.72'>
			<title>szoc-iroda</title>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<path
						className='cls-1'
						fill='201943'
						d='M47.25,45.63a.51.51,0,0,1-.5-.43L43.4,20.84a.91.91,0,0,0-.7-.76l-7.24-1.52-7.24,1.52a.92.92,0,0,0-.71.76L24.16,45.2a.5.5,0,0,1-.57.42.49.49,0,0,1-.42-.56L26.52,20.7A1.89,1.89,0,0,1,28,19.11l7.45-1.57,7.44,1.57a1.89,1.89,0,0,1,1.49,1.59l3.36,24.36a.5.5,0,0,1-.43.56Z'
					/>
					<path
						className='cls-1'
						fill='201943'
						d='M35.46,14.94a7.47,7.47,0,1,1,7.47-7.47A7.48,7.48,0,0,1,35.46,14.94ZM35.46,1a6.47,6.47,0,1,0,6.47,6.47A6.47,6.47,0,0,0,35.46,1Z'
					/>
					<path
						className='cls-1'
						fill='201943'
						d='M41,61.13a.5.5,0,0,1-.5-.5V45.82h-10V59.35a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5V44.82h12V60.63A.5.5,0,0,1,41,61.13Z'
					/>
					<path
						className='cls-1'
						fill='201943'
						d='M35.46,61.37a.5.5,0,0,1-.5-.5V50.49a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5V60.87A.5.5,0,0,1,35.46,61.37Z'
					/>
					<path
						className='cls-1'
						fill='201943'
						d='M35.46,18.55a.5.5,0,0,1-.5-.5V14.81a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5v3.24A.5.5,0,0,1,35.46,18.55Z'
					/>
					<path
						className='cls-1'
						fill='201943'
						d='M41.45,42.87h-12V24.27a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v17.6h10V24.27a.5.5,0,1,1,1,0Z'
					/>
					<path
						className='cls-1'
						fill='201943'
						d='M2.89,86.72,0,59.12,10.77,58a3.07,3.07,0,0,1,3.37,2.73l2.25,21.5a3,3,0,0,1-.67,2.25,3,3,0,0,1-2.06,1.12ZM1.1,60,3.78,85.62l9.77-1a2,2,0,0,0,1.39-.76,2,2,0,0,0,.45-1.51L13.15,60.82A2.08,2.08,0,0,0,10.87,59Z'
					/>
					<path
						className='cls-1'
						fill='201943'
						d='M47.91,80.3H15.76a.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5H47.91a.56.56,0,0,0,.35-.13L68.4,61.93a.49.49,0,0,0,.18-.41.53.53,0,0,0-.19-.4l-1.27-1a4.67,4.67,0,0,0-5.54-.24L52,66.66l-4.56,1.67A.49.49,0,0,1,46.8,68a.51.51,0,0,1,.3-.65l4.5-1.65L61,59a5.68,5.68,0,0,1,6.74.28l1.27,1a1.49,1.49,0,0,1,.56,1.16,1.48,1.48,0,0,1-.53,1.18L48.91,79.93A1.53,1.53,0,0,1,47.91,80.3Z'
					/>
					<path
						className='cls-1'
						fill='201943'
						d='M27.26,70a.5.5,0,0,1-.12-1l6.92-1.65H47.27a.52.52,0,0,0,.52-.52V65.09a3.47,3.47,0,0,0-2.95-3.43,26.62,26.62,0,0,0-9.19.1l-.09,0-.09,0a19.91,19.91,0,0,1-4.61-1.51,12.52,12.52,0,0,0-3.94-1.21C20.1,58.83,15,63.65,15,63.7a.5.5,0,0,1-.69-.72c.22-.21,5.43-5.15,12.69-4.93a13,13,0,0,1,4.3,1.29,19.58,19.58,0,0,0,4.28,1.43,27.68,27.68,0,0,1,9.46-.1,4.47,4.47,0,0,1,3.8,4.42v1.75a1.52,1.52,0,0,1-1.52,1.52H34.12L27.37,70Z'
					/>
				</g>
			</g>
		</svg>
	);
}
