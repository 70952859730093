import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import palette from '../../../../../themes/palette';
import 'moment/min/locales';

const useStyles = makeStyles(() => ({
	mainCard: {
		width: '264px',
		height: '320px',
		background: '#FAFAF5',
		position: 'relative',
		'&:hover': {
			cursor: 'pointer'
		},
		// marginRight: 24,
		marginBottom: 24,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	infoContainer: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: 0
	},
	imageBox: {
		width: '100%',
		height: '148px',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	img: {
		width: '100%'
		// height: '148px'
	},
	titleText: {
		fontWeight: 'bold',
		lineHeight: 'px',
		color: '#FAFAF5',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
	},
	titleBox: {
		background: palette.primary.dark,
		paddingRight: '8px',
		paddingLeft: '12px',
		paddingTop: 5,
		paddingBottom: 5,
		position: 'relative',
		right: '12px',
		// TODO itt az ertek jelenleg random
		top: '135px',
		zIndex: 100
	},
	dateBox: {
		paddingTop: '48px',
		paddingRight: '16px',
		paddingLeft: '16px'
	},
	dateText: {
		color: '#E0B080',
		fontSize: '12px',
		lineHeight: '13px',
		fontWeight: 600
	},
	descriptionBox: {
		height: '100px',
		overflow: 'hidden'
	},
	bodyText: {
		color: palette.text.primary,
		fontSize: 13,
		fontWeight: 'bold',
		fontFamily: 'Ubuntu',
		lineHeight: '20px',
		letterSpacing: '-0.26px',
		paddingTop: '20px',
		paddingRight: '17px',
		paddingLeft: '15px',
		paddingBottom: '30px',
		height: '60px',
		display: '-webkit-box',
		WebkitLineClamp: 4,
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		// backgroundColor: 'coral',
	}
}));

function NewsCard(props) {
	const classes = useStyles();
	const { newsItem } = props;
	const language = useSelector((state) => state.language.language);
	// TODO ezt innen ki kell loni
	const localizedNewsItem = newsItem.content.filter(
		(item) => item.lang === language
	)[0];
	const navigateToNews = () => {
		window.scrollTo(0, 0);

		props.history.push(`news/${newsItem.id}`);
	};
	return (
		<>
			{localizedNewsItem && (
				<Box className={classes.mainCard} onClick={navigateToNews}>
					<>
						<Box className={classes.titleBox}>
							<Typography className={classes.titleText}>
								{localizedNewsItem.title}
							</Typography>
						</Box>
						<Box className={classes.infoContainer}>
							<Box className={classes.imageBox}>
								<img
									src={`${localizedNewsItem.headerImage.url}?size=sm`}
									className={classes.img}
									alt={localizedNewsItem.shortDesc}
								/>
							</Box>
							<Box className={classes.dateBox}>
								<Typography className={classes.dateText}>
									{moment(newsItem.createdAt)
										.locale(language)
										.format('MMMM DD, YYYY')}
								</Typography>
							</Box>
							<Box className={classes.descriptionBox}>
								<Typography className={classes.bodyText}>
									{localizedNewsItem.shortDesc}
								</Typography>
							</Box>
						</Box>
					</>
				</Box>
			)}
		</>
	);
}
export default withRouter(NewsCard);
