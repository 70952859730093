import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Box, makeStyles, Hidden } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage06.png';

import {
	ChevronRight,
	StraightStripes,
	HomeScreenHalfCircle,
	DotIcon,
	Monument
} from '../../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',

		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		right: 0,
		width: 850,
		height: 640,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 300
		},
		[theme.breakpoints.only('md')]: {
			width: 700,
			height: 500,
			top: 70
		},
		[theme.breakpoints.only('sm')]: {
			width: 700,
			height: 500,
			top: 70
		},
		[theme.breakpoints.only('lg')]: {
			top: 39,
			width: 770,
			height: 590
		},
		[theme.breakpoints.only('xl')]: {
			top: -13
		}
	},
	pageContainer: {
		position: 'fixed',
		width: 1186,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 100,
		height: 772,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 0
		},
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%',
			height: 'auto',
			top: 0
		},
		[theme.breakpoints.only('lg')]: {
			top: 0
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},
	mainTitleBox: {
		top: 302,
		position: 'absolute',
		background: '#48B3C9',
		left: 0,
		zIndex: 101,
		[theme.breakpoints.only('md')]: {
			left: 30
		},
		[theme.breakpoints.only('xs')]: {
			left: 30,
			top: 190
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		textTransform: 'uppercase',
		color: '#070C35',
		fontSize: '50px',
		letterSpacing: '6px',
		lineHeight: '54px',
		marginTop: 7,
		marginRight: 38,
		marginBottom: 3,
		marginLeft: 27,
		[theme.breakpoints.only('xs')]: {
			marginTop: 7,
			marginRight: 20,
			marginBottom: 3,
			marginLeft: 20,
			fontSize: 30,
			lineHeight: '30px',
			letterSpacing: '2px'
		}
	},
	halfStripes: {
		top: 230,
		left: 62,
		position: 'absolute',
		zIndex: 100,
		width: '400px',
		heigh: '252px',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			left: 0,
			top: 50,
			opacity: 0.5
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: 516,
		width: 370,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 330
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			top: 600
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.only('md')]: {
			marginLeft: 30
		},
		[theme.breakpoints.only('xs')]: {
			marginLeft: 30
		},
		[theme.breakpoints.only('sm')]: {
			marginLeft: 30
		}
	},
	nextButton: {
		position: 'absolute',
		left: 0,
		bottom: 0,
		height: 60,
		width: 60,
		'&:hover': {
			background: '#FFFFFF'
		},
		transform: 'rotate(180deg)',
		margin: 20
	},
	buttonContainer: {
		position: 'absolute',
		top: 591,
		left: 'auto',
		right: 77
	},
	footerIconBox: {
		position: 'absolute',
		top: 404,
		height: 228,
		right: 199,
		[theme.breakpoints.only('xs')]: {
			height: 150,
			right: 20,
			top: 440
		},
		[theme.breakpoints.only('sm')]: {
			height: 150,
			right: 20,
			top: 630
		},
		[theme.breakpoints.only('md')]: {
			top: 550
		}
	},
	halfCircleContainer: {
		zIndex: 99,
		top: 341,
		left: 174,
		position: 'absolute',
		width: 192,
		height: 96,
		[theme.breakpoints.only('xs')]: {
			top: 220,
			height: 70,
			left: 150
		}
	},
	contentContainer: {
		height: 629,
		position: 'absolute',
		width: '100%',
		borderBottom: '3px solid #48B3C9',
		strokeLocation: 'center',
		strokeLinecap: 'round',
		strokeLinejoin: 'miter',
		[theme.breakpoints.only('xs')]: {
			top: 0,
			height: 5,
			border: 'none'
		},
		[theme.breakpoints.down('md')]: {
			height: 776,
			border: 'none'
		}
	},
	dotIconContainer: {
		position: 'absolute',
		top: 195,
		right: 178,
		[theme.breakpoints.only('xs')]: {
			display: 'none'
		}
	},
	navigateText: {
		color: '#48B3C9',
		fontFamily: 'Ubuntu',
		fontSize: 18,
		lineHeight: '18px',
		paddingRight: 17,
		paddingTop: 2,
		fontWeight: 'bold'
	},
	navigateButton: {
		margin: 'auto',
		width: 'fit-content',
		display: 'inline-flex',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	navigateToSiculicidium: {
		position: 'absolute',
		bottom: 72,
		right: 0,
		[theme.breakpoints.only('xs')]: {
			top: 600,
			right: 30
		},
		[theme.breakpoints.only('md')]: {
			top: 800,
			right: 30
		},
		[theme.breakpoints.only('sm')]: {
			top: 800
		}
	}
}));

function MonumentHistory1(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box
			className={classes.pageContainer}
			style={{ zIndex: showAnimation ? 200 : 150 }}>
			<Box
				className={classes.contentContainer}
				style={{
					borderBottom: showAnimation ? '3px solid #48B3C9' : 'none'
				}}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='vintageimage4'
					unmountOnExit>
					<Box className={classes.imageContainerFixed}>
						<Box className={classes.imageContainer} />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.dotIconContainer}>
						<DotIcon width={50} height={50} fill='#48B3C9' />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.halfCircleContainer}>
						<HomeScreenHalfCircle width='100%' height='100%' fill='#E0B080' />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							{t('monumhistory1.maintitle')}
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionText}>
							<Trans i18nKey='monumhistory1.description'>
								<br />
								<br />
							</Trans>
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.halfStripes}>
						<StraightStripes width='100%' height='100%' />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='rtllong'
					unmountOnExit>
					<Box className={classes.footerIconBox}>
						<Monument height='100%' fill='#48B3C9' dotfill='none' />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='rtllong'
					unmountOnExit>
					<Hidden xlDown>
						<Box className={classes.nextButton}>
							<ChevronRight height={24} width={24} fill='#070C35' />
						</Box>
					</Hidden>
				</CSSTransition>
			</Box>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.navigateToSiculicidium}>
					<Box
						className={classes.navigateButton}
						onClick={() => {
							props.history.push('/history/siculicidium');
						}}>
						<Typography className={classes.navigateText}>
							{t('monumhistory1.tosiculicidium')}
						</Typography>
						<ChevronRight fill='#48B3C9' width={24} height={24} />
					</Box>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('monumentHistory')(withRouter(MonumentHistory1));
