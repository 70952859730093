import React, { useEffect, useState, useLayoutEffect } from 'react';

import {
	Box,
	makeStyles,
	Typography,
	CardMedia,
	withWidth,
	Paper,
	CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import 'moment/min/locales';
import { withNamespaces } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';

import { withRouter } from 'react-router-dom';
import { Header } from './components/Header';
import {
	Call,
	Navigation,
	Village,
	NavigateBack,
} from '../../../components/icons';
import { PostBody } from '../../../components/layout';
import palette from '../../../themes/palette';
import { getHotelById } from '../../../store/hotel/actions';

const useStyles = makeStyles((theme) => ({
	mainContainer: {
		position: 'relative',
		height: 'fit-content',
	},
	titleContainer: {
		marginRight: '39.375%',
		marginLeft: '15.0625%',
		// top: "540px",
		background: palette.primary.dark,
		height: '116px',
		zIndex: 201,
		position: 'relative',
		[theme.breakpoints.only('xs')]: {
			top: 0,
			height: 'fit-content',
			marginLeft: -20,
			marginRight: -20,
			marginTop: -50,
			width: 'auto',
		},
		[theme.breakpoints.up('sm')]: {
			top: 0,
			height: 'fit-content',
			marginLeft: -20,
			marginRight: -20,
			marginTop: -50,
			width: 'auto',
		},
	},

	titleText: {
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		color: '#FAFAF5',
		paddingLeft: 30,
		paddingTop: 21,
		paddingBottom: 21,
		paddingRight: 27,

		[theme.breakpoints.only('xs')]: {
			fontSize: 25,
			paddingLeft: 20,
			paddingTop: 21,
			paddingBottom: 21,
			paddingRight: 20,
		},
	},
	infoContainer: {
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
	},
	contentContainer: {
		width: '58.75%',
		background: '#FAFAF5',
		height: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingLeft: 72,
		paddingRight: 72,
		// paddingTop: 112,
		display: 'table',
		marginBottom: '100px',
		boxSizing: 'border-box',
		[theme.breakpoints.only('xs')]: {
			paddingTop: 0,
			paddingRight: 20,
			paddingLeft: 20,
			marginLeft: 20,
			marginRight: 20,
			width: 'calc(100% - 40px)',
			height: 'fit-content',
		},
		[theme.breakpoints.only('sm')]: {
			paddingRight: 40,
			paddingLeft: 40,
			marginLeft: 40,
			marginRight: 40,
			width: 'calc(100% - 80px)',
		},
	},
	dateText: {
		color: '#E0B080',
		fontSize: '16px',
		lineHeight: '18px',
		paddingBottom: '21px',
		borderBottom: '1px solid #D5D4C9',
		marginBottom: '30px',
		fontWeight: 600,
		paddingTop: 50,
		[theme.breakpoints.only('xs')]: {
			paddingTop: 50,
		},
	},
	contentText: {
		lineHeight: '32px',
		fontSize: '14px',
		marginTop: '40px',
		fontWeight: 600,
		color: '#070c35',
		height: 'fit-content',
	},
	articleFooter: {
		paddingTop: '100px',
		borderTop: '1px solid #D5D4C9',
		marginTop: '24px',
	},
	navigateIconContainer: {
		top: 50,
		left: 50,
		position: 'absolute',
		[theme.breakpoints.down('sm')]: {
			top: 30,
			left: 30,
		},
	},

	tellAddress: {
		//  marginLeft:"24px",
		color: '#696B77',
		fontSize: '14px',
		// marginTop:"8px",
		fontWeight: 'bold',
	},
	hotelDetails: {
		// marginLeft:"-34px",
		color: '#070C35',
		fontSize: '14px',
		// marginTop:"8px",
		fontWeight: 'bold',
		marginLeft: 10,
	},
	hotelIntroContainer: {
		marginTop: '33px',
		marginBottom: '40px',
		color: '#070C35',
		fontSize: '14px',
		fontWeight: 'bold',
	},
	hotelDescription: {
		marginTop: '30px',
		marginLeft: '24px',
		color: '#696B77',
		fontSize: '14px',
		fontWeight: 'bold',
	},
	detailsContainer: {
		marginLeft: '-23px',
	},
	carousel: {
		height: '460px',
	},
	navigateCallContainer: {
		paddingTop: '24px',
	},
	footelVillage: {},
	imageContainer: {
		width: '100%',
		maxHeight: '600px',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		minWidth: 0,
		marginLeft: 0,
		height: 'fit-content',
	},
	img: {
		width: '100%',
		// height: "600px",
	},
	loaderContainer: {
		width: '100%',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

function Item(image) {
	const useStyles2 = makeStyles({
		root: {
			height: '100%',
			width: '100%',
		},
		media: {
			height: '460px',
		},
	});
	const classes = useStyles2();

	return (
		<Paper className={classes.papper}>
			<CardMedia
				className={classes.media}
				image={image.item.file.url}
				title='Contemplative Reptile'
			/>
		</Paper>
	);
}

function HotelDescription(props) {
	const { match } = props;
	const { history, t } = props;
	const { createdAt, content } = useSelector((state) => state.hotel.list);
	const [ready, setReady] = useState(30);
	const { hotel } = useSelector((state) => state);
	const language = useSelector((state) => state.language.language);
	const [localizedHotelItem, setLocalizedHotelItem] = useState(null);
	const classes = useStyles();
	const [imgReady, setImgReady] = useState(false);

	useEffect(() => {
		setLocalizedHotelItem(
			ready > 30 && content && content.length > 0
				? content.filter((item) => item.lang === language)[0]
				: null,
		);
		setReady(ready + 30);
		return () => {
			setLocalizedHotelItem(null);
		};
	}, [content]);

	useEffect(() => {
		if (localizedHotelItem != null) {
			const img = new Image();
			img.onload = () => {
				setReady(100);
				setImgReady(true);
			};
			img.src = localizedHotelItem.headerImage.url;
		} else {
			setImgReady(false);
		}
	}, [localizedHotelItem]);

	useLayoutEffect(() => {
		getHotelById(match.params.id);
		return () => {
			setLocalizedHotelItem(null);
		};
	}, []);

	const goBack = () => {
		history.push('/hotels');
		window.scrollTo(0, 0);
	};

	return (
		<Box className={classes.pageContainer}>
			<Header />
			{localizedHotelItem && imgReady ? (
				<Box className={classes.mainContainer}>
					<Box className={classes.infoContainer}>
						<Box className={classes.imageContainer}>
							<img
								className={classes.img}
								src={localizedHotelItem.headerImage.url}
								alt={localizedHotelItem.headerImage.id}
							/>
						</Box>
						<Box className={classes.contentContainer}>
							<Box className={classes.titleContainer}>
								<Typography className={classes.titleText}>
									{localizedHotelItem.name}
								</Typography>
							</Box>
							<Box className={classes.dateContainer}>
								<Typography className={classes.dateText}>
									{moment(createdAt).locale(language).format('MMMM DD, YYYY')}
								</Typography>
							</Box>
							<Grid
								container
								direction='row'
								justify='flex-start'
								alignItems='flex-start'>
								<Grid item md={10}>
									<Grid item md={12}>
										<Grid
											container
											direction='column'
											justify='flex-start'
											alignItems='flex-start'>
											{localizedHotelItem.address && (
												<Grid
													container
													direction='row'
													justify='flex-start'
													alignItems='flex-start'>
													<Grid item md={1}>
														<Typography className={classes.tellAddress}>
															{t('address')}:
														</Typography>
													</Grid>
													<Grid item md={11}>
														<Typography className={classes.hotelDetails}>
															{localizedHotelItem.address}
														</Typography>
													</Grid>
												</Grid>
											)}
										</Grid>
										<Grid item md={12}>
											<Grid
												container
												direction='column'
												justify='flex-start'
												alignItems='flex-start'>
												{localizedHotelItem.phone && (
													<Grid
														container
														direction='row'
														justify='flex-start'
														alignItems='flex-start'>
														<Grid item xs={1}>
															<Typography className={classes.tellAddress}>
																{t('phone')}:
															</Typography>
														</Grid>
														<Grid item xs={9}>
															<Typography className={classes.hotelDetails}>
																{localizedHotelItem.phone}
															</Typography>
														</Grid>
													</Grid>
												)}
											</Grid>
										</Grid>
										<Grid item md={12}>
											<Grid
												container
												direction='column'
												justify='flex-start'
												alignItems='flex-start'>
												{localizedHotelItem.page && (
													<Grid
														container
														direction='row'
														justify='flex-start'
														alignItems='flex-start'>
														<Grid item xs={1}>
															<Typography className={classes.tellAddress}>
																{t('web')}:
															</Typography>
														</Grid>
														<Grid item xs={9}>
															<Typography className={classes.hotelDetails}>
																{localizedHotelItem.page}
															</Typography>
														</Grid>
													</Grid>
												)}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid className={classes.navigateCallContainer} item md={1}>
									<Grid
										container
										direction='column'
										justify='center'
										alignItems='flex-end'>
										<Grid
											container
											direction='row'
											justify='space-evenly'
											alignItems='flex-end'>
											{localizedHotelItem.phone && (
												<Grid item xs={6}>
													<a href={`tel://+${localizedHotelItem.phone}`}>
														<Call height={24} width={24} />
													</a>
												</Grid>
											)}
											{localizedHotelItem.page && (
												<Grid item xs={6}>
													<a href={localizedHotelItem.page}>
														<Navigation height={24} width={24} />
													</a>
												</Grid>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								container
								direction='row'
								justify='flex-start'
								alignItems='flex-start'>
								<Grid item xs={12}>
									<Typography className={classes.hotelIntroContainer}>
										{localizedHotelItem.intro}
									</Typography>
								</Grid>
							</Grid>
							{hotel && hotel.slider && hotel.slider.content.length > 0 && (
								<Carousel
									navButtonsAlwaysVisible
									className={classes.carousel}
									indicators={false}>
									{hotel.slider.content.map((item, i) => (
										<Item key={i} item={item} />
									))}
								</Carousel>
							)}
							<Typography className={classes.contentText}>
								<PostBody content={localizedHotelItem.description} />
							</Typography>
							<Box className={classes.articleFooter} />
						</Box>
					</Box>
					<Box className={classes.navigateIconContainer}>
						<NavigateBack onButtonClick={goBack} />
					</Box>
				</Box>
			) : (
				<Box className={classes.loaderContainer}>
					<CircularProgress
						color='secondary'
						variant='determinate'
						value={ready}
					/>
				</Box>
			)}
			<Box className={classes.footelVillage}>
				<Village />
			</Box>
		</Box>
	);
}
export default withWidth()(
	withNamespaces('hotel')(withRouter(HotelDescription)),
);
