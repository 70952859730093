import React, { useState, useEffect, useRef } from 'react';
import { Box, makeStyles, Hidden } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import SiculicidiumHistoryAnimated from './SiculicidiumHistoryAnimated';
import SiculicidiumHistoryNotAnimated from './SiculicidiumHistoryNotAnimated';

const useStyles = makeStyles((theme) => ({
	container: {},
	slideContainer: {
		position: 'relative',
		height: '800px'
	}
}));

function SiculicidiumHistory(props) {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Hidden only={['xl', 'lg']}>
				<SiculicidiumHistoryNotAnimated />
			</Hidden>
			<Hidden only={['sm', 'xs', 'md']}>
				<SiculicidiumHistoryAnimated />
			</Hidden>
		</Box>
	);
}

export default withNamespaces('home')(SiculicidiumHistory);
