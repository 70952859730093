import { Box, makeStyles, Switch, Typography } from '@material-ui/core';
import React from 'react';
import { activateSight as activateItem } from '../../../store/sights/actions';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '8px',
		flexDirection: 'row',
		display: 'flex',
		alignItems: 'center'
	},
	title: { maxWidth: '20%' },
	buttons: {
		marginLeft: 'auto'
	}
}));

export default (props) => {
	const { active, itemId, onFinish } = props;
	const classes = useStyles();

	const toggle = (evt) => {
		activateItem(itemId, evt.target.checked);
		onFinish();
	};

	return (
		<Box divider className={classes.root}>
			<Typography className={classes.title}>Láthatóság</Typography>
			<Switch
				defaultChecked={active}
				className={classes.buttons}
				onClick={toggle}
			/>
		</Box>
	);
};
