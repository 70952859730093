import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function PanWithSquares(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width ? props.width : '431.764'}
			height={props.height ? props.height : '71.683'}
			viewBox='0 0 431.764 71.683'>
			<g transform='translate(-563 -2412)'>
				<g transform='translate(563 2412)'>
					<g transform='translate(60.889)'>
						<path
							className='a'
							fill='#ffb62c'
							d='M513.091,1386.025l-12.857-12.857a4.742,4.742,0,0,0-6.707,0l-12.857,12.857,12.857,12.857a4.743,4.743,0,0,0,6.707,0Z'
							transform='translate(-480.671 -1371.78)'
						/>
						<path
							className='b'
							fill='#ffce55'
							d='M509.321,1400.271a4.728,4.728,0,0,0,3.354-1.389l12.857-12.856-12.857-12.857a4.73,4.73,0,0,0-3.352-1.389Z'
							transform='translate(-493.112 -1371.78)'
						/>
						<g transform='translate(97.26)'>
							<path
								className='a'
								fill='#ffb62c'
								d='M665.435,1373.169l-12.857,12.857,12.857,12.857a4.743,4.743,0,0,0,6.707,0L685,1386.025l-12.857-12.857A4.742,4.742,0,0,0,665.435,1373.169Z'
								transform='translate(-652.578 -1371.78)'
							/>
							<path
								className='b'
								fill='#ffce55'
								d='M681.228,1371.78a4.728,4.728,0,0,1,3.353,1.389l12.856,12.857-12.856,12.856a4.729,4.729,0,0,1-3.354,1.389Z'
								transform='translate(-665.018 -1371.78)'
							/>
						</g>
						<g transform='translate(48.63 0)'>
							<path
								className='c'
								fill='#b54820'
								d='M599.044,1386.026l-12.857-12.857a4.743,4.743,0,0,0-6.707,0l-12.857,12.857,12.857,12.857a4.742,4.742,0,0,0,6.707,0Z'
								transform='translate(-566.624 -1371.78)'
							/>
							<path
								className='b'
								fill='#b97545'
								d='M595.275,1371.78a4.726,4.726,0,0,1,3.353,1.389l12.857,12.857-12.857,12.856a4.727,4.727,0,0,1-3.354,1.389Z'
								transform='translate(-579.065 -1371.78)'
							/>
						</g>
					</g>
					<g transform='translate(0 41.172)'>
						<path
							className='d'
							fill='#26002c'
							d='M621.112,1444.552H374.9a1.852,1.852,0,0,0-1.578,2.822l13.747,22.39a11.114,11.114,0,0,0,9.472,5.3H596.161a11.114,11.114,0,0,0,9.472-5.3l9.518-15.5H797.2a3.705,3.705,0,0,0,3.231-1.893l4.384-7.817Z'
							transform='translate(-373.049 -1444.552)'
						/>
						<path
							className='b'
							fill='#62006a'
							d='M718.123,1444.552H596.457v30.511h96.715a11.114,11.114,0,0,0,9.472-5.3l9.519-15.5H894.208a3.705,3.705,0,0,0,3.231-1.893l4.384-7.817Z'
							transform='translate(-470.06 -1444.552)'
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
