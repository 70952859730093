import React from 'react';
import { Drawer, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import navigationConfig from '../../navigationConfig';
import DrawerButton from './components/DrawerButton/DrawerButton';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.primary.dark,
	},
	rootOffice: {
		backgroundColor: '#F0F0EB',
	},
}));

export default (props) => {
	const { open, toggle, page } = props;
	const classes = useStyles();
	const emptySections = useSelector((state) => state.home.emptySections);

	const navConfig = navigationConfig(page).filter(
		({ name }) => !emptySections.includes(name),
	);

	return (
		<Drawer
			anchor='left'
			open={open}
			onClose={() => toggle(false)}
			classes={{ paper: page === 'lives' ? classes.root : classes.rootOffice }}>
			{navConfig.map((route, index) => (
				<DrawerButton
					route={route}
					page={page}
					key={index.toString()}
					onClick={() => toggle(false)}
				/>
			))}
		</Drawer>
	);
};
