import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Material icons: https://material.io/resources/icons/?icon=accessibility&style=baseline

export default function Emblema(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 114.876 194.657'>
			<path
				xmlns='http://www.w3.org/2000/svg'
				className='a'
				fill='#070c35'
				d='M553.516,1512.864a61.857,61.857,0,0,1,16.8-34.309,63.593,63.593,0,0,0-27.684,11.5A59.7,59.7,0,0,0,553.516,1512.864Z'
				transform='translate(-465.855 -1414.003)'
			/>
			<path
				xmlns='http://www.w3.org/2000/svg'
				className='a'
				fill='#070c35'
				d='M624.756,1572.112h0a32.027,32.027,0,0,0-20.037-11.631c-.547-.479-1.087-.977-1.615-1.48l-.065-.06q-1.267-1.2-2.46-2.477l-.067-.073q-1.2-1.273-2.321-2.62l-.048-.055c-.379-.451-.749-.91-1.114-1.371a61.549,61.549,0,0,1-13.3-38.265,62.491,62.491,0,0,1,40.5-58.243,64.128,64.128,0,0,0-11.424-3.174,55.179,55.179,0,0,0-20.422,16.018c-.72-.24-1.44-.45-2.16-.654a57.213,57.213,0,0,1,18.914-15.914c-2.09-.252-4.209-.41-6.36-.456h0a59.355,59.355,0,0,0-17.2,15.257c-.768-.154-1.541-.291-2.318-.418a61.384,61.384,0,0,1,15.482-14.8h0a64.76,64.76,0,0,0-9.379,1.061,59.631,59.631,0,0,0-14.734,83.036q.813,1.162,1.682,2.288c-.48.557-.926,1.127-1.375,1.7a61.777,61.777,0,0,1,9.432-85.875,62.084,62.084,0,0,0-21.06,110.14q-1.667.086-3.36.088a63.622,63.622,0,0,1-49.314-23.215,62.931,62.931,0,0,0,44.9,47.209,62.871,62.871,0,0,0-8.121,9.583,24.811,24.811,0,0,1,5.3,2.174,62.81,62.81,0,0,1,8.486-10.466q1.31.237,2.64.415a60.861,60.861,0,0,0-9.307,11.16,24.967,24.967,0,0,1,4.56,3.957,63.258,63.258,0,0,1,37.8-28.585q2.192.151,4.425.153a64.2,64.2,0,0,0,18.091-2.588h0a5.45,5.45,0,0,1,1.421.039,5.275,5.275,0,0,1,4.059,3.256,5.076,5.076,0,0,0-.151-5.116Zm-9.192-2.2a3.244,3.244,0,0,1-3.317-3.173,3.393,3.393,0,0,1,.029-.494q2.527,1.641,5.225,3.047A3.3,3.3,0,0,1,615.565,1569.913Z'
				transform='translate(-510.641 -1451.658)'
			/>
			<path
				xmlns='http://www.w3.org/2000/svg'
				className='a'
				fill='#070c35'
				d='M550.505,1515.747a24.759,24.759,0,1,0,8.265,34.025,24.761,24.761,0,0,0-8.265-34.025Zm-2.191,3.6a20.572,20.572,0,0,1,6.857,28.234l-35.09-21.377a20.568,20.568,0,0,1,28.233-6.859Z'
				transform='translate(-507.529 -1366.997)'
			/>
		</svg>
	);
}
