import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles, Hidden } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage12.png';
import Deposit from '../../../../../components/images/Depositphotos_4570392_xl-2015.png';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',

		backgroundSize: 'cover',
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		width: 545,
		height: 734,
		top: 0,
		right: 0,
		[theme.breakpoints.only('xs')]: {
			top: 0,
			width: '100%',
			left: 0,
			height: 500,
		},
		[theme.breakpoints.only('sm')]: {
			width: 430,
			height: 600,
			top: 300,
		},
	},
	depositContainer: {
		position: 'absolute',
		background: `transparent url(${Deposit}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover',
	},
	depositContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		width: 480,
		height: 320,
		top: 34,
		left: 144,
		[theme.breakpoints.only('xs')]: {
			top: 480,
			width: '100%',
			left: 0,
			height: 200,
		},
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%',
		},
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 62,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
		},
		[theme.breakpoints.only('md')]: {
			top: 0,
		},
		[theme.breakpoints.only('xl')]: {
			top: 200,
		},
	},
	mainTitleBox: {
		position: 'absolute',
		background: theme.palette.primary.dark,
		opacity: 1,
		textAlign: 'left',
		zIndex: 101,
		height: 70,
		[theme.breakpoints.only('xs')]: {
			height: 46,
		},
	},
	mainTitleText: {
		lineHeight: '54px',
		fontFamily: 'Bebas Neue',
		fontSize: '50px',
		textTransform: 'uppercase',
		display: 'flex',
		paddingTop: 12,
		paddingBottom: 4,
		[theme.breakpoints.only('xs')]: {
			fontSize: 30,
			lineHeight: '30px',
			letterSpacing: '2px',
		},
	},
	descriptionContainer: {
		position: 'absolute',
		top: 374,
		width: 576,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			top: 700,
			width: '100%',
		},
		[theme.breakpoints.only('md')]: {
			width: 500,
		},
		[theme.breakpoints.only('sm')]: {
			width: 'calc(100% - 400px)',
		},
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.only('xs')]: {
			marginRight: 30,
			marginLeft: 20,
			fontSize: '16px',
		},
		[theme.breakpoints.only('md')]: {
			marginRight: 30,
			marginLeft: 20,
		},
		[theme.breakpoints.only('sm')]: {
			marginRight: 30,
			marginLeft: 20,
		},
	},
}));

function MadefalvaHistory5(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);

	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='bttmiddle'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrmiddle'
				unmountOnExit>
				<Box className={classes.depositContainerFixed}>
					<Box className={classes.depositContainer} />
				</Box>
			</CSSTransition>

			<Hidden only={['xs']}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrshort'
					unmountOnExit>
					<Box
						className={classes.mainTitleBox}
						style={{ top: '98px', width: 249 }}>
						<Typography
							className={classes.mainTitleText}
							style={{
								background: '#E0B080',
								color: 'rgba(230, 15, 55, 0.9)',
								paddingRight: 47,
								paddingLeft: 47,
							}}>
							splendor
						</Typography>
					</Box>
				</CSSTransition>
			</Hidden>
			<Hidden smUp>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrshort'
					unmountOnExit>
					<Box
						className={classes.mainTitleBox}
						style={{ top: '520px', width: 150 }}>
						<Typography
							className={classes.mainTitleText}
							style={{
								background: '#E0B080',
								color: 'rgba(230, 15, 55, 0.9)',
								paddingRight: 20,
								paddingLeft: 20,
							}}>
							splendor
						</Typography>
					</Box>
				</CSSTransition>
			</Hidden>

			<Hidden only={['xs']}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrmiddle'
					unmountOnExit>
					<Box
						className={classes.mainTitleBox}
						style={{ top: '168px', left: '40px' }}>
						<Typography
							className={classes.mainTitleText}
							style={{
								background: 'rgba(230, 15, 55, 0.8)',
								color: 'rgba(7, 12, 53, 1)',
								paddingRight: 84,
								paddingLeft: 84,
							}}>
							domus
						</Typography>
					</Box>
				</CSSTransition>
			</Hidden>
			<Hidden smUp>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrmiddle'
					unmountOnExit>
					<Box
						className={classes.mainTitleBox}
						style={{ top: '566px', left: '40px' }}>
						<Typography
							className={classes.mainTitleText}
							style={{
								background: 'rgba(230, 15, 55, 0.8)',
								color: 'rgba(7, 12, 53, 1)',
								paddingRight: 50,
								paddingLeft: 50,
							}}>
							domus
						</Typography>
					</Box>
				</CSSTransition>
			</Hidden>

			<Hidden only={['xs']}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box
						className={classes.mainTitleBox}
						style={{ top: '238px', left: '120px' }}>
						<Typography
							className={classes.mainTitleText}
							style={{
								background: 'rgba(7, 12, 53, 1)',
								color: 'rgba(238, 44, 72, 1)',
								paddingRight: 86,
								paddingLeft: 86,
							}}>
							nobilitas
						</Typography>
					</Box>
				</CSSTransition>
			</Hidden>
			<Hidden smUp>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box
						className={classes.mainTitleBox}
						style={{ top: '612px', left: '80px' }}>
						<Typography
							className={classes.mainTitleText}
							style={{
								background: 'rgba(7, 12, 53, 1)',
								color: 'rgba(238, 44, 72, 1)',
								paddingRight: 50,
								paddingLeft: 50,
							}}>
							nobilitas
						</Typography>
					</Box>
				</CSSTransition>
			</Hidden>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='madehistory5.description' />
					</Typography>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('madefalvaHistory')(MadefalvaHistory5);
