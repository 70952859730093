import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Vintage from '../../../../../components/images/vintage14.png';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Vintage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		width: 783,
		height: 590,
		top: 0,
		right: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 0,
			left: 0,
			height: 320
		},
		[theme.breakpoints.only('md')]: {
			width: 600,
			height: 500
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 530,
			right: 0,
			left: 0
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: 1209,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 120,
		[theme.breakpoints.only('xs')]: {
			top: 0,
			width: '100%'
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%'
		},
		[theme.breakpoints.only('xl')]: {
			top: 200
		}
	},
	mainTitleBox: {
		top: 52,
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: 63,
		textAlign: 'left',
		zIndex: 101
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#E0B080',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: 12,
		marginRight: 86,
		marginBottom: 12,
		marginLeft: 86
	},
	halfStripes: {
		top: 0,
		left: 71,
		position: 'absolute',
		zIndex: 100
	},
	descriptionContainer: {
		position: 'absolute',
		top: 67,
		width: 480,
		left: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 300
		},
		[theme.breakpoints.only('md')]: {
			width: 400
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			top: 530
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.only('xs')]: {
			fontSize: 16,
			marginLeft: 30,
			marginRight: 10
		},
		[theme.breakpoints.only('md')]: {
			marginLeft: 30,
			marginRight: 10
		},
		[theme.breakpoints.only('sm')]: {
			marginLeft: 30,
			marginRight: 10
		}
	},
	noteText: {
		fontFamily: 'Ubuntu',
		color: '#070C35',
		fontSize: '11px',
		letterSpacing: '-0.22px',
		lineHeight: '20px',
		marginTop: 4,
		marginRight: 18,
		marginBottom: 4,
		marginLeft: 16,
		fontWeight: 'bold'
	},
	noteContainer: {
		top: 624,
		position: 'absolute',
		background: '#E0B080',
		opacity: 1,
		right: 0,
		width: 493,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 900
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			top: 830
		}
	},
	contentContainer: {
		height: 594,
		position: 'absolute',
		width: '100%',
		borderBottom: '3px solid #48B3C9',
		[theme.breakpoints.only('xs')]: {
			border: 'none'
		},
		[theme.breakpoints.only('sm')]: {
			border: 'none'
		},
		strokeLocation: 'center',
		strokeLinecap: 'round',
		strokeLinejoin: 'miter'
	}
}));

function MadefalvaHistory7(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.contentContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.imageContainerFixed}>
					<Box className={classes.imageContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='bttmiddle'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						<Trans i18nKey='madehistory7.description' />
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='rtllong'
				unmountOnExit>
				<Box className={classes.noteContainer}>
					<Typography className={classes.noteText}>
						<Trans i18nKey='madehistory7.note' />
					</Typography>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('madefalvaHistory')(MadefalvaHistory7);
