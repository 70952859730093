import React from 'react';
import { FieldArray } from 'formik';
import {
	Box,
	Grid,
	CardMedia,
	makeStyles,
	Button,
	Paper
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import { ImageSelector } from '../modals';

import palette from '../../themes/palette';

function Item(image) {
	const useStyles = makeStyles({
		root: {
			height: '100%',
			width: '100%'
		},
		media: {
			height: 200
		}
	});
	const classes = useStyles();
	return (
		<Paper className={classes.papper}>
			<CardMedia
				className={classes.media}
				image={image.item.url}
				title='Contemplative Reptile'
			/>
		</Paper>
	);
}

const useStyles = makeStyles({
	slider: {
		marginTop: '20px'
	},
	sliderImageSelectorsBox: {
		border: `1px solid ${palette.black}`,
		padding: '20px'
	},
	btn: {
		color: 'white',
		marginTop: 20
	}
});

function Gallery(props) {
	const image = useSelector((state) => state.file.view);

	const { setFieldValue, values } = props;
	const classes = useStyles();

	const addNewImage = (index, img) => {
		setFieldValue(`gallery.content[${index}].fileId`, img.id);
	};

	return (
		<Box className={classes.sliderImageSelectorsBox}>
			<Grid container spacing={4}>
				<FieldArray
					name='gallery.content'
					render={() =>
						values.content.map((item, index) => (
							<Grid item md={image && image.url ? 2 : 4}>
								<ImageSelector
									slider
									fieldName={`gallery.content[${index}].fileId`}
									image={item.file}
									setImageId={(img) => {
										addNewImage(index, img);
									}}
								/>
							</Grid>
						))
					}
				/>
			</Grid>
			<Button
				className={classes.btn}
				onClick={() => {
					setFieldValue(
						`gallery.content[${values.content.length}].fileId`,
						null
					);
					setFieldValue(
						`gallery.content[${values.content.length}].num`,
						values.content.length + 1
					);
				}}>
				+ Új kép
			</Button>
		</Box>
	);
}
export default Gallery;
