import { VIEW_IMAGE, FILE_TYPE } from '../types';

export default function file(state = {}, action) {
	switch (action.type) {
		case FILE_TYPE.IMAGE:
			return { ...state, images: action.payload };
		case FILE_TYPE.ATTACHMENT:
			return { ...state, files: action.payload };
		case VIEW_IMAGE:
			return { ...state, view: action.payload };
		default:
			return state;
	}
}
