import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Box,
	Grid,
	Typography,
	withWidth,
	Hidden
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';

import { useSelector } from 'react-redux';
import QueryString from 'qs';
import { useHistory } from 'react-router-dom';
import {
	Village2,
	Document1,
	SocialOffice
} from '../../../../../components/icons';
import { getByCategory } from '../../../../../store/office/actions';
import Description from './components/Description/Description';
import Downloadables from './components/Downloadables/Downloadables';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import { transformQuery } from '../../../../../lib/helpers/queryTransformer';
import Search from '../Search/Search';
import Pagination from '../../../../../components/Pagination/Pagination';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		width: '100%',
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	contentContainer: {
		maxWidth: 1128,
		right: 0,
		left: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		paddingTop: 30
	},
	titleContainer: {
		width: '100%',
		height: 25,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 30,
		borderBottom: '1px solid #D5D4C9'
	},
	categoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '14px',
		fontSize: '13px',
		letterSpacing: '0px'
	},
	churchCategoryTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '32px',
		letterSpacing: '0px',
		paddingTop: 89,
		paddingLeft: 10,
		paddingBottom: 50
	},
	imageContainer: {
		height: 264,
		width: 264,
		background: `transparent url(${Image}) 0% 0% no-repeat padding-box`,
		marginRight: 24
	},
	desciptionContainer: {
		width: 'calc(100% - 264px)'
	},
	content: {
		display: 'flex',
		width: '100%'
	},
	descriptionTitle: {
		fontFamily: 'Ubuntu',
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '34px'
	},
	descriptionTitleContainer: {
		paddingBottom: 39
	},
	descriptionText: {
		fontFamily: 'Ubuntu',
		lineHeight: '24px',
		fontSize: '14px',
		flexWrap: 'wrap',
		fontWeight: 'bold'
	},
	cardsContainer: {
		display: 'inline-flex',
		paddingTop: 40
	},
	village2Container: {
		paddingTop: '40px'
	},
	title2Container: {
		width: '100%',
		height: 73,
		flex: 2,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 70,
		paddingBottom: 10,
		borderBottom: '1px solid #D5D4C9',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		[theme.breakpoints.only('xs')]: {
			marginTop: 52
		}
	},
	title2ContainerSmall: {
		width: '100%',
		height: 73,
		flex: 1,
		display: 'inline-flex',
		margin: 'auto',
		marginBottom: 70,
		borderBottom: '1px solid #D5D4C9',
		alignItems: 'flex-end',
		justifyContent: 'space-between'
	},
	pageTitle: {
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: '34px',
		fontSize: '28px',
		letterSpacing: '0px',
		marginLeft: 20,
		marginBottom: theme.spacing(1),
		[theme.breakpoints.only('sm')]: {
			fontSize: 28
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: 20
		}
	},
	pageTitleVertical: {
		fontFamily: 'Ubuntu',
		fontSize: 18,
		letterSpacing: 0,
		marginBottom: theme.spacing(1),
		textAlign: 'center'
	},
	tabButton: {
		cursor: 'pointer',
		width: '50%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end'
	},
	tabButtonVertical: {
		cursor: 'pointer',
		width: '50%',
		marginBottom: 8
	},
	fixHeight: {
		height: 50,
		maxHeight: 50,
		marginTop: 8
	}
}));

export default withNamespaces('council')(
	withWidth()((props) => {
		const { t } = props;
		const classes = useStyles();

		const [selectedTab, setSelectedTab] = useState(0);

		const data = useSelector((state) => state.office.list);
		const history = useHistory();

		const queries = QueryString.parse(history.location.search, {
			ignoreQueryPrefix: true
		});

		const [page, setPage] = useState(1);

		const pushPage = (up) => {
			const nPage = page ? Number(page) : 1;
			const pageTmp = up ? nPage + 1 : nPage - 1;
			history.push({
				pathname: '/soc_off',
				search: transformQuery({
					category: 'soc_off',
					limit: 10,
					page: pageTmp
				})
			});
			updatePage(pageTmp);
			window.scrollTo(0, 0);
		};

		const handlePageChange = (e) => {
			if (e.keyCode === 13) {
				if (e.target.value > data?.meta?.totalPages || e.target.value < 1) {
					return;
				}
				history.push({
					pathname: '/soc_off',
					search: transformQuery({
						category: 'soc_off',
						limit: 10,
						page: e.target.value
					})
				});
				window.scrollTo(0, 0);
			}
		};

		useEffect(() => {
			if (
				data?.meta?.totalPages &&
				queries.page &&
				queries.page <= data.meta.totalPages
			) {
				updatePage(queries.page);
			}
		}, [data, queries.page]);

		const updatePage = (newPage) => {
			if (page !== newPage) {
				setPage(newPage);
			}
		};

		useEffect(() => {
			getByCategory(transformQuery(queries));
		}, [page]);

		return (
			<Box className={classes.pageContainer}>
				<Box className={classes.contentContainer}>
					<Box className={classes.titleContainer}>
						<Breadcrumbs
							breadcrumbs={[
								{
									href: 'council',
									name: t('category')
								},
								{
									href: 'soc_off',
									name: t('soc_off'),
									final: true
								}
							]}
						/>
					</Box>

					<Box className={classes.title2Container}>
						<Hidden smUp>
							<Grid
								container
								direction='column'
								alignContent='center'
								alignItems='center'
								className={classes.tabButtonVertical}
								onClick={() => setSelectedTab(0)}>
								<SocialOffice width={60} height={44} />
								<Box className={classes.fixHeight}>
									<Typography className={classes.pageTitleVertical}>
										{t('socialhelp')}
									</Typography>
								</Box>
							</Grid>
							<Grid
								container
								direction='column'
								alignContent='center'
								alignItems='center'
								className={classes.tabButtonVertical}
								onClick={() => setSelectedTab(1)}>
								<Document1
									width={60}
									height={44}
									dotfill={selectedTab === 1 ? false : '#93959F'}
									fill={selectedTab === 1 ? false : '#93959F'}
								/>
								<Box className={classes.fixHeight}>
									<Typography className={classes.pageTitleVertical}>
										{t('downloadable')}
									</Typography>
								</Box>
							</Grid>
						</Hidden>
						<Hidden xsDown>
							<Box
								className={classes.tabButton}
								onClick={() => setSelectedTab(0)}>
								<SocialOffice width={75} height={55} />
								<Typography className={classes.pageTitle}>
									{t('socialhelp')}
								</Typography>
							</Box>
							<Box
								className={classes.tabButton}
								onClick={() => setSelectedTab(1)}>
								<Document1
									width={75}
									height={55}
									dotfill={selectedTab === 1 ? false : '#93959F'}
									fill={selectedTab === 1 ? false : '#93959F'}
								/>
								<Typography className={classes.pageTitle}>
									{t('downloadable')}
								</Typography>
							</Box>
						</Hidden>
					</Box>
					{selectedTab === 1 && <Search category='soc_off' />}
					<Box className={classes.content}>
						{selectedTab === 0 ? (
							<Description />
						) : (
							<Downloadables data={data} />
						)}
					</Box>
					{selectedTab === 1 && (
						<Pagination
							page={page}
							setPage={setPage}
							handlePageChange={handlePageChange}
							currentPage={data?.meta?.currentPage}
							totalPages={data?.meta?.totalPages ? data.meta.totalPages : 1}
							t={t}
							pushPage={pushPage}
						/>
					)}
				</Box>

				<Box className={classes.village2Container}>
					<Village2 />
				</Box>
			</Box>
		);
	})
);
