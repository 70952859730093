import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	cls1: {
		fill: 'none',
		stroke: '#160e44',
		strokeLinecap: 'round',
		strokeMiterlimit: 10
	}
}));

const Organigram = (props) => {
	const classes = useStyles();
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={props.width}
			height={props.height}
			viewBox='0 0 89.29 80.05'>
			<g id='Layer_2' data-name='Layer 2'>
				<g id='Layer_1-2' data-name='Layer 1'>
					<g id='Layer_2-2' data-name='Layer 2'>
						<g id='Layer_1-2-2' data-name='Layer 1-2'>
							<line
								className={classes.cls1}
								x1='44.65'
								y1='14.44'
								x2='44.65'
								y2='65.61'
							/>
							<path
								className={classes.cls1}
								d='M7.47,65.62V42.86A2.84,2.84,0,0,1,10.3,40H79a2.83,2.83,0,0,1,2.83,2.84h0V65.62'
							/>
							<circle className={classes.cls1} cx='44.62' cy='7.47' r='6.97' />
							<circle className={classes.cls1} cx='44.62' cy='7.47' r='2.72' />
							<circle className={classes.cls1} cx='44.65' cy='72.58' r='6.97' />
							<circle className={classes.cls1} cx='7.47' cy='72.58' r='6.97' />
							<circle className={classes.cls1} cx='81.82' cy='72.58' r='6.97' />
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default Organigram;
