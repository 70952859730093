import React from 'react';
import {
	Box,
	makeStyles,
	Typography,
	Button,
	Grid,
	useMediaQuery
} from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useSelector } from 'react-redux';
import { Document1 } from '../../../../../components/icons';

const BP1 = '@media (max-width: 610px)';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 180,
		background: '#FAFAF5',
		paddingBottom: '16px',
		paddingRight: '10px',
		paddingLeft: '10px',
		marginLeft: '44px',
		marginRight: '24px',
		marginBottom: theme.spacing(5)
	},
	content: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row'
	},
	border: {
		border: '1px solid #D5D4C9',
		borderRadius: 4
	},
	iconBox: {
		// position: 'relative',
		height: 192,
		width: 192,
		minWidth: 192,
		backgroundColor: '#FFFFFF',
		marginLeft: '-30px',
		marginTop: '-20px'
	},
	iconContainer: {
		width: 102,
		marginTop: 55,
		marginLeft: 45
	},
	title: {
		marginTop: 20,
		marginLeft: 24,
		transition: 'all .3s',
		[BP1]: {
			marginLeft: 8
		}
	},
	cardTitle: {
		color: theme.palette.primary.dark,
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		fontSize: 20,
		transition: 'all .3s',
		overflowWrap: 'break-word',
		[BP1]: {
			fontSize: 18
		}
	},
	cardSubTitle: {
		color: '#E0B080',
		fontFamily: 'Ubuntu',
		fontWeight: 'medium',
		fontSize: '16px',
		transition: 'all .3s',
		marginTop: 10,
		[BP1]: {
			fontSize: '14px',
			marginTop: '8px'
		}
	},
	buttonContainer: {
		// width: 176,
		marginLeft: 'auto',
		marginRight: 20,
		marginTop: 20
	},
	anchor: {
		marginLeft: 'auto',
		marginRight: 'auto',
		textDecoration: 'none'
	},
	button: {
		width: 160,
		height: 60,
		color: theme.palette.primary.contrastText,
		fontFamily: 'Bebas Neue',
		fontWeight: 'bold',
		fontSize: '18px',
		transition: 'all .3s',
		'&:hover': {
			backgroundColor: '#48B3C9',
			color: '#FFFFFF'
		},
		[BP1]: {
			width: 140,
			height: 40
		}
	},
	rightContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	bigBtnContainer: {
		width: '160px',
		marginLeft: '8px'
	},
	bottomTextContainer: {
		marginTop: '20px',
		marginBottom: '8px'
	},
	bottomBtnContainer: {
		marginLeft: 'auto',
		marginRight: 'auto'
	}
}));

function FileCard(props) {
	const classes = useStyles();
	const br1 = useMediaQuery('(max-width: 640px)');
	const br2 = useMediaQuery('(max-width: 480px)');

	const { file, t, border } = props;
	const lang = useSelector((state) => state.language.language);

	const data =
		file.content.length === 1
			? file.content[0]
			: file.content.find((item) => item.lang === lang);

	const renderButton = () => (
		<Grid container direction='row' className={classes.buttonContainer}>
			<a
				href={data.file.url}
				download
				target='_blank'
				rel='noopener noreferrer'
				className={classes.anchor}>
				<Button
					className={classes.button}
					color='primary'
					endIcon={<CloudDownloadIcon />}>
					{t('common:download')}
				</Button>
			</a>
		</Grid>
	);

	const renderTitle = () => (
		<Typography className={classes.cardTitle}>
			{data && data.fileName
				? data.fileName
				: data.file.url.substring(
					data.file.url.lastIndexOf('/') + 1,
					data.file.url.length
				  )}
		</Typography>
	);

	return (
		<>
			<Box className={[classes.root, border ? classes.border : {}]}>
				<Box className={classes.content}>
					<Box className={classes.iconBox}>
						<Box className={classes.iconContainer}>
							<Document1 width='120px' height='80px' />
						</Box>
					</Box>
					<Box className={classes.rightContainer}>
						{!br2 && (
							<Box className={classes.title}>
								{renderTitle()}
								{br1 && <>{renderButton()}</>}
							</Box>
						)}
						{!br1 && (
							<Box className={classes.bigBtnContainer}>{renderButton()}</Box>
						)}
					</Box>
				</Box>
				{br2 && (
					<>
						<Box className={classes.bottomTextContainer}>{renderTitle()}</Box>
						<Box className={classes.bottomBtnContainer}>{renderButton()}</Box>
					</>
				)}
			</Box>
		</>
	);
}
export default withNamespaces('council')(FileCard);
