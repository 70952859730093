import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import {
	makeStyles,
	Box,
	Grid,
	IconButton,
	InputBase,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

import qs from 'qs';

import { transformQuery } from '../../../../../../lib/helpers/queryTransformer';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#FAFAF5',
		marginTop: 30,
		marginBottom: 30,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	searchBox: {
		width: '100%',
		height: 40,
		backgroundColor: '#F0F0EB',
		padding: 1,
		marginTop: 20,
		marginBottom: 20,
		marginLeft: 24,
		marginRight: 24,
	},
	iconButton: {
		borderRadius: 0,
		width: 38,
		height: 38,
		backgroundColor: '#FFFFFF',
		color: theme.palette.primary.dark,
	},
	input: {
		fontFamily: 'Ubuntu',
		marginLeft: 10,
		fontSize: '14px',
		letterSpacing: '0px',
		width: '90%',
		[theme.breakpoints.only('xs')]: {
			width: '80%',
		},
	},
}));

export default withNamespaces('gazette')((props) => {
	const { t, category } = props;
	const classes = useStyles();

	const history = useHistory();

	const [query, setQuery] = useState({});
	const [search, setSearch] = useState(null);

	useEffect(() => {
		setQuery(
			qs.parse(history.location.search, {
				ignoreQueryPrefix: true,
			}),
		);
	}, [history.location.search]);

	useEffect(() => {
		setSearch(query?.s ? query.s : null);
	}, [query]);

	const handleSearch = () => {
		window.scrollTo(0, 0);

		history.push({
			pathname: `/gazette/${category}`,
			search: transformQuery({ ...query, s: search || '', page: 1 }),
		});
	};

	return (
		<Grid container spacing={0} className={classes.root}>
			<Grid item className={classes.searchBox}>
				<Box>
					<IconButton className={classes.iconButton} onClick={handleSearch}>
						<SearchIcon />
					</IconButton>
					<InputBase
						onChange={(evt) => setSearch(evt.target.value)}
						value={search}
						className={classes.input}
						placeholder={t('search')}
						inputProps={{ 'aria-label': 'search news' }}
					/>
				</Box>
			</Grid>
		</Grid>
	);
});
