import React, { useState, useEffect } from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import Horse from '../../../../components/images/h_03.png';
import BackgroundImage from '../../../../components/images/wcolor_bg_04.png';

import { StraightStripes } from '../../../../components/icons';

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		position: 'absolute',
		background: `transparent url(${Horse}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		left: 0,
		width: '600px',
		height: '265px',
		top: '330px',
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 190,
			left: 0,
			top: 150
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 450,
			left: 0,
			top: 150
		},
		[theme.breakpoints.only('md')]: {
			width: 500,
			height: 270,
			left: 0,
			top: 300
		}
	},
	imageBackgroundContainer: {
		position: 'absolute',
		background: `transparent url(${BackgroundImage}) 0% 0% no-repeat padding-box`,
		mixBlendMode: 'multiply',
		width: '100%',
		height: '100%',
		backgroundSize: 'cover'
	},
	imageBackContainerFixed: {
		position: 'absolute',
		backgroundColor: '#DDE9E6',
		left: '89px',
		width: '600px',
		height: '239px',
		top: '58px',
		backgroundSize: 'cover',
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			height: 130,
			left: 0,
			top: 30
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			height: 250,
			left: 0,
			top: 30
		},
		[theme.breakpoints.only('md')]: {
			width: 500,
			height: 270,
			left: 0
		}
	},
	pageContainer: {
		position: 'fixed',
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			width: '100%'
		},
		width: '1128px',
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		[theme.breakpoints.only('lg')]: {
			top: 120
		},
		[theme.breakpoints.only('xl')]: {
			top:147
		}
	},
	contentContainer: {
		top: '97px',
		width: '100%',
		height: '869px',
		position: 'absolute'
	},
	mainTitleBox: {
		top: '114px',
		position: 'absolute',
		background: theme.palette.primary.dark,
		left: '396px',
		textAlign: 'left',
		zIndex: 101,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			top: 840,
			left: 'auto',
			right: 0
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		color: '#EE2C48',
		fontSize: '30px',
		letterSpacing: '0.30px',
		lineHeight: '30px',
		marginTop: '18.5px',
		marginRight: '39px',
		marginBottom: '15.5px',
		marginLeft: '39px'
	},
	halfStripes: {
		top: '0px',
		left: '392px',
		position: 'absolute',
		zIndex: 100,
		width: '400px',
		height: '252px',
		[theme.breakpoints.only('xs')]: {
			width: 250,
			top: 0,
			left: 'auto',
			right: 0
		},
		[theme.breakpoints.only('sm')]: {
			width: 400,
			top: 0,
			left: 'auto',
			right: 100
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: '278px',
		width: '552px',
		right: 0,
		[theme.breakpoints.only('xs')]: {
			width: '100%',
			top: 350
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			top: 600
		}
	},
	descriptionText: {
		color: '#282D38',
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 30,
			marginRight: 20
		}
	}
}));

function SiculicidiumHistory5(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<Box className={classes.pageContainer}>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.contentContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageBackContainerFixed}>
					<Box className={classes.imageBackgroundContainer} />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.imageContainer} />
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.halfStripes}>
					<StraightStripes width='100%' height='100%' />
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.mainTitleBox}>
					<Typography className={classes.mainTitleText}>
						{t('megerint13.maintitle')}
					</Typography>
				</Box>
			</CSSTransition>
			<CSSTransition
				in={showAnimation}
				timeout={300}
				classNames='ltrlong'
				unmountOnExit>
				<Box className={classes.descriptionContainer}>
					<Typography className={classes.descriptionText}>
						{t('megerint13.description')}
					</Typography>
				</Box>
			</CSSTransition>
		</Box>
	);
}

export default withNamespaces('siculicidiumHistory')(SiculicidiumHistory5);
