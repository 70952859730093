import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { withNamespaces, Trans } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import {
	ChevronRight,
	StraightStripes,
	HomeScreenHalfCircle
} from '../../../../../components/icons';

const BP380 = '@media (max-width: 380px)';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		position: 'relative',
		width: 1128,
		marginLeft: 'auto',
		marginRight: 'auto',
		right: 0,
		left: 0,
		top: 150,
		[theme.breakpoints.up('lg')]: {
			marginTop: '-100px'
		},
		[theme.breakpoints.down('md')]: {
			position: 'relative',
			width: '100%',
			minHeight: '100vh'
		}
		/* [theme.breakpoints.only('lg')]: {
			height: '100%'
		}, */
	},
	mainTitleBox: {
		top: 73,
		position: 'absolute',
		background: '#EE2C48',
		left: 0,
		zIndex: 101,
		[theme.breakpoints.only('xs')]: {
			top: 330
		},
		[theme.breakpoints.only('sm')]: {
			top: 220,
			left: 30
		}
	},
	mainTitleText: {
		fontFamily: 'Bebas Neue',
		textTransform: 'uppercase',
		color: '#070C35',
		fontSize: '50px',
		letterSpacing: '25px',
		lineHeight: '54px',
		marginTop: 7,
		marginRight: 38,
		marginBottom: 3,
		marginLeft: 27,
		[theme.breakpoints.only('xs')]: {
			letterSpacing: '10px'
		}
	},
	halfStripes: {
		top: 0,
		left: 62,
		position: 'absolute',
		zIndex: 100,
		width: 300,
		[theme.breakpoints.only('xs')]: {
			width: 300,
			height: 300,
			top: 230
		},
		[theme.breakpoints.only('sm')]: {
			top: 150
		}
	},
	descriptionContainer: {
		position: 'absolute',
		top: 75,
		width: 432,
		right: 0,
		[theme.breakpoints.only('xs')]: {
			top: 0,
			width: '100%'
		},
		[theme.breakpoints.only('sm')]: {
			top: 0,
			width: '100%'
		}
	},
	descriptionContainer2: {
		position: 'absolute',
		top: 307,
		width: 432,
		left: 220,
		[theme.breakpoints.only('xs')]: {
			// top: 0,
			width: '100%',
			top: 490,
			left: 0
		},
		[theme.breakpoints.only('sm')]: {
			width: '100%',
			top: 400,
			left: 0
		}
	},
	descriptionText: {
		textAlign: 'left',
		letterSpacing: '0.18px',
		fontSize: '18px',
		lineHeight: '30px',
		flexWrap: 'wrap',
		fontWeight: 'bold',
		[theme.breakpoints.only('xs')]: {
			marginRight: 20,
			marginLeft: 30
		},
		[theme.breakpoints.only('sm')]: {
			marginRight: 20,
			marginLeft: 30
		}
	},
	nextButton: {
		position: 'absolute',
		left: 0,
		top: 590,
		height: 60,
		width: 60,

		transform: 'rotate(-90deg)',
		margin: 20,
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			display: 'none'
		},
		[theme.breakpoints.only('lg')]: {
			top: 490
		}
	},
	buttonContainer: {
		position: 'absolute',
		top: 591,
		left: 'auto',
		right: 77
	},
	halfCircleContainer: {
		zIndex: 99,
		top: 111,
		left: 174,
		position: 'absolute',
		width: 192,
		height: 96,
		[theme.breakpoints.only('xs')]: {
			width: 150,
			top: 370
		},
		[theme.breakpoints.only('sm')]: {
			top: 260
		}
	},
	contentContainer: {
		height: 594,
		position: 'absolute',
		width: '100%',
		borderBottom: '3px solid #48B3C9',
		strokeLocation: 'center',
		strokeLinecap: 'round',
		strokeLinejoin: 'miter',
		[theme.breakpoints.only('xs')]: {
			border: 'none'
		},
		[theme.breakpoints.only('sm')]: {
			border: 'none'
		},
		[theme.breakpoints.only('lg')]: {
			height: 520
		}
	},
	dotIconContainer: {
		position: 'absolute',
		top: 195,
		right: 178
	},
	navigateText: {
		color: '#48B3C9',
		fontFamily: 'Ubuntu',
		fontSize: 18,
		lineHeight: '18px',
		paddingRight: 17,
		paddingTop: 2
	},
	navigateButton: {
		margin: 'auto',
		width: 'fit-content',
		display: 'inline-flex',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	navigateToSiculicidium: {
		position: 'absolute',
		bottom: 72,
		right: 0
	},
	noteText: {
		fontFamily: 'Ubuntu',
		color: '#070C35',
		fontSize: '11px',
		letterSpacing: '-0.22px',
		lineHeight: '20px',
		marginTop: 4,
		marginRight: 18,
		marginBottom: 4,
		marginLeft: 16,
		fontWeight: 'bold'
	},
	noteContainer: {
		top: 624,
		position: 'absolute',
		background: '#E0B080',
		opacity: 1,
		right: 0,
		[theme.breakpoints.only('xs')]: {
			top: 800
		},
		[theme.breakpoints.only('lg')]: {
			top: 524
		},
		[BP380]: {
			top: 860
		}
	},
	overflow: {
		[theme.breakpoints.down('md')]: {
			height: '20vh'
		}
	}
}));

function MonumentHistory6(props) {
	const classes = useStyles();
	const { t } = props;
	const [showAnimation, setShowAnimation] = useState(props.showAnimation);
	useEffect(() => {
		if (props.showAnimation) {
			const timeOut = setTimeout(() => {
				setShowAnimation(props.showAnimation);
			}, 300);
			return () => clearTimeout(timeOut);
		}
		setShowAnimation(props.showAnimation);
	}, [props.showAnimation]);
	return (
		<>
			<Box
				height={showAnimation && '100vh'}
				className={classes.pageContainer}
				style={{ zIndex: showAnimation ? 200 : 199 }}>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.contentContainer} />
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.imageContainer} />
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.halfCircleContainer}>
						<HomeScreenHalfCircle width='100%' height='100%' fill='#E0B080' />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.mainTitleBox}>
						<Typography className={classes.mainTitleText}>
							SICVLICIDIVM
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='bttmiddle'
					unmountOnExit>
					<Box className={classes.descriptionContainer}>
						<Typography className={classes.descriptionText}>
							<Trans i18nKey='monumhistory6.description1' />
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='bttmiddle'
					unmountOnExit>
					<Box className={classes.descriptionContainer2}>
						<Typography className={classes.descriptionText}>
							<Trans i18nKey='monumhistory6.description2' />
						</Typography>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box className={classes.animatedCircles}>
						<Box className={classes.halfStripes}>
							<StraightStripes width='100%' height='100%' />
						</Box>
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='ltrlong'
					unmountOnExit>
					<Box
						className={classes.nextButton}
						onClick={() => {
							props.prev();
						}}>
						<ChevronRight height={24} width={24} fill='#070C35' />
					</Box>
				</CSSTransition>
				<CSSTransition
					in={showAnimation}
					timeout={300}
					classNames='rtllong'
					unmountOnExit>
					<Box className={classes.noteContainer}>
						<Typography className={classes.noteText}>
							{t('monumhistory6.note')}
						</Typography>
					</Box>
				</CSSTransition>
			</Box>
			<Box className={classes.overflow} />
		</>
	);
}

export default withNamespaces('monumentHistory')(withRouter(MonumentHistory6));
