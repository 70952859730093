import React from 'react';
import {
	Box,
	Button,
	InputBase,
	makeStyles,
	Typography,
	useMediaQuery
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRighIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';

const BP1 = '@media (max-width: 690px)';
const BP2 = '@media (max-width: 380px)';

const useStyles = makeStyles((theme) => ({
	buttonBox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 50,
		paddingLeft: 24,
		paddingRight: 24,
		boxSizing: 'border-box'
	},
	button: {
		width: 200,
		color: theme.palette.primary.contrastText,
		transition: 'width .3s',
		'&:hover': {
			backgroundColor: '#48B3C9'
		},
		'&:disabled': {
			backgroundColor: '#FAFAF5'
		},
		[BP1]: {
			width: 60
		}
	},
	pageNrContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.dark,
		fontSize: '14px',
		fontFamily: 'Ubuntu'
	},
	pageNrInput: {
		width: 40,
		height: 40,
		borderWidth: 1,
		borderColor: '#D8E1E699',
		backgroundColor: '#F0F0EB',
		fontFamily: 'Ubuntu',
		fontSize: '16px',
		color: theme.palette.primary.dark,
		marginLeft: 10,
		marginRight: 4,
		transition: 'all .3s',
		[BP2]: {
			marginLeft: 0
		}
	}
}));

const Pagination = ({
	page,
	setPage,
	handlePageChange,
	currentPage,
	totalPages,
	t,
	pushPage
}) => {
	const classes = useStyles();
	const isMobileView = useMediaQuery('(max-width: 690px)');
	const isSmall = useMediaQuery('(max-width: 380px)');
	return (
		<Box className={classes.buttonBox}>
			<Button
				className={classes.button}
				color='primary'
				startIcon={isMobileView ? null : <KeyboardArrowLeftIcon />}
				onClick={() => pushPage(false)}
				disabled={currentPage === 1}>
				{isMobileView ? <KeyboardArrowLeftIcon /> : t('gazette:previous-page')}
			</Button>
			<Box className={classes.pageNrContainer}>
				{!isSmall && <Typography>{t('gazette:current-page')}</Typography>}
				<Box
					component={InputBase}
					className={classes.pageNrInput}
					value={page}
					inputProps={{
						min: 0,
						style: { textAlign: 'center' }
					}}
					borderWidth={1}
					onChange={(e) => setPage(e.target.value)}
					onKeyDown={(e) => handlePageChange(e)}
				/>
				<Typography> {`/\xa0 ${totalPages}`}</Typography>
			</Box>
			<Button
				className={classes.button}
				color='primary'
				endIcon={isMobileView ? null : <KeyboardArrowRighIcon />}
				disabled={currentPage >= totalPages}
				onClick={() => pushPage(true)}>
				{isMobileView ? <KeyboardArrowRighIcon /> : t('gazette:next-page')}
			</Button>
		</Box>
	);
};

/* eslint-disable react/forbid-prop-types */
Pagination.propTypes = {
	page: PropTypes.number.isRequired,
	setPage: PropTypes.any.isRequired,
	handlePageChange: PropTypes.any.isRequired,
	currentPage: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	t: PropTypes.any.isRequired,
	pushPage: PropTypes.any.isRequired
};

export default Pagination;
