import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getHotelById } from '../../../../../store/hotel/actions';

function Header(props) {
	const { match } = props;
	useEffect(() => {
		getHotelById(match.params.id);
	}, []);

	return <></>;
}
export default withNamespaces('hotel')(withRouter(Header));
