/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './lib/i18n';
import { ThemeProvider } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Provider } from 'react-redux';
import theme from './themes';
import store from './store/store';
import * as serviceWorker from './serviceWorker';
import App from './App';
import themeMadefalva from './themes/indexMadefalva';

const isOffice = process.env.REACT_APP_IS_OFFICE === 'true';
const page = isOffice ? 'office' : 'lives';

if (!isOffice) {
	document.body.style = 'background: #DDE9E6;';
}
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ThemeProvider theme={isOffice ? theme : themeMadefalva}>
				<MuiThemeProvider theme={isOffice ? theme : themeMadefalva}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<App page={page} />
					</MuiPickersUtilsProvider>
				</MuiThemeProvider>
			</ThemeProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);

serviceWorker.unregister();
